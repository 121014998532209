import * as CryptoJS from 'crypto-js';


export const isEmpty = (value: any) => {
  return value === undefined || value === null || value === '';
};

export const encrypt = (value: string): string => {
  return CryptoJS.AES.encrypt(
    value,
    process.env.REACT_APP_ENCRYPTION_KEY as string
  ).toString();
};

export const decrypt = (encryptedStr: string) => {
  return CryptoJS.AES.decrypt(
    encryptedStr,
    process.env.REACT_APP_ENCRYPTION_KEY as string
  ).toString(CryptoJS.enc.Utf8);
};

// Function to compare resources start times and return in ascending order
export function compareStartTimeAsc(a:any, b:any) {
  const startTimeA = new Date(a.startTime);
  const startTimeB = new Date(b.startTime);

  if (startTimeA < startTimeB) {
    return -1;
  } else if (startTimeA > startTimeB) {
    return 1;
  } else {
    return 0;
  }
}
// Function to compare resources start times and return in descending order
export function compareStartTimeDesc(a:any, b:any) {
  const startTimeA = new Date(a.startTime);
  const startTimeB = new Date(b.startTime);

  if (startTimeB < startTimeA) {
    return -1;
  } else if (startTimeB > startTimeA) {
    return 1;
  } else {
    return 0;
  }
}
// Function to convert time from db to exact time stored
export function convertUTCToTime(utcTimestamp: any) {
  const date = new Date(utcTimestamp);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  
  let period = 'AM';
  let formattedHours = hours;

  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) {
      formattedHours = hours - 12;
    }
  }

  const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  return formattedTime;
}

// get date and time e.g September 12, 12pm
export function convertUTCToDateTime(utcTimestamp: any) {
  const months = [
    "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
    "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
  ];

  const date = new Date(utcTimestamp);
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  let period = 'AM';
  let formattedHours = hours;

  if (hours >= 12) {
    period = 'PM';
    if (hours > 12) {
      formattedHours = hours - 12;
    }
  }

  const formattedDateTime = `${month} ${day}, ${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  return formattedDateTime;
}

// date function e.g OCT 12, 2023
export function convertUTCToDate(utcTimestamp: any) {
  const months = [
    "JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE",
    "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"
  ];

  const date = new Date(utcTimestamp);
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear(); // Get the year

  const formattedDateTime = `${month.slice(0, 3)} ${day}, ${year}`;
  return formattedDateTime;
}

export const isHexColorBright = (color: string) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 2), 16);
  const c_g = parseInt(hex.substring(2, 4), 16);
  const c_b = parseInt(hex.substring(4, 6), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
};

export const isValidDate = (date: Date | string) => {
  date = new Date(date);
  return (
    date instanceof Date && !isNaN(Date.parse((date as unknown) as string))
  );
};

export const getURLWithQueryParams = (
  base: string,
  params: Record<string, string>
) => {
  const query = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return `${base}?${query}`;
};

export const formatAmount = (amount: number, precision: number = 2) => {
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }

  if (isNaN(amount)) {
    return '0.00';
  }

  const options = {
    style: 'currency',
    currency: 'USD',
    // minimumFractionDigits: 2,
    ...{
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  };

  return new Intl.NumberFormat('en-US', options).format(amount);
};

export const formatNumber = (figure: number, precision?: number) => {
  if (typeof figure === 'string') {
    figure = parseFloat(figure);
  }

  if (isNaN(figure)) {
    return '0.00';
  }

  return new Intl.NumberFormat(
    'en-US',
    precision ? { minimumFractionDigits: precision } : {}
  ).format(figure);
};

export const constructDateFormat = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export function formatDate(date: Date): string {
  date = date instanceof Date ? date : new Date(date);
  const month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  return `${month}/${day}/${date.getFullYear()}`;
}

export const convertFileSizeFromBytes = (size: number) => (
  unit: 'kb' | 'mb'
) => {
  if (unit === 'kb') {
    return (size / 1024).toFixed(2) + ' Kb';
  }

  return (size / 1024 / 1024).toFixed(2) + ' Mb';
};

export function formatFileSize(size: number) {
  if (size < 1024) {
    return `${size} bytes`;
  } else if (size >= 1024 && size < 1048576) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else if (size >= 1048576) {
    return `${(size / 1048576).toFixed(2)} MB`;
  }
}

export const isValidFileType = (file: File, allowedTypes: string) => {
  if (!file) return false;
  const fileType = file.type;
  const allowed = allowedTypes
    .split(',')
    .map((fileType) => fileType.trim())
    .find((type) => fileType.includes(type));
  return allowed !== undefined;
};

export const isValidFileSize = (file: File, maxSize: number) => {
  if (!file) return false;
  return file.size! / 1024 / 1024 <= maxSize;
};

export const resolveErrorMessage = (error: boolean) => (message: string) => {
  if (error) {
    return message;
  }

  return undefined;
};

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden';
  } else {
    return 'hidden';
  }
}

export function getIsDocumentHidden() {
  return !(document as any)[getBrowserDocumentHiddenProp()];
}

export const parseAmount = (amount: string) => {
  amount = amount.replace(/,/g, '').trim();
  return amount;
};

export const sortListByIdAsc = (list: any[]): any[] => {
  const sortedList = list.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }

    if (a.id > b.id) {
      return 1;
    }

    return 0;
  });

  return sortedList;
};

export function getRandomItemFromArray<T>(list: T[]): T {
  return list[Math.floor(Math.random() * list.length)];
}

export function getMultipleRandomItemsFromArray<T>(list: T[], count: number) {
  const randomItems: T[] = [];
  // for (let i = 0; i < 3; i++) {
  // }

  while (randomItems.length < count) {
    const rand = list[Math.floor(Math.random() * list.length)];

    if (!randomItems.includes(rand)) {
      randomItems.push(rand);
    }
  }

  return randomItems;
}

export const checkInvalidArray = <T>(arr: Array<T>): T[] => {
  if (!arr || !Array.isArray(arr)) {
    return [];
  }

  return arr;
};

// for free memberships
export const isFreeMembership = (price: number): boolean => {
  try {
    if (isNaN(price)) {
      throw new Error();
    }
  } catch (error) {
    console.error('price should be of type number');
  }

  if (price === 0) {
    return true;
  }

  return false;
};

// for memberships that require customers contact us to get a quote
export const isMembershipPriceless = (price: number) => {
  try {
    if (isNaN(price)) {
      throw new Error();
    }
  } catch (error) {
    console.error('price should be of type number');
  }

  if (price === Infinity) {
    return true;
  }

  return false;
};

export const formatEmailForStorage = (email: string): string => {
  return String(email).toLowerCase().trim();
};

export function debug(message: string): void {
  var debugMessage = document.getElementById('debug-message');
  console.log('DEBUG: ', message);
  (debugMessage as HTMLElement).innerText += "\n" + message;
}

export function filterDataBySlug(data:any, targetCateg:any) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data by the targetCateg
  const filteredData = data.filter((item) => item.slug === targetCateg);
  return filteredData;
}


interface DataItem {
  [key: string]: any; // You can refine this to match your data structure
}

export const sortByCreatedAtDescending = (
  data: DataItem[],
  sortOption: string
): DataItem[] => {
  return data.sort((a, b) => {
    const dateA = new Date(a[sortOption]);
    const dateB = new Date(b[sortOption]);
    return dateB.getTime() - dateA.getTime();
  });
};

export const sortByCreatedAtAscending = (
  data: DataItem[],
  sortOption: string
): DataItem[] => {
  return data.sort((a, b) => {
    const dateA = new Date(a[sortOption]);
    const dateB = new Date(b[sortOption]);
    return dateA.getTime() - dateB.getTime();
  });
};
