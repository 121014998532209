import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const PodSubscribe = () => {
  return (
    <React.Fragment>
      {/* Web */}

      <Stack
        className="podcast-component hidden md:block"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="h1"
          align="center"
          // textTransform="uppercase"
          fontSize="70px"
          color="#ffffff"
          paragraph
          letterSpacing="-0.06em"
          lineHeight="103.5%"
          className="md:block hidden"
          sx={{
            mb: 2,
            // paddingBottom: 5,
            paddingTop: 33,
          }}
        >
          Subscribe to the Podcast
        </Typography>

        <Typography
          variant="body1"
          fontFamily="Area-Normal-Black"
          textTransform="uppercase"
          color="#ffffff"
          fontSize="14px"
          letterSpacing="0.1em"
          lineHeight="230%"
          sx={{
            mb: 2,
            paddingBottom: 3,
            textAlign: "center",
          }}
        >
          Listen to the latest episode weekly
        </Typography>

        <Box
          className="flex justify-center items-center gap-5 "
          sx={{
            mb: 2,
            paddingBottom: 5,
          }}
        >
          <a
            onClick={() =>
              window.open(
                "https://open.spotify.com/show/54t1C1cw8TeS2UK4doDTL4"
              )
            }
            className="cursor-pointer"
          >
            <img
              className="w-36 h-[52px]"
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612804/spotify-pod_jyslea.png"
              alt=""
            />
          </a>
          <a
            onClick={() =>
              window.open(
                "https://podcasts.apple.com/us/podcast/mother-honestly-podcast/id1439395271"
              )
            }
            className="cursor-pointer"
          >
            <img
              className="w-48 h-[52px] "
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/apple-pod_yzrrsw.png"
              alt=""
            />
          </a>
          <a
            onClick={() =>
              window.open(
                "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL21vdGhlcmhvbmVzdGx5L2ZlZWQueG1s"
              )
            }
            className="cursor-pointer"
          >
            <img
              className="w-48 h-[52px] "
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/google-pod_eavovx.png"
              alt=""
            />
          </a>
        </Box>
      </Stack>

      {/* Mobile */}

      <Stack
        className="podmobile-component md:hidden block"
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="h1"
          align="center"
          // textTransform="capitalize"
          fontSize="55px"
          color="#ffffff"
          paragraph
          letterSpacing="-0.06em"
          lineHeight="103.5%"
          className="block md:hidden"
          sx={{
            mb: 2,
            mt: 10,
            // paddingBottom: 5,
            paddingTop: 15,
          }}
        >
          Subscribe to <br /> the Podcast
        </Typography>

        <Typography
          variant="body1"
          fontFamily="Area-Normal-Black"
          textTransform="uppercase"
          color="#ffffff"
          fontSize="14px"
          letterSpacing="0.1em"
          lineHeight="230%"
          sx={{
            mb: 2,
            paddingBottom: 3,
            textAlign: "center",
          }}
        >
          Listen to the latest episode <br className="block md:hidden" /> every
          Wednesday
        </Typography>

        <Box
          className=" md:flex items-center gap-5"
          sx={{
            mb: 2,
            paddingBottom: 5,
          }}
        >
          <a
            onClick={() =>
              window.open(
                "https://open.spotify.com/show/54t1C1cw8TeS2UK4doDTL4"
              )
            }
            className="cursor-pointer mb-12"
          >
            <img
              className="w-36 h-[52px] mx-auto md:mx-0 my-4 md:my-0"
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612804/spotify-pod_jyslea.png"
              alt=""
            />
          </a>
          <a
            onClick={() =>
              window.open(
                "https://podcasts.apple.com/us/podcast/mother-honestly-podcast/id1439395271"
              )
            }
            className="cursor-pointer"
          >
            <img
              className="w-48 h-[52px] mx-auto md:mx-0 my-4 md:my-0"
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/apple-pod_yzrrsw.png"
              alt=""
            />
          </a>
          <a
            onClick={() =>
              window.open(
                "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL21vdGhlcmhvbmVzdGx5L2ZlZWQueG1s"
              )
            }
            className="cursor-pointer"
          >
            <img
              className="w-48 h-[52px] mx-auto md:mx-0 my-4 md:my-0"
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/google-pod_eavovx.png"
              alt=""
            />
          </a>
        </Box>
      </Stack>
    </React.Fragment>
  );
};

export default PodSubscribe;
