import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MHButton from '../../Common/Button/MHButton';
import useDialog from '../../../hooks/use-dialog';
import StaticDataContext from '../../../store/context/static-data.context';
import geoData from '../../../data/georef-united-states-of-america-state.json';
import AppContext from '../../../store/context/app.context';

// type Props = {}

const CareFundSection = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/employers`);
  };

  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
    closeWaitlist();
  };

  const dataCtx = React.useContext(StaticDataContext);
  const { openWaitlist, closeWaitlist } = dataCtx;

  const appCtx = React.useContext(AppContext);
  const { states } = appCtx;

  React.useEffect(() => {
    if (openWaitlist) {
      handleOpen();
    }
  }, [openWaitlist]);

  return (
    <React.Fragment>
      <Box className="pt-20">
        <Box className="items-center place-content-center ">
          {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
            THE ENTERPRISE PLAN
          </Typography> */}
          <Typography
            variant="subtitle1"
            fontSize={{ xs: '12px', sm: '11px' }}
            lineHeight={'102%'}
            letterSpacing={'0.1em'}
            className="uppercase text-center">
            PROACTIVE CARE THAT PROTECTS
          </Typography>
          {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
            Join the many forward-
            <br className="md:hidden block" />
            thinking companies <br /> supporting their employee caregivers
          </Typography> */}
          <Typography
            variant="h2"
            width={{ xs: '90%', md: '65%' }}
            fontSize={{ xs: '30px', sm: '40px', md: '50px' }}
            lineHeight={'120.5%'}
            letterSpacing={'-0.04em'}
            mx="auto"
            className="py-8  text-center">
            Don’t wait until your employees feel overwhelmed with the burden of
            care and the financial stress that comes with it.
            {/* While companies spend money, time, and effort on providing benefits, */}
            {/* often those benefits are of little use to employees. By not offering
            benefits that employees actually want—and by not encouraging
            employees to use the benefits they do offer—companies incur millions
            of dollars of hidden costs due to employee turnover, loss of
            institutional knowledge, and temporary hiring, in addition to
            substantial productivity costs such as absenteeism and presenteeism.
            - The Caring Company, Harvard Business Review */}
          </Typography>

          {/* Web */}

          <Box
            width="75%"
            className="hidden md:flex items-center place-content-center mx-auto text-center">
            {/* <Typography className="ml-8 mr-8 py-8 font-areaSemi text-[14px] text-center md:text-[14px]  lg:text-[16px] leading-[170%] md:leading-[150%] tracking-[0.02em] text-navy-900 ">
              Personalized Employee Work- <br /> Life Care Solutions
            </Typography> */}
            <Typography
              variant="body1"
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'150%'}
              letterSpacing={'0.02em'}
              className="ml-8 mr-8 py-8  text-center  ">
              73% of Americans are living paycheck to paycheck and the rising
              cost of care and of goods and services have become untenable for
              many, especially marginalized groups.
            </Typography>
            &bull;
            <Typography
              variant="body1"
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'150%'}
              letterSpacing={'0.02em'}
              className="ml-8 mr-8 py-8  text-center ">
              Your company’s care wallet/fund connects your employees to the
              right money at the right time to meet their caregiving needs, and
              minimize disruptions to work and life.
            </Typography>
            &bull;
            <Typography
              variant="body1"
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'150%'}
              letterSpacing={'0.02em'}
              className="ml-8 py-8 text-center ">
              Your organization’s care wallet and fund is a strategic cash set
              aside to absorb your employees caregiving and life shocks. You can
              think of it as an employee relief fund too.
            </Typography>
          </Box>

          {/* Mobile */}

          <Box className="px-4 md:hidden block items-center place-content-center mx-auto text-center">
            <Typography
              variant="body1"
              fontSize={{ xs: '12px' }}
              lineHeight={'170%'}
              letterSpacing={'0.02em'}
              className=" py-3 text-center">
              73% of Americans are living paycheck to paycheck and the rising
              cost of care and of goods and services have become untenable for
              many, especially marginalized groups.{' '}
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: '12px' }}
              lineHeight={'170%'}
              letterSpacing={'0.02em'}
              className=" py-3 text-center">
              Your company’s care wallet/fund connects your employees to the
              right money at the right time to meet their caregiving needs, and
              minimize disruptions to work and life.
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: '12px' }}
              lineHeight={'170%'}
              letterSpacing={'0.02em'}
              className=" py-3 text-center">
              Your organization’s care wallet and fund is a strategic cash set
              aside to absorb your employees caregiving and life shocks. You can
              think of it as an employee relief fund too.
            </Typography>
          </Box>

          {/* <Box className="mx-auto text-center py-10 md:py-0">
            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={handleOpen}>
              Request a Demo
            </MHButton>
          </Box> */}
        </Box>

        <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300 mt-10" />
      </Box>
    </React.Fragment>
  );
};

export default CareFundSection;
