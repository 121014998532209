import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import MHSlider, {
  MHSliderItem
} from '../../Common/SliderCarousel/Slider/MHSlider';
import ContentCard from '../../UI/ContentCard';
import MHmarquee from '../../Common/Marquee/MHmarquee';

const WALLET_SLIDER_CONTENT = [
  {
    imageSrc:
      'https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Set+up+Your+Organization\'s+Care+Fund.png',
    title: 'Design Your Organization’s Wallet',
    description:
      'Set up your organization’s care wallet using our sophisticated financial technology and automated enrollment via your payroll or HRIS. '
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1688473369/Website_Images_-_How_It_Works_-_Care_Wallet_2_cyhhzd.png',
    title: 'Employees Onboard',
    description:
      'Employees onboard their personalized dashboard and connect their preferred bank account for timely reimbursement of transactions or requests.'
  },
  {
    imageSrc:
      'https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Automatic+Care+Reimbursements.png',
    title: 'Automatic Care Reimbursements',
    description:
      'MH automatically screens for care expenses and flag for reimbursement. Use our proprietary Care At Work Insights to optimize your contributions.'
  }
];

const CareWalletHeroSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={7}>
        <Typography
          variant="h1"
          fontSize={{ xs: '30px', sm: '40px', md: '50px' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          THE CARE WALLET
        </Typography>
        {/* <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '100%' }}
          fontSize={{ sm: '14px', md: '16px' }}
          lineHeight={'190%'}
          letterSpacing={'0.02em'}
          align="center"
          mx="auto"
          paragraph>
          The Care Fund is an emergency relief program to provide support to
          employees facing significant caregiving needs. The fund aims to
          alleviate financial burdens and provide assistance to employees who
          are experiencing high intensity caregiving challenges that may affect
          their physical, emotional, and financial well-being. These challenges
          may arise due to various reasons, such as caring for family members
          with serious medical conditions, supporting children with special
          needs, or handling unexpected emergencies related to childcare.
        </Typography> */}
      </Box>

      <Box py="3rem" px={{ xs: 0, md: '4rem' }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {WALLET_SLIDER_CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>

      {/* <Typography
        variant="body1"
        fontFamily="Area-Extended"
        textTransform="uppercase"
        textAlign="center"
        color="#194049"
        fontSize={12}
        letterSpacing="0.1em"
        sx={{
          mt: 0
        }}>
        Top Companies Trust MH
      </Typography>

      <MHmarquee /> */}

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={5}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', md: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          An Array Of Care Solutions Throughout Your Employees Working Lives
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Many Employees Are Being Crushed Under The Unrelenting and Unexpected
          Burden Of Care, It’s Time Your Benefits Care
        </Typography>
      </Box>

      {!matchesMdDown && (
        <Box py="3rem" px={'4rem'}>
          <MHSlider slidesToShow={3} slidesToScroll={1}>
            {CONTENT.map((item, index) => (
              <MHSliderItem width="100%" key={index}>
                <ContentCard
                  imageSrc={item.imageSrc}
                  title={item.title}
                  description={item.description}
                  onMouseClick={() => {}}
                />
              </MHSliderItem>
            ))}
          </MHSlider>
        </Box>
      )}

      {matchesMdDown && (
        <Grid
          container
          direction={{ xs: 'row' }}
          flexWrap="nowrap"
          rowSpacing={{ xs: 0, sm: 0 }}
          columnSpacing={3}
          mt={2}
          width={{ xs: '90%', sm: '80%' }}
          mx="auto"
          mb={8}
          overflow="auto"
          className="horizontal-scroll">
          {CONTENT.map((item) => (
            <Grid item xs={12} sm={4} flexShrink={0}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </Grid>
          ))}
        </Grid>
      )} */}
    </div>
  );
};

export default CareWalletHeroSlider;
