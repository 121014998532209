import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import MHDialog from "../Common/Dialog/MHDialog";
import MHFormControl from "../Common/Form/MHFormControl";
import MHButton from "../Common/Button/MHButton";
import useInput from "../../hooks/use-input";
import useHttp from "../../hooks/use-http";

import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import useDialog from "../../hooks/use-dialog";

import { resolveErrorMessage } from "../../utils/utils";
import * as validators from "../../utils/validators";
import StaticDataContext from "../../store/context/static-data.context";
import { ReactComponent as CloseIcon } from "../../static/svg/cancel.svg";
import { ReactComponent as ArrowRightIcon } from "../../static/svg/arrow-right.svg";
import { useHistory } from "react-router-dom";
import ResultIcon from "../../theme/icons/ResultIcon";
import { Stack } from "@mui/material";
import SearchField from "../Common/Form/SearchField";
import MHFormGroup from "../Common/Form/MHFormGroup";
import MHTextInput from "../Common/Form/MHTextInput";
import RequestConfirmationPrompt from "../Contact/RequestConfirmation";
import { getCookie, setCookie } from "../../hooks/use-cookie";

const SubscribeNewsDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [subscribed, setSubscribed] = React.useState(false);

  const dataCtx = React.useContext(StaticDataContext);
  const { states } = dataCtx;

  // const notificationCtx = React.useContext(Notifica)

  const [completed, setCompleted] = React.useState(false);

  const [error, setError] = React.useState(false);
  // const { loading, error, sendHttpRequest } = useHttp();

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  const handleCookies = () => {
    // Getting the value of the cookie
    const existingCookie = getCookie("_mhCookie");

    if (!existingCookie) {
      // Setting the cookie vlaue
      setCookie("_mhCookie", enteredWorkEmail, 30);
      console.log("Cookie set successfully");
    } else {
      console.log("Cookie already exists");
    }
  };

  const errorMail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(true);
  };

  const flodeskSubmit = async () => {
    // e.preventDefault();
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "The Care Gap (www.thecaregap.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // first_name: firstName,
          // last_name: lastName,
          email: enteredWorkEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
        }),
      });
      flodeskSegmentSubmit();
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["651595e5b92f35cecbc05c82"]; // Replace with actual segment IDs
    // const testsegmentIds = ["64edd24f139c9cfad37e4808"]; // Replace with actual segment IDs
    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredWorkEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredWorkEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "The Care Gap (www.thecaregap.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const newsLetterHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "newsletter-subscriptions"), {
      NewsLetterEmail: enteredWorkEmail,
      createdAt: serverTimestamp(),
    });
    handleCookies();
    handleOpenDialog();
    flodeskSubmit();
    setTimeout(() => {
      onClose();
    }, 3000);
    resetForm();
    setError(false);

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const resetForm = () => {
    workEmailInputChangeHandler("");
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={!completed ? "" : " "}
        handleClose={onClose}
        scroll="paper"
        actions={null}
        maxWidth={completed ? "xs" : "sm"}
        fullWidth
        disablePadding
      >
        {!completed ? (
          <Box
            component={"div"}
            // onSubmit={submitHandler}
            id="contact-demo"
          >
            <Stack
              className="subscribe-dialog"
              justifyContent="center"
              alignItems="center"
              sx={{
                "& svg": {
                  color: "common.white",
                },
                "& button:hover svg": {
                  color: "primary.main",
                },
              }}
            >
              <MHButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  background: "transparent",
                  ":hover": "none",
                }}
                onClick={onClose}
                className="nohova text-[12px] leading-[102%] w-full tracking-[0.05em] h-[52px] "
              >
                <CloseIcon />
              </MHButton>
              <Typography
                variant="body1"
                fontFamily="Area-Normal-Black"
                textTransform="uppercase"
                color="#fff"
                fontSize={{ xs: "11px", sm: "12px", md: "12px" }}
                lineHeight={{ xs: "230%", sm: "102%" }}
                letterSpacing={"0.1em"}
                sx={{
                  mb: 2,
                  paddingBottom: 3,
                }}
              >
                Close The Care Gap
              </Typography>

              <Typography
                variant="h1"
                align="center"
                paragraph
                color="#fff"
                fontSize={{ xs: "20px", sm: "25px", md: "30px" }}
                lineHeight={{ xs: "140%", sm: "143.5%" }}
                letterSpacing={{ xs: "-0.02em", sm: "-0.04em" }}
                sx={{
                  mb: 2,
                  paddingBottom: 5,
                }}
              >
                Transform how you care, at work <br /> and at home. Subscribe
                for essential <br /> data, insights, and solutions.
              </Typography>

              <MHFormGroup
                onSubmit={
                  validators.email(enteredWorkEmail) === true
                    ? newsLetterHandler
                    : errorMail
                }
                disableWhileSubmitting={
                  validators.email(enteredWorkEmail) === true
                    ? httpState.loading
                    : false
                }
              >
                <Box
                  component={"div"}
                  width={200}
                  display="flex"
                  bgcolor={"#F1F1F1"}
                  // sx={{
                  //   width: "380px",
                  // }}
                >
                  <MHTextInput
                    id="email"
                    type="email"
                    placeholder="Enter email address"
                    value={enteredWorkEmail}
                    onChange={workEmailInputChangeHandler}
                    onBlur={workEmailInputBlurHandler}
                    required
                    className="flex-grow h-fit"
                  />

                  <MHButton
                    type="submit"
                    color="secondary"
                    // loading={httpState.loading}
                    sx={{
                      minWidth: "auto",
                      "& svg": {
                        stroke: "grey.500",
                        width: "1rem",
                      },
                      "&.MuiButton-root:hover svg": {
                        stroke: "primary",
                      },
                    }}
                  >
                    {<ArrowRightIcon />}
                  </MHButton>
                </Box>

                {error === false ? (
                  ""
                ) : enteredWorkEmail !== "" ? (
                  ""
                ) : (
                  <Typography
                    variant="body2"
                    color="#fff"
                    fontSize={{ xs: "11px", sm: "12px", md: "12px" }}
                    lineHeight={{ xs: "230%", sm: "102%" }}
                    letterSpacing={"0.1em"}
                    sx={{
                      mb: 2,
                      pt: 1,
                      paddingBottom: 3,
                    }}
                  >
                    Enter a valid email address
                  </Typography>
                )}
              </MHFormGroup>

              {openDialog && (
                <RequestConfirmationPrompt
                  open={openDialog}
                  onClose={handleCloseDialog}
                  header="Subscription Successful!"
                  message="Thank you for subscribing on The Care Gap weekly newsletter. We will be in touch with
          you shortly."
                />
              )}
            </Stack>
          </Box>
        ) : (
          ""
        )}
      </MHDialog>
    </React.Fragment>
  );
};

export default SubscribeNewsDialog;
