import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Label from "./Label";

interface CheckboxItem {
  label: string;
  value: string;
}

interface CheckboxGroupProps {
  label?: string;
  className?: string;
  checkboxes: CheckboxItem[];
  onCheckboxChange: (checkedValues: string[]) => void;
}

export default function CheckboxGroup({
  label,
  className,
  checkboxes,
  onCheckboxChange,
}: CheckboxGroupProps) {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  const handleCheckboxChange =
    (value: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCheckedValues = event.target.checked
        ? [...checkedValues, value]
        : checkedValues.filter((item) => item !== value);

      setCheckedValues(newCheckedValues);
      onCheckboxChange(newCheckedValues);
    };

  return (
    <FormGroup className={className}>
      {label && <Label>{label}</Label>}
      {checkboxes.map((checkbox, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={checkedValues.includes(checkbox.value)}
              onChange={handleCheckboxChange(checkbox.value)}
            />
          }
          label={checkbox.label}
        />
      ))}
    </FormGroup>
  );
}
