import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MHDialog from '../../components/Common/Dialog/MHDialog';
import MHButton from '../../components/Common/Button/MHButton';


const MemberUpgradeDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/individuals`);
  };

  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={' '}
        handleClose={undefined}
        scroll="paper"
        actions={null}
        maxWidth={'sm'}
        fullWidth>
        <Box
          id="contact-demo"
          paddingX={{ xs: 2, sm: 6 }}
          paddingY={{ xs: 1, sm: 4 }}
          m={'auto'}>
          <Typography
            variant="h2"
            fontSize={{ xs: '24px', sm: '28px', md: '30px' }}
            lineHeight={{ xs: '142%', sm: '143.5%' }}
            letterSpacing={'-0.04em'}
            className="capitalize mx-auto text-center"
            justifyContent="center"
            alignItems="center"
            textAlign={'center'}
            paddingBottom={4}>
            Upgrade your plan to view this content.
          </Typography>

          <MHButton
            fullWidth
            sx={{ paddingY: 2, marginY: 2 }}
            onClick={() => handleClickOpen()}
            className=" text-[12px] leading-[102%] w-full tracking-[0.05em] h-[52px] ">
            View Memberships
          </MHButton>
        </Box>
      </MHDialog>
    </React.Fragment>
  );
};

export default MemberUpgradeDialog;
