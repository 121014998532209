import React from 'react';
import Slider from 'react-slick';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import MHButton from '../components/Common/Button/MHButton';
import Marquee, { MarqueeItem } from '../components/UI/Marquee';
import ContentCard from '../components/UI/ContentCard';
import JoinWaitlist from '../components/Features/Employers/JoinWaitlist';

import { ReactComponent as BounteousLogo } from '../static/svg/bounteous.svg';
import { ReactComponent as IndeedLogo } from '../static/svg/indeed.svg';
import { ReactComponent as AMNLogo } from '../static/svg/amn-healthcare.svg';
import { ReactComponent as MMGYLogo } from '../static/svg/mmgy.svg';
import { ReactComponent as MercuryLogo } from '../static/svg/mercury.svg';
import { ReactComponent as DeloitteLogo } from '../static/svg/deloitte.svg';
import { ReactComponent as ChildCareIcon } from '../static/svg/categories/child-care.svg';
import { ReactComponent as MedicalTravelIcon } from '../static/svg/categories/medical-travel.svg';
import { ReactComponent as ElderCareIcon } from '../static/svg/categories/elder-care.svg';
import { ReactComponent as HouseholdIcon } from '../static/svg/categories/household.svg';
import { ReactComponent as PetCareIcon } from '../static/svg/categories/pet-care.svg';
import { ReactComponent as CoWorkingIcon } from '../static/svg/categories/co-working.svg';
import { ReactComponent as SelfCareIcon } from '../static/svg/categories/self-care.svg';
import { ReactComponent as WorkLifeIcon } from '../static/svg/categories/work-life.svg';
import { ReactComponent as PlugLgIcon } from '../static/svg/plus-lg.svg';
import { ReactComponent as EBNLogo } from '../static/svg/featured/ebn.svg';
import { ReactComponent as FastCompanyLogo } from '../static/svg/featured/fast-company.svg';
import { ReactComponent as ForbesLogo } from '../static/svg/featured/forbes.svg';
import { ReactComponent as TechCrunchLogo } from '../static/svg/featured/tech-crunch.svg';
import { ReactComponent as WSJLogo } from '../static/svg/featured/wsj.svg';

import StaticDataContext from '../store/context/static-data.context';
import geoData from '../data/georef-united-states-of-america-state.json';
import Drawer from '@mui/material/Drawer';
import Subscribe from '../components/Features/Employers/Subscribe';
import RoundedLogoIcon from '../theme/icons/RoundedLogo';
import { ReactComponent as LeftBtn } from '../static/svg/left-btn.svg';
import { ReactComponent as RightBtn } from '../static/svg/right-btn.svg';
import MHSlider, {
  MHSliderItem
} from '../components/Common/SliderCarousel/Slider/MHSlider';
import FAQ from '../components/Features/Employers/FAQ';
import FollowMH from '../components/Landing/FollowMH';
import MHSubscribe from '../components/Layout/MHSubscribe';
import MHFooter from '../components/Layout/MHFooter';

import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import EnterprisePlan from '../components/Landing/EnterprisePlan';
import HeroSlider from '../components/Landing/HeroSlider';
import useDialog from '../hooks/use-dialog';
import CaringApproachSection from '../components/Features/Employers/CaringApproachSection';
import EmployersHeroSlider from '../components/Features/Employers/EmployersHeroSlider';
import MembershipPlans from '../components/Features/Memberships/MembershipPlans';
import MembershipFeatures from '../components/Features/Memberships/MembershipFeatures';
import { useHistory } from 'react-router-dom';
import useTitle from '../hooks/use-title';
import useAnalytics from '../hooks/use-analytics';

type CareCategoryProps = {
  icon: React.ReactElement;
  title: string;
  color: string;
  amount: number;
  value: number;
};

const CONTENT = [
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1676441391/Image_16_jwcjag.png',
    title: 'Self and Wellbeing',
    description:
      'Transit, co-working spaces, fitness, food, tampon, mental wellness, pets, games, financial wellness, and other unforeseen expenses etc.'
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1676441388/Image_17_o5vpwr.png',
    title: 'Household Operational Excellence',
    description:
      'Help with housekeeping, laundry, lawn mowing, home office set-ups, etc.'
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1665674645/Image_4_zzfq8h.png',
    title: 'Family & Relationships',
    description:
      'daycare, babysitter, nanny, summer camp, baby formula, diapers, care for aging parent, relatives or family of choice, pet care, pet sitting'
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1676441391/Image_16_jwcjag.png',
    title: 'Household Operational Excellence',
    description:
      'Help with housekeeping, laundry, lawn mowing, home office set-ups, etc.'
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1676441388/Image_17_o5vpwr.png',
    title: 'Family & Relationships',
    description:
      'daycare, babysitter, nanny, summer camp, baby formula, diapers, care for aging parent, relatives or family of choice, pet care, pet sitting'
  }
];

const CARE_FEATURES = [
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_6_kmmdg4.png',
    caption: 'Financial Wellbeing'
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_7_u5cayk.png',
    caption: 'Ongoing Connection'
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_8_zihrf3.png',
    caption: 'Coaching and Mental Health'
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_9_c8qgdy.png',
    caption: 'Resources and Concierge'
  }
  // {
  //   imageSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_6_kmmdg4.png',
  //   caption: 'Child care'
  // },
  // {
  //   imageSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_9_c8qgdy.png',
  //   caption: 'Household Management'
  // },
  // {
  //   imageSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_8_zihrf3.png',
  //   caption: 'Co-working'
  // },
  // {
  //   imageSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_7_u5cayk.png',
  //   caption: 'Return-to-work'
  // }
];

const CARE_CATEGORIES: CareCategoryProps[] = [
  {
    icon: <ChildCareIcon />,
    title: 'Child Care',
    color: '#817EB9',
    amount: 3100,
    value: 13
  },
  {
    icon: <ElderCareIcon />,
    title: 'Elder Care',
    color: '#D49C9C',
    amount: 2900,
    value: 10
  },
  // {
  //   icon: <FoodIcon />,
  //   title: 'Food',
  //   color: '#F9BA52',
  //   amount: 2100,
  //   value: 9
  // },
  {
    icon: <PetCareIcon />,
    title: 'Pet Care',
    color: '#AFCEA0',
    amount: 700,
    value: 4
  },
  {
    icon: <HouseholdIcon />,
    title: 'Household',
    color: '#DADAF2',
    amount: 1100,
    value: 7
  },
  // {
  //   icon: <CoWorkingIcon />,
  //   title: 'Co-Working',
  //   color: '#A0BDD2',
  //   amount: 1700,
  //   value: 8
  // },
  {
    icon: <SelfCareIcon />,
    title: 'Self Care',
    color: '#A0BDD2',
    amount: 1700,
    value: 8
  },
  {
    icon: <WorkLifeIcon />,
    title: 'Work-Life',
    color: '#2A5D68',
    amount: 4200,
    value: 18
  },
  {
    icon: <MedicalTravelIcon />,
    title: 'Medical Travel',
    color: '#D8E3EC',
    amount: 2100,
    value: 9
  },
  {
    icon: <PlugLgIcon />,
    title: 'More',
    color: '#FFFC9B',
    amount: 2100,
    value: 9
  }
];

const CareFeature = (props: { imageSrc: string; caption: string }) => {
  return (
    <Stack alignItems="center">
      <img src={props.imageSrc} alt="care-feature" />

      <Typography variant="body2" align="center" my={3}>
        {props.caption}
      </Typography>
    </Stack>
  );
};

const CareCategory = (props: CareCategoryProps) => {
  return (
    <Stack spacing={2} p={{ xs: 1, md: 4 }}>
      <RoundedLogoIcon
        sx={{
          height: 70,
          width: 70,
          backgroundColor: props.color,
          '& svg': {
            color: '#ffffff',
            width: '3rem',
            height: '2rem'
          }
        }}>
        {props.icon}
      </RoundedLogoIcon>

      <Typography variant="body2" align="center" fontSize=".8rem">
        {props.title}
      </Typography>
    </Stack>
  );
};

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 lg:-right-8 md:-right-6">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 -left-8">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

export const MagazineFeature = (props: {
  title: string;
  content: string;
  imageSrc: string;
  inverted?: boolean;
}) => {
  return (
    <Grid
      container
      direction={{
        xs: 'column-reverse',
        md: props.inverted ? 'row-reverse' : 'row'
      }}
      mb={8}>
      <Grid item xs={12} md={6}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width={{ xs: '90%', md: '70%' }}
          mx="auto"
          height="100%"
          mt={{ xs: 3, md: 0 }}>
          <Typography
            variant="h1"
            fontSize={{ xs: '2rem', md: '2.4rem' }}
            width={{ xs: '100%', md: '90%' }}
            textTransform="capitalize"
            align="center"
            sx={{
              mb: 4
            }}
            paragraph>
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: '100%', md: '80%' }}
            fontSize="14px"
            align="center"
            lineHeight="180%"
            letterSpacing="0.02em"
            mx="auto"
            mb={5}>
            {props.content}
          </Typography>

          {/* <MHButton
        sx={{
          width: '169px'
        }}>
        Request a Demo
      </MHButton> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          width={{ xs: '100%', md: '100%' }}
          mx="auto"
          // p={{ xs: 3, md: 0 }}
          // pr={{ xs: 2, md: 10 }}
          // pl={2}
          sx={{
            '& img': {
              width: '100%',
              [props.inverted ? 'pl' : 'pr']: { xs: 2, md: 10 },
              [props.inverted ? 'pr' : 'pl']: 2
              // height: '500px'
            }
          }}>
          <img src={props.imageSrc} alt="WorkLife Dashboard" width="800" />
        </Box>
      </Grid>
    </Grid>
  );
};

const Employers = () => {
  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
    closeWaitlist();
  };

  const dataCtx = React.useContext(StaticDataContext);
  const { openWaitlist, closeWaitlist } = dataCtx;

  React.useEffect(() => {
    if (openWaitlist) {
      handleOpen();
    }
  }, [openWaitlist]);

  const settings = {
    centerMode: true,
    centerPadding: '0px',
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const history = useHistory();

  useTitle('For Employers');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'For Employers'
  });

  return (
    <React.Fragment>
      <Grid container direction={{ xs: 'column-reverse', md: 'row' }} mb={4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              '& > img': {
                height: 600,
                width: '100%',
                objectPosition: 'center',
                objectFit: 'cover'
              }
            }}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1688473369/F85899C8-2A59-4B54-8815-A41886ACCA37_wmf5qm.png"
              alt="Banner"
              width="700"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} bgcolor="#F3F4F6">
          <Stack
            height={600}
            justifyContent="center"
            alignItems="center"
            px={{ xs: 2, md: 10 }}
            sx={{
              '& > p': {
                textAlign: 'center',
                pointerEvents: 'none'
              }
            }}>
            <Typography
              variant="body1"
              fontFamily="Area-Extended"
              textTransform="uppercase"
              color="#194049"
              fontSize={10}
              letterSpacing="0.1em"
              lineHeight="102%"
              sx={{
                mb: 5
              }}
              paragraph>
              for Employers
            </Typography>

            <Typography
              variant="h1"
              fontSize={{ xs: '30px', sm: '40px', md: '50px' }}
              textTransform="capitalize"
              width="75%"
              sx={{
                mb: 4
              }}
              paragraph>
              Care Infrastructure For Today’s Modern Workforce
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color="#194049"
              width="75%"
              sx={{
                mb: 7
              }}
              paragraph>
              Unlock a resilient and productive workforce with our innovative
              care solutions. From personalized work-life tools to expert
              guidance and coverage for leave and care gaps, we're here to help
              your workforce navigate life's complexities with confidence and
              ease.
            </Typography>

            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={() => history.push('/contact')}>
              Request a Demo
            </MHButton>
          </Stack>
        </Grid>
      </Grid>

      <CaringApproachSection />

      <EmployersHeroSlider />

      <MHButton
        sx={{
          width: 'fit-content',
          display: 'block',
          mx: 'auto',
          mt: 5
        }}
        onClick={() => history.push('/contact')}>
        Request a Demo
      </MHButton>

      <Divider
        variant="middle"
        sx={{
          borderColor: 'primary.main',
          width: '91%',
          mx: 'auto',
          opacity: 0.1,
          my: 8
        }}
      />

      {/* <MembershipPlans />

      <Box mt={4} mb={8}>
        <MembershipFeatures theme="light" />
      </Box> */}

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" my={6}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', sm: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          Care Infrastructure Designed For All
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', sm: '55%' }}
          fontSize={'13px'}
          letterSpacing="0.02em"
          lineHeight="185%"
          align="center"
          mx="auto"
          paragraph>
          Personalized care solutions that support the mental, physical, and
          financial aspects of a distributed workforce.
        </Typography>
      </Box>

      <Grid
        container
        width={{ xs: '85%', sm: '93%' }}
        mx={{ xs: 'auto' }}
        mb={10}>
        {CARE_FEATURES.map((feature) => (
          <Grid item xs={12} sm={3}>
            <CareFeature
              imageSrc={feature.imageSrc}
              caption={feature.caption}
            />
          </Grid>
        ))}
      </Grid>

      <Divider
        variant="middle"
        sx={{
          borderColor: 'primary.main',
          width: '91%',
          mx: 'auto',
          opacity: 0.1,
          my: 8
        }}
      /> */}

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1689337928/A_New_Kind_Of_Care_Algorithm_oivrhv.png"
        content="Access workplace care and well-being insights, on-demand. MH WorkLife’s Insights Hub provides you on-demand access to key metrics on the caregiving responsibilities of your employees, where the pain points exist, and how your organization is directly reducing their care load for your workforce."
        title="A New Kind Of Care Algorithm"
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1676442922/Group_4_mot8dy.png"
        content="Your employees will have access to a personalized dashboard filled with content, resources, advisors, handpicked care merchants, company benefits, on-demand library of videos, podcasts, worksheets, toolkits, and guides to meet their personal and caregiving needs."
        title="Personalized WorkLife Care Dashboard"
        inverted
      />
      {/* https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Content_Community_x8qpn5.png */}
      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Fund_lhhgcf.png"
        content="Minimize productivity disruptions and support the well-being of your employees by providing an added layer of care to help them meet their caregiving and wellness responsibilities when they fall short. Whether it is helping to pay for an additional can of baby formula, or covering the additional tutoring needs of a teenage child falling behind in school, employees will appreciate you stepping in to care."
        title="An Additional Layer Of Protection For Care"
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Work_Care_Guidance_ll60bs.png"
        content="Our 1:1 Advisors are available 24/7 to provide on-demand guidance to address a wide range of topics that includes relationships, career, parenting, eldercare, adoption, return-to-work, and more. Providing your employees with proactive care that minimizes stress and disruption to work."
        title="MH WorkLife’s Advisors provide proactive and timely support"
        inverted
      />

      <MagazineFeature
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1685625848/Cover_The_Gap_gwekvt.png"
        content="By covering the care gap with qualified and vetted MH Care Gap Consultants, your company can foster a more supportive work environment that respects the personal care needs of employees, reduces the risk of delayed projects or missed opportunities, and  upholds the company’s reputation for reliability and stability without placing additional burden on existing employees."
        title="Uninterrupted Productivity"
      />

      {/* <Box px={{ xs: 3, md: 8 }} mb={6}>
        <FAQ />
      </Box> */}

      <FollowMH />

      <MHSubscribe />

      <MHFooter />

      <JoinWaitlist open={open} onClose={handleClose} />

      {/* 
          <Grid container direction={{ xs: 'column-reverse', md: 'row' }} mb={6}>
      <Grid item xs={12} md={6}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width={{ xs: '90%', md: '70%' }}
          mx="auto"
          height="100%"
          mt={{ xs: 3, md: 0 }}>
          <Typography
            variant="h1"
            fontSize={{ xs: '2rem', md: '2.4rem' }}
            width={{ xs: '100%', md: '90%' }}
            textTransform="capitalize"
            align="center"
            sx={{
              mb: 4
            }}
            paragraph>
            A New Kind Of Care Algorithm
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: '100%', md: '80%' }}
            fontSize="14px"
            align="center"
            lineHeight="180%"
            letterSpacing="0.02em"
            mx="auto"
            mb={5}>
            Access workplace care and well-being insights, on-demand. MH
            WorkLife’s Insights Hub provides you on-demand access to key metrics
            on the caregiving responsibilities of your employees, where the pain
            points exist, and how your organization is directly reducing their
            care load for your workforce.
          </Typography>

          <MHButton
        sx={{
          width: '169px'
        }}>
        Request a Demo
      </MHButton> 
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          width={{ xs: '100%', md: '100%' }}
          mx="auto"
          // p={{ xs: 3, md: 0 }}
          pr={{ xs: 2, md: 10 }}
          pl={2}
          sx={{
            '& img': {
              width: '100%'
              // height: '500px'
            }
          }}>
          <img
            src="https://res.cloudinary.com/mother-honestly/image/upload/v1676442922/Group_4_mot8dy.png"
            alt="Work-Life Dashboard"
            width="800"
          />
        </Box>
      </Grid>
    </Grid>
      
      
      <Grid container direction={{ xs: 'column', md: 'row' }} mb={3}>
      <Grid item xs={12} md={6}>
        <Box
          width={{ xs: '100%', md: '100%' }}
          mx="auto"
          // p={{ xs: 3, md: 0 }}
          pl={{ xs: 2, md: 10 }}
          pr={2}
          sx={{
            '& img': {
              width: '100%'
              // height: '500px'
            }
          }}>
          <img
            src="https://res.cloudinary.com/mother-honestly/image/upload/v1676443718/Image_18_fdsgnl.png"
            alt="Work-Life Dashboard"
            width="800"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width={{ xs: '90%', md: '70%' }}
          mx="auto"
          height="100%"
          mt={{ xs: 3, md: 0 }}>
          <Typography
            variant="h1"
            fontSize={{ xs: '2rem', md: '2.4rem' }}
            width={{ xs: '100%', md: '90%' }}
            textTransform="capitalize"
            align="center"
            sx={{
              mb: 4
            }}
            paragraph>
            Achieve Your Company Objective With MH
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: '100%', md: '80%' }}
            fontSize="14px"
            align="center"
            lineHeight="180%"
            letterSpacing="0.02em"
            mx="auto"
            mb={5}>
            We are the first solution to cover the full spectrum of workplace
            and life needs. From diversity and inclusion, to smarter spending,
            and retaining employees who want a better quality of life; we
            strengthen and demonstrate your commitment to your greatest asset
            - your people.
          </Typography>

          <MHButton
            sx={{
              width: '169px'
            }}>
            Request a Demo
          </MHButton>
        </Stack>
      </Grid>
    </Grid> */}

      {/* <Marquee continuous>
        <MarqueeItem>
          <IndeedLogo />
        </MarqueeItem>

        <MarqueeItem>
          <DeloitteLogo />
        </MarqueeItem>

        <MarqueeItem>
          <AMNLogo />
        </MarqueeItem>

        <MarqueeItem>
          <BounteousLogo />
        </MarqueeItem>

        <MarqueeItem>
          <MMGYLogo />
        </MarqueeItem>

        <MarqueeItem>
          <MercuryLogo />
        </MarqueeItem>
      </Marquee> */}

      {/* <Grid container mt={4}>
        <Grid item xs={12} md={6}>
          <Box width={{ xs: '100%', md: '80%' }} mx="auto" p={{ xs: 3, md: 6 }}>
            <img
              src="https://res.cloudinary.com/dgnjpsobb/image/upload/v1666287755/Screen_1_1_twuytx.png"
              alt="Work-Life Dashboard"
              width="700"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            justifyContent="center"
            width={{ xs: '90%', md: '80%' }}
            mx="auto"
            mt={{ xs: 2, md: 15 }}>
            <Typography
              variant="h1"
              fontSize={{ xs: '2rem', md: '2.4rem' }}
              textTransform="capitalize"
              align="center"
              sx={{
                mb: 4
              }}
              paragraph>
              Personalized Benefit Designed To Care
            </Typography>
            <Typography
              variant="body1"
              color="primary.main"
              width={{ xs: '100%', md: '90%' }}
              fontSize="0.9rem"
              align="center"
              mx="auto"
              paragraph>
              Employees link their debit card or bank account, and we screen and
              qualify work-life related expenses for reimbursement within
              seconds. They can also access care merchants and personalized care
              solutions within their work-life dashboard.
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Divider
        variant="middle"
        sx={{
          my: 5
        }}
        light
      />

      <Grid container my={6}>
        <Grid item xs={12} md={6}>
          <Stack
            justifyContent="center"
            width={{ xs: '90%', md: '80%' }}
            mx="auto"
            mt={{ xs: 3, md: 15 }}>
            <Typography
              variant="h1"
              fontSize={{ xs: '2rem', md: '2.4rem' }}
              textTransform="capitalize"
              align="center"
              sx={{
                mb: 4
              }}
              paragraph>
              A New Kind Of Care Algorithm
            </Typography>
            <Typography
              variant="body1"
              color="primary.main"
              width={{ xs: '100%', md: '90%' }}
              fontSize="0.9rem"
              align="center"
              mx="auto"
              paragraph>
              For the first time ever, employers have data and insights about
              the spectrum of care their employees provide, where the caregiving
              pain points exist, and how they are directly reducing the care
              load for their workforce.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width={{ xs: '100%', md: '80%' }} mx="auto" p={{ xs: 3, md: 6 }}>
            <img
              src="https://res.cloudinary.com/dgnjpsobb/image/upload/v1666076735/laptop_copy_nlmuud_ozivos.png"
              alt="Employer Insights"
              width="700"
            />
          </Box>
        </Grid>
      </Grid> */}

      {/* <Box my={10}>
        <Typography
          variant="body1"
          fontFamily="Area-Extended"
          textTransform="uppercase"
          textAlign="center"
          color="#194049"
          fontSize={12}
          letterSpacing="0.1em"
          lineHeight="102%"
          sx={{
            mb: 4
          }}
          paragraph>
          as featured in
        </Typography>

        <Stack
          direction="row"
          spacing={{ xs: 2, md: 10 }}
          alignItems="center"
          justifyContent="center"
          display={{ xs: 'none', md: 'flex' }}>
          <WSJLogo width="4rem" />
          <TechCrunchLogo width="6rem" />
          <ForbesLogo width="9rem" />
          <FastCompanyLogo width="20rem" />
          <EBNLogo />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          display={{ xs: 'flex', md: 'none' }}
          flexWrap="wrap">
          <Box px={2} py={2}>
            <WSJLogo width="4rem" />
          </Box>
          <Box px={2} py={2}>
            <TechCrunchLogo width="5rem" />
          </Box>
          <Box px={2} py={2}>
            <ForbesLogo width="9rem" />
          </Box>
          <Box px={2} py={2}>
            <FastCompanyLogo width="18.5rem" />
          </Box>
          <Box px={2} py={2}>
            <EBNLogo width="4rem" />
          </Box>
        </Stack>
      </Box>

      <Subscribe />

      <JoinWaitlist open={open} onClose={handleClose} />

      <Grid
        container
        spacing={{ xs: 3, sm: 4 }}
        width={{ xs: '85%', sm: '90%' }}
        mx={{ xs: 'auto' }}
        mb={6}>
        {CARE_FEATURES.map((feature) => (
          <Grid item xs={12} sm={3}>
            <CareFeature
              imageSrc={feature.imageSrc}
              caption={feature.caption}
            />
          </Grid>
        ))}
      </Grid>

      <Box width={{ xs: '90%', sm: '60%' }} mx="auto" my={6}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', sm: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          Personalized Benefit Designed To Care
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', sm: '90%' }}
          align="center"
          mx="auto"
          paragraph>
          Your employees can now meet an array of unplanned care needs, manage
          care disruptions, and deliver their best work
        </Typography>
      </Box>

      <Box width={{ xs: '90%', sm: '60%' }} mx="auto" my={4}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', sm: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 2
          }}
          paragraph>
          Give your employees the power to decide
        </Typography>

        <Stack direction="row" justifyContent="center" flexWrap="wrap">
          {CARE_CATEGORIES.map((category) => (
            <CareCategory {...category} />
          ))}
        </Stack>
      </Box> */}

      {/* <Grid
        container
        direction={{ xs: 'row' }}
        flexWrap="nowrap"
        rowSpacing={{ xs: 0, sm: 0 }}
        columnSpacing={3}
        mt={2}
        width={{ xs: '90%', sm: '80%' }}
        mx="auto"
        mb={8}
        overflow="auto"
        className="horizontal-scroll">
        {CONTENT.map((item) => (
          <Grid item xs={12} sm={4} flexShrink={0}>
            <ContentCard
              imageSrc={item.imageSrc}
              title={item.title}
              description={item.description}
              onMouseClick={() => {}}
            />
          </Grid>
        ))}
      </Grid> */}

      {/* <Grid container mb={3}>
        <Grid item xs={12} md={6}>
          <Stack
            justifyContent="center"
            width={{ xs: '90%', md: '80%' }}
            mx="auto"
            mt={{ xs: 3, md: 10 }}>
            <Typography
              variant="h1"
              fontSize={{ xs: '2rem', md: '2.4rem' }}
              textTransform="capitalize"
              align="center"
              sx={{
                mb: 4
              }}
              paragraph>
              A New Kind Of Care Algorithm
            </Typography>
            <Typography
              variant="body1"
              color="primary.main"
              width={{ xs: '100%', md: '90%' }}
              fontSize="0.9rem"
              align="center"
              mx="auto"
              paragraph>
              We combine care, technology, and human touchpoints to drive
              personalized care outcomes that supports a workplace where
              everyone flourish. See better retention, productivity, and
              employee engagement with MH. 
            </Typography>

            <MHButton >
              Request a Demo
            </MHButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width={{ xs: '100%' }} mx="auto" p={{ xs: 2 }}>
            <Stack direction="row" justifyContent="center" flexWrap="wrap">
              {CARE_CATEGORIES.map((category) => (
                <CareCategory {...category} />
              ))}
            </Stack> 
          </Box>
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
};

export default Employers;
