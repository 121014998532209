import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import MHSlider, {
  MHSliderItem,
} from "../../Common/SliderCarousel/Slider/MHSlider";
import ContentCard from "../../UI/ContentCard";

const CONTENT = [
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/1.png",
    // 'https://res.cloudinary.com/mother-honestly/image/upload/v1688473369/Website_Images_-_How_It_Works_-_Care_Wallet_2_cyhhzd.png',
    title: "The Caregiving Revolution",
    description:
      "In recent years, the traditional roles of parents and caregivers have evolved dramatically. Today, millions of employees juggle the responsibilities of both their careers and caregiving, whether for children, aging parents, or loved ones with unique needs. Data shows that this caregiving revolution is reshaping the workforce, demanding new strategies and support systems.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/wellbeing.png",
    title: "Wellbeing at the Core",
    description:
      "Employee wellbeing is no longer a buzzword but a strategic imperative. Research consistently demonstrates that employees who feel supported in their caregiving roles exhibit higher job satisfaction, productivity, and overall happiness. Conversely, neglecting these needs can lead to burnout, absenteeism, and attrition.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/caring-leadership.png",
    title: "Caring Leadership's Impact",
    description:
      "The data unequivocally supports the positive impact of caring leadership on organizations. Leaders who prioritize the needs of their employees, particularly parents and caregivers, witness improved team morale, reduced turnover, and increased innovation and creativity. Caring leadership isn't just ethical; it's also smart business.",
  },
];

const CareInsightsSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Box width={{ xs: "90%", sm: "75%" }} mx="auto" mt={12}>
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 2,
          }}
          paragraph
        >
          Data-Driven Care Insights
        </Typography>
        {/* <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Personalized care solutions that support the mental, physical, and
          financial aspects of a distributed workforce.
        </Typography> */}
      </Box>

      <Box py="3rem" px={{ xs: 0, md: "4rem" }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
                cardContainerSx={{
                  height: { xs: 640, md: 680, lg: 680 },
                }}
                cardContentSx={{
                  height: 280,
                }}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>
    </div>
  );
};

export default CareInsightsSlider;
