import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import React from "react";
import MHButton from "../Common/Button/MHButton";

import StaticDataContext from "../../store/context/static-data.context";
import ContactDialog from "./BlessingDialog";
import { useHistory } from "react-router-dom";

type Props = {};

const ContactDemo = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const dataCtx = React.useContext(StaticDataContext);
  const { openWaitlist, closeWaitlist } = dataCtx;

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  //   closeWaitlist();
  // };

  // React.useEffect(() => {
  //   if (openWaitlist) {
  //     handleOpen();
  //   }
  // }, [openWaitlist]);

  let history = useHistory();

  const handleClickOpen = () => {
    history.push("/summit");
  };

  return (
    <React.Fragment>
      <Box className="bg-lilac-300 h-[480px] lg:h-[292px]">
        <Grid container spacing={6} className="md:px-32 lg:px-48">
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Box className="">
              <Typography
                variant="h2"
                fontSize={{ xs: "22px", sm: "30px", md: "32px" }}
                lineHeight={{ xs: "160%", sm: "120.5%" }}
                letterSpacing={"-0.04em"}
                className="py-10 md:py-2 lg:py-8 md:text-center lg:text-start lg:w-[95%] md:block hidden"
                justifyContent="center"
                alignItems="center"
              >
                Dive into unlocking workplace care at our Care At Work Summit.
                Discover brand partnerships, secure tickets, and gain
                transformative insights. Join us!
              </Typography>

              {/* Mobile Text */}

              <Typography
                variant="subtitle2"
                fontSize={{ xs: "22px", sm: "30px", md: "32px" }}
                lineHeight={{ xs: "160%", sm: "120.5%" }}
                letterSpacing={"-0.04em"}
                className="md:py-10 capitalize lg:py-8 mx-auto text-center w-[80%] md:hidden block"
                justifyContent="center"
                alignItems="center"
              >
                Dive into unlocking workplace care at our Care At Work Summit.
                Discover brand partnerships, secure tickets, and gain
                transformative insights. Join us!
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box className="lg:py-8 lg:pl-16  ">
              <Typography
                variant="body2"
                fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
                className="pb-8 mx-auto text-center lg:mx-0 lg:text-start w-[85%] md:w-[85%]"
              >
                Reach out to us and our team will get in touch to kick off the
                conversation.
              </Typography>

              <MHButton
                onClick={() => handleClickOpen()}
                className="hidden lg:block text-[12px] leading-[102%] w-[130px] tracking-[0.05em] h-[52px] "
              >
                Learn More
              </MHButton>

              {/* Mobile Button */}

              <Box className="lg:hidden flex justify-center">
                <MHButton
                  onClick={() => handleClickOpen()}
                  className="text-[12px] leading-[102%] w-[130px] tracking-[0.05em] h-[52px] "
                >
                  Learn More
                </MHButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <ContactDialog open={open} onClose={handleClose} /> */}
    </React.Fragment>
  );
};

export default ContactDemo;
