import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { ReactComponent as PlusIcon } from "../../../static/svg/plus.svg";
import { ReactComponent as MinusIcon } from "../../../static/svg/minus.svg";

import { useState } from "react";

type Props = {
  question?: string;
  answer?: string;
  index?: number;
  DataId?: string;
};

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">
        <MinusIcon />
      </div>
      <div className="collapsIconWrapper">
        <PlusIcon />
      </div>
    </Box>
  );
};

const MHAccordion = (props: Props) => {
  const [expandedPanel, setExpandedPanel] = useState<string | false>("panel_1");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : false);
      // setExpandedPanel(isExpanded ? `panel_${props.DataId}` : false);
    };

  return (
    <Accordion
      className="shadow-none -ml-4"
      expanded={expandedPanel === `panel_${props.DataId}`}
      onChange={handleChange(`panel_${props.DataId}`)}
      key={props.index}
    >
      <AccordionSummary
        expandIcon={<CustomExpandIcon />}
        aria-controls={`panel-${props.DataId}bh-content`}
        id={`panel-${props.DataId}bh-header`}
      >
        <Typography
          variant="body2"
          color="primary"
          fontSize={{ xs: "13px", sm: "16px" }}
          lineHeight={{ xs: "200%", sm: "150%" }}
          // letterSpacing={"0.02em"}
          className="py-4"
        >
          {props.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="">
        <Typography
          variant="body2"
          color="primary"
          fontSize={{ xs: "13px", sm: "14px" }}
          lineHeight={{ xs: "200%", sm: "180%" }}
          letterSpacing={"0.02em"}
          className="py-2 "
        >
          {props.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default MHAccordion;
