import React, { Fragment, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import ResCard from "../SubComponents/ResCard";
import { Link, useLocation, useParams } from "react-router-dom";
import Slider from "react-slick";

import MHSubscribe from "../../Layout/MHSubscribe";
import MHFooter from "../../Layout/MHFooter";

import { ReactComponent as Instagram } from "../../../static/svg/instagram.svg";
import { ReactComponent as Facebook } from "../../../static/svg/facebook.svg";
import { ReactComponent as LinkedIn } from "../../../static/svg/linkedin-icon.svg";
import { ReactComponent as Pinterest } from "../../../static/svg/pinterest.svg";
import { ReactComponent as Twitter } from "../../../static/svg/twitter.svg";
import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import { checkInvalidArray } from "../../../utils/utils";
import VideoHeader from "../Videos/VideoHeader";
import shuffleArray from "../../../hooks/use-random";
import useTitle from "../../../hooks/use-title";
import useAnalytics from "../../../hooks/use-analytics";
import MHButton from "../../Common/Button/MHButton";

type ComponentProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  title?: string;
  texts?: string;
  catchPhrase?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
};

const ViewPastEvent = (props: ComponentProps) => {
  useTitle("Events");

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: "Events",
  });

  const location = useLocation();
  const [resources, setResources] = useState<ResourceItemType[]>([]);

  const [noOfElement, setnoOfElement] = useState(4);
  const slice = resources.slice(0, noOfElement);

  var resUrl = `${process.env.REACT_APP_PAST_EVENT_URL}`;

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      // Function to filter the data according to vimeo
      // let filterKeyword = "vimeo";
      // const filtered = formattedResources.filter((item) =>
      //   item.ticketLink.toLowerCase().includes(filterKeyword.toLowerCase())
      // );

      // Function to filter the data according to vimeo, youtube, and linkedin.com/events/
      let filterKeywords = ["vimeo", "youtube", "linkedin.com/events/"];
      const filtered = formattedResources.filter((item) =>
        filterKeywords.some((keyword) =>
          item.ticketLink.toLowerCase().includes(keyword.toLowerCase())
        )
      );

      // Shuffle data to display randomly
      const randomizedData = shuffleArray(filtered); // Randomize the filtered array
      setResources(randomizedData);

      // setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  const [data, setData] = useState<ResourceItemType | null>(null);
  const params = useParams<any>();
  // console.log(params.id!);

  var viewUrl = `${process.env.REACT_APP_ALL_RES_VIEW_URL}${params.id}`;

  const getData = async () => {
    try {
      const response = await fetch(viewUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data }: HttpResponse<ResourceItemType> = await response.json();

      const priorityList: string[] = data.priorities.split(",");
      let newItemList: ItemList[] = [];

      for (const item of priorityList) {
        const newObject = data.itemList.find((it) => it.id === +item);

        if (newObject) {
          if (!newItemList.length) {
            newItemList = [newObject];
          } else {
            newItemList = [...newItemList, newObject];
          }
        }
      }

      const formattedResource: ResourceItemType = {
        ...data,
        newItemList,
      };

      setData(formattedResource);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: "38px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false,
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  useEffect(() => {
    getResource();
    getData();
  }, [params.id]);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <VideoHeader
        title={data.title}
        description={data.content}
        imageUrl={data.thumbNailImageSrc}
        category={data.newItemList}
        categoryOne={
          checkInvalidArray(data.newItemList).length
            ? data.newItemList[0].interestName
            : ""
        }
        downloadClassName="hidden flex -ml-4 my-6"
      />
      <Box className="md:px-20 lg:px-60 md:py-10 bg-white">
        {data.ticketLink.includes("linkedin.com/events/") ? (
          // <a href={data.ticketLink} target="_blanc">
          //   Watch on LinkedIn
          // </a>
          <Box className="flex  justify-center py-12">
            <img
              src={data.source}
              alt="Resource Image"
              className="mx-auto bg-pink-700 w-full h-[257px] bodyImage object-cover lg:w-full lg:h-[630px] md:h-[456px]"
            />
          </Box>
        ) : (
          ""
        )}

        {data.ticketLink.includes("linkedin.com/events/") ? (
          // <a href={data.ticketLink} target="_blanc">
          //   Watch on LinkedIn
          // </a>
          <Box className="flex  justify-center py-12">
            <MHButton
              onClick={() => {
                window.open(data.ticketLink);
              }}
              sx={{ width: "185px" }}
            >
              Watch The Recap
            </MHButton>
          </Box>
        ) : (
          <Box>
            <iframe
              src={data.ticketLink}
              title={data.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-[400px] md:h-[400px] lg:h-[700px]"
            ></iframe>
          </Box>
        )}

        <Box className="block mb-12 -mt-12 md:mb-0 md:mt-0">
          <Box className="flex gap-4 text-center place-content-center">
            <a
              className="cursor-pointer"
              href="https://instagram.com/mhworklife?igshid=YmMyMTA2M2Y="
              target="_blanc"
              title="Visit our socials"
            >
              <Instagram />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
              target="_blanc"
              title="Visit our socials"
            >
              <Facebook />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.linkedin.com/company/mhworklife/mycompany/"
              target="_blanc"
              title="Visit our socials"
            >
              <LinkedIn />
            </a>
            <a
              className="cursor-pointer"
              href="https://www.pinterest.com/motherhonestly/"
              target="_blanc"
              title="Visit our socials"
            >
              <Pinterest />
            </a>
            <a
              className="cursor-pointer"
              href="https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
              target="_blanc"
              title="Visit our socials"
            >
              <Twitter />
            </a>
          </Box>
        </Box>
      </Box>

      <Box className="px-4 w-full md:px-20 lg:px-64 pb-6 bg-white">
        <Box className="md:px-10">
          <Typography
            variant="h3"
            color="primary"
            className="text-[22px] text-start font-areaSemi font-[600]"
          >
            {data.catchPhrase}
          </Typography>

          <Box className="flex">
            {/* Content */}
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </Box>
        </Box>
      </Box>
      <Box className="mx-auto block md:hidden pt-10 bg-white px-20 py-4">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-4"
        >
          Past Events You Might like
        </Typography>
      </Box>
      {/* For Mobile */}
      <Box className="block md:hidden bg-mhbluish overflow-x-hidden">
        <Slider {...settings}>
          {resources.map((res, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[452px] object-cover bg-cream-100 rounded-md"
                iconClass="absolute top-14 left-[38%] mt-12 w-20 h-20"
                imgBg="bg-sky-400 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                top={
                  checkInvalidArray(res.newItemList).length
                    ? res.newItemList[0].interestName
                    : ""
                }
                podTop="hidden"
                title={res.title}
                category={res.newItemList}
                titleUrl={`/resources/past-events/${res.slug}/${res.id}`}
                playUrl={`/resources/past-events/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Slider>
      </Box>

      {/* For Web  */}
      <Box className="mx-auto pt-10 hidden md:block bg-white px-20 py-4">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-4"
        >
          Past Events You Might like
        </Typography>
        <Grid container spacing={2}>
          {slice.map((res, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={res.id}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[452px] object-cover bg-cream-100 rounded-md"
                iconClass="absolute top-14 left-[38%] mt-12 w-20 h-20"
                imgBg="bg-sky-400 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                top={
                  checkInvalidArray(res.newItemList).length
                    ? res.newItemList[0].interestName
                    : ""
                }
                podTop="hidden"
                title={res.title}
                category={res.newItemList}
                titleUrl={`/resources/past-events/${res.slug}/${res.id}`}
                playUrl={`/resources/past-events/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <MHSubscribe />
      <MHFooter />
    </Fragment>
  );
};

export default ViewPastEvent;
