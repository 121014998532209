import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import MHButton from '../../components/Common/Button/MHButton';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MHFormControl from '../../components/Common/Form/MHFormControl';
import useInput from '../../hooks/use-input';
import * as validators from '../../utils/validators';

import StyledTab, {
  a11yProps,
  StyledTabs,
  TabPanel
} from '../../components/Common/Tabs/MHTabs';
import { MHSelect } from '../../components/Common/Form/MHSelect';
import { SelectOption } from '@mui/base';
import useTabs from '../../hooks/use-tabs';
import AuthContext from '../../store/context/auth-context';
import useHttp from '../../hooks/use-http';
import { formatEmailForStorage, resolveErrorMessage } from '../../utils/utils';
import { HttpResponse } from '../../models/api.interface';
import useToastHttp from '../../hooks/use-toast-http';

const LoginContent = (props: { onAuthenticate: VoidFunction }) => {
  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    },
    {
      validator: (value: string) => validators.email(value)
    }
  ]);
  const {
    value: enteredPassword,
    valid: enteredPasswordIsValid,
    error: enteredPasswordHasError,
    onChange: passwordInputChangeHandler,
    onBlur: passwordInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);
  const {
    value: enteredCompanyLocation,
    valid: enteredCompanyLocationIsValid,
    error: enteredCompanyLocationHasError,
    onChange: companyLocationInputChangeHandler,
    onBlur: companyLocationInputBlurHandler
    // markAsTouched: markMerchantNameInputAsTouched
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const { tabIndex, onSwitchTab } = useTabs();

  const EMPLOYERS: SelectOption<string>[] = [
    {
      label: 'Indeed',
      value: 'indeed'
    },
    {
      label: 'MMGY Global',
      value: 'mmgy'
    },
    {
      label: 'AMN Healthcare',
      value: 'amn'
    },
    {
      label: 'Mercury',
      value: 'mercury'
    },
    {
      label: 'Bounteous',
      value: 'bounteous'
    }
  ];

  const authCtx = React.useContext(AuthContext);
  const { loading, error, sendHttpRequest: signIn } = useHttp();

  let formIsValid = enteredEmailIsValid && enteredPasswordIsValid;

  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();

  const signInHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
    // emailInputRef.current!.focus();
    preventDefault(event);

    if (!formIsValid) {
      return;
    }

    signIn(
      process.env.REACT_APP_API_BASE_URL + 'employee/dashboard/login',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: formatEmailForStorage(enteredEmail),
          password: enteredPassword
        })
      },
      (response: HttpResponse<any>) => {
        // console.log(response.data);
        authCtx.login(response.data.token, response.data.uuid);
        props.onAuthenticate();

        fromPathname && history.push(fromPathname);
      }
    );
  };

  useToastHttp({
    httpError: error
  });

  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromPathname = queryParams.get('from');

  const formLoginCredentials = (
    <>
      {error && (
        <Alert
          severity="error"
          sx={{
            mb: 3
          }}>
          {error.message}
        </Alert>
      )}

      <MHFormControl
        id="email"
        type="text"
        label="Email"
        placeholder="Enter Email"
        value={enteredEmail}
        onChange={emailInputChangeHandler}
        onBlur={emailInputBlurHandler}
        error={resolveErrorMessage(enteredEmailHasError)(
          'Email Address is required'
        )}
        required
      />

      <MHFormControl
        id="password"
        type="password"
        label="Password"
        placeholder="Enter Password"
        value={enteredPassword}
        onChange={passwordInputChangeHandler}
        onBlur={passwordInputBlurHandler}
        error={resolveErrorMessage(enteredPasswordHasError)(
          'Password is required'
        )}
        required
      />
    </>
  );

  const formSubmitButonAndFooter = (
    <>
      <MHButton
        type="submit"
        sx={{ paddingY: 2, marginY: 2 }}
        loading={loading}
        className="text-[12px] mt-4 leading-[102%] w-full tracking-[0.05em] h-[52px] "
        fullWidth>
        Log In
      </MHButton>

      <Box
        className="py-6 flex place-content-between"
        display={'flex'}
        justifyContent={'space-between'}
        paddingTop={1}>
        <Link
          to={`/auth/subscribe?from=${location.pathname}`}
          className="remove-line"
          onClick={authCtx.closeAuthDialog}>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: '12px', sm: '12px' }}
            lineHeight={{ xs: '102%', sm: '102%' }}
            letterSpacing={'0.05em'}
            className="">
            Create Account
          </Typography>
          <Box
            sx={{
              backgroundColor: '#194049',
              marginY: '8px',
              height: '1px',
              width: '100%',
              marginLeft: '0px'
            }}></Box>
        </Link>

        <Link
          to={'/auth/forgot-password'}
          className="remove-line"
          onClick={authCtx.closeAuthDialog}>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: '12px', sm: '12px' }}
            lineHeight={{ xs: '102%', sm: '102%' }}
            letterSpacing={'0.05em'}
            className="">
            Forgot Password?
          </Typography>
          <Box
            sx={{
              backgroundColor: '#194049',
              marginY: '8px',
              height: '1px',
              width: '100%',
              marginLeft: '0px'
            }}></Box>
        </Link>
      </Box>
    </>
  );

  return (
    <div>
      <Box
        className="mx-auto pl-1 md:pl-1 mt-16 gap-1 w-[335px] md:w-[522px]"
        mb={2}>
        <StyledTabs
          value={tabIndex}
          onChange={onSwitchTab}
          aria-label="login tabs">
          <StyledTab
            label="Individual Login"
            {...a11yProps(0)}
            sx={{
              width: '50%'
            }}
          />
          <StyledTab
            label="Corporate Login"
            {...a11yProps(1)}
            sx={{
              width: '50%'
            }}
          />
        </StyledTabs>
      </Box>

      <TabPanel value={tabIndex} index={0}>
        <Box
          component={'form'}
          onSubmit={signInHandler}
          id="sign-in-form"
          className="mx-auto pl-1 md:pl-1 mt-8 gap-1 w-[335px] md:w-[522px]">
          {formLoginCredentials}

          {formSubmitButonAndFooter}
        </Box>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <Box className="mx-auto pl-1 md:pl-1 mt-8 gap-1 w-[335px] md:w-[522px]">
          {formLoginCredentials}

          <MHSelect
            label="Please select your employer"
            placeholder="Please select"
            options={EMPLOYERS || []}
            value={enteredCompanyLocation}
            onChange={(value) =>
              companyLocationInputChangeHandler(value as string)
            }
            onBlur={companyLocationInputBlurHandler}
            required
          />

          {formSubmitButonAndFooter}
        </Box>
      </TabPanel>
    </div>
  );
};

export default LoginContent;
