import React from 'react';
import './App.scss';
import AppNavigator from './navigator/Navigator';
import { AppContextProvider } from './store/context/app.context';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarContextProvider } from './store/context/snackbar.context';
import { CartContextProvider } from './store/context/cart-context';
import { MembershipContextProvider } from './store/context/membership.context';
import { AuthContextProvider } from './store/context/auth-context';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthContextProvider>
          <AppContextProvider>
            <SnackbarContextProvider>
              <MembershipContextProvider>
                <CartContextProvider>
                  <AppNavigator />
                </CartContextProvider>
              </MembershipContextProvider>
            </SnackbarContextProvider>
          </AppContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
