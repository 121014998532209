import React from "react";
import { db } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

type Props = {
  companyName: string;
};

const PartnerRegLimitation = (props: Props) => {
  const partnerCounter = async () => {
    try {
      const collectionRef = collection(db, "in-person-summit-registrations");
      const quazo = query(
        collectionRef,
        where("company", "==", props.companyName)
      );

      const querySnapshot = await getDocs(quazo);
      const count = querySnapshot.size;
      // console.log(count);
      return count;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return partnerCounter();
};

export default PartnerRegLimitation;
