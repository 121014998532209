import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Link, useRouteMatch } from "react-router-dom";

import { ReactComponent as FooterIcon } from "../../static/svg/logo-mh.svg";
import MHLogo from "../../theme/icons/MHLogo";
import OldMHLogo from "../../theme/icons/OldMHLogo";

type Props = {};

const Footer = (props: Props) => {
  let match = useRouteMatch();

  return (
    <Fragment>
      <Box
        className={`bg-lilac-300 h-auto w-full pb-12 md:pb-0 overflow-x-hidden`}
      >
        {/* <Grid container spacing={1} columns={24} className="gap-40"> */}

        <Grid container spacing={2} className="py-4 px-12 md:mb-0">
          <Grid item xs={12} sm={6} md={6} lg={6} className="">
            <Box className="flex relative w-full p-2">
              <Typography className="bottom-0 left-0 md:left-0 md:absolute text-center mx-auto">
                2024 The Care Gap, All Rights Reserved
              </Typography>
              <Link to={"/privacy-policy"}>
                <Typography className="bottom-0 w-full left-0 md:left-72 lg:left-72 absolute hidden md:block">
                  Privacy Policy
                </Typography>
              </Link>
              <Link to={"/refund-policy"}>
                <Typography className="bottom-0 w-full left-0 md:left-96 lg:left-96 absolute hidden md:block">
                  Refund Policy
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className="">
            <Box className="flex relative w-full p-2 gap-6 md:gap-0">
              <Typography className="bottom-0 gap-4 right-12 absolute hidden md:block">
                Powered by &nbsp;
                <OldMHLogo
                  style={{
                    width: "4rem",
                    display: "inline",
                    verticalAlign: "middle",
                  }}
                />
              </Typography>

              <Box className=" mx-auto" display="flex" gap={2}>
                <Link to={"/privacy-policy"}>
                  <Typography className=" block md:hidden">
                    Privacy Policy
                  </Typography>
                </Link>
                <Link to={"/refund-policy"}>
                  <Typography className="  block md:hidden">
                    Refund Policy
                  </Typography>
                </Link>

                <Typography className="-bottom-8 ml-3 w-full mx-auto absolute block md:hidden ">
                  Powered by &nbsp;
                  <OldMHLogo
                    style={{
                      width: "4rem",
                      display: "inline",
                      verticalAlign: "middle",
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Footer;
