import React from "react";
import { Link, useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

import MHButton from "../components/Common/Button/MHButton";
import FAQ from "../components/Features/Employers/FAQ";
import FollowMH from "../components/Landing/FollowMH";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHFooter from "../components/Layout/MHFooter";
import CaringApproachSection from "../components/Features/Employers/CaringApproachSection";
import EmployersHeroSlider from "../components/Features/Employers/EmployersHeroSlider";
import useDialog from "../hooks/use-dialog";
import CareAcademyHeroSlider from "../components/Features/CareAcademy/CareInsightsSlider";
import CareInsightsSlider from "../components/Features/CareAcademy/CareInsightsSlider";
import DataPoweredSolutionsSlider from "../components/Features/CareAcademy/DataPoweredSolutionsSlider";

import { ReactComponent as ConsultantIcon } from "../static/svg/brand/consultant.svg";
import StoryHeader from "../components/Story/StoryHeader";

import EquityBannerIcon from "../static/svg/banner-yellow-branded.svg";
import CohortSessionsWidget from "../components/Features/CareAcademy/CohortSessionsWidget";
import useTitle from "../hooks/use-title";
import useAnalytics from "../hooks/use-analytics";

const CONTENT = [
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1676441391/Image_16_jwcjag.png",
    title: "Self and Wellbeing",
    description:
      "Transit, co-working spaces, fitness, food, tampon, mental wellness, pets, games, financial wellness, and other unforeseen expenses etc.",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1676441388/Image_17_o5vpwr.png",
    title: "Household Operational Excellence",
    description:
      "Help with housekeeping, laundry, lawn mowing, home office set-ups, etc.",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1665674645/Image_4_zzfq8h.png",
    title: "Family & Relationships",
    description:
      "daycare, babysitter, nanny, summer camp, baby formula, diapers, care for aging parent, relatives or family of choice, pet care, pet sitting",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1676441391/Image_16_jwcjag.png",
    title: "Household Operational Excellence",
    description:
      "Help with housekeeping, laundry, lawn mowing, home office set-ups, etc.",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1676441388/Image_17_o5vpwr.png",
    title: "Family & Relationships",
    description:
      "daycare, babysitter, nanny, summer camp, baby formula, diapers, care for aging parent, relatives or family of choice, pet care, pet sitting",
  },
];

const CARE_FEATURES = [
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_6_kmmdg4.png",
    caption: "Financial Wellbeing",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_7_u5cayk.png",
    caption: "Ongoing Connection",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_8_zihrf3.png",
    caption: "Coaching and Mental Health",
  },
  {
    imageSrc:
      "https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_9_c8qgdy.png",
    caption: "Resources and Concierge",
  },
  // {
  //   imageSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_6_kmmdg4.png',
  //   caption: 'Child care'
  // },
  // {
  //   imageSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_9_c8qgdy.png',
  //   caption: 'Household Management'
  // },
  // {
  //   imageSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_8_zihrf3.png',
  //   caption: 'Co-working'
  // },
  // {
  //   imageSrc:
  //     'https://res.cloudinary.com/mother-honestly/image/upload/v1665737015/Image_7_u5cayk.png',
  //   caption: 'Return-to-work'
  // }
];

export const MagazineFeature = (props: {
  title: string;
  content: string;
  imageSrc: string;
  inverted?: boolean;
  buttonLabel: string;
  /**
   * accepts a route path for the Link component
   */
  href: string;
}) => {
  return (
    <Grid
      container
      direction={{
        xs: "column-reverse",
        md: props.inverted ? "row-reverse" : "row",
      }}
      mb={8}
    >
      <Grid item xs={12} md={6}>
        <Stack
          justifyContent="center"
          alignItems="center"
          width={{ xs: "90%", md: "70%" }}
          mx="auto"
          height="100%"
          mt={{ xs: 3, md: 0 }}
        >
          <Typography
            variant="h1"
            fontSize={{ xs: "2rem", md: "2.4rem" }}
            width={{ xs: "100%", md: "90%" }}
            textTransform="capitalize"
            align="center"
            sx={{
              mb: 4,
            }}
            paragraph
          >
            {props.title}
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            width={{ xs: "100%", md: "80%" }}
            fontSize="14px"
            align="center"
            lineHeight="180%"
            letterSpacing="0.02em"
            mx="auto"
            mb={5}
          >
            {props.content}
          </Typography>

          <MHButton
            sx={{
              width: "169px",
            }}
            to={props.href}
          >
            {props.buttonLabel}
          </MHButton>

          {/* <MHButton
            onClick={() => {
              window.open(props.href);
            }}
            sx={{
              width: "169px",
            }}
          >
            {props.buttonLabel}
          </MHButton> */}
        </Stack>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box
          width={{ xs: "100%", md: "100%" }}
          mx="auto"
          // p={{ xs: 3, md: 0 }}
          // pr={{ xs: 2, md: 10 }}
          // pl={2}
          sx={{
            "& img": {
              width: "100%",
              [props.inverted ? "pl" : "pr"]: { xs: 2, md: 10 },
              [props.inverted ? "pr" : "pl"]: 2,
              // height: '500px'
            },
          }}
        >
          <img src={props.imageSrc} alt="Care Academy" width="800" />
        </Box>
      </Grid>
    </Grid>
  );
};

const CareAcademy = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  useTitle("Advisory");

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: "Advisory",
  });

  return (
    <React.Fragment>
      <StoryHeader
        title2="Advisory"
        textColor="#194049"
        icon={<ConsultantIcon color="#194049" />}
        imageSrc={EquityBannerIcon}
        imageMobSrc={EquityBannerIcon}
        // Center Class attributes
        headerClassName="absolute items-center top-32 md:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[331px] mx-auto"

        // Left Class attributes
        // headerClassName="absolute items-center top-32 md:top-40 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      />

      <Box width={{ xs: "90%", sm: "75%" }} mx="auto" my={7}>
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          Nurturing Inclusive and Caring Corporations
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", md: "70%" }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph
        >
          In today's rapidly evolving corporate landscape, the demand for
          inclusive and caring workplaces is more significant than ever before.
          We stand at the forefront of this transformative movement, serving as
          an advisory firm dedicated to nurturing inclusive and caring
          corporations. Rooted in data and insights, we align your business and
          profit objectives with the care load and wellbeing solutions your
          employee needs.
        </Typography>
      </Box>

      <CareInsightsSlider />

      <Divider
        variant="middle"
        sx={{
          borderColor: "primary.main",
          width: "91%",
          mx: "auto",
          opacity: 0.1,
          my: 8,
        }}
      />

      <Box width={{ xs: "90%", sm: "60%" }} mx="auto" my={6}>
        <Typography
          variant="h1"
          fontSize={{ xs: "2rem", sm: "2.4rem" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          Our Firm's Mission
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", sm: "55%" }}
          fontSize={"13px"}
          letterSpacing="0.02em"
          lineHeight="185%"
          align="center"
          mx="auto"
          paragraph
        >
          To reduce the care load in your organization, build employee
          resilience, and improve performance:
        </Typography>
      </Box>

      <MagazineFeature
        imageSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/The+Care+Academy%E2%80%99s+Mission+.png"
        content="For employees, we provide a haven of resources, courses, and support tailored to the unique challenges of parenting and caregiving while navigating a fulfilling career. Here, employees gain invaluable skills, strategies, and networks to excel both at work and in their caregiving roles. With data-driven insights, our programs are designed to foster caregiving resilience, improve work-life integration, and optimize wellbeing for your workforce."
        title="Powering Employees"
        buttonLabel="Get Started"
        href="/schedule"
        // href="https://calendly.com/brooke-thecaregap/the-care-gap-interest-call"
      />

      <MagazineFeature
        imageSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/Equipping+Leaders+And+Organizations.png"
        content="For leaders and organizations, we serve as strategic partners in creating caring, inclusive cultures. We offer a wealth of data-backed insights and training, empowering leaders to cultivate environments where every employee feels valued, heard, and supported. Our data-driven approach ensures that leaders are equipped with the latest research and best practices in caring leadership."
        title="Equipping Leaders and Organizations"
        buttonLabel="Get Started"
        href="/schedule"
        // href="https://calendly.com/brooke-thecaregap/the-care-gap-interest-call"
        inverted
      />

      <Box mt={8}>
        <DataPoweredSolutionsSlider />
      </Box>

      {/* <Box mb={9}>
        <CohortSessionsWidget />
      </Box> */}

      <FollowMH />

      <MHSubscribe />

      <MHFooter />
      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" my={6}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', sm: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          Care Infrastructure Designed For All
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', sm: '55%' }}
          fontSize={'13px'}
          letterSpacing="0.02em"
          lineHeight="185%"
          align="center"
          mx="auto"
          paragraph>
          Personalized care solutions that support the mental, physical, and
          financial aspects of a distributed workforce.
        </Typography>
      </Box>

      <Grid
        container
        width={{ xs: '85%', sm: '93%' }}
        mx={{ xs: 'auto' }}
        mb={10}>
        {CARE_FEATURES.map((feature) => (
          <Grid item xs={12} sm={3}>
            <CareFeature
              imageSrc={feature.imageSrc}
              caption={feature.caption}
            />
          </Grid>
        ))}
      </Grid>

      <Divider
        variant="middle"
        sx={{
          borderColor: 'primary.main',
          width: '91%',
          mx: 'auto',
          opacity: 0.1,
          my: 8
        }}
      /> */}
    </React.Fragment>
  );
};

export default CareAcademy;
