import { Box, Typography, typographyClasses } from "@mui/material";
import React from "react";
import MHButton from "../Common/Button/MHButton";
import { useHistory } from "react-router-dom";

type Props = {};

const LandingNewsletter = (props: Props) => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/newsletter`);
  };

  return (
    <React.Fragment>
      <Box
        py={7}
        px={{ xs: 2, md: 6, lg: 8 }}
        bgcolor="primary.main"
        color="#EDD5B1"
        sx={{
          [`& > .${typographyClasses.root}`]: {
            color: "#EDD5B1",
            textAlign: "center",
          },
        }}
      >
        <Typography
          variant="caption"
          fontSize="12px"
          lineHeight="102%;"
          letterSpacing="0.1em"
          paragraph
        >
          subscribe today!
        </Typography>

        <Typography
          variant="h1"
          fontSize={{ xs: "38px", md: "50px" }}
          lineHeight="120.5%"
          letterSpacing="-0.04em"
          mb={2}
        >
          The Newsletter
        </Typography>

        <Typography
          variant="body2"
          fontSize={{ xs: "13px", md: "16px" }}
          lineHeight={{ xs: "200%", md: "169%" }}
          letterSpacing="0.02em"
          width={{ xs: "100%", md: "45%" }}
          mx="auto"
          mb={4}
        >
          insightful analyses, stories, and the latest research findings related
          to care
        </Typography>

        <div id="substack-feed-embed"></div>

        <Box className=" text-center">
          <MHButton
            onClick={() => handleClickOpen()}
            color="secondary"
            className="my-9 capitalize text-[12px] leading-[120%] md:leading-[102%] tracking-[0.05em] w-[230px] md:w-[250px]  h-[50px] md:h-[55px] "
          >
            Subscribe To The Care Gap
          </MHButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LandingNewsletter;
