import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Resources_Categ } from '../../utils/res-categ';
import Slider from 'react-slick';
import AbsolutePositionedContainer from '../UI/AbsolutePositionedContainer';

type Props = {
  text?: string;
};

const LandingResCategory = (props: Props) => {
  const settingsTab = {
    centerMode: true,
    centerPadding: '30px',
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false
  };
  const settingsMobile = {
    centerMode: true,
    centerPadding: '100px',
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false
  };

  return (
    <React.Fragment>
      <Box className="pt-12 pb-16">
        <Typography
          variant="subtitle2"
          color="#194049"
          fontSize={{ xs: '16px', sm: '22px', md: '22px' }}
          lineHeight={'140%'}
          className="text-center mx-auto w-80 py-6 md:w-full">
          {props.text}
        </Typography>

        {/* Web */}
        <Grid
          container
          spacing={2}
          className=" place-content-center gap-[30px] hidden md:hidden lg:flex">
          {Resources_Categ.map((resCateg, index) => (
            <Grid item xs={12} md={3} lg={2} key={index} className="">
              {/* <Box className="relative h-[120px] w-[232px]">
                <Typography
                  variant="body1"
                  color="#fff"
                  fontSize={{ xs: '13px', sm: '18px' }}
                  lineHeight={{ xs: '200%', sm: '169%' }}
                  letterSpacing={'0.02em'}
                  fontWeight={700}
                  className="absolute top-[40%] left-[35%]">
                  {resCateg.label}
                </Typography>
                <img
                  src={resCateg.imgSrc}
                  alt="resources category"
                  className="h-[120px] w-[232px] object-contain"
                />
              </Box> */}

              <Box
                borderRadius={2}
                position="relative"
                overflow="hidden"
                width="100%"
                sx={{
                  '& img': {
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    objectPosition: 'center'
                  }
                }}>
                <img src={resCateg.imgSrc} alt="Career" />
                <AbsolutePositionedContainer width="100%">
                  <Typography
                    variant="body1"
                    align="center"
                    color="common.white"
                    fontSize="1rem">
                    {resCateg.label}
                  </Typography>
                </AbsolutePositionedContainer>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Tab */}
        <Grid
          container
          spacing={0}
          className=" place-content-center overflow-hidden gap-[30px] hidden md:block lg:hidden">
          <Slider {...settingsTab}>
            {Resources_Categ.map((resCateg, index) => (
              <Grid item xs={12} md={3} lg={2} key={index} className="">
                <Box className="relative h-[95px] w-[183px] md:h-[120px] md:w-[232px]">
                  <Typography
                    variant="body1"
                    color="#fff"
                    fontSize={{ xs: '13px', sm: '18px' }}
                    lineHeight={{ xs: '200%', sm: '169%' }}
                    letterSpacing={'0.02em'}
                    fontWeight={700}
                    className="absolute top-[40%] left-[35%]">
                    {resCateg.label}
                  </Typography>
                  <img
                    src={resCateg.imgSrc}
                    alt="resources category"
                    className="h-[95px] w-[183px] md:h-[120px] md:w-[232px] object-contain"
                  />
                </Box>
              </Grid>
            ))}
          </Slider>
        </Grid>

        {/* Mobile */}
        <Grid
          container
          spacing={0}
          className=" place-content-center overflow-hidden gap-[30px] block md:hidden lg:hidden">
          <Slider {...settingsMobile}>
            {Resources_Categ.map((resCateg, index) => (
              <Grid item xs={12} md={3} lg={2} key={index} className="">
                <Box className="relative h-[95px] w-[183px] md:h-[120px] md:w-[232px]">
                  <Typography
                    variant="body1"
                    color="#fff"
                    fontSize={{ xs: '13px', sm: '18px' }}
                    lineHeight={{ xs: '200%', sm: '169%' }}
                    letterSpacing={'0.02em'}
                    fontWeight={700}
                    className="absolute top-[40%] left-[35%]">
                    {resCateg.label}
                  </Typography>
                  <img
                    src={resCateg.imgSrc}
                    alt="resources category"
                    className="h-[95px] w-[183px] md:h-[120px] md:w-[232px] object-contain"
                  />
                </Box>
              </Grid>
            ))}
          </Slider>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default LandingResCategory;
