import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppBar from './AppBar';
import ScrollToTop from './ScrollToTop';

import { FnComponent } from '../../models/component.model';
import AppBarTemp from './AppBarTemp';

import useOnline from '../../hooks/use-online';
import ErrorBoundaryPage from '../Common/Error/ErrorBoundaryPage';

const Layout: FnComponent = (props) => {
  const userIsOnline = useOnline();

  return (
    <Box>
      {ScrollToTop()}
      <AppBar />
      {/* <AppBarTemp /> */}

      <Stack direction="row" component={'main'}>
        <Box
          sx={{
            backgroundColor: '#ffffff',
            flexGrow: 1,
            overflow: 'auto'
          }}>
          {userIsOnline ? (
            props.children
          ) : (
            <ErrorBoundaryPage
              title="No connection"
              content="It looks like you are offline. Please check your internet connection."
              buttonLabel="Retry"
              onButtonClick={() => window.location.reload()}
              includeFooter
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Layout;
