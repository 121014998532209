import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MHDialog from '../../components/Common/Dialog/MHDialog';
import MHFormControl from '../../components/Common/Form/MHFormControl';
import MHButton from '../../components/Common/Button/MHButton';
import useInput from '../../hooks/use-input';
import useHttp from '../../hooks/use-http';

import { formatEmailForStorage, resolveErrorMessage } from '../../utils/utils';
import * as validators from '../../utils/validators';
import * as constants from '../../utils/constants';
import StaticDataContext from '../../store/context/static-data.context';
import { ReactComponent as DialogSuccessIcon } from '../../static/svg/dialog-success.svg';
import { Link, useHistory } from 'react-router-dom';
import { HttpResponse } from '../../models/api.interface';
import AuthContext from '../../store/context/auth-context';
import LoginContent from './LoginContent';

const MemberLoginDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={' '}
        handleClose={onClose}
        scroll="paper"
        actions={null}
        maxWidth={'sm'}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 100 + ' !important'
        }}
        fullWidth>
        <Box>
          <Typography
            variant="h2"
            fontSize={{ xs: '24px', sm: '28px', md: '30px' }}
            lineHeight={{ xs: '142%', sm: '143.5%' }}
            letterSpacing={'-0.04em'}
            className="capitalize mx-auto text-center"
            justifyContent="center"
            alignItems="center"
            textAlign={'center'}
            paddingBottom={4}>
            Please login to view this content.
          </Typography>

          <LoginContent onAuthenticate={onClose} />
        </Box>
        {/* <Box
            sx={{
              margin: "auto",
              "& svg": {
                display: "block",
                mx: "auto",
                mb: 2,
              },
            }}
          >
            <DialogSuccessIcon width={150} />

            <Typography
              variant="body2"
              color="initial"
              align="center"
              mb={3}
              gutterBottom
            >
              Thank you for requesting a demo! We will email you soon.
            </Typography>
          </Box> */}
      </MHDialog>
    </React.Fragment>
  );
};

export default MemberLoginDialog;
