import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MHButton from '../../Common/Button/MHButton';
import useDialog from '../../../hooks/use-dialog';
import StaticDataContext from '../../../store/context/static-data.context';
import geoData from '../../../data/georef-united-states-of-america-state.json';

// type Props = {}

const CaringApproachSection = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/employers`);
  };

  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
    closeWaitlist();
  };

  const dataCtx = React.useContext(StaticDataContext);
  const { openWaitlist, closeWaitlist } = dataCtx;

  React.useEffect(() => {
    if (openWaitlist) {
      handleOpen();
    }
  }, [openWaitlist]);

  return (
    <React.Fragment>
      <Box className="pt-20">
        <Box className="items-center place-content-center ">
          {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
            THE ENTERPRISE PLAN
          </Typography> */}
          <Typography
            variant="subtitle1"
            fontSize={{ xs: '12px', sm: '11px' }}
            lineHeight={'102%'}
            letterSpacing={'0.1em'}
            className="uppercase text-center">
            A CARING APPROACH TO WORK
          </Typography>
          {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
            Join the many forward-
            <br className="md:hidden block" />
            thinking companies <br /> supporting their employee caregivers
          </Typography> */}
          <Typography
            variant="h2"
            width={{ xs: '90%', md: '65%' }}
            fontSize={{ xs: '30px', sm: '40px', md: '50px' }}
            lineHeight={'120.5%'}
            letterSpacing={'-0.04em'}
            mx="auto"
            className="py-8  text-center">
            Today’s employees are being crushed under the unrelenting demands of
            their personal lives, caregiving responsibilities, home, and work.
          </Typography>

          {/* Web */}

          <Box className="hidden md:flex items-center place-content-center mx-auto text-center">
            {/* <Typography className="ml-8 mr-8 py-8 font-areaSemi text-[14px] text-center md:text-[14px]  lg:text-[16px] leading-[170%] md:leading-[150%] tracking-[0.02em] text-navy-900 ">
              Personalized Employee Work- <br /> Life Care Solutions
            </Typography> */}
            <Typography
              variant="body1"
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'150%'}
              letterSpacing={'0.02em'}
              className="ml-8 mr-8 py-8  text-center  ">
              Financial anxiety and stress is at an all-time
              <br /> high especially for parents, caregivers, and
              <br /> marginalized groups
            </Typography>
            &bull;
            <Typography
              variant="body1"
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'150%'}
              letterSpacing={'0.02em'}
              className="ml-8 mr-8 py-8  text-center ">
              Employees need help navigating life hurdles and
              <br /> the daily transitions between caregiving,
              <br /> work and life.
            </Typography>
            &bull;
            <Typography
              variant="body1"
              fontSize={{ sm: '14px', md: '16px' }}
              lineHeight={'150%'}
              letterSpacing={'0.02em'}
              className="ml-8 py-8 text-center ">
              Those challenges become heightened when they
              <br /> need to step away to care for themselves
              <br /> or their loved ones
            </Typography>
          </Box>

          {/* Mobile */}

          <Box className="md:hidden block items-center place-content-center mx-auto text-center">
            <Typography
              variant="body1"
              fontSize={{ xs: '12px' }}
              lineHeight={'170%'}
              letterSpacing={'0.02em'}
              className=" py-3 text-center">
              Financial anxiety and stress is at an all-time
              <br /> high especially for parents, caregivers, and
              <br /> marginalized groups
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: '12px' }}
              lineHeight={'170%'}
              letterSpacing={'0.02em'}
              className=" py-3 text-center">
              Employees need help navigating life hurdles and
              <br /> the daily transitions between caregiving,
              <br /> work and life.
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: '12px' }}
              lineHeight={'170%'}
              letterSpacing={'0.02em'}
              className=" py-3 text-center">
              Those challenges become heightened when they
              <br /> need to step away to care for themselves
              <br /> or their loved ones{' '}
            </Typography>
          </Box>

          {/* <Box className="mx-auto text-center py-10 md:py-0">
            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={handleOpen}>
              Request a Demo
            </MHButton>
          </Box> */}
        </Box>

        <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300 mt-10" />
      </Box>
    </React.Fragment>
  );
};

export default CaringApproachSection;
