import React from 'react';

import useHttp from '../../hooks/use-http';
import { HttpResponse } from '../../models/api.interface';
import {
  BillingPeriod,
  MembershipFeatureType,
  MembershipPlanType
} from '../../models/membership.type';

type MembershipCtxType = {
  membershipPlans: MembershipPlanType[];
  loadingMembershipPlans: boolean;
  exclusiveFeatures: MembershipFeatureType[];
  billingPeriod: BillingPeriod;
  toggleBillingPeriod: (period: BillingPeriod) => void;
};

const MembershipContext = React.createContext<MembershipCtxType>({
  membershipPlans: [],
  loadingMembershipPlans: false,
  exclusiveFeatures: [],
  billingPeriod: 'year',
  toggleBillingPeriod: (period: BillingPeriod) => {}
});

export const MembershipContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [membershipPlans, setMembershipPlans] = React.useState<
    MembershipPlanType[]
  >([]);

  const [exclusiveFeatures, setExclusiveFeatures] = React.useState<
    MembershipFeatureType[]
  >([]);

  const [membershipFeatures, setMembershipFeatures] = React.useState<{
    [key: string]: MembershipFeatureType[];
  } | null>(null);

  const [billingPeriod, setBillingPeriod] = React.useState<BillingPeriod>(
    'year'
  );

  const {
    loading: loadingMembershipPlans,
    error,
    sendHttpRequest: getMembershipPlans
  } = useHttp();

  const getPlatformMembershipPlans = () => {
    // if (membershipPlans.length > 0) {
    //   return;
    // }

    getMembershipPlans(
      process.env.REACT_APP_API_BASE_URL + 'employee/dashboard/membership/plan',
      {},
      (response: HttpResponse<MembershipPlanType[]>) => {
        const MEMBERSHIP_PLANS: Array<MembershipPlanType> = response.data
          .map((item) => ({
            ...item,
            price: item.price < 0 ? Infinity : item.price,
            yearlyPrice: item.price < 0 ? Infinity : item.yearlyPrice,
            basePrice: item.price < 0 ? Infinity : (item.yearlyPrice/12),
            billingPeriod: 'year' as BillingPeriod,
            features: item.description.map((featureItem) => ({
              value: featureItem.value,
              description: featureItem.description?.trim()
            }))
          }))
          .sort((a, b) => {
            if (a.price < b.price) {
              return -1;
            }

            if (a.price > b.price) {
              return 1;
            }

            return 0;
          });
        // console.log('membership', MEMBERSHIP_PLANS);

        setMembershipPlans(MEMBERSHIP_PLANS);
        extractExclusiveFeatures(MEMBERSHIP_PLANS);
        // extractMembershipFeatures(MEMBERSHIP_PLANS);
      }
    );
  };

  function extractExclusiveFeatures(membershipPlans: MembershipPlanType[]) {
    let exclusiveFeatures: MembershipFeatureType[] = [];

    const EXCLUSIVE_FEATURES_LIST: MembershipFeatureType[] = [];

    for (let i = 0; i < membershipPlans.length; i++) {
      const membershipPlan = membershipPlans[i];

      if (exclusiveFeatures.length === 0) {
        exclusiveFeatures = [...membershipPlan.features];
        EXCLUSIVE_FEATURES_LIST.push(...exclusiveFeatures);
      } else {
        exclusiveFeatures = membershipPlan.features.filter((feature) => {
          const existingFeatureIndex = membershipPlans[
            i - 1
          ].features.findIndex(
            (prevFeature) => feature.description === prevFeature.description
          );

          if (existingFeatureIndex > -1) {
            return false;
          }

          return true;
        });
        EXCLUSIVE_FEATURES_LIST.push(...exclusiveFeatures);
      }

      membershipPlan.exclusiveFeatures = exclusiveFeatures;
      // membershipPlan.featureDescription =
      //   i === 0
      //     ? 'Includes all of these benefits'
      //     : `Everything in the ${membershipPlans[i - 1].name} and...`;
    }
    // console.log(EXCLUSIVE_FEATURES_LIST);

    setExclusiveFeatures(EXCLUSIVE_FEATURES_LIST);
  }

  function toggleBillingPeriod(period: BillingPeriod) {
    const MEMBERSHIP_PLANS: Array<MembershipPlanType> = membershipPlans.map(
      (item) => ({
        ...item,
        basePrice: period === 'year' ? (item.yearlyPrice/12) : item.price,
        billingPeriod: period
      })
    );

    setBillingPeriod(period);
    // .sort((a, b) => {
    //   if (a.price < b.price) {
    //     return -1;
    //   }

    //   if (a.price > b.price) {
    //     return 1;
    //   }

    //   return 0;
    // });
    // console.log('membership', MEMBERSHIP_PLANS);

    setMembershipPlans(MEMBERSHIP_PLANS);
  }

  function extractMembershipFeatures(membershipPlans: MembershipPlanType[]) {
    let features: { [key: string]: MembershipFeatureType[] } = {};

    for (const plan of membershipPlans) {
      features = {
        ...features,
        [plan.name]: plan.features
      };
    }

    setMembershipFeatures(features);
  }

  React.useEffect(() => {
    getPlatformMembershipPlans();
  }, []);

  const value: MembershipCtxType = {
    membershipPlans,
    loadingMembershipPlans,
    exclusiveFeatures,
    billingPeriod,
    toggleBillingPeriod
  };

  return (
    <MembershipContext.Provider value={value}>
      {children}
    </MembershipContext.Provider>
  );
};

export default MembershipContext;
