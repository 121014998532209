import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";

import MHFormGroup from "../Common/Form/MHFormGroup";
import MHFormControl from "../Common/Form/MHFormControl";
import MHButton from "../Common/Button/MHButton";
import useInput from "../../hooks/use-input";

import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import * as validators from "../../utils/validators";
import * as constants from "../../utils/constants";
import { resolveErrorMessage } from "../../utils/utils";
import { MHMultiSelect, MHSelect } from "../Common/Form/MHSelect";
import { styled } from "@mui/material/styles";
import { SelectOption } from "@mui/base";
import useMultiSelect from "../../hooks/use-multi-select";
import Stack from "@mui/material/Stack";
import RequestLinkConfirmation from "../Contact/RequestLinkConfirmation";
import useDialog from "../../hooks/use-dialog";
import { capitalizer } from "../../hooks/use-capitalize-all";
import { useHistory } from "react-router-dom";
import PartnerRegLimitation from "./PartnerRegLimitation";

export const PARTNERS: SelectOption<string>[] = [
  {
    label: "Indeed",
    value: "Indeed",
  },
  {
    label: "Mamava",
    value: "Mamava",
  },
  {
    label: "Vivvi",
    value: "Vivvi",
  },
  {
    label: "Cocoon",
    value: "Cocoon",
  },
  {
    label: "Lumo",
    value: "Lumo",
  },
  {
    label: "Greenhouse",
    value: "Greenhouse",
  },
  {
    label: "Kindercare",
    value: "Kindercare",
  },
  {
    label: "Luminary",
    value: "Luminary",
  },
  {
    label: "Mirza",
    value: "Mirza",
  },
  {
    label: "MHPARTNER",
    value: "MHPARTNER",
  },
];

const PartnerInPersonRegistration = () => {
  const [limit, setLimit] = useState<number>(0);
  const {
    value: enteredFullName,
    valid: enteredFullNameIsValid,
    error: enteredFullNameHasError,
    onChange: fullNameInputChangeHandler,
    onBlur: fullNameInputBlurHandler,
    resetInput: resetFullNameInput,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
    resetInput: resetEmailInput,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);

  const {
    value: enteredGender,
    valid: enteredGenderIsValid,
    error: enteredGenderHasError,
    onChange: genderInputChangeHandler,
    onBlur: genderInputBlurHandler,
    resetInput: resetGenderInput,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredPartner,
    valid: enteredPartnerIsValid,
    error: enteredPartnerHasError,
    onChange: partnerInputChangeHandler,
    onBlur: partnerInputBlurHandler,
    resetInput: resetPartnerInput,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEthnicity,
    valid: enteredEthnicityIsValid,
    error: enteredEthnicityHasError,
    onChange: ethnicityInputChangeHandler,
    onBlur: ethnicityInputBlurHandler,
    resetInput: resetEthnicityInput,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  // const {
  //   value: enteredCompanyName,
  //   valid: enteredCompanyNameIsValid,
  //   error: enteredCompanyNameHasError,
  //   onChange: companyNameInputChangeHandler,
  //   onBlur: companyNameInputBlurHandler,
  // } = useInput([
  //   {
  //     validator: (value: string) => validators.required(value),
  //   },
  // ]);

  const {
    value: enteredJobTitle,
    valid: enteredJobTitleIsValid,
    error: enteredJobTitleHasError,
    onChange: jobTitleInputChangeHandler,
    onBlur: jobTitleInputBlurHandler,
    resetInput: resetJobTitleInput,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredChallenge,
    valid: enteredChallengeIsValid,
    error: enteredChallengeHasError,
    onChange: challengeInputChangeHandler,
    onBlur: challengeInputBlurHandler,
    resetInput: resetChallengeInput,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const { values: interests, inputChangeHandler: interestInputChangeHandler } =
    useMultiSelect();

  const SelectTag = styled("span")(
    ({ theme }) => `
      border-radius: 3px;
      background: #EBE5F1;
      padding: 4px 9px;
      display: inline-block;
      color: ${theme.palette.primary.main};
      width: fit-content;
    `
  );
  const history = useHistory();

  useEffect(() => {
    const fetchLimit = async () => {
      try {
        const limitcount = await PartnerRegLimitation({
          companyName: enteredPartner,
        });
        setLimit(limitcount!);
        console.log(enteredPartner, "counts limit: ", limitcount);
      } catch (error) {
        // Handle any errors from the Promise here
        console.error(error);
      }
    };

    if (enteredPartner) {
      fetchLimit();
    }
  }, [enteredPartner]);

  useEffect(() => {
    // if (limit >= 5) {
    //   console.log("Partner has reached registration limit ");
    //   handlerOpen();
    //   partnerInputChangeHandler("");
    // }
    if (enteredPartner === "Indeed" && limit >= 10) {
      console.log("Partner has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "Mamava" && limit >= 5) {
      console.log("Mamava has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "Vivvi" && limit >= 5) {
      console.log("Vivvi has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "Cocoon" && limit >= 5) {
      console.log("Cocoon has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "Lumo" && limit >= 5) {
      console.log("Lumo has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "Greenhouse" && limit >= 5) {
      console.log("Greenhouse has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "Kindercare" && limit >= 5) {
      console.log("Kindercare has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "Luminary" && limit >= 5) {
      console.log("Luminary has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "Mirza" && limit >= 5) {
      console.log("Mirza has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    } else if (enteredPartner === "MHPARTNER" && limit >= 1000000) {
      console.log("MHPARTNER has reached registration limit");
      handlerOpen();
      partnerInputChangeHandler("");
    }
  }, [limit, enteredPartner]);

  //   function renderValue(options: SelectOption<string>[] | null) {
  //     let content = null;

  //     if (!options) return content;

  //     return (
  //       <StackedRow
  //         spacing={1}
  //         maxWidth="calc(100% - 30px)"
  //         sx={{
  //           //   whiteSpace: 'nowrap',
  //           //   overflowX: 'hidden',
  //           '::-webkit-scrollbar': {
  //             height: '0px'
  //           }
  //         }}>
  //         {options.map((item) => (
  //           <SelectTag key={item.value}>{item.label}</SelectTag>
  //         ))}
  //       </StackedRow>
  //     );
  //   }

  function renderValue(options: SelectOption<string>[] | null) {
    let content = null;

    if (!options) return content;

    return (
      <Stack
        direction="row"
        spacing={1}
        maxWidth="calc(100% - 30px)"
        overflow={"auto"}
        sx={{
          whiteSpace: "nowrap",
          "::-webkit-scrollbar": {
            height: "0px",
          },
        }}
      >
        {options.map((item) => (
          <SelectTag key={item.value}>{item.label}</SelectTag>
        ))}
      </Stack>
    );
  }

  const formIsValid =
    enteredFullNameIsValid &&
    enteredEmailIsValid &&
    // enteredCompanyNameIsValid &&
    enteredJobTitleIsValid &&
    enteredGenderIsValid &&
    enteredPartnerIsValid &&
    enteredEthnicityIsValid &&
    enteredChallengeIsValid &&
    enteredEmailIsValid &&
    interests &&
    interests.length > 0;

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  const emailSender = async () => {
    // MAILER START

    var subUrl = `${process.env.REACT_APP_FORUM_URL}/email-caring-awards`;

    const emailer = {
      email: enteredEmail,
      name: capitalizer(enteredFullName),
      type: "PARTNER_SUMMIT_REG",
      title:
        "Confirmation: Your Registration for the Care At Work Summit [Partner Registration]",
    };
    const response = await fetch(subUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(emailer),
    });

    // console.log(emailer);

    // MAILER END
  };

  // Split the full name into an array of words
  let nameArray = enteredFullName.split(" ");

  // Extract the first and last names
  let firstName = nameArray[0];
  let lastName = nameArray[nameArray.length - 1];

  const flodeskSubmit = async () => {
    // e.preventDefault();
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "MH WorkLife (www.mhworklife.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: enteredEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
        }),
      });
      flodeskSegmentSubmit();
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["651308b6fc3c5dd11b428798"]; // Replace with actual segment IDs
    // const testsegmentIds = ["64edd24f139c9cfad37e4808"]; // Replace with actual segment IDs
    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "MH WorkLife (www.mhworklife.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "in-person-summit-registrations"), {
      fullName: enteredFullName,
      companyEmail: enteredEmail,
      gender: enteredGender,
      ethnicity: enteredEthnicity,
      company: enteredPartner,
      jobTitle: enteredJobTitle,
      topicOfInterest: interests?.join(", "),
      workLifeChallenge: enteredChallenge,
      createdAt: serverTimestamp(),
    });

    emailSender();
    flodeskSubmit();
    handleOpenDialog();
    resetForm();
    setTimeout(() => {
      history.push("/summit");
    }, 3000);

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const resetForm = () => {
    resetEmailInput();
    resetChallengeInput();
    resetEthnicityInput();
    resetGenderInput();
    resetJobTitleInput();
    resetPartnerInput();
    resetFullNameInput();
    interestInputChangeHandler([]);
    // fullNameInputChangeHandler("");
    // emailInputChangeHandler("");
    // genderInputChangeHandler("");
    // ethnicityInputChangeHandler("");
    // partnerInputChangeHandler("");
    // jobTitleInputChangeHandler("");
    // challengeInputChangeHandler("");
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();
  const {
    openDialog: opener,
    handleOpenDialog: handlerOpen,
    handleCloseDialog: handlerClose,
  } = useDialog();

  return (
    <React.Fragment>
      <MHFormGroup
        onSubmit={submitHandler}
        disableWhileSubmitting={httpState.loading}
      >
        <Grid container spacing={2} className="lg:px-8">
          <Grid item xs={12} md={6}>
            <MHFormControl
              id="fullName"
              type="text"
              label="Full Name"
              placeholder="Enter Full Name"
              value={enteredFullName}
              onChange={fullNameInputChangeHandler}
              onBlur={fullNameInputBlurHandler}
              error={resolveErrorMessage(enteredFullNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MHFormControl
              id="email"
              type="email"
              label="Company Email"
              placeholder="Email"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              error={resolveErrorMessage(enteredEmailHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MHSelect
              label="Gender"
              placeholder="select your gender"
              options={constants.GENDER}
              value={enteredGender}
              onChange={(val) => genderInputChangeHandler(val as string)}
              onBlur={genderInputBlurHandler}
              error={resolveErrorMessage(enteredGenderHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MHSelect
              label="Ethnicity"
              placeholder="select your ethnicity"
              options={constants.ETHNICITY_OPTIONS}
              value={enteredEthnicity}
              onChange={(val) => ethnicityInputChangeHandler(val as string)}
              onBlur={ethnicityInputBlurHandler}
              error={resolveErrorMessage(enteredEthnicityHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MHSelect
              label="Partner"
              placeholder="Select Company"
              options={PARTNERS}
              value={enteredPartner}
              onChange={(val) => partnerInputChangeHandler(val as string)}
              onBlur={partnerInputBlurHandler}
              error={resolveErrorMessage(enteredPartnerHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <MHFormControl
              id="companyName"
              type="text"
              label="Company Name"
              placeholder="Enter Company Name"
              value={enteredCompanyName}
              onChange={companyNameInputChangeHandler}
              onBlur={companyNameInputBlurHandler}
              error={resolveErrorMessage(enteredCompanyNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid> */}

          <Grid item xs={12} md={6}>
            <MHFormControl
              id="jobTitle"
              type="text"
              label="Job Title"
              placeholder="Enter Job Title"
              value={enteredJobTitle}
              onChange={jobTitleInputChangeHandler}
              onBlur={jobTitleInputBlurHandler}
              error={resolveErrorMessage(enteredJobTitleHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <MHMultiSelect
              label="Please indicate which of these topics are of interest to you:"
              placeholder="Please indicate which of these topics are of interest to you:"
              options={constants.INTEREST_TOPICS}
              value={interests as string[]}
              onChange={interestInputChangeHandler}
              onBlur={() => {}}
              renderValue={renderValue}
            />

            {/* <MHMultiSelect
              label="Interests"
              placeholder="What are your interests?"
              options={interestsData.map((interest) => ({
                label: interest.interest,
                value: interest.interest
              }))}
              value={interests as string[]}
              onChange={interestInputChangeHandler}
              renderValue={renderValue}
            /> */}
          </Grid>

          <Grid item xs={12} className="pb-4">
            <MHFormControl
              id="message"
              type="text"
              label="Have you faced any work and life challenges related to caregiving? If so please describe."
              placeholder="Have you faced any work and life challenges related to caregiving? If so please describe."
              value={enteredChallenge}
              onChange={challengeInputChangeHandler}
              onBlur={challengeInputBlurHandler}
              error={resolveErrorMessage(enteredChallengeHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
              multiline
              rows={5}
            />
          </Grid>

          <Grid item xs={12} className="lg:px-1 lg:pl-3 mb-8">
            <MHButton
              type="submit"
              fullWidth
              loading={httpState.loading}
              className="text-[12px] leading-[102%] w-full tracking-[0.05em] md:h-[52px] "
            >
              Submit
            </MHButton>
          </Grid>
        </Grid>
      </MHFormGroup>

      {openDialog && (
        <RequestLinkConfirmation
          open={openDialog}
          onClose={handleCloseDialog}
          header="We'll be in touch soon!"
          message="Thank you for registering for Care At Work summit "
          // ticketLink={"/summit#registration"}
          // ticketName="tickets now"
        />
      )}
      {opener && (
        <RequestLinkConfirmation
          open={opener}
          onClose={handlerClose}
          header="Partner Registration Limit Reached!"
          message={`Thank you for your interest in the Care At Work summit; however, this partner has reached the allocated limit.`}
          // ticketLink={"/summit#registration"}
          // ticketName="tickets now"
        />
      )}
    </React.Fragment>
  );
};

export default PartnerInPersonRegistration;
