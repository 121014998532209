import React from "react";

import { SelectOption } from "@mui/base";
import ContactDialog from "../../components/Contact/BlessingDialog";
import useDialog from "../../hooks/use-dialog";

type StaticDataType = {
  states: SelectOption<string>[];
  configureStates: (geoData: any) => void;
  openWaitlist: boolean;
  handleOpen: () => void;
  closeWaitlist: () => void;
  handleOpenDemoRequest: () => void;
};

const StaticDataContext = React.createContext<StaticDataType>({
  states: [],
  configureStates: (geoData: any) => {},
  openWaitlist: false,
  handleOpen: () => {},
  closeWaitlist: () => {},
  handleOpenDemoRequest: () => {},
});

export const StaticDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [states, setStates] = React.useState<Array<SelectOption<string>>>([]);

  const configureStates = React.useCallback((geoData: Array<unknown>) => {
    const mappedStates = geoData
      .filter((item: any) => item.fields.ste_type === "state")
      .map((item: any) => {
        return {
          value: item.fields.ste_name,
          label: item.fields.ste_name,
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    setStates(mappedStates);
  }, []);

  const {
    openDialog: openWaitlist,
    handleOpenDialog: handleOpenWailist,
    handleCloseDialog: handleCloseWaitlist,
  } = useDialog();

  const {
    openDialog: openDemoRequest,
    handleOpenDialog: handleOpenDemoRequest,
    handleCloseDialog: handleCloseDemoRequest,
  } = useDialog();

  const contextValue: StaticDataType = {
    states,
    configureStates,
    openWaitlist,
    handleOpen: handleOpenWailist,
    closeWaitlist: handleCloseWaitlist,
    handleOpenDemoRequest,
  };

  return (
    <StaticDataContext.Provider value={contextValue}>
      <ContactDialog open={openDemoRequest} onClose={handleCloseDemoRequest} />

      {children}
    </StaticDataContext.Provider>
  );
};

export default StaticDataContext;
