import { Avatar, Box, Typography } from "@mui/material";
import React from "react";

type Props = {};

const AboutCEO = (props: Props) => {
  return (
    <React.Fragment>
      <Avatar
        alt="Blessing Adesiyan"
        className="h-[150px] w-[150px] md:h-[200px] md:w-[200px] mx-auto md:-mt-24 -mt-20"
        src="https://res.cloudinary.com/mother-honestly/image/upload/v1663241743/BlessingAdesiyan_eixt3a.png"
      />
      <Box className="text-center mt-10 w-[80%] md:w-[100%] mx-auto">
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "12px", sm: "12px" }}
          lineHeight={{ xs: "100%", sm: "102%" }}
          width={{ xs: "100px", sm: "369px" }}
          letterSpacing={"0.1em"}
          className="uppercase opacity-50 md:opacity-100 "
        >
          BLESSING ADESIYAN | Founder & CEO, Mother Honestly Group Inc.
        </Typography>
        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "30px", sm: "38px", md: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize py-4 w-[321px] md:w-[700px] text-center mx-auto"
        >
          We are closing the care gap for families, workplaces, and economies
          through advocacy, community, and technology
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[335px] md:w-[700px] pt-4 text-center mx-auto"
        >
          From the tender age of 13, life thrust me into the realm of
          caregiving. In the wake of my parents' separation, I became the pillar
          for my five siblings, navigating the roles of caregiver, housekeeper,
          and guardian. These early experiences laid the foundation for a
          lifetime commitment to care, a commitment that would only deepen with
          time.
          <br />
          <br />
          By 22, as a single mother stepping into the esteemed halls of DuPont
          to forge a path as a Chemical Engineer, I was no stranger to the
          intricacies of caregiving. My personal journey intertwined with my
          professional aspirations, leading me to champion the cause of parents
          and caregivers within the corporate sphere. Over 15 years, amidst the
          challenges of exhaustion and the relentless pace of Fortune 100
          environments, I not only ascended the corporate ladder but also became
          a steadfast advocate for policies that support and uplift women
          burdened by the heavy mantle of caregiving responsibilities.
          <br />
          <br />
          The call to care echoed once more as I stepped in to support my
          father, facing firsthand the gaps in our systems for aging care. This
          experience solidified my resolve that dignity and respect in eldercare
          are non-negotiable, igniting a passion to effect change on a grand
          scale.
          <br />
          <br />
          Today, as CEO and a mother of four, straddling the vibrant cities of
          New York and Lagos, Nigeria, my perspective on care has expanded to a
          global vista. I've come to understand that care is not just a personal
          issue but a universal one, critical to the growth of economies and the
          wellbeing of populations worldwide. Addressing the needs of children,
          women, families, the elderly, the disabled, and the ill is paramount
          for fostering active economic participation and holistic wellbeing.
          <br />
          <br />
          At Caring Bloc, through initiatives like The Care Gap, Caring Blocks,
          and Caring Africa, we're not just dreaming of a better future; we're
          actively constructing it. Our mission is to bridge the care gap,
          partnering with leading organizations and funders to catalyze not just
          economic growth, but a culture of care that permeates every facet of
          society. Together, we're building a world where care is woven into the
          fabric of our daily lives, ensuring that every individual, regardless
          of age or circumstance, is supported, valued, and empowered.
          <br />
          <br />
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default AboutCEO;
