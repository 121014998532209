import React from 'react';
import useToast from './use-toast';

const useToastHttp = (config: {
  httpError: {
    message: string;
  } | null;
}) => {
  const toast = useToast();

  React.useEffect(() => {
    if (config.httpError) {
      toast(config.httpError.message, 'error');
    }
  }, [config.httpError]);
};

export default useToastHttp;
