import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import * as validators from "../../utils/validators";
import { ReactComponent as ArrowRightIcon } from "../../static/svg/arrow-right.svg";

import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import useDialog from "../../hooks/use-dialog";
import useInput from "../../hooks/use-input";

import MHFormGroup from "../Common/Form/MHFormGroup";
import { Box } from "@mui/material";
import MHButton from "../Common/Button/MHButton";
import MHTextInput from "../Common/Form/MHTextInput";
import RequestConfirmationPrompt from "../Contact/RequestConfirmation";

const Subscribe = () => {
  const [error, setError] = React.useState(false);
  // const { loading, error, sendHttpRequest } = useHttp();

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  const errorMail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(true);
  };

  const newsLetterHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "newsletter-subscriptions"), {
      NewsLetterEmail: enteredWorkEmail,
      createdAt: serverTimestamp(),
    });

    handleOpenDialog();
    resetForm();
    setError(false);

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const resetForm = () => {
    workEmailInputChangeHandler("");
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <Stack
      className="subscribe-component"
      justifyContent="center"
      alignItems="center"
      sx={{
        "& svg": {
          color: "common.white",
        },
        "& button:hover svg": {
          color: "primary.main",
        },
      }}
    >
      <Typography
        variant="body1"
        fontFamily="Area-Normal-Black"
        textTransform="uppercase"
        color="#194049"
        fontSize={13}
        letterSpacing="0.1em"
        lineHeight="102%"
        sx={{
          mb: 2,
          paddingBottom: 3,
        }}
      >
        Subscribe to care
      </Typography>

      <Typography
        variant="h1"
        align="center"
        paragraph
        className="md:block hidden"
        sx={{
          mb: 2,
          paddingBottom: 5,
        }}
      >
        Everything you need to flourish at home and in the <br /> workplace,
        straight to your inbox weekly.
      </Typography>

      <Typography
        variant="h3"
        align="center"
        // textTransform="capitalize"
        paragraph
        className="block md:hidden font-areaSemi"
        sx={{
          mb: 2,
          paddingBottom: 5,
        }}
      >
        Everything you need to flourish <br /> at home and in the workplace,
        <br /> straight to your inbox weekly.
      </Typography>

      <MHFormGroup
        onSubmit={
          validators.email(enteredWorkEmail) === true
            ? newsLetterHandler
            : errorMail
        }
        disableWhileSubmitting={
          validators.email(enteredWorkEmail) === true
            ? httpState.loading
            : false
        }
      >
        <Box
          component={"div"}
          width={250}
          display="flex"
          bgcolor={"#F1F1F1"}
          sx={{
            width: "380px",
          }}
        >
          <MHTextInput
            id="email"
            type="email"
            placeholder="Enter your email address"
            value={enteredWorkEmail}
            onChange={workEmailInputChangeHandler}
            onBlur={workEmailInputBlurHandler}
            required
            className="flex-grow h-fit"
          />

          <MHButton
            type="submit"
            loading={httpState.loading}
            sx={{
              minWidth: "auto",
              "& svg": {
                stroke: "grey.500",
                width: "1rem",
              },
              "&.MuiButton-root:hover svg": {
                stroke: "primary",
              },
            }}
          >
            {<ArrowRightIcon />}
          </MHButton>
        </Box>

        {error === false ? (
          ""
        ) : enteredWorkEmail !== "" ? (
          ""
        ) : (
          <Typography
            variant="body2"
            color="red"
            fontSize={{ xs: "11px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "230%", sm: "102%" }}
            letterSpacing={"0.1em"}
            sx={{
              mb: 2,
              pt: 1,
              paddingBottom: 3,
            }}
          >
            Enter a valid email address
          </Typography>
        )}
      </MHFormGroup>

      {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={handleCloseDialog}
          header="Subscription Successful!"
          message="Thank you for subscribing on MH WorkLife weekly newsletter. We will be in touch with
          you shortly."
        />
      )}

      {/* <SearchField
        placeholder="Enter Email"
        icon={<ArrowRightIcon />}
        sx={{
          width: "380px",
        }}
        loading={loading}
        onSearch={subscribe}
      />

      {subscribed && (
        <div className="flex space-x-2 items-center mt-2">
          <ResultIcon type="success" />
          <span>Thank you for subscribing!</span>
        </div>
      )} */}
    </Stack>
  );
};

export default Subscribe;
