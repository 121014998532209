import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { PartnershipCard } from "./PartnershipCard";

type Props = {};

const HowToPartner = (props: Props) => {
  return (
    <Fragment>
      <Box className="bg-lilac-300 overflow-hidden pb-10">
        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "30px", sm: "40px", md: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize pt-10 pb-6 md:w-[409px] w-[298px] text-center mx-auto"
        >
          How To Partner With Us
        </Typography>

        <Grid
          container
          spacing={0}
          className="lg:ml-5 lg:px-12 md:gap-4 lg:gap-0 md:mx-auto md:place-content-center"
        >
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/CuratedEvents.png"
              title="Curated Events"
              content="The Care Gap Care At Work Summit, City Meet-Ups, Offsites, Retreats, and other custom events. We integrate your brand into activities."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/DigitalEvents.png"
              title="Digital Events"
              content="Reach a diverse audience through our virtual workshops, webinars, summits, and events featuring your products and services."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/Campaigns.png"
              title="Campaigns"
              content="Reach over 25M+ parents, caregivers, employers, and more through our targeted social campaigns designed to inspire and take action."
            />
          </Grid>

          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/CustomizedSolutions.png"
              title="Customized Solutions"
              content="We create customized solutions tailored to your marketing and PR needs to build awareness, trust, and position you as a thought-leader."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/Grants.png"
              title="Grants"
              content="Provide care grants and support our nonprofits - WorkLife Africa and WorkLife Equity to elevate care for underprivileged and historically excluded employees."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/Podcast.png"
              title="Podcast"
              content="Sponsor any of our podcast seasons or place ad reel within our podcast with over 1M+ downloads."
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default HowToPartner;
