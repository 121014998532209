import { Box, Typography } from "@mui/material";
import React from "react";

type BulletProps = {
  bulletData?: string[];
};

const LegalAlphabet: React.FC<BulletProps> = ({ bulletData = [] }) => {
  return (
    <Box className="grid items-center gap-2  pl-4">
      {bulletData.map((item, index) => (
        <div key={index} style={{ display: "flex", alignItems: "start" }}>
          <span
            style={{ marginRight: "0.5em", fontSize: "16px" }}
            className=" lowercase"
          >
            {String.fromCharCode(65 + index)}.
          </span>
          <Typography
            variant="body2"
            color="primary"
            fontSize={{ xs: "14px", sm: "16px" }}
            lineHeight={{ xs: "185%", sm: "180%" }}
            letterSpacing={"0.02em"}
            // className=" mx-auto text-start"
          >
            {item}
          </Typography>
        </div>
      ))}
    </Box>
  );
};

export default LegalAlphabet;
