import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type Props = {
  name: string | any;
  company: string | any;
  title: string | any;
  imgSrc: string | any;
};

const SpeakerCard = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="h-[435px] md:h-[435px] w-[300px] bg-cream-100 rounded-b-[6px]">
        <img
          src={props.imgSrc}
          alt={props.title}
          className="w-[300px] h-[300px] rounded-t-[6px] object-cover"
        />
        <Box className="pl-4 ">
          <Typography
            variant="body1"
            fontSize={{ xs: "14px", sm: "15px", md: "15.6px" }}
            fontWeight={700}
            lineHeight={{ xs: "100%", sm: "100%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="text-start pt-5 py-3"
          >
            {props.name}
          </Typography>

          <hr className="block w-[95%]  h-0 border-t-navy-900 opacity-10" />

          <Typography
            variant="body1"
            fontSize={{ xs: "12px", sm: "12px", md: "12px" }}
            fontWeight={600}
            lineHeight={{ xs: "180%", sm: "150%" }}
            letterSpacing={{ xs: "0.04em", sm: "0.04em" }}
            className="text-start py-3"
          >
            {props.company}
          </Typography>

          <Typography
            variant="body1"
            fontSize={{ xs: "11.61px", sm: "11.61px", md: "11.61px" }}
            fontWeight={600}
            lineHeight={{ xs: "120%", sm: "120%" }}
            letterSpacing={{ xs: "0.04em", sm: "0.04em" }}
            className="text-start  opacity-40"
          >
            {props.title}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SpeakerCard;
