import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import MHSlider, {
  MHSliderItem,
} from "../../Common/SliderCarousel/Slider/MHSlider";
import ContentCard from "../../UI/ContentCard";
import MHmarquee from "../../Common/Marquee/MHmarquee";

const CONTENT = [
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/custom-program.png",
    // https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/custom-program.png
    // 'https://res.cloudinary.com/mother-honestly/image/upload/v1688473369/Website_Images_-_How_It_Works_-_Care_Wallet_2_cyhhzd.png',
    title: "Customized Programs",
    description:
      "We leverage data to create tailored programs addressing the specific needs of your organization. Whether it's parental leave policies, caregiver support, or fostering inclusivity, our programs are data-driven and employee-centered.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/leader-training.png",
    title: "Leadership Training",
    description:
      "Our data-backed leadership training equips leaders with the skills to manage diverse teams effectively, providing valuable insights into caring leadership's impact on business outcomes.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/community-support.png",
    title: "Editorial and Community",
    description:
      "We create tailor-made communications, newsletters, and more to elevate care for your employees while fostering a community where employees and leaders can connect, share experiences, and learn from one another.",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/research-insights.png",
    title: "Research and Insights",
    description:
      "Our institution constantly conducts and updates research on caregiving, wellbeing, and caring leadership. We provide access to the latest insights to inform your corporate strategy.",
  },
];

const DataPoweredSolutionsSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Box width={{ xs: "90%", sm: "75%" }} mx="auto" mt={7}>
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          Performance-Driven Care Solutions
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: "100%", md: "70%" }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph
        >
          In a world where care and inclusivity are paramount, we partner with
          organizations to reshape a new future of work. With your business
          objective as our compass, we are committed to transforming workplaces
          into spaces where every individual, regardless of their caregiving
          responsibilities, can thrive. Join us in this journey towards a more
          caring, inclusive, and prosperous corporate world.
        </Typography>
      </Box>

      <Box py="3rem" px={{ xs: 0, md: "4rem" }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={5}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', md: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          An Array Of Care Solutions Throughout Your Employees Working Lives
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Many Employees Are Being Crushed Under The Unrelenting and Unexpected
          Burden Of Care, It’s Time Your Benefits Care
        </Typography>
      </Box>

      {!matchesMdDown && (
        <Box py="3rem" px={'4rem'}>
          <MHSlider slidesToShow={3} slidesToScroll={1}>
            {CONTENT.map((item, index) => (
              <MHSliderItem width="100%" key={index}>
                <ContentCard
                  imageSrc={item.imageSrc}
                  title={item.title}
                  description={item.description}
                  onMouseClick={() => {}}
                />
              </MHSliderItem>
            ))}
          </MHSlider>
        </Box>
      )}

      {matchesMdDown && (
        <Grid
          container
          direction={{ xs: 'row' }}
          flexWrap="nowrap"
          rowSpacing={{ xs: 0, sm: 0 }}
          columnSpacing={3}
          mt={2}
          width={{ xs: '90%', sm: '80%' }}
          mx="auto"
          mb={8}
          overflow="auto"
          className="horizontal-scroll">
          {CONTENT.map((item) => (
            <Grid item xs={12} sm={4} flexShrink={0}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </Grid>
          ))}
        </Grid>
      )} */}
    </div>
  );
};

export default DataPoweredSolutionsSlider;
