import React from "react";

import Grid from "@mui/material/Grid";
import {
  CONTACT_EMPLOYEE_NO,
  CONTACT_INTEREST,
  CONTACT_ROLE,
  CONTACT_TITLE_LEVEL,
} from "../../utils/landing-data";
import MHButton from "../Common/Button/MHButton";

import MHFormControl from "../../components/Common/Form/MHFormControl";
import useInput from "../../hooks/use-input";
import * as validators from "../../utils/validators";
import * as constants from "../../utils/constants";
import { MHSelect } from "../Common/Form/MHSelect";
import MHCheckbox from "../Common/Form/MHCheckbox";
import useCheckbox from "../../hooks/use-checkbox";
import MHFormGroup from "../Common/Form/MHFormGroup";
import MHPhoneInput from "../Common/PhoneInput/MHPhoneInput";
import usePhoneInput from "../../hooks/use-phone";

///firebase
import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { resolveErrorMessage } from "../../utils/utils";
import useForm from "../../hooks/use-form";
import AppContext from "../../store/context/app.context";
import DemoRequestConfirmationDialog from "./DemoRequestConfirmationDialog";
import useDialog from "../../hooks/use-dialog";
import { MuiTelInputInfo } from "mui-tel-input";
import { TopLevelCategory } from "../../models/dashboard.model";

const ContactForm = () => {
  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    error: enteredFirstNameHasError,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    error: enteredLastNameHasError,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredCompanyName,
    valid: enteredCompanyNameIsValid,
    error: enteredCompanyNameHasError,
    onChange: companyNameInputChangeHandler,
    onBlur: companyNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEmail,
    valid: enteredEmailIsValid,
    error: enteredEmailHasError,
    onChange: emailInputChangeHandler,
    onBlur: emailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
    {
      validator: (value: string) => validators.email(value),
    },
  ]);

  const {
    value: enteredMessage,
    valid: enteredMessageIsValid,
    error: enteredMessageHasError,
    onChange: messageInputChangeHandler,
    onBlur: messageInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredLevel,
    valid: enteredLevelIsValid,
    error: enteredLevelHasError,
    onChange: levelInputChangeHandler,
    onBlur: levelInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const {
    value: enteredRole,
    valid: enteredRoleIsValid,
    error: enteredRoleHasError,
    onChange: roleInputChangeHandler,
    onBlur: roleInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const {
    value: enteredState,
    valid: enteredStateIsValid,
    error: enteredStateHasError,
    onChange: stateInputChangeHandler,
    onBlur: stateInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const {
    value: enteredNoOfEmployees,
    valid: enteredNoOfEmployeesIsValid,
    error: enteredNoOfEmployeesHasError,
    onChange: noOfEmployeesInputChangeHandler,
    onBlur: noOfEmployeesInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const {
    value: enteredInterest,
    valid: enteredInterestIsValid,
    error: enteredInterestHasError,
    onChange: interestInputChangeHandler,
    onBlur: interestInputBlurHandler,
  } = useInput([{ validator: (value: string) => validators.required(value) }]);

  const {
    phoneNumber,
    // phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const { checked, handleCheck } = useCheckbox(false);

  const formIsValid =
    enteredFirstNameIsValid &&
    enteredLastNameIsValid &&
    enteredCompanyNameIsValid &&
    enteredEmailIsValid &&
    enteredLevelIsValid &&
    enteredRoleIsValid &&
    enteredStateIsValid &&
    enteredNoOfEmployeesIsValid &&
    enteredInterestIsValid &&
    enteredMessageIsValid &&
    checked;

  const appCtx = React.useContext(AppContext);
  const { states, staticDataCacheMap } = appCtx;

  const topLvlCategories: TopLevelCategory[] = React.useMemo(
    () => staticDataCacheMap.get("topLevelCategories") || [],
    [staticDataCacheMap]
  );

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  const flodeskSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables

    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "MH WorkLife (www.mhworklife.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: enteredFirstName,
          last_name: enteredLastName,
          email: enteredEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
          custom_fields: {
            title: enteredLevel,
            role: enteredRole,
            state: enteredState,
            employeesnumber: enteredNoOfEmployees,
            companyname: enteredCompanyName,
            companyphone: phoneNumber,
            interest: enteredInterest,
            message: enteredMessage,
          },
        }),
      });

      flodeskSegmentSubmit();

      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["651597e4e726e853c14a2930"]; // Replace with actual segment IDs
    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "MH WorkLife (www.mhworklife.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateForm();

    if (!formIsValid) {
      return;
    }

    flodeskSubmit();
    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "demo-requests"), {
      firstName: enteredFirstName,
      lastName: enteredLastName,
      company: enteredCompanyName,
      email: enteredEmail,
      phone: phoneNumber,
      level: enteredLevel,
      role: enteredRole,
      state: enteredState,
      noOfEmployees: enteredNoOfEmployees,
      contactInterest: enteredInterest,
      message: enteredMessage,
      consent: checked,
      createdAt: serverTimestamp(),
    });

    // resetForm();
    handleOpenDialog();

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const { validateForm } = useForm();

  const resetForm = () => {
    firstNameInputChangeHandler("");
    lastNameInputChangeHandler("");
    companyNameInputChangeHandler("");
    emailInputChangeHandler("");
    phoneNumberInputChangeHandler("", {} as MuiTelInputInfo);
    levelInputChangeHandler("");
    roleInputChangeHandler("");
    stateInputChangeHandler("");
    noOfEmployeesInputChangeHandler("");
    interestInputChangeHandler("");
    messageInputChangeHandler("");
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <React.Fragment>
      <MHFormGroup
        onSubmit={submitHandler}
        disableWhileSubmitting={httpState.loading}
      >
        <Grid container spacing={1} className="lg:px-8">
          <Grid item xs={12} sm={6} md={5} lg={5} className="lg:px-2">
            <MHFormControl
              id="firstName"
              type="text"
              label="First Name"
              placeholder="Enter Name"
              value={enteredFirstName}
              onChange={firstNameInputChangeHandler}
              onBlur={firstNameInputBlurHandler}
              error={resolveErrorMessage(enteredFirstNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={5} className="lg:px-2">
            <MHFormControl
              id="lastName"
              type="text"
              label="Last Name"
              placeholder="Enter Name"
              value={enteredLastName}
              onChange={lastNameInputChangeHandler}
              onBlur={lastNameInputBlurHandler}
              error={resolveErrorMessage(enteredLastNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <MHFormControl
              id="companyName"
              type="text"
              label="Company Name"
              placeholder="Enter Company Name"
              value={enteredCompanyName}
              onChange={companyNameInputChangeHandler}
              onBlur={companyNameInputBlurHandler}
              error={resolveErrorMessage(enteredCompanyNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5} lg={5} className="lg:px-2">
            <MHFormControl
              id="companyEmail"
              type="text"
              label="Company Email"
              placeholder="Enter Email"
              value={enteredEmail}
              onChange={emailInputChangeHandler}
              onBlur={emailInputBlurHandler}
              error={resolveErrorMessage(enteredEmailHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5} lg={5} className="lg:px-2">
            <MHPhoneInput
              value={phoneNumber}
              onChange={phoneNumberInputChangeHandler}
              label="Company Phone"
              errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                "Please enter a valid phone number"
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5} lg={5} className="lg:px-2">
            <MHFormControl
              id="title-level"
              type="text"
              label="Title level in the company"
              placeholder="Title level in the company"
              value={enteredLevel}
              onChange={(e) => levelInputChangeHandler(e)}
              onBlur={levelInputBlurHandler}
              error={resolveErrorMessage(enteredLevelHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5} lg={5} className="lg:px-2">
            <MHFormControl
              id="role"
              type="text"
              label="Role in the company"
              placeholder="Role in the company"
              value={enteredRole}
              onChange={(e) => roleInputChangeHandler(e)}
              onBlur={roleInputBlurHandler}
              error={resolveErrorMessage(enteredRoleHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5} lg={5} className="lg:px-2">
            <MHSelect
              label="state"
              placeholder="State"
              options={states}
              value={enteredState}
              onChange={(val) => stateInputChangeHandler(val as string)}
              onBlur={stateInputBlurHandler}
              error={resolveErrorMessage(enteredStateHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={5} lg={5} className="lg:px-2">
            <MHSelect
              label="No. of employees"
              placeholder="No. of employees"
              options={constants.COMPANY_SIZE_OPTIONS}
              value={enteredNoOfEmployees}
              onChange={(val) => noOfEmployeesInputChangeHandler(val as string)}
              onBlur={noOfEmployeesInputBlurHandler}
              error={resolveErrorMessage(enteredNoOfEmployeesHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={10} lg={10} className="">
            <MHSelect
              label="I’m interested in..."
              placeholder="I’m interested in..."
              options={constants.CONTACT_PURPOSE_LIST}
              value={enteredInterest}
              onChange={(val) => interestInputChangeHandler(val as string)}
              onBlur={interestInputBlurHandler}
              error={resolveErrorMessage(enteredInterestHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={10} lg={10} className="pb-4">
            <MHFormControl
              id="message"
              type="text"
              label="Write a Message"
              placeholder="Start typing..."
              value={enteredMessage}
              onChange={messageInputChangeHandler}
              onBlur={messageInputBlurHandler}
              error={resolveErrorMessage(enteredMessageHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
              multiline
              rows={5}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            lg={10}
            className="md:px-0 pb-4 flex"
          >
            <MHCheckbox
              label="By submitting my information, I consent to receive electronic
            communications from The Care Gap"
              value={checked}
              onChange={handleCheck}
              sx={{
                fontSize: "11px",
                lineHeight: "160.5%",
                letterSpacing: "0.04em",
                // opacity: 0.5
              }}
              required
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            lg={10}
            className="lg:px-1 lg:pl-3 mb-8"
          >
            <MHButton
              type="submit"
              loading={httpState.loading}
              className="text-[12px] leading-[102%] w-full tracking-[0.05em] md:h-[52px] "
            >
              Submit
            </MHButton>
          </Grid>
        </Grid>
      </MHFormGroup>

      {openDialog && (
        <DemoRequestConfirmationDialog
          open={openDialog}
          onClose={handleCloseDialog}
        />
      )}
    </React.Fragment>
  );
};

export default ContactForm;
