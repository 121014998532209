import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import MHButton from "../components/Common/Button/MHButton";
import FaqContactHeader from "../components/Common/Header/FaqContactHeader";
import MHFooter from "../components/Layout/MHFooter";
import MHSubscribe from "../components/Layout/MHSubscribe";
import CaringLeaderForm from "../components/Summit/CaringLeaderForm";
import PartnerInPersonRegistration from "../components/Summit/PartnerInPersonRegistration";
import useTitle from "../hooks/use-title";
import useAnalytics from "../hooks/use-analytics";

const LEADER_FACTORS = [
  {
    id: 1,
    caption: "Embraces Diversity and Inclusion: ",
    text: "Promote an inclusive leadership style that embraces diversity and texs the unique perspectives and experiences of all team members.",
  },
  {
    id: 2,
    caption: "Empowers and Support Employees: ",
    text: "Empower employees by providing the necessary resources, tools, and support to thrive personally and professionally.",
  },
  {
    id: 3,
    caption: "Encourages Work-Life Integration: ",
    text: "Foster a work environment that encourages work-life integration and recognizes the importance of personal well-being.",
  },
];

type Props = {};

const RegSummitPartner = (props: Props) => {
  let history = useHistory();

  const handleOpenCaringWorkplace = () => {
    history.push(`/summit/caring-workplace`);
  };

  const handleOpenCaringLeaders = () => {
    history.push(`/summit/caring-leader`);
  };

  useTitle('Summit Partners');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Summit Partners'
  });

  return (
    <React.Fragment>
      <FaqContactHeader
        title="Partners Summit Registration"
        textColor="white"
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1672946054/FAQ_ulvkpc.png"
        imageMobSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1672946054/FAQ-mob_kcwqmd.png"
        // Center Class attributes
        headerClassName="absolute items-center top-24 md:top-24 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[231px] mx-auto"

        // Left Class attributes
        // headerClassName="absolute items-center top-32 md:top-40 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      />

      <Grid
        container
        spacing={6}
        className="px-8 md:px-12 lg:pl-20 py-20 place-content-center"
      >
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          className=" order-2 lg:order-none"
        >
          <Box className="relative p-4">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Caring-Leaders-Award.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md md:h-[350px] md:w-[629px] h-[255px] w-[343px]"
            />

             </Box>
          <Box
            justifyContent={"center"}
            display="flex"
          >
            <MHButton
              fullWidth
              onClick={() => handleOpenCaringLeaders()}
              type="submit"
              className=" text-[12px] leading-[102%] w-[35%] md:w-[30%] tracking-[0.05em] h-[52px] "
            >
              Apply Now
            </MHButton>
          </Box>

          <Box className="relative p-4">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Caring-Workplace-Award.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md md:h-[350px] md:w-[629px] h-[255px] w-[343px]"
            />

            </Box>
          <Box
            justifyContent={"center"}
            display="flex"
          >
            <MHButton
              fullWidth
              onClick={() => handleOpenCaringWorkplace()}
              type="submit"
              className=" text-[12px] leading-[102%] w-[35%] md:w-[30%] tracking-[0.05em] h-[52px] "
            >
              Apply Now
            </MHButton>
          </Box>
        </Grid> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={8}
          className=" order-1 lg:order-none"
        >
          <PartnerInPersonRegistration />
        </Grid>
      </Grid>
      {/* FOOTER BEGINS */}
      {/* <MHSubscribe />
      <MHFooter /> */}
    </React.Fragment>
  );
};

export default RegSummitPartner;
