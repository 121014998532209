import React from 'react';
import { useHistory } from 'react-router-dom';
import Cart from '../../components/Features/Checkout/Cart';

import CartDrawer from '../../components/Features/Memberships/CartDrawer';
import CartDrawerContent from '../../components/Features/Memberships/CartDrawerContent';

import useDrawer from '../../hooks/use-drawer';
import { BillingPeriod } from '../../models/membership.type';

const CART_LOCATION = 'cart';

export type Cart = {
  id: number;
  description: string;
  amount: number;
  yearlyCharge: number;
  type: 'membership';
  priceId: string;
  period: BillingPeriod;
};

type CartContextType = {
  cart: Cart[];
  addItemToCart: (
    cartItem: Cart
  ) => (openCart: boolean, e: React.KeyboardEvent | React.MouseEvent) => void;
  removeItemFromCart: (cartId: number) => void;
  openCartDrawer: (e: React.KeyboardEvent | React.MouseEvent) => void;
  closeCartDrawer: (e: React.KeyboardEvent | React.MouseEvent) => void;
};

const CartContext = React.createContext<CartContextType>({
  cart: [],
  addItemToCart: (cartItem: Cart) => (
    openCart: boolean,
    e: React.KeyboardEvent | React.MouseEvent
  ) => {},
  removeItemFromCart: (cartId: number) => {},
  openCartDrawer: (e: React.KeyboardEvent | React.MouseEvent) => {},
  closeCartDrawer: (e: React.KeyboardEvent | React.MouseEvent) => {}
});

export const CartContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const storedCart: string = localStorage.getItem(CART_LOCATION) as string;
  let initialCart: Cart[] = !!storedCart ? [JSON.parse(storedCart)] : [];

  const [cart, setCart] = React.useState<Cart[]>(initialCart);

  const { open: isDrawerOpen, anchor, toggleDrawer } = useDrawer(
    'right',
    false
  );

  const addItemToCart = (cartItem: Cart) => (
    openCart: boolean,
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    setCart((prevCart) => [cartItem]);
    localStorage.setItem(CART_LOCATION, JSON.stringify(cartItem));

    if (openCart) {
      openCartDrawer(e);
    }
  };

  const removeItemFromCart = (cartId: number) => {
    setCart((prevCart) => {
      return prevCart.filter((item) => item.id !== cartId);
    });
    localStorage.removeItem(CART_LOCATION);
  };

  function openCartDrawer(e: React.KeyboardEvent | React.MouseEvent) {
    toggleDrawer(true)(e);
  }

  function closeCartDrawer(e: React.KeyboardEvent | React.MouseEvent) {
    toggleDrawer(false)(e);
  }

  const value: CartContextType = {
    cart: cart,
    addItemToCart,
    removeItemFromCart,
    openCartDrawer,
    closeCartDrawer
  };

  return (
    <CartContext.Provider value={value}>
      {children}
      <CartDrawer
        variant="temporary"
        open={isDrawerOpen}
        anchor={anchor}
        onClose={toggleDrawer(false)}>
        <CartDrawerContent />
      </CartDrawer>
    </CartContext.Provider>
  );
};

export default CartContext;
