import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
  matchPath,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import ArticlesPage from "../components/Resources/Articles/ArticlesPage";
import EventsPage from "../components/Resources/Events/EventsPage";
import PodcastsPage from "../components/Resources/Podcasts/PodcastsPage";
import ToolkitsPage from "../components/Resources/Toolkits/ToolkitsPage";
import ViewToolkit from "../components/Resources/Toolkits/ViewToolkit";
import VideosPage from "../components/Resources/Videos/VideosPage";
import ViewVideo from "../components/Resources/Videos/ViewVideo";
import ViewEvent from "../components/Resources/Events/ViewEvent";
import ViewArticle from "../components/Resources/Articles/ViewArticle";
import ViewPodcast from "../components/Resources/Podcasts/ViewPodcast";
import ViewPastEvent from "../components/Resources/Events/ViewPastEvent";
import AuthContext from "../store/context/auth-context";
import GuardedRoute from "../components/Features/Auth/GuardedRoute";
import MemberUpgradeDialog from "../pages/auth/MemberUpgradeDialog";
import useDialog from "../hooks/use-dialog";

const ResourcesNavigator = (props: { isComponentAccessible: boolean }) => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const authCtx = React.useContext(AuthContext);
  const { auxUserDetails, user } = authCtx;

  const [isSubscriptionActive, setIsSubscriptionActive] = React.useState(false);

  React.useEffect(() => {
    // console.log(auxUserDetails?.subscriptionStatus);
    if (
      user?.email.includes("@mhworklife.com") ||
      auxUserDetails?.subscriptionStatus === "active"
    ) {
      setIsSubscriptionActive(true);
      handleCloseDialog();
    } else {
      setIsSubscriptionActive(false);
    }
    // console.log("User Email", user?.email);
    // console.log("Sub status", isSubscriptionActive);
  }, [auxUserDetails, user]);

  React.useEffect(() => {
    // commented code has been refactored, no longer needed
    // const renderView = function (): boolean {
    //   if (allowedPathMatches.some((match) => match)) {
    //     return true;
    //   }
    //   return false;
    // };
    // if (isSubscriptionActive || renderView()) {
    //   handleCloseDialog();
    // } else {
    //   handleOpenDialog();
    // }
    if (isSubscriptionActive) {
      handleCloseDialog();
    }
  }, [isSubscriptionActive]);

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  const onDenyHandler = () => {
    if (props.isComponentAccessible) {
      handleOpenDialog();
    }
  };

  const articlesPathMatch = matchPath(window.location.pathname, {
    path: "/resources/articles",
    exact: false,
    strict: false,
  });

  const articlesDetailPathMatch = matchPath(window.location.pathname, {
    path: "/resources/articles/:slug/:id",
    exact: false,
    strict: false,
  });

  const podcastsPathMatch = matchPath(window.location.pathname, {
    path: "/resources/podcasts",
    exact: false,
    strict: false,
  });

  const podcastsDetailPathMatch = matchPath(window.location.pathname, {
    path: "/resources/podcasts/:slug/:id",
    exact: false,
    strict: false,
  });

  const allowedPathMatches = [
    articlesPathMatch,
    articlesDetailPathMatch,
    podcastsPathMatch,
    podcastsDetailPathMatch,
    // allPathMatch
  ];

  return (
    // <TransitionGroup>
    //   <CSSTransition
    //     unmountOnExit
    //     key={location.pathname}
    //     classNames="fade"
    //     timeout={400}>
    <>
      <Switch location={location}>
        <Route
          path={`${path}/toolkits`}
          render={(routeProps) => {
            return (
              <GuardedRoute
                isRouteAccessible={isSubscriptionActive}
                protectedRoute={`${path}/toolkits`}
                redirectRoute={routeProps.location.pathname}
                onDeny={onDenyHandler}
                onDestroy={undefined}
                renderGuardedPage={true}
                guardedPage={<ToolkitsPage />}
              />
            );
          }}
          exact
        />

        {/* <Route path={`${path}/toolkits`}>
          <ToolkitsPage />
        </Route> */}

        <Route
          path={`${path}/toolkits/:slug/:id`}
          render={(routeProps) => {
            return (
              <GuardedRoute
                isRouteAccessible={isSubscriptionActive}
                protectedRoute={`${path}/toolkits/:slug/:id`}
                redirectRoute={routeProps.location.pathname}
                onDeny={onDenyHandler}
                onDestroy={undefined}
                renderGuardedPage={true}
                guardedPage={<ViewToolkit />}
              />
            );
          }}
          exact
        />

        <Route
          path={`${path}/videos`}
          render={(routeProps) => {
            return (
              <GuardedRoute
                isRouteAccessible={isSubscriptionActive}
                protectedRoute={`${path}/videos`}
                redirectRoute={routeProps.location.pathname}
                onDeny={onDenyHandler}
                onDestroy={undefined}
                renderGuardedPage={true}
                guardedPage={<VideosPage />}
              />
            );
          }}
          exact
        />

        <Route
          path={`${path}/videos/:slug/:id`}
          render={(routeProps) => {
            return (
              <GuardedRoute
                isRouteAccessible={isSubscriptionActive}
                protectedRoute={`${path}/videos/:slug/:id`}
                redirectRoute={routeProps.location.pathname}
                onDeny={onDenyHandler}
                onDestroy={undefined}
                renderGuardedPage={true}
                guardedPage={<ViewVideo />}
              />
            );
          }}
          exact
        />

        <Route
          path={`${path}/events`}
          render={(routeProps) => {
            return (
              <GuardedRoute
                isRouteAccessible={isSubscriptionActive}
                protectedRoute={`${path}/events`}
                redirectRoute={routeProps.location.pathname}
                onDeny={onDenyHandler}
                onDestroy={undefined}
                renderGuardedPage={true}
                guardedPage={<EventsPage />}
              />
            );
          }}
          exact
        />

        <Route
          path={`${path}/events/:slug/:id`}
          render={(routeProps) => {
            return (
              <GuardedRoute
                isRouteAccessible={isSubscriptionActive}
                protectedRoute={`${path}/events/:slug/:id`}
                redirectRoute={routeProps.location.pathname}
                onDeny={onDenyHandler}
                onDestroy={undefined}
                renderGuardedPage={true}
                guardedPage={<ViewEvent />}
              />
            );
          }}
          exact
        />

        <Route
          path={`${path}/past-events/:slug/:id`}
          render={(routeProps) => {
            return (
              <GuardedRoute
                isRouteAccessible={isSubscriptionActive}
                protectedRoute={`${path}/past-events/:slug/:id`}
                redirectRoute={routeProps.location.pathname}
                onDeny={onDenyHandler}
                onDestroy={undefined}
                renderGuardedPage={true}
                guardedPage={<ViewPastEvent />}
              />
            );
          }}
          exact
        />

        <Route path={`${path}/articles`} exact>
          <ArticlesPage />
        </Route>

        <Route path={`${path}/articles/:slug/:id`} exact>
          <ViewArticle />
        </Route>

        <Route path={`${path}/podcasts`} exact>
          <PodcastsPage />
        </Route>

        <Route path={`${path}/podcasts/:slug/:id`} exact>
          <ViewPodcast />
        </Route>
      </Switch>

      {/* {openDialog && (
        <MemberUpgradeDialog open={openDialog} onClose={handleCloseDialog} />
      )} */}

      {/* 
      
        <Route path={`${path}/events`} exact>
          <EventsPage />
        </Route>
        <Route path={`${path}/events/:slug/:id`} exact>
          <ViewEvent />
        </Route>
        <Route path={`${path}/past-events/:slug/:id`} exact>
          <ViewPastEvent />
        </Route>
              <Route path={`${path}/toolkits`} exact>
          <ToolkitsPage />
        </Route>
        <Route path={`${path}/toolkits/:slug/:id`} exact>
          <ViewToolkit />
        </Route>
      <Route path={`${path}/videos`} exact>
          <VideosPage />
        </Route> 
        <Route path={`${path}/videos/:slug/:id`} exact>
          <ViewVideo />
        </Route>*/}
    </>
    //   </CSSTransition>
    // </TransitionGroup>
  );
};

export default ResourcesNavigator;
