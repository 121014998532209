import { ReactComponent as OldMHIcon } from "../../static/svg/motherhonestly.svg";

export default function OldMHLogo({ style }: { style?: object }) {
  return (
    <OldMHIcon
      width="3.5rem"
      height="auto"
      style={{
        margin: "0 auto",
        ...style,
      }}
    />
  );
}
