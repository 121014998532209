import React from "react";
import FollowMH from "../components/Landing/FollowMH";
import LandingResCategory from "../components/Landing/LandingResCategory";
import MHFooter from "../components/Layout/MHFooter";
import MHmarquee from "../components/Common/Marquee/MHmarquee";

import { ReactComponent as ResLove } from "../static/svg/reslove.svg";
import AboutCEO from "../components/Story/AboutCEO";
import StoryHeader from "../components/Story/StoryHeader";
import StoryGrid from "../components/Story/StoryGrid";
import PressMedia from "../components/Story/PressMedia";
import BlessingCard from "../components/Story/BlessingCard";
import InflutientialSpeakers from "../components/Story/InflutientialSpeakers";
import useTitle from "../hooks/use-title";
import useAnalytics from "../hooks/use-analytics";

type Props = {};

const OurStory = (props: Props) => {
  useTitle("Our Story");

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: "Our Story",
  });

  return (
    <React.Fragment>
      <StoryHeader
        title2="Our Story"
        textColor="primary"
        icon={<ResLove />}
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1675677394/Group_g4c5hj.png"
        imageMobSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1675677394/Group_1_dyorkx.png"
        // Center Class attributes
        headerClassName="absolute items-center top-32 md:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[331px] mx-auto"

        // Left Class attributes
        // headerClassName="absolute items-center top-32 md:top-40 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      />
      <AboutCEO />
      <MHmarquee />
      <StoryGrid />
      <InflutientialSpeakers />
      <LandingResCategory text="Personalized care for our members across the five pillars" />
      <PressMedia />
      <BlessingCard />
      <FollowMH />
      <MHFooter />
    </React.Fragment>
  );
};

export default OurStory;
