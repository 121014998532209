import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MarqueeHorizontal, { MarqueeItemAlt } from "../UI/MarqueeHorizontal";

// type Props = {}

const FollowMH = () => {
  return (
    <React.Fragment>
      <Box className="h-[396px] md:h-[500px] bg-lilac-300">
        {/* WEB */}

        <Box className="hidden md:flex md:gap-8 md:items-center md:place-content-center">
          <Typography
            variant="caption"
            fontSize={{ xs: "12px", sm: "10px" }}
            lineHeight={"102%"}
            letterSpacing={"0.1em"}
            className="uppercase "
          >
            Follow Along
          </Typography>

          <Typography
            variant="h2"
            fontSize={{ xs: "30px", sm: "36px", md: "43px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.06em"}
            className="py-8 text-center"
          >
            @theCareGap
          </Typography>

          <Typography
            variant="caption"
            fontSize={{ xs: "12px", sm: "10px" }}
            lineHeight={"102%"}
            letterSpacing={"0.1em"}
            className="uppercase "
          >
            CARE INFRASTRUCTURE AT WORK
          </Typography>
        </Box>

        {/* Mobile */}

        <Box className="md:hidden block  h-[170px] mx-auto text-center py-8">
          <Typography
            variant="caption"
            fontSize={{ xs: "12px", sm: "10px" }}
            lineHeight={"102%"}
            letterSpacing={"0.1em"}
            className="uppercase text-center"
          >
            CARE INFRASTRUCTURE AT WORK
          </Typography>

          <Typography
            variant="h2"
            fontSize={{ xs: "30px", sm: "36px", md: "43px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.06em"}
            className="py-3 text-center "
          >
            @theCareGap
          </Typography>

          <Typography
            variant="caption"
            fontSize={{ xs: "12px", sm: "10px" }}
            lineHeight={"102%"}
            letterSpacing={"0.1em"}
            className="uppercase text-center"
          >
            follow along
          </Typography>
        </Box>

        <Box className=" w-full h-fit md:py-2">
          <MarqueeHorizontal continuous>
            <MarqueeItemAlt>
              <img
                className="w-[200px] md:w-[300px] h-[200px] md:h-[300px] object-cover rounded-[4px] md:rounded-[6px]"
                src="https://res.cloudinary.com/mother-honestly/image/upload/v1689946620/website_social_4_hekwav.png"
                alt="MH Image"
              />
            </MarqueeItemAlt>
            <MarqueeItemAlt>
              <img
                className="w-[200px] md:w-[300px] h-[200px] md:h-[300px] object-cover rounded-[4px] md:rounded-[6px]"
                src="https://res.cloudinary.com/mother-honestly/image/upload/v1689946617/website_social_3_k3uwhz.png"
                alt="MH Image"
              />
            </MarqueeItemAlt>
            <MarqueeItemAlt>
              <img
                className="w-[200px] md:w-[300px] h-[200px] md:h-[300px] object-cover rounded-[4px] md:rounded-[6px]"
                src="https://res.cloudinary.com/mother-honestly/image/upload/v1689946615/website_social_1_j4gmgp.png"
                alt="MH Image"
              />
            </MarqueeItemAlt>
            <MarqueeItemAlt>
              <img
                className="w-[200px] md:w-[300px] h-[200px] md:h-[300px] object-cover rounded-[4px] md:rounded-[6px]"
                src="https://res.cloudinary.com/mother-honestly/image/upload/v1689946621/Website_social_media_du6b6r.png"
                alt="MH Image"
              />
            </MarqueeItemAlt>
            <MarqueeItemAlt>
              <img
                className="w-[200px] md:w-[300px] h-[200px] md:h-[300px] object-cover rounded-[4px] md:rounded-[6px]"
                src="https://res.cloudinary.com/mother-honestly/image/upload/v1689946616/website_social_2_jewud3.png"
                alt="MH Image"
              />
            </MarqueeItemAlt>
          </MarqueeHorizontal>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default FollowMH;
