import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ReactComponent as YellowIcon } from "../../static/svg/eventyellow.svg";
import MHButton from "../Common/Button/MHButton";
import useDialog from "../../hooks/use-dialog";
import RegistrationDialog from "./RegistrationDialog";

type Props = {};

const summitObj = [
  {
    caption: "Raising Global Awareness: ",
    info: "We will elevate the conversation on care to the global stage, showing how care impacts economies, societies, and future generations.",
  },
  {
    caption: "Driving Policy Action: ",
    info: "In each city, we will engage with policymakers, advocating for bold investments in the care economy, including paid family leave, universal childcare, eldercare support, and protection for care workers.",
  },
  {
    caption: "Building a Global Network: ",
    info: "The summit will convene world leaders, care advocates, businesses, and community organizations to form a unified network dedicated to closing the global care gap.",
  },
  {
    caption: "Making Care a Global Priority: ",
    info: "Our ultimate goal is to ensure care is treated as a global priority—on par with climate change—through sustained advocacy, funding, and international cooperation.",
  },
  // {
  //   caption: "Care Solutions: ",
  //   info: "Discover solutions and brands dedicated to elevating care in the workplace and making a positive impact on the lives of employees and their families.",
  // },
];

const summitAim = [
  {
    info: "Showcase FamTech Solutions: Present innovative technology solutions designed to support working parents and caregivers.",
  },
  {
    info: "Strengthen Resilience: Highlight the importance of building resilience and provide tools and strategies to do so in the workplace.",
  },
  {
    info: "Support Employee Resource Groups: Share effective strategies for nurturing and supporting Employee Resource Groups (ERGs).",
  },
  {
    info: "Highlight SMBs and Startups: Showcase how smaller businesses and startups are innovating in creating balanced workplaces.",
  },
  {
    info: "Foster Networking: Provide opportunities for attendees to connect, collaborate, and learn from one another, fostering a community dedicated to caring workplaces.",
  },
];

const SummitInfo = (props: Props) => {
  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <Fragment>
      <section id="objectives" className="">
        {/* First */}
        <Box className=" lg:flex lg:justify-center lg:gap-10 ">
          <Box className="order-last md:h-[630px] md:w-[630px] h-[343px] w-[343px] mx-auto lg:mx-0">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-8.jpeg"
              alt="Image"
              className=" rounded-[6px] object-cover h-full w-full md:mt-10"
            />
          </Box>
          <Box className="order-first text-center mx-auto lg:mx-0 w-[343px] md:h-[630px] md:w-[630px] md:mt-20 mt-10">
            <Box
              className="flex gap-2 md:-mt-10 md:pb-16 lg:pb-0 lg:mt-0 mx-auto"
              sx={{}}
              justifySelf="center"
              justifyContent="center"
              alignItems="center"
            >
              <YellowIcon className=" h-6 w-6" />

              <Typography
                variant="caption"
                fontSize={{ xs: "11px", sm: "12px" }}
                lineHeight={{ xs: "230%", sm: "102%" }}
                letterSpacing={"0.1em"}
                className="uppercase "
              >
                SUMMIT
                {/* The CARE GAP SUMMIT */}
              </Typography>
            </Box>

            <Typography
              variant="h1"
              color="primary"
              fontSize={{ xs: "30px", sm: "43px" }}
              lineHeight={{ xs: "120%", sm: "120.5%" }}
              letterSpacing={"-0.06em"}
              className="lg:mt-20 w-[300px] mx-auto mt-4 md:mt-0 md:w-full"
            >
              THE CARE GAP SUMMIT
            </Typography>

            <Box className=" hidden md:grid lg:flex mt-6 place-content-center text-center">
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "12px", sm: "18px" }}
                lineHeight={{ xs: "247%", sm: "169%" }}
                letterSpacing={"0.02em"}
                className=" "
              >
                November 19th, 2024
              </Typography>

              <a
                onClick={() => {
                  window.open(
                    "https://us06web.zoom.us/meeting/register/tZErd-qrqz4jHtFTOZf0eLev54fQuQrNfZ0M"
                  );
                }}
                className="cursor-pointer"
                target="_blanc"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "18px" }}
                  lineHeight={{ xs: "247%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className=" opacity-60 pl-2 underline"
                >
                  Digital Event
                </Typography>
              </a>
              <Typography
                variant="subtitle1"
                color="primary"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={{ xs: "150%", sm: "100%" }}
                letterSpacing={"0.02em"}
                className=" uppercase text-center"
              >
                1pm-5pm EST
              </Typography>
            </Box>

            {/* Mobile */}

            <Box
              className="block md:hidden my-6 place-content-center text-center mx-auto"
              justifySelf="center"
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "12px", sm: "18px" }}
                lineHeight={{ xs: "247%", sm: "169%" }}
                letterSpacing={"0.02em"}
                className=" "
              >
                November 19th, 2024
              </Typography>

              <a
                onClick={() => {
                  window.open(
                    "https://us06web.zoom.us/meeting/register/tZErd-qrqz4jHtFTOZf0eLev54fQuQrNfZ0M"
                  );
                }}
                className="cursor-pointer"
                target="_blanc"
              >
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "12px", sm: "18px" }}
                  lineHeight={{ xs: "247%", sm: "169%" }}
                  letterSpacing={"0.02em"}
                  className=" opacity-60 pl-2"
                >
                  Digital Event
                </Typography>
              </a>
              <Typography
                variant="subtitle1"
                color="primary"
                fontSize={{ xs: "12px", sm: "14px" }}
                lineHeight={{ xs: "150%", sm: "100%" }}
                letterSpacing={"0.02em"}
                className=" uppercase text-center"
              >
                1pm-5pm EST
              </Typography>
            </Box>

            {/* <Typography
              variant="body2"
              color="primary"
              fontSize={{ xs: "16px", sm: "22px" }}
              lineHeight={{ xs: "140%", sm: "140%" }}
              letterSpacing={"0.02em"}
              className="w-[335px] md:w-[440px] mx-auto"
            >
              We believe that elevating care within the workplace sparks
              innovation, enhances employee well-being, and propels business
              growth.
            </Typography> */}
            <Typography
              variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[560px] w-[300px] pb-10 mx-auto text-center mt-6"
            >
              The Care Gap Summit will convene world leaders, care advocates,
              businesses, and policy leaders to form a unified network dedicated
              to closing the global care gap for children, women, and families
              exploring the critical link between the care gap—defined by unpaid
              care work, insufficient care infrastructure, and inadequate
              policies—and wellbeing + economic outcomes. Through a
              comprehensive lens, the summit will address solutions to close the
              care gap and improve societal wellbeing.
            </Typography>
            {/* <Box>
              <MHButton
                sx={{
                  width: "fit-content",
                  marginBottom: 5,
                  mr: 2,
                }}
                color="secondary"
                onClick={() =>
                  window.open(
                    "https://us06web.zoom.us/meeting/register/tZErd-qrqz4jHtFTOZf0eLev54fQuQrNfZ0M"
                  )
                }
              >
                Digital Registration
              </MHButton>
              <MHButton
                sx={{
                  width: "fit-content",
                  marginBottom: 5,
                }}
                onClick={handleOpenDialog}
              >
                Join In-Person
              </MHButton>
            </Box> */}
          </Box>
        </Box>

        {/* Second */}
        <Box className=" lg:flex lg:justify-center lg:gap-10 ">
          <Box className="order-first md:h-[630px] md:w-[630px] h-[343px] w-[343px] mx-auto lg:mx-0 ">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/CAW2024-5.jpeg"
              alt="Image"
              className=" rounded-[6px] md:mt-10 lg:ml-16 object-cover h-full"
            />
          </Box>
          <Box className="order-last text-center mx-auto lg:py-5 w-[343px] md:h-[630px] md:w-[630px] md:mt-10 mt-10 mb-10 ">
            <Typography
              variant="h1"
              color="primary"
              fontSize={{ xs: "26px", sm: "32px" }}
              lineHeight={{ xs: "143%", sm: "120.5%" }}
              letterSpacing={"-0.04em"}
              className="w-[302px] md:w-[560px] mx-auto text-center"
            >
              What the Global Care Gap Tour + Summit Aims to Achieve
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[491px] w-[300px] mx-auto mt-6"
            >
              The Global Care Gap Tour + Summit is designed to catalyze a global
              care movement by:
            </Typography>

            <Box
              // variant="body2"
              color="primary"
              fontSize={{ xs: "13px", sm: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="md:w-[560px] w-[340px] pb-10 mx-auto text-start mt-6"
            >
              {summitObj.map((obj, index) => (
                <Box className="flex gap-2 py-2">
                  •
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "13px", sm: "14px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" mx-auto text-start"
                    key={index}
                  >
                    <b className="font-areaNorm"> {obj.caption} </b>
                    {obj.info}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </section>
      {openDialog && (
        <RegistrationDialog
          open={openDialog}
          onClose={handleCloseDialog}
          title={"Join The Tour"}
          onComplete={handleCloseDialog}
        />
      )}
    </Fragment>
  );
};

export default SummitInfo;
