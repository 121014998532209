import React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MHSlider, {
  MHSliderItem,
} from "../Common/SliderCarousel/Slider/MHSlider";
import ContentCard from "../UI/ContentCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";

// const CONTENT = [
//   {
//     imageSrc:
//       'https://res.cloudinary.com/mother-honestly/image/upload/v1676441391/Image_16_jwcjag.png',
//     title: 'Care Wallet & Fund',
//     description:
//       'It’s easy to show employees you care by helping them catch unexpected care-related expenses, and provide emergency relief when they need it.'
//   },
//   {
//     imageSrc:
//       'https://res.cloudinary.com/mother-honestly/image/upload/v1676441388/Image_17_o5vpwr.png',
//     title: '1:1 Work & Care Guidance',
//     description:
//       'Access on-demand support and advice for diverse life, personal, and caregiving challenges from top national work-life experts.'
//   },
//   {
//     imageSrc:
//       'https://res.cloudinary.com/mother-honestly/image/upload/v1665674645/Image_4_zzfq8h.png',
//     title: 'Close The Care Gap',
//     description:
//       'When employees take leave for family care, their work remains, our Care Gap Consultants provide interim support to cover parental leave absences.'
//   }
// ];

const CONTENT = [
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Content+%26+Community.png",
    title: "Newsletter",
    description:
      "Merging heart with hard data to address the pressing challenges of caregiving. Blessing Adesiyan delivers weekly insights and strategies that enlighten and equip all parties — from home to headquarters— creating a unified front to tackle the care divide .      ",
    path: "/newsletter",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/Podcast.png",
    title: "Podcast",
    description:
      "The podcast brings a diverse range of voices, including experts, advocates, and individuals with firsthand experiences to discuss the multifaceted nature of care gaps, exploring potential solutions, and fostering a deeper understanding and empathy among listeners.      ",
    path: "/resources/podcasts",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/1.png",
    title: "Advisory",
    description: `The consulting arm of "The Care Gap" provides specialized services to organizations, businesses, and communities seeking to address and close care gaps within their own contexts, offering impactful solutions and implementation strategies.      `,
    path: "/advisory",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caw-men.jpg",
    title: "Partnership",
    description:
      "We’ve partnered with forward-thinking organizations like Indeed, Bright Horizons, Care.com, Bobbie, Greenhouse, and more on campaigns, surveys and reports, webinars, and events to create a caring and more inclusive workplace and society.      ",
    path: "/partnership",
  },
  {
    imageSrc:
      "https://mh-resources-production.s3.us-west-1.amazonaws.com/caw4.jpg",
    // "https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Academy+Images/leader-training.png",
    title: "Advocacy",
    description:
      "Our advocacy efforts promote policy changes, raise awareness, and mobilize support for addressing care gaps at local, national, and global levels, creating a  favorable environment for the systemic changes needed to close care gaps.      ",
    path: "/advocacy",
  },
];

const HeroSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const history = useHistory();

  return (
    <div className="overflow-hidden">
      <Box width={{ xs: "90%", sm: "60%" }} mx="auto" mt={10}>
        <Typography
          variant="h1"
          fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4,
          }}
          paragraph
        >
          A comprehensive array of care solutions for every career and life
          stages
        </Typography>
        {/* <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize={{ sm: '14px', md: '16px' }}
          lineHeight={'150%'}
          letterSpacing={'0.02em'}
          align="center"
          mx="auto"
          paragraph>
          Boost Productivity & Transform Your Workplace With Care
        </Typography> */}
      </Box>
      <Box py="3rem" px={{ xs: 0, md: "4rem" }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {
                  history.push(item.path);
                }}
                cardContainerSx={{
                  height: { xs: 600, md: 640, lg: 640 },
                }}
                cardContentSx={{
                  height: { xs: 220, md: 220, lg: 220 },
                }}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={5}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', md: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          An Array Of Care Solutions Throughout Your Employees Working Lives
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Many Employees Are Being Crushed Under The Unrelenting and Unexpected
          Burden Of Care, It’s Time Your Benefits Care
        </Typography>
      </Box>

      {!matchesMdDown && (
        <Box py="3rem" px={'4rem'}>
          <MHSlider slidesToShow={3} slidesToScroll={1}>
            {CONTENT.map((item, index) => (
              <MHSliderItem width="100%" key={index}>
                <ContentCard
                  imageSrc={item.imageSrc}
                  title={item.title}
                  description={item.description}
                  onMouseClick={() => {}}
                />
              </MHSliderItem>
            ))}
          </MHSlider>
        </Box>
      )}

      {matchesMdDown && (
        <Grid
          container
          direction={{ xs: 'row' }}
          flexWrap="nowrap"
          rowSpacing={{ xs: 0, sm: 0 }}
          columnSpacing={3}
          mt={2}
          width={{ xs: '90%', sm: '80%' }}
          mx="auto"
          mb={8}
          overflow="auto"
          className="horizontal-scroll">
          {CONTENT.map((item) => (
            <Grid item xs={12} sm={4} flexShrink={0}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </Grid>
          ))}
        </Grid>
      )} */}
    </div>
  );
};

export default HeroSlider;
