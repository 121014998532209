import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { PRESS_MEDIA_DATA } from "../../utils/summit-data";
import { PressMediaComp } from "./PressMediaComp";

type Props = {
  image?: string;
  title?: string;
  link?: string;
};

const PressMediaGrid = (props: Props) => {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        className="lg:pl-10 md:gap-4 lg:gap-0 place-content-center"
      >
        {PRESS_MEDIA_DATA.map((media, index) => (
          <Grid item xs={12} sm={5} md={4} lg={4} key={index}>
            <PressMediaComp
              image={media.image}
              title={media.title}
              text={media.text}
              link={media.link}
            />
          </Grid>
        ))}
        {/* <Grid item xs={12} sm={5} md={4} lg={4}>
          <PressMediaComp
            image="https://res.cloudinary.com/mother-honestly/image/upload/v1675705741/Image_iaijem.png"
            title="In Wall Street Journal"
            text="Wall Street Journal"
            link="https://facebook.com"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <PressMediaComp
            image="https://res.cloudinary.com/mother-honestly/image/upload/v1675705741/Image_1_hccybz.png"
            title="In Forbes"
            text="Wall Street Journal"
            link="https://facebook.com"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <PressMediaComp
            image="https://res.cloudinary.com/mother-honestly/image/upload/v1675697996/Image_1_kx5k0t.png"
            title="In Fast Company"
            text="Wall Street Journal"
            link="https://facebook.com"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <PressMediaComp
            image="https://res.cloudinary.com/mother-honestly/image/upload/v1675705741/Image_iaijem.png"
            title="In Wall Street Journal"
            text="Wall Street Journal"
            link="https://facebook.com"
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <PressMediaComp
            image="https://res.cloudinary.com/mother-honestly/image/upload/v1675705741/Image_1_hccybz.png"
            title="In Forbes"
            text="Wall Street Journal"
            link="https://facebook.com"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default PressMediaGrid;
