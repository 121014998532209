import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

import MHButton from "../../Common/Button/MHButton";

import { ReactComponent as DownloadIcon } from "../../../static/svg/downloads.svg";
import { ReactComponent as Instagram } from "../../../static/svg/instagram.svg";
import { ReactComponent as Facebook } from "../../../static/svg/facebook.svg";
import { ReactComponent as LinkedIn } from "../../../static/svg/linkedin-icon.svg";
import { ReactComponent as Pinterest } from "../../../static/svg/pinterest.svg";
import { ReactComponent as Twitter } from "../../../static/svg/twitter.svg";

import { ItemList } from "../../../models/resources.interface";

type HeaderProps = {
  titles?: string;
  description?: string;
  imageUrl?: string;
  headerClassName?: string;
  headerDateClass?: string;
  downloadClassName?: string;
  imageClass?: string;
  ticketClassName?: string;
  writtenBy?: string;
  bottomDateClass?: string;
  authorClassName?: string;
  SocialClassName?: string;
  TabSocialClass?: string;
  categoryClassName?: string;
  downloadLink?: string;
  ticketLink?: string;
  categoryOne?: string;
  category?: ItemList[] | undefined;
  date?: string | undefined;
  startTime?: string | undefined;
  endTime?: string | undefined;
  dateTwo?: string | undefined;
  onClick?: string;
  dateClassName?: string;
  podClassName?: string;
  episode?: string;
  season?: string;
  author?: string;
  episodeClassName?: string;
  event?: boolean;
  handleTourDialog?: () => void;
};

const NewViewHeader = (props: HeaderProps) => {
  return (
    <Fragment>
      <Grid container spacing={0} className="relative overflown-hidden">
        <Grid item xs={12} sm={6.5} md={6.5} lg={6.5}>
          <Box className={props.headerClassName}>
            <Typography
              variant="body2"
              className={props.headerDateClass}
              color="primary"
            >
              {props.dateTwo}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              className="text-left my-6 w-4/4 font-columbia text-3xl capitalize font-[500]"
            >
              <Typography
                variant="body2"
                className={props.dateClassName}
                color="primary"
              >
                {props.date}
              </Typography>

              <Typography
                variant="body2"
                color="primary"
                className="md:text-left lg:leading-[120.5%] text-center h-fit md:w-[98%] lg:w-[98%] lg:line-clamp-4 md:line-clamp-4 line-clamp-6 font-columbia text-2xl md:text-xl lg:text-[32px] capitalize font-[500]"
                // h-28 md:h-24 lg:h-36
              >
                {props.titles}
              </Typography>
            </Typography>
            <Typography
              variant="body2"
              className="md:text-left pt-6 md:pt-0 lg:pt-2  text-center lg:w-[493px] md:w-[85%] text-[14px] line-clamp-6 md:line-clamp-3 font-areaSemi"
              color="primary"
            >
              {props.description}
            </Typography>
            {props.event && (
              <Box className="block md:hidden text-center py-5">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="p"
                  fontSize={{ xs: "20px", sm: "14px", md: "20px" }}
                >
                  {props.author}
                </Typography>
              </Box>
            )}
            {/* <Box className="flex -ml-4 my-2"> */}
            <Box className={props.downloadClassName}>
              <a
                download
                onClick={() => {
                  window.open(props.downloadLink);
                }}
                title="Click to download"
              >
                <DownloadIcon className="h-12 mt-8" />
              </a>

              <a
                download
                onClick={() => {
                  window.open(props.downloadLink);
                }}
                title="Click to download"
                className="no-underline"
              >
                <Typography
                  variant="body2"
                  className="text-left mt-12 text-xs uppercase font-areaBold tracking-wider"
                  color="primary"
                >
                  Download Toolkit
                </Typography>
              </a>
            </Box>

            <Box className={props.ticketClassName}>
              {props.date?.toLowerCase().includes("summit") ? (
                <Box>
                  <MHButton
                    sx={{
                      width: "fit-content",
                      marginBottom: 5,
                      mr: 2,
                    }}
                    onClick={() => {
                      window.open(props.ticketLink);
                    }}
                  >
                    Register Here
                  </MHButton>
                  {/* <MHButton
                    sx={{
                      width: "fit-content",
                      marginBottom: 5,
                    }}
                    onClick={props.handleTourDialog}
                  >
                    Join In-Person
                  </MHButton> */}
                </Box>
              ) : (
                <MHButton
                  onClick={props.handleTourDialog}
                  sx={{ width: "155px" }}
                >
                  Join The Tour
                </MHButton>
              )}
            </Box>

            <Box className={props.podClassName}>
              <button
                onClick={() => {
                  window.open(
                    "https://open.spotify.com/show/54t1C1cw8TeS2UK4doDTL4"
                  );
                }}
              >
                <img
                  src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612804/spotify-pod_jyslea.png"
                  className="h-8 md:h-7 lg:h-10 md:w-fit absolute"
                  alt="Resources Image"
                />
              </button>
              <button
                onClick={() => {
                  window.open(
                    "https://podcasts.apple.com/us/podcast/mother-honestly-podcast/id1439395271"
                  );
                }}
              >
                <img
                  src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/apple-pod_yzrrsw.png"
                  className="h-8 md:h-7 lg:h-10 md:w-fit absolute ml-24 md:-ml-5 lg:-ml-2"
                  alt="Resources Image"
                />
              </button>

              <button
                onClick={() => {
                  window.open(
                    "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL21vdGhlcmhvbmVzdGx5L2ZlZWQueG1s"
                  );
                }}
              >
                <img
                  src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/google-pod_eavovx.png"
                  className="h-8 md:h-7 lg:h-10 md:w-fit absolute ml-[230px] md:ml-0 lg:ml-8"
                  alt="Resources Image"
                />
              </button>
            </Box>

            <Box className={props.authorClassName} color="primary">
              BY {props.writtenBy}
            </Box>

            <Box className={props.categoryClassName}>
              <Box className="lg:py-9 md:py-2 py-12">
                <Typography
                  className="text-[15px] text-center md:text-left font-areaExt uppercase text-navy-900"
                  gutterBottom
                  variant="h5"
                >
                  {props.dateTwo} | {props.startTime} {props.season}
                </Typography>
              </Box>

              <Box className={props.bottomDateClass}>
                <Typography
                  variant="body2"
                  className="lg:absolute text-left my-2 hidden md:block text-[12px] uppercase font-areaExt"
                  color="primary"
                >
                  {props.dateTwo} | {props.startTime} {props.season}
                </Typography>
              </Box>

              {props.event && (
                <Box className="hidden md:block">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="p"
                    fontSize={{ xs: "20px", sm: "16px", md: "22px" }}
                  >
                    {props.author}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box className={props.SocialClassName}>
              {/* Web */}
              <Box className="absolute gap-4 bottom-6 md:bottom-14  text-center place-content-center hidden md:hidden lg:flex md:right-10 lg:right-20">
                <a
                  className="cursor-pointer"
                  href="https://instagram.com/mhworklife?igshid=YmMyMTA2M2Y="
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Instagram />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Facebook />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.linkedin.com/company/mhworklife/mycompany/"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <LinkedIn />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.pinterest.com/motherhonestly/"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Pinterest />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Twitter />
                </a>
              </Box>
              {/* Tab */}
              <Box className={props.TabSocialClass}>
                <a
                  className="cursor-pointer"
                  href="https://instagram.com/mhworklife?igshid=YmMyMTA2M2Y="
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Instagram />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Facebook />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.pinterest.com/motherhonestly/"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Pinterest />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Twitter />
                </a>
              </Box>
              {/* Mobile */}
              <Box className="absolute gap-4 bottom-6 md:bottom-9  text-center place-content-center flex md:hidden right-0 left-0">
                <a
                  className="cursor-pointer"
                  href="https://instagram.com/mhworklife?igshid=YmMyMTA2M2Y="
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Instagram />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Facebook />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.pinterest.com/motherhonestly/"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Pinterest />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Twitter />
                </a>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} className="relative">
          <Box className="bg-green-100 h-96 w-full viewImage md:h-[375.83px] lg:h-[600px] lg:w-full object-cover">
            <img
              src={props.imageUrl}
              alt="Resources Image"
              className={props.imageClass}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default NewViewHeader;
