import React, { useState } from "react";

import Grid from "@mui/material/Grid";

import MHFormControl from "../Common/Form/MHFormControl";
import MHButton from "../Common/Button/MHButton";
import useInput from "../../hooks/use-input";

import * as validators from "../../utils/validators";
import * as constants from "../../utils/constants";
import { MHSelect } from "../Common/Form/MHSelect";
// import { CONTACT_MODE } from "../../utils/landing-data";
import MHPhoneInput from "../Common/PhoneInput/MHPhoneInput";
import usePhoneInput from "../../hooks/use-phone";

///firebase
import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { resolveErrorMessage } from "../../utils/utils";
import useForm from "../../hooks/use-form";
import { MuiTelInputInfo } from "mui-tel-input";
import useDialog from "../../hooks/use-dialog";
import MHFormGroup from "../Common/Form/MHFormGroup";
import RequestConfirmationPrompt from "../Contact/RequestConfirmation";
import MHCheckbox from "../Common/Form/MHCheckbox";
import useCheckbox from "../../hooks/use-checkbox";
import CheckboxGroup from "../Common/Form/MHNewCheckBox";

const TRACK_OPTIONS = [
  {
    label: "Parents and Caregivers",
    value: "Parents and Caregivers",
  },
  {
    label: "Managers and ERG Leaders",
    value: "Managers and ERG Leaders",
  },
  {
    label: "Care Innovations and Famtech",
    value: "Care Innovations and Famtech",
  },
  {
    label: "Workforce, Wellbeing, and Equity",
    value: "Workforce, Wellbeing, and Equity",
  },
];

const TOPIC_OPTIONS = [
  {
    label:
      "Unlocking Care Solutions: A Morning Workshop for Managers and ERG Champions",
    value:
      "Unlocking Care Solutions: A Morning Workshop for Managers and ERG Champions",
  },
  {
    label:
      "The Cost Of Care: The Crushing Demand of Caregiving And Career On Women's Health and Wellbeing",
    value:
      "The Cost Of Care: The Crushing Demand of Caregiving And Career On Women's Health and Wellbeing",
  },
  {
    label: "Care Revolution: Men Leading Change In Work And Family Life",
    value: "Care Revolution: Men Leading Change In Work And Family Life",
  },
  {
    label:
      "Caring Policies in Action: Advancing Family-Friendly Solutions from Capitol to Conference Room",
    value:
      "Caring Policies in Action: Advancing Family-Friendly Solutions from Capitol to Conference Room",
  },
  {
    label:
      "From Breastfeeding To The Boardroom - Integrating Motherhood Into The Modern Workplace",
    value:
      "From Breastfeeding To The Boardroom - Integrating Motherhood Into The Modern Workplace",
  },
  {
    label: "Lead Lunch Roundtable Conversations",
    value: "Lead Lunch Roundtable Conversations",
  },
  {
    label:
      "Reimagining The Pause: Why Caregivers Leave The Workforce and Paving The Way For Their Successful Return",
    value:
      "Reimagining The Pause: Why Caregivers Leave The Workforce and Paving The Way For Their Successful Return",
  },
  {
    label:
      "The Care Advantage: Real-life Testimonials of Thriving Employees in Caring Workplaces",
    value:
      "The Care Advantage: Real-life Testimonials of Thriving Employees in Caring Workplaces",
  },
  {
    label:
      "Balancing the Books of Care: Navigating Caregiving Finances and Inclusive Benefits",
    value:
      "Balancing the Books of Care: Navigating Caregiving Finances and Inclusive Benefits",
  },
  {
    label:
      "Fulfilling Dual Roles: Strategies for Supporting the New Norm of Multigenerational Caregivers",
    value:
      "Fulfilling Dual Roles: Strategies for Supporting the New Norm of Multigenerational Caregivers",
  },
  {
    label:
      "Voices of Care: Reflecting on Summit Insights and Shaping Future Action",
    value:
      "Voices of Care: Reflecting on Summit Insights and Shaping Future Action",
  },
  {
    label:
      "Closing Keynote - Care-Forward: Envisioning a Future of Work Centered on Wellbeing and Care",
    value:
      "Closing Keynote - Care-Forward: Envisioning a Future of Work Centered on Wellbeing and Care",
  },
];

const OPTIONS = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const HEAR_OPTIONS = [
  {
    label: "Social Media",
    value: "Social Media",
  },
  {
    label: "Referral",
    value: "Referral",
  },
  {
    label: "Email Newsletter",
    value: "Email Newsletter",
  },
  {
    label: "Website",
    value: "Website",
  },
  {
    label: "Other",
    value: "Other",
  },
];

type Props = {};

const SpeakerRequestForm = (props: Props) => {
  const {
    value: enteredName,
    valid: enteredNameIsValid,
    error: enteredNameHasError,
    onChange: nameInputChangeHandler,
    onBlur: nameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    phoneNumber,
    // phoneInfo,
    phoneNumberInputChangeHandler,
    isValid: phoneNumberIsValid,
  } = usePhoneInput([]);

  const {
    value: enteredCompanyName,
    valid: enteredCompanyNameIsValid,
    error: enteredCompanyNameHasError,
    onChange: companyNameInputChangeHandler,
    onBlur: companyNameInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredJobTitle,
    valid: enteredJobTitleIsValid,
    error: enteredJobTitleHasError,
    onChange: JobTitleInputChangeHandler,
    onBlur: JobTitleInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredLinkedIn,
    valid: enteredLinkedInIsValid,
    error: enteredLinkedInHasError,
    onChange: LinkedInInputChangeHandler,
    onBlur: LinkedInInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const [trackValues, setTrackValues] = useState<string[]>([]);

  const handleTrackChange = (newTrackValues: string[]) => {
    setTrackValues(newTrackValues);
  };

  const [topicValues, setTopicValues] = useState<string[]>([]);

  const handleTopicChange = (newTopicValues: string[]) => {
    setTopicValues(newTopicValues);
  };

  const {
    value: enteredBiography,
    valid: enteredBiographyIsValid,
    error: enteredBiographyHasError,
    onChange: BiographyInputChangeHandler,
    onBlur: BiographyInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredPassionateTopic,
    valid: enteredPassionateTopicIsValid,
    error: enteredPassionateTopicHasError,
    onChange: PassionateTopicInputChangeHandler,
    onBlur: PassionateTopicInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredSpokenBefore,
    valid: enteredSpokenBeforeIsValid,
    error: enteredSpokenBeforeHasError,
    onChange: SpokenBeforeInputChangeHandler,
    onBlur: SpokenBeforeInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredPerspectives,
    valid: enteredPerspectivesIsValid,
    error: enteredPerspectivesHasError,
    onChange: PerspectivesInputChangeHandler,
    onBlur: PerspectivesInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredUniqueExperience,
    valid: enteredUniqueExperienceIsValid,
    error: enteredUniqueExperienceHasError,
    onChange: UniqueExperienceInputChangeHandler,
    onBlur: UniqueExperienceInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredPublications,
    valid: enteredPublicationsIsValid,
    error: enteredPublicationsHasError,
    onChange: PublicationsInputChangeHandler,
    onBlur: PublicationsInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredAvailable,
    valid: enteredAvailableIsValid,
    error: enteredAvailableHasError,
    onChange: AvailableInputChangeHandler,
    onBlur: AvailableInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredCocktailReception,
    valid: enteredCocktailReceptionIsValid,
    error: enteredCocktailReceptionHasError,
    onChange: CocktailReceptionInputChangeHandler,
    onBlur: CocktailReceptionInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);
  const {
    value: enteredSocialMedia,
    valid: enteredSocialMediaIsValid,
    error: enteredSocialMediaHasError,
    onChange: SocialMediaInputChangeHandler,
    onBlur: SocialMediaInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredEventAvailable,
    valid: enteredEventAvailableIsValid,
    error: enteredEventAvailableHasError,
    onChange: EventAvailableInputChangeHandler,
    onBlur: EventAvailableInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const {
    value: enteredHowYouHeard,
    valid: enteredHowYouHeardIsValid,
    error: enteredHowYouHeardHasError,
    onChange: HowYouHeardInputChangeHandler,
    onBlur: HowYouHeardInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  let formIsValid =
    enteredNameIsValid &&
    enteredWorkEmailIsValid &&
    enteredLinkedInIsValid &&
    enteredCompanyNameIsValid &&
    enteredJobTitleIsValid &&
    enteredAvailableIsValid &&
    enteredEventAvailableIsValid &&
    enteredHowYouHeardIsValid &&
    enteredBiographyIsValid &&
    enteredPassionateTopicIsValid &&
    enteredSpokenBeforeIsValid &&
    enteredPerspectivesIsValid &&
    enteredUniqueExperienceIsValid &&
    enteredPublicationsIsValid &&
    enteredCocktailReceptionIsValid &&
    enteredSocialMediaIsValid;

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateForm();

    if (!formIsValid) {
      return;
    }

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "summit-speaker-requests"), {
      SpeakerName: enteredName,
      SpeakerEmail: enteredWorkEmail,
      SpeakerPhone: phoneNumber,
      JobTitle: enteredJobTitle,
      CompanyName: enteredCompanyName,
      InterestedTracks: trackValues,
      DomainExpertiseTopics: topicValues,
      LinkedIn: enteredLinkedIn,
      Biography: enteredBiography,
      PassionateTopic: enteredPassionateTopic,
      SpokenBefore: enteredSpokenBefore,
      Perspectives: enteredPerspectives,
      UniqueExperience: enteredUniqueExperience,
      Publications: enteredPublications,
      Available: enteredAvailable,
      CocktailReception: enteredCocktailReception,
      SocialMedia: enteredSocialMedia,
      AvailableForEvent: enteredAvailable,
      HowYouHeardAboutUs: enteredHowYouHeard,
      createdAt: serverTimestamp(),
    });

    // MAILER START - FOR SPEAKERS

    var subUrl = `${process.env.REACT_APP_FORUM_URL}/email-caring-awards`;

    const emailer = {
      email: enteredWorkEmail,
      name: enteredName,
      type: "SUMMIT_SPEAKER_REQUEST",
      title: "Thank You for Your Care at Work Summit Speaker Application",
    };
    const response = await fetch(subUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(emailer),
    });

    // console.log(response);

    // MAILER END

    handleOpenDialog();
    resetForm();

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const { validateForm } = useForm();

  const resetForm = () => {
    nameInputChangeHandler("");
    workEmailInputChangeHandler("");
    phoneNumberInputChangeHandler("", {} as MuiTelInputInfo);
    AvailableInputChangeHandler("");
    EventAvailableInputChangeHandler("");
    HowYouHeardInputChangeHandler("");
    JobTitleInputChangeHandler("");
    companyNameInputChangeHandler("");
    LinkedInInputChangeHandler("");
    BiographyInputChangeHandler("");
    PassionateTopicInputChangeHandler("");
    SpokenBeforeInputChangeHandler("");
    PerspectivesInputChangeHandler("");
    PublicationsInputChangeHandler("");
    CocktailReceptionInputChangeHandler("");
    SocialMediaInputChangeHandler("");
    UniqueExperienceInputChangeHandler("");
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  // let history = useHistory();

  // const handleClickOpen = () => {
  //   // history.push(`/`);
  //   // onClose();
  //   setCompleted(true);
  // };
  // const closure = () => {
  //   // history.push(`/`);
  //   resetForm();
  //   onClose();
  //   setCompleted(false);
  // };

  return (
    <React.Fragment>
      <MHFormGroup
        onSubmit={submitHandler}
        disableWhileSubmitting={httpState.loading}
      >
        {/* <Typography
          variant="h2"
          fontSize={{ xs: "24px", sm: "28px", md: "30px" }}
          lineHeight={{ xs: "142%", sm: "143.5%" }}
          letterSpacing={"-0.04em"}
          className="capitalize mx-auto text-center"
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          paddingBottom={4}
        >
          Caring Leader Nomination Form
        </Typography>

        <Typography
          variant="body1"
          fontSize={{ xs: "13px", sm: "18px", md: "18px" }}
          lineHeight={{ xs: "200%", sm: "169%" }}
          letterSpacing={"0.02em"}
          className=" capitalize mx-auto text-center"
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          paddingBottom={4}
          // sx={{ width: "80%" }}
        >
          Provide some information.
        </Typography> */}

        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <MHFormControl
              id="name"
              type="text"
              label="Speaker"
              placeholder="Enter your speaker full name"
              value={enteredName}
              onChange={nameInputChangeHandler}
              onBlur={nameInputBlurHandler}
              error={resolveErrorMessage(enteredNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
              // error={merchantNameErrorTip}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MHFormControl
              id="email"
              type="text"
              label="Speaker's Email Address"
              placeholder="Enter your email address"
              value={enteredWorkEmail}
              onChange={workEmailInputChangeHandler}
              onBlur={workEmailInputBlurHandler}
              required
              error={resolveErrorMessage(enteredWorkEmailHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={6}>
            <MHPhoneInput
              value={phoneNumber}
              onChange={phoneNumberInputChangeHandler}
              label="Speaker Phone Number"
              errorMessage={resolveErrorMessage(phoneNumberIsValid)(
                "Please enter a valid phone number"
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MHFormControl
              id="companyName"
              type="text"
              label="Speaker Company"
              placeholder="Who do you work for?"
              value={enteredCompanyName}
              onChange={companyNameInputChangeHandler}
              onBlur={companyNameInputBlurHandler}
              required
              error={resolveErrorMessage(enteredCompanyNameHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MHFormControl
              id="name"
              type="text"
              label="Job Title"
              placeholder="Job Title"
              value={enteredJobTitle}
              onChange={JobTitleInputChangeHandler}
              onBlur={JobTitleInputBlurHandler}
              error={resolveErrorMessage(enteredJobTitleHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
              // error={merchantNameErrorTip}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MHFormControl
              id="name"
              type="text"
              label="LinkedIn Profile"
              placeholder="LinkedIn Profile"
              value={enteredLinkedIn}
              onChange={LinkedInInputChangeHandler}
              onBlur={LinkedInInputBlurHandler}
              error={resolveErrorMessage(enteredLinkedInHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
              required
              // error={merchantNameErrorTip}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <CheckboxGroup
              label="Which track(s) are you interested in speaking on? (Select all that apply)"
              className="mb-4 flex flex-row"
              checkboxes={TRACK_OPTIONS}
              onCheckboxChange={handleTrackChange}
            />
            {/* <div>Checked Values: {JSON.stringify(trackValues)}</div> */}
          </Grid>

          <Grid item xs={12} md={12}>
            <CheckboxGroup
              label="Which of these topics do you have domain expertise on and can speak on as part of a panel discussion or as a keynote speaker? (Select all that apply)"
              className="mb-4 flex flex-row"
              checkboxes={TOPIC_OPTIONS}
              onCheckboxChange={handleTopicChange}
            />
            {/* <div>Checked Values: {JSON.stringify(trackValues)}</div> */}
          </Grid>
        </Grid>

        <Grid container spacing={{ xs: 0, md: 2 }}>
          <Grid item xs={12} md={12}>
            <MHFormControl
              id="biography"
              type="text"
              label="Please provide a brief biography (150-200 words):"
              placeholder="Please provide a brief biography"
              value={enteredBiography}
              onChange={BiographyInputChangeHandler}
              onBlur={BiographyInputBlurHandler}
              required
              multiline
              maxRows={2}
              error={resolveErrorMessage(enteredBiographyHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHFormControl
              id="passionateTopic"
              type="text"
              label="What topic or idea are you passionate about sharing with our audience? (150-200 words):"
              placeholder="What topic or idea are you passionate about sharing with our audience?"
              value={enteredPassionateTopic}
              onChange={PassionateTopicInputChangeHandler}
              onBlur={PassionateTopicInputBlurHandler}
              required
              multiline
              maxRows={2}
              error={resolveErrorMessage(enteredPassionateTopicHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHFormControl
              id="spokenBefore"
              type="text"
              label="Have you spoken at events or conferences before? If yes, please provide some examples:"
              placeholder="Have you spoken at events or conferences before?"
              value={enteredSpokenBefore}
              onChange={SpokenBeforeInputChangeHandler}
              onBlur={SpokenBeforeInputBlurHandler}
              required
              multiline
              maxRows={2}
              error={resolveErrorMessage(enteredSpokenBeforeHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHFormControl
              id="Perspectives"
              type="text"
              label="Why do you believe your perspective or insights are valuable to our audience? (150-200 words):"
              placeholder="Why do you believe your perspective or insights are valuable to our audience?"
              value={enteredPerspectives}
              onChange={PerspectivesInputChangeHandler}
              onBlur={PerspectivesInputBlurHandler}
              required
              multiline
              maxRows={2}
              error={resolveErrorMessage(enteredPerspectivesHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHFormControl
              id="UniqueExperience"
              type="text"
              label="What unique experiences or expertise do you bring to the table? How do you envision contributing to the conversation on care at work? (150-200 words):"
              placeholder="What unique experiences or expertise do you bring to the table? How do you envision contributing to the conversation on care at work?"
              value={enteredUniqueExperience}
              onChange={UniqueExperienceInputChangeHandler}
              onBlur={UniqueExperienceInputBlurHandler}
              required
              multiline
              maxRows={2}
              error={resolveErrorMessage(enteredUniqueExperienceHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHFormControl
              id="Publications"
              type="text"
              label="Please list any publications, articles, or content you've authored or contributed to related to caregiving, workplace wellbeing, or related topics:"
              placeholder="Please list any publications, articles, or content you've authored or contributed to related to caregiving, workplace wellbeing, or related topics:"
              value={enteredPublications}
              onChange={PublicationsInputChangeHandler}
              onBlur={PublicationsInputBlurHandler}
              required
              multiline
              maxRows={2}
              error={resolveErrorMessage(enteredPublicationsHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHSelect
              label="Available to participate at the Care at Work Summit 2023?"
              placeholder="Available to participate at the Care at Work Summit 2023?"
              options={OPTIONS}
              value={enteredAvailable}
              onChange={(val) => AvailableInputChangeHandler(val as string)}
              onBlur={AvailableInputBlurHandler}
              error={resolveErrorMessage(enteredAvailableHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHFormControl
              id="CocktailReception"
              type="text"
              label="Would be available the evening prior on October 25th for a Cocktail Reception?"
              placeholder="Would be available the evening prior on October 25th for a Cocktail Reception?"
              value={enteredCocktailReception}
              onChange={CocktailReceptionInputChangeHandler}
              onBlur={CocktailReceptionInputBlurHandler}
              required
              multiline
              maxRows={2}
              error={resolveErrorMessage(enteredCocktailReceptionHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHFormControl
              id="SocialMedia"
              type="text"
              label="Do you have any social media handles or websites we should be aware of?"
              placeholder="Do you have any social media handles or websites we should be aware of?"
              value={enteredSocialMedia}
              onChange={SocialMediaInputChangeHandler}
              onBlur={SocialMediaInputBlurHandler}
              required
              multiline
              maxRows={2}
              error={resolveErrorMessage(enteredSocialMediaHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHSelect
              label="If we are unable to place you for the Care At Work Summit. Would you be open to speaking at other digital and in-person events we host throughout the year?"
              placeholder="If we are unable to place you for the Care At Work Summit. Would you be open to speaking at other digital and in-person events we host throughout the year?"
              options={OPTIONS}
              value={enteredEventAvailable}
              onChange={(val) =>
                EventAvailableInputChangeHandler(val as string)
              }
              onBlur={EventAvailableInputBlurHandler}
              error={resolveErrorMessage(enteredEventAvailableHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <MHSelect
              label="How did you hear about our event or the speaker application?"
              placeholder="How did you hear about our event or the speaker application?"
              options={HEAR_OPTIONS}
              value={enteredHowYouHeard}
              onChange={(val) => HowYouHeardInputChangeHandler(val as string)}
              onBlur={HowYouHeardInputBlurHandler}
              error={resolveErrorMessage(enteredHowYouHeardHasError)(
                constants.REQUIRED_ERROR_TIP
              )}
            />
          </Grid>
        </Grid>

        <MHButton
          fullWidth
          sx={{ paddingY: 2, marginY: 2 }}
          type="submit"
          // onClick={submitHandler}
          // onClick={() => handleOpenDialog()}
          loading={httpState.loading}
          className=" text-[12px] leading-[102%] w-full tracking-[0.05em] h-[52px] "
        >
          Submit
        </MHButton>
      </MHFormGroup>

      {openDialog && (
        <RequestConfirmationPrompt
          open={openDialog}
          onClose={handleCloseDialog}
          header="We'll be in touch soon!"
          message="Thank you for Requesting to Speak at MH WorkLife Care at Work Summit. We will be in touch with
          you shortly."
        />
      )}
    </React.Fragment>
  );
};

export default SpeakerRequestForm;
