import React from 'react';
import MarqueeHorizontal, { MarqueeItemAlt } from '../../UI/MarqueeHorizontal';
import Box from '@mui/material/Box';

import { ReactComponent as BounteousLogo } from '../../../static/svg/bounteous.svg';
import { ReactComponent as IndeedLogo } from '../../../static/svg/indeed.svg';
import { ReactComponent as AMNLogo } from '../../../static/svg/amn-healthcare.svg';
import { ReactComponent as MMGYLogo } from '../../../static/svg/mmgy.svg';
import { ReactComponent as MercuryLogo } from '../../../static/svg/mercury.svg';
import { ReactComponent as DeloitteLogo } from '../../../static/svg/deloitte.svg';
import { ReactComponent as FastCompanyLogo } from '../../../static/svg/featured/fast-company.svg';
import { ReactComponent as WSJLogo } from '../../../static/svg/featured/wsj.svg';
import Marquee, { MarqueeItem } from '../../UI/Marquee';

type Props = {};

const MHmarquee = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="md:block hidden w-full h-fit">
        <Marquee continuous>
          <MarqueeItem>
            <IndeedLogo />
          </MarqueeItem>

          <MarqueeItem>
            <DeloitteLogo width={150} />
          </MarqueeItem>

          <MarqueeItem>
            <AMNLogo />
          </MarqueeItem>

          <MarqueeItem>
            <BounteousLogo />
          </MarqueeItem>

          <MarqueeItem>
            <MMGYLogo />
          </MarqueeItem>

          <MarqueeItem>
            <MercuryLogo />
          </MarqueeItem>
          {/* <MarqueeItem>
            <FastCompanyLogo />
          </MarqueeItem> */}
          {/* <MarqueeItem>
            <WSJLogo />
          </MarqueeItem> */}
        </Marquee>
      </Box>
      <Box className="block md:hidden w-full h-fit py-2">
        <MarqueeHorizontal continuous>
          <MarqueeItemAlt reducedOpacity>
            <BounteousLogo />
          </MarqueeItemAlt>
          <MarqueeItemAlt reducedOpacity>
            <DeloitteLogo width={150} />
          </MarqueeItemAlt>
          <MarqueeItemAlt reducedOpacity>
            <IndeedLogo />
          </MarqueeItemAlt>
          <MarqueeItemAlt reducedOpacity>
            <AMNLogo />
          </MarqueeItemAlt>
          <MarqueeItemAlt reducedOpacity>
            <MMGYLogo />
          </MarqueeItemAlt>
          <MarqueeItemAlt reducedOpacity>
            <MercuryLogo />
          </MarqueeItemAlt>
          {/* <MarqueeItemAlt reducedOpacity>
            <FastCompanyLogo />
          </MarqueeItemAlt> */}
          {/* <MarqueeItemAlt>
            <WSJLogo />
          </MarqueeItemAlt> */}
        </MarqueeHorizontal>
      </Box>
    </React.Fragment>
  );
};

export default MHmarquee;
