import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as Vivvi } from "../../static/svg/vivvi.svg";
import { ReactComponent as Indeed } from "../../static/svg/indeed-icon.svg";
import { ReactComponent as Lumo } from "../../static/svg/sponsors/lumo.svg";
import { ReactComponent as CareDotCom } from "../../static/svg/caredot-com.svg";
import { ReactComponent as SplendidSpoon } from "../../static/svg/splendid-spoon.svg";
import { ReactComponent as Unilever } from "../../static/svg/unilever.svg";
import { ReactComponent as Maven } from "../../static/svg/maven.svg";
import { ReactComponent as Chase } from "../../static/svg/jpmorgan.svg";
import { ReactComponent as Google } from "../../static/svg/google.svg";
import { ReactComponent as Linkedin } from "../../static/svg/linkedin.svg";
import { ReactComponent as Lincoln } from "../../static/svg/lincoln.svg";
import { ReactComponent as Bobbie } from "../../static/svg/bobbie.svg";
import { ReactComponent as Drybar } from "../../static/svg/drybar.svg";
import { ReactComponent as Mml } from "../../static/svg/mml.svg";
import { ReactComponent as BrightHorizon } from "../../static/svg/brighthorizon.svg";
import { ReactComponent as Bounteous } from "../../static/svg/sponsors/bounteous.svg";
import { ReactComponent as HouseOfWise } from "../../static/svg/sponsors/house of wise.svg";
import { ReactComponent as GoldFish } from "../../static/svg/sponsors/goldfish.svg";
import { ReactComponent as LittleOtter } from "../../static/svg/sponsors/little otter.svg";
import { ReactComponent as GoodbuyGear } from "../../static/svg/sponsors/goodbuy gear.svg";
import { ReactComponent as McDonalds } from "../../static/svg/sponsors/mcdonalds.svg";
import { ReactComponent as Maple } from "../../static/svg/sponsors/maple.svg";
import { ReactComponent as MoroccanOil } from "../../static/svg/sponsors/moroccanoil.svg";
import { ReactComponent as Oxo } from "../../static/svg/sponsors/oxo.svg";
import { ReactComponent as BenefitBump } from "../../static/svg/sponsors/benefitbump.svg";
import { ReactComponent as B } from "../../static/svg/sponsors/b.svg";
import { ReactComponent as PumpSpotting } from "../../static/svg/sponsors/pumpspotting.svg";
import { ReactComponent as Pacira } from "../../static/svg/sponsors/pacira.svg";
import { ReactComponent as Shinola } from "../../static/svg/sponsors/shinola detroit.svg";
import { ReactComponent as SitterCity } from "../../static/svg/sponsors/sittercity.svg";
import { ReactComponent as TIA } from "../../static/svg/sponsors/sittercity.svg";
import { ReactComponent as UGG } from "../../static/svg/sponsors/ugg.svg";
import { ReactComponent as WaterWipes } from "../../static/svg/sponsors/waterwipes.svg";
import { ReactComponent as Willow } from "../../static/svg/sponsors/willow.svg";
import { ReactComponent as Wiwiurka } from "../../static/svg/sponsors/wiwiurka.svg";
import { ReactComponent as Workday } from "../../static/svg/sponsors/workday.svg";
import { ReactComponent as Deloite } from "../../static/svg/sponsors/deloite.svg";
import { ReactComponent as DetroitPistons } from "../../static/svg/sponsors/detroitpistons.svg";
import { ReactComponent as GogoSqueez } from "../../static/svg/sponsors/gogo-squeez.svg";
import { ReactComponent as Mamava } from "../../static/svg/sponsors/mamava.svg";

type Props = {
  headerText?: string;
};

const Clients = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="my-20 overflow-hidden">
        <Typography
          variant="caption"
          fontSize={{ xs: "11px", sm: "11px" }}
          fontWeight={900}
          lineHeight={{ xs: "100%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className=" py-6 uppercase text-center flex justify-center"
        >
          {props.headerText}
        </Typography>

        <Grid
          container
          spacing={2}
          className=" text-center lg:px-6 py-4 mx-auto place-content-center  items-center"
        >
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Indeed className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Chase className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Google className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Linkedin className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Lincoln className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Vivvi className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Bobbie className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <CareDotCom className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Unilever className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <McDonalds className="w-[120px] h-14  object-cover grayscale" />
          </Grid>

          <Grid item xs={6} sm={3} md={2} lg={2}>
            <MoroccanOil className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Maven className="w-[150px]  object-cover grayscale" />
          </Grid>

          <Grid item xs={6} sm={3} md={2} lg={2}>
            <SplendidSpoon className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Drybar className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Mml className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <BrightHorizon className="w-[150px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Bounteous className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Mamava className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <HouseOfWise className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <LittleOtter className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <GoldFish className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <GoodbuyGear className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Maple className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Oxo className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <BenefitBump className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <B className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <PumpSpotting className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Pacira className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Shinola className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <SitterCity className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <TIA className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <UGG className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <WaterWipes className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Willow className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Wiwiurka className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Workday className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Deloite className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <DetroitPistons className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <GogoSqueez className="w-[120px] h-14  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Lumo className="w-[150px] h-[60px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1694094731/speakers/Mirza_ey7xlw.png"
              alt="Sponsors"
              className=" w-[170px] h-[58px] grayscale"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1694094733/speakers/Cocoon_zqmbyw.png"
              alt="Sponsors"
              className=" w-[170px] h-8 md:h-[40px] grayscale"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1694095161/speakers/greenhouse_zpnyu7.png"
              alt="Sponsors"
              className=" w-[170px] h-[43px] grayscale"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1694111992/speakers/ianacare-black_lpfsto.png"
              alt="Sponsors"
              className=" w-[170px] h-10 md:h-[51.75px] grayscale"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/luminary.png"
              alt="Sponsors"
              className=" w-[170px] h-10 md:h-[51.75px] grayscale"
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/kindercare.png"
              alt="Sponsors"
              className=" w-[80px] h-18 md:h-20 grayscale"
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Clients;
