import React from 'react';
import { Grid, Typography } from '@mui/material';
import MHAccordion from '../../Common/Accordion/MHAccordion';

const FAQ_MEMBERSHIP_Data = [
  {
    id: 1,
    question: 'What does MH Offer?',
    answer:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quam rem minima, incidunt vitae mollitia. Rerum, explicabo omnis dicta ullam nulla, aperiam quas perferendis, mollitia deleniti odit voluptatum suscipit maiores.'
  },
  {
    id: 2,
    question: 'What does the personalized dashboard entail?',
    answer:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quam rem minima, incidunt vitae mollitia. Rerum, explicabo omnis dicta ullam nulla, aperiam quas perferendis, mollitia deleniti odit voluptatum suscipit maiores.'
  },
  {
    id: 3,
    question: 'How about the concierge services?',
    answer:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quam rem minima, incidunt vitae mollitia. Rerum, explicabo omnis dicta ullam nulla, aperiam quas perferendis, mollitia deleniti odit voluptatum suscipit maiores.'
  }
];

const OTHER_FAQ_MEMBERSHIP_Data = [
  {
    id: 1,
    question: 'What does the onboarding process consists of?',
    answer:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quam rem minima, incidunt vitae mollitia. Rerum, explicabo omnis dicta ullam nulla, aperiam quas perferendis, mollitia deleniti odit voluptatum suscipit maiores.'
  },
  {
    id: 2,
    question: 'Tell me more about the coaching and mentorship',
    answer:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quam rem minima, incidunt vitae mollitia. Rerum, explicabo omnis dicta ullam nulla, aperiam quas perferendis, mollitia deleniti odit voluptatum suscipit maiores.'
  },
  {
    id: 3,
    question: 'What is the MH Employer Dashboard?',
    answer:
      'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quam rem minima, incidunt vitae mollitia. Rerum, explicabo omnis dicta ullam nulla, aperiam quas perferendis, mollitia deleniti odit voluptatum suscipit maiores.'
  }
];

type FaqProps = {
  id: number;
  question: string;
  answer: string;
};

const FAQ = (props: {
  questions?: FaqProps[];
  additionalQuestions?: FaqProps[];
}) => {
  const QUESTIONS =
    props.questions && props.questions.length
      ? props.questions
      : FAQ_MEMBERSHIP_Data;

  const ADDITIONAL_QUESTIONS =
    props.additionalQuestions && props.additionalQuestions
      ? props.additionalQuestions
      : OTHER_FAQ_MEMBERSHIP_Data;

  return (
    <div>
      <Typography
        variant="h1"
        fontSize="32px"
        textAlign={{ xs: 'left', md: 'center' }}
        lineHeight="120.5%"
        mb={4}>
        Learn About MH through Frequently Asked Questions
      </Typography>

      <Grid container spacing={{ xs: 2, md: 7 }}>
        <Grid item xs={12} md={6}>
          {QUESTIONS.map((faq, index) => (
            <MHAccordion
              key={faq.id}
              DataId={`${faq.id}`}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {ADDITIONAL_QUESTIONS.map((faq, index) => (
            <MHAccordion
              key={faq.id}
              DataId={`${faq.id}`}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQ;
