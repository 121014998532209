export class User {
  constructor(
    public uuid: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    private _token: Token,
    private _tokenExpirationDate: Date,
    public onboarded: boolean,
    public profilePicName: string
  ) {}

  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }

    return this._token;
  }
}

export interface Token {
  accessToken: string;
  refreshToken: string;
}

export type Employee = {
  id:                       number;
  uuid:                     number;
  firstName:                string;
  lastName:                 string;
  company:                  string;
  workDepartment:           string;
  userId:                   number;
  employerId:               number;
  employerRefId:            number;
  workEmail:                string;
  position:                 string;
  jobTitle:                 string;
  headShotName:             string;
  onboarded:                boolean;
  pulledFromMerge:          boolean;
  stripeId:                 string;
  dependants:               string;
  employerDomain:           string;
  houseHoldAnnualIncome:    string;
  ageOfChildren:            string;
  ageOfDependant:           string;
  employmentStatus:         string;
  numberOfEligibleEmployee: number;
  dateOfBirth:              string;
  numberOfKids:             string;
  metaData:                 string;
  houseHoldSize:            number;
  race:                     string;
  stripeSubIsActive:        SubscriptionStatus;
  identity:                 string;
  relationShipStatus:       string;
  zipCode:                  string;
  status:                   string;
  state:                    string;
  verified:                 boolean;
}

export type SubscriptionStatus = 'incomplete' | 'incomplete_expired' | 'trialing' | 'active' | 'past_due' | 'canceled' | 'unpaid'