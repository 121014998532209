import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MHAccordion from "../Common/Accordion/MHAccordion";
import { Summit_FAQ_Data } from "../../utils/faq-data";
import { Grid } from "@mui/material";

type Props = {};

const SummitFAQ = (props: Props) => {
  return (
    <React.Fragment>
      <Box className=" px-10 md:px-20">
        <section id="summitfaq" className="py-8">
          <Typography
            variant="h1"
            color="primary"
            fontSize={{ xs: "38px", sm: "44px" }}
            lineHeight={{ xs: "120%", sm: "120.5%" }}
            letterSpacing={"-0.04em"}
            className="mx-auto text-center mt-10"
          >
            Frequently Asked Questions
          </Typography>

          {/* {Summit_FAQ_Data.map((faq, index) => (
            <MHAccordion
              DataId={`${faq.id}`}
              question={faq.question}
              answer={faq.answer}
            />
          ))} */}

          <Grid container spacing={{ xs: 0, md: 4 }}>
            <Grid item xs={12} md={6}>
              {Summit_FAQ_Data.filter((faq: any) => faq.id % 2 !== 0).map(
                (faq: any) => (
                  <MHAccordion
                    DataId={`${faq.id}`}
                    question={faq.question}
                    answer={faq.answer}
                    key={faq.id}
                  />
                )
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              {Summit_FAQ_Data.filter((faq: any) => faq.id % 2 === 0).map(
                (faq: any) => (
                  <MHAccordion
                    DataId={`${faq.id}`}
                    question={faq.question}
                    answer={faq.answer}
                    key={faq.id}
                  />
                )
              )}
            </Grid>
          </Grid>
        </section>
      </Box>
    </React.Fragment>
  );
};

export default SummitFAQ;
