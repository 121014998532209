import { Box } from "@mui/material";
import React from "react";
import ImageGrid from "./ImageGrid";

type Props = {};

const BlessingCard = (props: Props) => {
  return (
    <Box className="pt-8">
      <ImageGrid
        // label="mother honestly"
        titleB="Blessing Adesiyan"
        demoClass="block"
        urlClass="hidden"
        order="order-last"
        imgSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1675768317/blessing-square_foqnlz.jpg"
        content=""
        content2=""
        demoText="Request Blessing For Your Org"
        demoWidth="w-[250px]"
      />
    </Box>
  );
};

export default BlessingCard;
