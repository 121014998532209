import { MembershipPlanType } from '../models/membership.type';

export function retrievePriceId(membershipPlan: MembershipPlanType): string {
  let paymentPriceId = membershipPlan.stripeYearlyPriceId;

  switch (membershipPlan.billingPeriod) {
    case 'month':
      paymentPriceId = membershipPlan.stripeMonthlyPriceId;
      break;

    case 'year':
      paymentPriceId = membershipPlan.stripeYearlyPriceId;
      break;
    default:
      paymentPriceId = membershipPlan.stripeYearlyPriceId;
      break;
  }

  return paymentPriceId;
}
