import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import MHSlider, {
  MHSliderItem
} from '../../Common/SliderCarousel/Slider/MHSlider';
import ContentCard from '../../UI/ContentCard';
import MHmarquee from '../../Common/Marquee/MHmarquee';

const CONTENT = [
  {
    imageSrc:
      'https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Care+Wallet_Fund.png',
    title: 'Care Wallet/Fund',
    description:
      'It’s easy to show employees you care by helping them catch unexpected care-related expenses, and provide emergency relief when they need it.'
  },
  {
    imageSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1688473368/Website_Images_-_1_1_Guidance_ecmfg6.png',
    title: 'Workplace & Care Advisory',
    description:
      'Access on-demand support and advice for diverse life, personal, and caregiving challenges from top national work-life experts.'
  },
  {
    imageSrc:
      'https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Cover+The+Gap.png',
    title: 'Cover The Care Gap',
    description:
      'Address the workforce gap during family care leaves to ensure employees feel protected and are prepared to re-engage upon their return to work.'
  },
  {
    imageSrc:
      'https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Content+%26+Community.png',
    title: 'Content & Community',
    description:
      "Personalized content libraries of how-tos, worksheets, toolkits, live and on-demand classes, peer-to-peer communities - tailored to every individual's career and life stages."
  }
];

const EmployersHeroSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Box width={{ xs: '90%', sm: '75%' }} mx="auto" mt={7}>
        <Typography
          variant="h1"
          fontSize={{ xs: '30px', sm: '40px', md: '50px' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          extend care beyond the company’s walls, creating a win-win scenario
          where you and <br /> your employees thrive and prosper.
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Personalized care solutions that support the mental, physical, and
          financial aspects of a distributed workforce.
        </Typography>
      </Box>

      <Box py="3rem" px={{ xs: 0, md: '4rem' }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>

      <Typography
        variant="body1"
        fontFamily="Area-Extended"
        textTransform="uppercase"
        textAlign="center"
        color="#194049"
        fontSize={12}
        letterSpacing="0.1em"
        sx={{
          mt: 0,
          mb: 2
        }}>
        Top Companies Trust MH
      </Typography>

      <MHmarquee />

      {/* <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={5}>
        <Typography
          variant="h1"
          fontSize={{ xs: '2rem', md: '2.4rem' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          An Array Of Care Solutions Throughout Your Employees Working Lives
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '70%' }}
          fontSize="13px"
          lineHeight="185%"
          letterSpacing="0.02em"
          align="center"
          mx="auto"
          paragraph>
          Many Employees Are Being Crushed Under The Unrelenting and Unexpected
          Burden Of Care, It’s Time Your Benefits Care
        </Typography>
      </Box>

      {!matchesMdDown && (
        <Box py="3rem" px={'4rem'}>
          <MHSlider slidesToShow={3} slidesToScroll={1}>
            {CONTENT.map((item, index) => (
              <MHSliderItem width="100%" key={index}>
                <ContentCard
                  imageSrc={item.imageSrc}
                  title={item.title}
                  description={item.description}
                  onMouseClick={() => {}}
                />
              </MHSliderItem>
            ))}
          </MHSlider>
        </Box>
      )}

      {matchesMdDown && (
        <Grid
          container
          direction={{ xs: 'row' }}
          flexWrap="nowrap"
          rowSpacing={{ xs: 0, sm: 0 }}
          columnSpacing={3}
          mt={2}
          width={{ xs: '90%', sm: '80%' }}
          mx="auto"
          mb={8}
          overflow="auto"
          className="horizontal-scroll">
          {CONTENT.map((item) => (
            <Grid item xs={12} sm={4} flexShrink={0}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </Grid>
          ))}
        </Grid>
      )} */}
    </div>
  );
};

export default EmployersHeroSlider;
