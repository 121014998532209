import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  image?: string;
  title?: string;
  content?: string;
};

export const PartnershipCard = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="lg:block hidden mx-auto text-center place-content-center">
        <img
          src={props.image}
          alt=""
          className=" object-cover w-[413px] mx-auto h-[271px] rounded-[6px] my-4"
        />

        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[374px] text-center mx-auto"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[374px] pt-4 text-center mx-auto"
        >
          {props.content}
        </Typography>
      </Box>
      <Box className="block mt-10 lg:hidden mx-auto text-center relative">
        <img
          src={props.image}
          alt=""
          className="w-[305px] h-[201px] mb-2 md:mb-2 md:w-[400px] md:h-[250px] object-cover rounded-[6px] mx-auto"
        />
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[329px] pt-2 text-center mx-auto"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[329px] pt-2 text-center mx-auto"
        >
          {props.content}
        </Typography>
      </Box>
    </React.Fragment>
  );
};
