export const MEMBERSHIP = [
  // {
  //   title: 'About',
  //   link: '/ourstory'
  // },
  // {
  //   title: 'Login',
  //   link: '/auth/login'
  // },
  // {
  //   title: 'Join Today',
  //   link: '/auth/subscribe'
  // },
  // {
  //   title: 'WorkLife Portal',
  //   link: '/employers'
  // },
  // {
  //   title: 'Partnerships',
  //   link: '/partner'
  // }
  // {
  //   title: 'Individual',
  //   link: '/individuals'
  // },

  {
    title: 'Legal Terms',
    link: '/terms-and-condition'
  },
  {
    title: 'Legal Policy',
    link: '/privacy-policy'
  },
  {
    title: 'Advisory',
    link: '/advisory'
  },
  {
    title: 'Partnership',
    link: '/partnership'
  }
  // {
  //   title: 'Consultants',
  //   link: '/leave-gap'
  // },
  // {
  //   title: 'Employers',
  //   link: '/employers'
  // },
 
  
  
  // {
  //   title: 'Referral Program',
  //   link: '/resources/events'
  // },
];

export const PLATFORM = [
  // {
  //   title: 'Individuals',
  //   link: '/individuals'
  // },
  // {
  //   title: 'Advisors',
  //   link: '/advisors'
  // },
  // {
  //   title: 'Consultants',
  //   link: '/leave-gap'
  // },
  // {
  //   title: 'Employers',
  //   link: '/employers'
  // },
  // {
  //   title: 'Brokers',
  //   link: ''
  // }
  
  {
    title: 'Our Story',
    link: '/ourstory'
  },
  {
    title: 'FAQ',
    link: '/faq'
  },
 
  {
    title: 'Featured Press',
    link: '/ourstory'
  },
  {
    title: 'Newsletter',
    link: '/newsletter'
  },
 
];

export const TOPICS = [
  {
    title: 'Career',
    link: '/'
  },
  {
    title: 'Caregiving',
    link: '/'
  },
  {
    title: 'Wellbeing',
    link: '/'
  },
  {
    title: 'Finances',
    link: '/'
  },
  {
    title: 'Lifestyle',
    link: '/'
  }
];

export const CONTENT = [
  {
    id: 1,
    title: 'Toolkits',
    link: '/resources/toolkits'
  },
  { id: 2, title: 'Articles', link: '/resources/articles' },
  { id: 3, title: 'Podcasts', link: '/resources/podcasts' },
  { id: 4, title: 'Videos', link: '/resources/videos' },
  { id: 5, title: 'Events', link: '/resources/events' }
];

export const UP_EVENTS = [
  {
    imgSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1670672604/image_nua0bs.png',
    title: 'The Motherhood Summit 2022: The Realities of Caregivers',
    slug: 'motherhood-summit',
    interests: 'Family, Mothers, Work',
    date: 'Aug 14-18'
  },
  {
    imgSrc:
      'https://res.cloudinary.com/mother-honestly/image/upload/v1670672604/image_nua0bs.png',
    title: 'Level Up Summit 2022: Levelling It Up At Home',
    slug: 'level-up-summit',
    interests: 'Career-Women, Household, Family',
    date: 'Aug 20-22'
  }
];
