import React, { Fragment, useEffect, useState } from "react";
import ViewHeader from "../SubComponents/ViewHeader";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import ResCard from "../SubComponents/ResCard";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import Slider from "react-slick";

import MHSubscribe from "../../Layout/MHSubscribe";
import MHFooter from "../../Layout/MHFooter";
import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import { checkInvalidArray, convertUTCToDate } from "../../../utils/utils";
import shuffleArray from "../../../hooks/use-random";
import useTitle from "../../../hooks/use-title";
import useAnalytics from "../../../hooks/use-analytics";

type ComponentProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  texts?: string;
  content?: string;
  catchPhrase?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
  title?: string | undefined;
};

const ViewToolkit = (props: ComponentProps) => {
  useTitle('Toolkits');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Toolkits'
  });

  const [resources, setResources] = useState<ResourceItemType[]>([]);

  const params = useParams<any>();

  var resUrl = `${process.env.REACT_APP_RES_TOOLKIT_URL}`;
  var viewUrl = `${process.env.REACT_APP_ALL_RES_VIEW_URL}${params.id}`;

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      // Shuffle data to display randomly
      const randomizedData = shuffleArray(formattedResources); // Randomize the filtered array
      setResources(randomizedData);

      // setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  console.log(resources, "resources");
  const [noOfElement, setnoOfElement] = useState(4);
  const slice = resources.slice(0, noOfElement);

  const [data, setData] = useState<ResourceItemType | null>(null);

  const getData = async () => {
    try {
      const response = await fetch(viewUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data }: HttpResponse<ResourceItemType> = await response.json();

      const priorityList: string[] = data.priorities.split(",");
      let newItemList: ItemList[] = [];

      for (const item of priorityList) {
        const newObject = data.itemList.find((it) => it.id === +item);

        if (newObject) {
          if (!newItemList.length) {
            newItemList = [newObject];
          } else {
            newItemList = [...newItemList, newObject];
          }
        }
      }

      const formattedResource: ResourceItemType = {
        ...data,
        newItemList,
      };

      setData(formattedResource);
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: "38px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false,
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  useEffect(() => {
    getResource();
    getData();
  }, [params.id]);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <ViewHeader
        titles={data.title}
        headerClassName="bg-lilac-300 w-full h-full px-4 md:px-0 md:pt-2 lg:pt-6 pt-6 md:pl-12 lg:pl-20 relative"
        headerDateClass="text-center block md:hidden mt-12 text-[12px] uppercase font-areaExt"
        description={data.content}
        imageUrl={data.thumbNailImageSrc}
        // imageUrl="https://res.cloudinary.com/kehinde-motherhoneestly/image/upload/v1669837683/MHresources/square_qvetb1.png"
        imageClass="overflow-hidden h-full w-full right-0 absolute"
        categoryClassName="lg:bottom-20 lg:absolute md:relative md:bottom-0 md:w-2/3"
        category={data.newItemList}
        categoryOne={
          checkInvalidArray(data.newItemList).length
            ? data.newItemList[0].interestName
            : ""
        }
        downloadLink={data.source}
        // SocialClassName="bottom-10"
        TabSocialClass="absolute gap-4 bottom-3 text-center place-content-center hidden md:flex lg:hidden md:right-4"
        downloadClassName="flex cursor-pointer md:-ml-4 my-8 md:my-2 lg:my-0 px-6 md:pt-0 pt-6 md:px-0"
        date={dayjs(data.createdDate).format("DD/MM/YYYY h:mm")}
        dateTwo={convertUTCToDate(data.startTime)}
        ticketClassName="py-6 hidden"
        podClassName="mt-10 flex gap-32 hidden"
        dateClassName="hidden text-left pb-2 w-3/4 text-base font-areaSemi"
        episodeClassName="hidden"
        authorClassName="hidden"
      />

      <Box className="px-4 md:px-20 lg:px-[300px] py-10 bg-white">
        <Typography
          variant="h3"
          color="primary"
          className="text-2xl md:text-3xl w-[90%] text-left font-columbia font-[500]"
        >
          {data.catchPhrase}
        </Typography>
        {/* <Typography
          variant="body2"
          color="primary"
          className="text-[16px] text-left mt-6 leading-[200%] font-areaSemi"
        >
          {data.description}
        </Typography> */}

        {/* Toolkits Description */}
        <div dangerouslySetInnerHTML={{ __html: data.description }} />
      </Box>

      <Box className="bg-gray-300 h-[1px] w-[91.4%] opacity-50 overflow-hidden mx-auto"></Box>

      {/* For Mobile */}
      <Box className="mx-auto pt-10 block md:hidden bg-white px-20 py-4">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-12"
        >
          Toolkits You Might like
        </Typography>
      </Box>

      <Box className="block md:hidden bg-mhbluish overflow-x-hidden">
        <Slider {...settings}>
          {resources.map((res, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[452px] object-cover bg-cream-100 rounded-md"
                iconClass="hidden"
                imgBg="bg-cream-200 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                // imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1665657584/employers-banner_xbxbyu.png"
                top={
                  checkInvalidArray(res.newItemList).length
                    ? res.newItemList[0].interestName
                    : ""
                }
                title={res.title}
                podTop="hidden"
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                playUrl={`/resources/toolkits/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Slider>
      </Box>

      {/* For Web */}
      <Box className="mx-auto pt-10 bg-white hidden md:block px-20 py-4">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-12"
        >
          Toolkits You Might like
        </Typography>
        <Grid container spacing={2}>
          {slice.map((res, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={res.id}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[452px] object-cover bg-cream-100 rounded-md"
                iconClass="hidden"
                imgBg="bg-cream-200 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                top={
                  checkInvalidArray(res.newItemList).length
                    ? res.newItemList[0].interestName
                    : ""
                }
                title={res.title}
                podTop="hidden"
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                playUrl={`/resources/toolkits/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <MHSubscribe />
      <MHFooter />
    </Fragment>
  );
};

export default ViewToolkit;
