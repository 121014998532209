import { ReactComponent as MHIcon } from "../../static/svg/brand/Care_Gap_Stacked.svg";

export default function MHLogo({ style }: { style?: object }) {
  return (
    <MHIcon
      width="3.5rem"
      height="auto"
      style={{
        margin: "0 auto",
        ...style,
      }}
    />
  );
}
