import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import useDialog from '../../../hooks/use-dialog';
import { DropdownButton } from '../../Common/Dropdown/MHDropdown';

const DrawerDropdownContent = styled(Box)<{ open: boolean }>(
  ({ theme, open }) => ({
    paddingBlock: 0,
    overflowY: 'hidden',
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    ...(!open && {
      height: 0
    })
  })
);

const MobileDropdown = (props: {
  label: string;
  children: React.ReactNode;
}) => {
  const {
    openDialog: openDropdown,
    handleOpenDialog: handleOpenDropdown,
    handleCloseDialog: handleCloseDropdown
  } = useDialog();

  const toggleDropdown = () => {
    openDropdown ? handleCloseDropdown() : handleOpenDropdown();
  };

  return (
    <Box borderBottom={1} borderColor="rgba(25, 64, 73, .1)" py={1}>
      <DropdownButton
        label={props.label}
        isOpen={openDropdown}
        onExpand={toggleDropdown}
        sx={{
          p: '1rem'
        }}
      />
      <DrawerDropdownContent open={openDropdown}>
        {props.children}
      </DrawerDropdownContent>
    </Box>
  );
};

export default MobileDropdown;
