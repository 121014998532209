import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';

import { ReactComponent as EBNLogo } from '../../static/svg/featured/ebn.svg';
import { ReactComponent as FastCompanyLogo } from '../../static/svg/featured/fast-company.svg';
import { ReactComponent as ForbesLogo } from '../../static/svg/featured/forbes.svg';
import { ReactComponent as TechCrunchLogo } from '../../static/svg/featured/tech-crunch.svg';
import { ReactComponent as WSJLogo } from '../../static/svg/featured/wsj.svg';
import { ReactComponent as CarecomLogo } from '../../static/svg/featured/care.com.svg';
import { ReactComponent as FortuneLogo } from '../../static/svg/featured/fortune.svg';
import Marquee, { MarqueeItem } from '../UI/Marquee';
import MHLink from '../Common/Link/MHLink';

const FeaturedCompanies = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className="grayscale">
      <Typography
        variant="body1"
        fontFamily="Area-Extended"
        textTransform="uppercase"
        textAlign="center"
        color="#194049"
        fontSize={12}
        letterSpacing="0.1em"
        >
        as featured in
      </Typography>
      
      <Marquee continuous>
        <MarqueeItem>
          <MHLink
            type="default"
            href="https://www.wsj.com/articles/parental-overload-these-two-moms-are-working-on-tech-cures-11592299801">
            <WSJLogo width="4rem" />
          </MHLink>
        </MarqueeItem>
        <MarqueeItem>
          <MHLink
            type="default"
            href="https://techcrunch.com/2022/10/18/mother-honestlys-new-commerce-offering-aims-to-give-employees-more-freedom-when-it-comes-to-caregiving-spend/">
            <TechCrunchLogo width="6rem" />
          </MHLink>
        </MarqueeItem>
        <MarqueeItem>
          <MHLink
            type="default"
            href="https://www.forbes.com/sites/amyshoenthal/2022/11/16/a-future-of-work-that-includes-care-starts-with-a-work-life-wallet-to-support-individual-employee-needs/">
            <ForbesLogo width="9rem" />
          </MHLink>
        </MarqueeItem>
        <MarqueeItem>
          <MHLink type="default" href="">
            <FastCompanyLogo width="18.3rem" />
          </MHLink>
        </MarqueeItem>
        <MarqueeItem>
          <MHLink
            type="default"
            href="https://www.benefitnews.com/list/5-things-employers-should-know-on-caregiving-and-remote-work">
            <EBNLogo />
          </MHLink>
        </MarqueeItem>
        <MarqueeItem>
          <MHLink type="default" href="">
            <FortuneLogo width="9rem" />
          </MHLink>
        </MarqueeItem>
      </Marquee>

      {/* <StackedRow
        alignItems="center"
        justifyContent="center"
        display={{ xs: 'flex', md: 'none' }}
        flexWrap="wrap">
        <Box px={2} py={2}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.wsj.com/articles/parental-overload-these-two-moms-are-working-on-tech-cures-11592299801">
            <WSJLogo width="4rem" />
          </a>
        </Box>

        <Box px={2} py={2}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://techcrunch.com/2022/10/18/mother-honestlys-new-commerce-offering-aims-to-give-employees-more-freedom-when-it-comes-to-caregiving-spend/">
            <TechCrunchLogo width="5rem" />
          </a>
        </Box>

        <Box px={2} py={2}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.forbes.com/sites/amyshoenthal/2022/11/16/a-future-of-work-that-includes-care-starts-with-a-work-life-wallet-to-support-individual-employee-needs/">
            <ForbesLogo width="9rem" />
          </a>
        </Box>

        <Box px={2} py={2}>
          <FastCompanyLogo width="18.5rem" />
        </Box>

        <Box px={2} py={2}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.businesswire.com/news/home/20221103005335/en/New-Research-by-Care.com-and-Mother-Honestly-Reveals-Remote-Work-Creates-More-Equitable-Workplaces-and-Homes-While-Driving-Productivity-and-Overall-Happiness">
            <CarecomLogo width="9rem" />
          </a>
        </Box>

        <Box px={2} py={2}>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.benefitnews.com/list/5-things-employers-should-know-on-caregiving-and-remote-work">
            <EBNLogo width="4rem" />
          </a>
        </Box>
      </StackedRow> */}
    </Box>
  );
};

export default FeaturedCompanies;
