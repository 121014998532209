import React from "react";
import FaqContactHeader from "../components/Common/Header/FaqContactHeader";
import BodyCards from "../components/Layout/BodyCards";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHFooter from "../components/Layout/MHFooter";
import useTitle from "../hooks/use-title";
import useAnalytics from "../hooks/use-analytics";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  useTitle('Privacy Policy');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Privacy Policy'
  });

  return (
    <React.Fragment>
      <FaqContactHeader
        label=" LEGAL"
        title=" Privacy Policy "
        textColor="primary"
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1673343295/image_pqg6kr.png"
        imageMobSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1673343294/image_1_udf6fv.png"
        // Center Class attributes
        // headerClassName="absolute items-center top-32 md:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        // titleClassName="md:w-[688px] w-[331px] mx-auto"

        // Left Class attributes
        headerClassName="absolute items-center top-12 md:top-32 lg:top-20 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      />

      <BodyCards />

      {/* FOOTER BEGINS */}
      <MHSubscribe />
      <MHFooter />
    </React.Fragment>
  );
};

export default PrivacyPolicy;
