import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {
  image?: string;
  title?: string;
  link?: string;
  text?: string;
};

export const PressMediaComp = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="lg:block hidden mx-auto place-content-center">
        <a
          href={props.link}
          target="_blank"
          className="w-fit no-underline hover:no-underline"
        >
          <img
            src={props.image}
            alt=""
            className=" object-cover mx-auto w-[413px] h-[271px] rounded-[6px] my-4"
          />

          <Typography
            variant="subtitle2"
            color="#194049"
            fontSize={{ xs: "16px", sm: "18px", md: "18px" }}
            lineHeight={"140%"}
            className="text-center mx-auto w-[90%] py-1 md:w-[90%]"
          >
            {props.title}
          </Typography>

          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "180%", sm: "195%" }}
            letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
            className=" w-[92%]  text-center mx-auto"
          >
            {props.text}
          </Typography>
        </a>
      </Box>
      <Box className="block mt-10 lg:hidden mx-auto relative">
        <a
          href={props.link}
          target="_blank"
          className="w-fit no-underline hover:no-underline"
        >
          <img
            src={props.image}
            alt=""
            className="w-[305px] h-[201px] md:w-[400px] md:h-[250px] object-cover rounded-[6px] mx-auto"
          />

          <Typography
            variant="subtitle2"
            color="#194049"
            fontSize={{ xs: "16px", sm: "18px", md: "18px" }}
            lineHeight={"140%"}
            className="text-center mx-auto w-80 py-1 md:w-[100%]"
          >
            {props.title}
          </Typography>

          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
            lineHeight={{ xs: "180%", sm: "195%" }}
            letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
            className=" w-[90%] text-center mx-auto"
          >
            {props.text}
          </Typography>
        </a>
      </Box>
    </React.Fragment>
  );
};
