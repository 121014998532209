import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import LandingArticle from "./LandingArticle";
import LandingPodcast from "./LandingPodcast";
import LandingVideo from "./LandingVideo";
import { Badge } from "@mui/material";
import MHBadge from "../Common/Badge/MHBadge";
// import MHBadge from "../Badge/MHBadge";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [resourcesPod, setResourcesPod] = React.useState<TabPanelProps[]>([]);
  var podUrl = `${process.env.REACT_APP_RES_PODCAST_URL}`;

  const getPodResource = async () => {
    try {
      const response = await fetch(podUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResourcesPod(jsonData.data);
      setResourcesPod(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
    } catch (err) {
      console.error("Cannot find Data");
    }
  };
  const [resourcesArt, setResourcesArt] = React.useState<TabPanelProps[]>([]);
  var artUrl = `${process.env.REACT_APP_RES_ARTICLE_URL}`;

  const getArtResource = async () => {
    try {
      const response = await fetch(artUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResourcesArt(jsonData.data);
      setResourcesArt(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
    } catch (err) {
      console.error("Cannot find Data");
    }
  };
  const [resourcesVid, setResourcesVid] = React.useState<TabPanelProps[]>([]);
  var videoUrl = `${process.env.REACT_APP_RES_VIDEO_URL}`;

  const getVidResource = async () => {
    try {
      const response = await fetch(videoUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      // setResourcesVid(jsonData.data);
      setResourcesVid(
        jsonData.data && Array.isArray(jsonData.data) ? jsonData.data : []
      );
    } catch (err) {
      console.error("Cannot find Data");
    }
  };

  React.useEffect(() => {
    getPodResource();
    getArtResource();
    getVidResource();
  }, []);

  return (
    <Box className="relative overflow-hidden">
      <Box className=" md:px-20 lg:px-20 absolute md:top-[10px] lg:top-[60px] md:right-0 top-[180px] text-center md:text-end z-30 ">
        <Tabs
          value={value}
          className=""
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              height: "1.2px !important",
              marginX: "16px !important",
              width: "52px !important",
              marginY: "20px !important",
              backgroundColor: "#194049 !important",
            },
          }}
        >
          <Tab
            className="ml-2 mr-6 font-areaNorm md:mr-0 md:ml-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
            label="Articles"
            disableRipple
            {...a11yProps(0)}
            icon={
              <MHBadge
                content={`${resourcesArt.length}`}
                sx={{
                  backgroundColor: "transparent",
                  opacity: 0.5,
                  marginTop: "-10px",
                }}
              />
            }
            iconPosition="end"
          />
          <Tab
            className=" font-areaNorm mr-6  md:mr-0 md:ml-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
            label="Podcast"
            disableRipple
            {...a11yProps(1)}
            icon={
              <MHBadge
                content={`${resourcesPod.length}`}
                sx={{
                  opacity: 0.5,
                  backgroundColor: "transparent",
                  marginTop: "-10px",
                }}
              />
            }
            iconPosition="end"
          />
          <Tab
            className=" font-areaNorm md:mr-0 text-xs capitalize leading-[102%] tracking-[0.04em]"
            label="Videos"
            disableRipple
            {...a11yProps(2)}
            icon={
              <MHBadge
                content={`${resourcesVid.length}`}
                sx={{
                  opacity: 0.5,
                  backgroundColor: "transparent",
                  marginTop: "-10px",
                }}
              />
            }
            iconPosition="end"
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LandingArticle />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LandingPodcast />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LandingVideo />
      </TabPanel>
    </Box>
  );
}
