import React from 'react';
import AppContext from './app.context';
import { TopLevelCategory } from '../../models/dashboard.model';
import { LOCATION_OPTIONS } from '../../utils/constants';

type SearchContextType = {
  selectedTopLvlCategoryIds: number[];
  selectedLocationIds: number[];
  handleTopLvlCategorySelection: (
    categoryId: number,
    selected: boolean
  ) => void;
  handleLocationSelection: (categoryId: number, selected: boolean) => void;
  clearTopLvlCategorySelection: () => void;

  selectedTopics: string[];
  handleGuidanceTopicSelection: (topics: string[]) => void;
};

type EventLocation = {
  id: number;
  label: string;
};

const SearchContext = React.createContext<SearchContextType>({
  selectedTopLvlCategoryIds: [],
  selectedLocationIds: [],
  handleTopLvlCategorySelection: (categoryId: number, selected: boolean) => {},
  handleLocationSelection: (categoryId: number, selected: boolean) => {},
  clearTopLvlCategorySelection: () => {},
  selectedTopics: [],
  handleGuidanceTopicSelection: (topics: string[]) => {}
});

export const SearchContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [selectedCategories, setSelectedCategories] = React.useState<
    TopLevelCategory[]
  >([]);
  const [selectedTopics, setSelectedTopics] = React.useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = React.useState<
    EventLocation[]
  >([]);

  const staticDataCtx = React.useContext(AppContext);
  const { staticDataCacheMap } = staticDataCtx;
  const topLvlCategories: TopLevelCategory[] = React.useMemo(
    () => staticDataCacheMap.get('topLevelCategories') || [],
    [staticDataCacheMap]
  );

  const handleTopLvlCategorySelection = React.useCallback(
    (categoryId: number, selected: boolean) => {
      const category = topLvlCategories.find((cat) => cat.id === categoryId);

      if (!category) {
        return;
      }

      setSelectedCategories((prevState) => {
        let topLvlCategories = [...prevState];

        if (selected) {
          topLvlCategories = topLvlCategories.concat(category);
        } else {
          topLvlCategories = topLvlCategories.filter(
            (topLvlCat) => topLvlCat.id !== category.id
          );
        }

        return topLvlCategories;
      });
    },
    [topLvlCategories]
  );

  const handleGuidanceTopicSelection = React.useCallback((topics: string[]) => {
    setSelectedTopics(topics);
  }, []);

  const handleLocationSelection = React.useCallback(
    (categoryId: number, selected: boolean) => {
      const eventLocation = LOCATION_OPTIONS.find(
        (cat) => cat.id === categoryId
      );

      if (!eventLocation) {
        return;
      }

      setSelectedLocations((prevState) => {
        let LOCATION_OPTIONS = [...prevState];

        if (selected) {
          LOCATION_OPTIONS = LOCATION_OPTIONS.concat(eventLocation);
        } else {
          LOCATION_OPTIONS = LOCATION_OPTIONS.filter(
            (topLvlCat) => topLvlCat.id !== eventLocation.id
          );
        }

        return LOCATION_OPTIONS;
      });
    },
    []
  );

  // can call this when component is unmounted to clear search state
  const clearTopLvlCategorySelection = React.useCallback(() => {
    setSelectedCategories([]);
  }, []);

  //   const handleChecklistChange = (checkedItems: ChecklistItem[]) => {
  //     let filterTerms: TopLevelCategory[] = [];

  //     for (const checkedItem of checkedItems) {
  //       const selectedTopLvlCat = topLvlCategories.find(
  //         (cat) => cat.id === checkedItem.id
  //       );

  //       if (!selectedTopLvlCat) {
  //         return;
  //       }

  //       if (
  //         selectedCategories.findIndex(
  //           (cat) => cat.id === selectedTopLvlCat.id
  //         ) > -1
  //       ) {
  //         return;
  //       }

  //       filterTerms = filterTerms.concat(selectedTopLvlCat);
  //     }

  //     setSelectedCategories((prevState) => {
  //       return [...prevState, ...filterTerms];
  //     });
  //   };

  // const updateCoachCategoriesHandler = (categories: string, index: number) => {
  //   setConsultants((prevConsultants: Consultant[]) => {
  //     const newConsultants = [...prevConsultants];

  //     newConsultants[index].interests = categories;
  //     return newConsultants;
  //   });

  //   setFilteredConsultants((prevConsultants: Consultant[]) => {
  //     const newConsultants = [...prevConsultants];

  //     newConsultants[index].interests = categories;
  //     return newConsultants;
  //   });
  // };

  const value: SearchContextType = {
    selectedTopLvlCategoryIds: selectedCategories.map((cat) => cat.id),
    selectedLocationIds: selectedLocations.map((cat) => cat.id),
    handleTopLvlCategorySelection,
    handleLocationSelection,
    clearTopLvlCategorySelection,
    selectedTopics,
    handleGuidanceTopicSelection
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export default SearchContext;
