import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import MHButton from '../components/Common/Button/MHButton';
import FaqContactHeader from '../components/Common/Header/FaqContactHeader';
import SpeakerRequestForm from '../components/Summit/SpeakerRequestForm';
import useTitle from '../hooks/use-title';
import useAnalytics from '../hooks/use-analytics';

const REASONS = [
  {
    id: 1,
    caption: 'Care-Centric Strategies: ',
    text:
      'Present innovative approaches and strategies that embed care and wellbeing at the core of workplace culture.'
  },
  {
    id: 2,
    caption: 'Employee Wellbeing: ',
    text:
      'Share insights on fostering holistic wellbeing, encompassing physical, emotional, financial, and mental aspects, for a more engaged and productive workforce.'
  },
  {
    id: 3,
    caption: 'Equity and Inclusion: ',
    text:
      'Discuss ways to ensure care and support for all employees, including marginalized groups, women of color, immigrants, and hourly workers, to create a more equitable work environment.'
  },
  {
    id: 4,
    caption: 'Future of Work: ',
    text:
      'Explore how the landscape of work is evolving and how businesses can adapt to the changing needs of employees and their caregiving responsibilities.'
  },
  {
    id: 5,
    caption: 'Innovations in Care: ',
    text:
      'Showcase groundbreaking technologies and solutions that are redefining the way employees access care and support.'
  }
  // {
  //   id: 6,
  //   caption: "Promoting Collaboration: ",
  //   text: "Understand the role of recognizing unpaid labor in promoting collaboration and teamwork, building a strong sense of camaraderie among colleagues.",
  // },
  // {
  //   id: 7,
  //   caption: "Nurturing a Positive Culture: ",
  //   text: "Discover how valuing care and unpaid labor nurtures a positive and supportive workplace culture, fostering employee engagement and loyalty.",
  // },
];

type Props = {};

const SpeakerRequest = (props: Props) => {
  let history = useHistory();

  const handleOpenCaringWorkplace = () => {
    history.push(`/summit/caring-workplace`);
  };

  const handleOpenCaringLeaders = () => {
    history.push(`/summit/caring-leader`);
  };

  useTitle('Event Speakers');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Event Speakers'
  });

  return (
    <React.Fragment>
      <FaqContactHeader
        title="Care At Work Summit Speaker Application"
        textColor="white"
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1672946054/FAQ_ulvkpc.png"
        imageMobSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1672946054/FAQ-mob_kcwqmd.png"
        // Center Class attributes
        headerClassName="absolute items-center top-24 md:top-24 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[231px] mx-auto"

        // Left Class attributes
        // headerClassName="absolute items-center top-32 md:top-40 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      />

      <Grid container spacing={6} className="px-8 md:px-12 lg:pl-32 py-20 ">
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Typography
            variant="h2"
            fontSize={{ xs: '30px', sm: '43px', md: '43px' }}
            lineHeight={{ xs: '120%', sm: '120.5%' }}
            letterSpacing={{ xs: '-0.04em', sm: '-0.06em' }}
            className=" text-start lg:w-[80%]"
            alignItems="start">
            Call for Speakers: Care At Work Summit
          </Typography>

          <Typography
            variant="body1"
            fontSize={{ xs: '13px', sm: '14px', md: '14px' }}
            lineHeight={{ xs: '185%', sm: '180%' }}
            letterSpacing={{ xs: '0.02em', sm: '0.02em' }}
            className="py-6 lg:w-[90%]">
            Are you a trailblazer in the realms of caregiving, employee
            wellbeing, or workplace transformation? We invite you to share your
            insights and expertise at the upcoming{' '}
            <b className="font-areaNorm">Care At Work Summit,</b> scheduled to
            take place on October 26 at Industry City, NYC.
            <br />
            <br />
            <b className="font-areaNorm">About the Summit:</b> The modern
            workplace is undergoing a paradigm shift, recognizing the pivotal
            role of care in fostering employee wellbeing and driving
            organizational success. The Care At Work Summit is a premier event
            that aims to shed light on the intersection of care and work, paving
            the way for a more inclusive, supportive, and thriving work
            environment.
            <br />
            <br />
            <b className="font-areaNorm">Why Speak:</b> We are seeking dynamic
            speakers who can contribute to the summit's objectives by addressing
            the following themes:
          </Typography>

          {/* <Typography
            variant="caption"
            fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "185%", sm: "180%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="py-6 lg:w-[90%]"
          >
            Key Takeaways:
          </Typography> */}

          <Box className="pb-4">
            {REASONS.map((item) => (
              <Box className="flex gap-2">
                •
                <Typography
                  variant="body1"
                  fontSize={{ xs: '13px', sm: '14px', md: '14px' }}
                  lineHeight={{ xs: '185%', sm: '180%' }}
                  letterSpacing={{ xs: '0.02em', sm: '0.02em' }}
                  className="py-2 lg:w-[95%] -mt-2">
                  <b className=" font-areaNorm"> {item.caption}</b>
                  {item.text}
                </Typography>
              </Box>
            ))}
          </Box>

          <Typography
            variant="body1"
            fontSize={{ xs: '13px', sm: '14px', md: '14px' }}
            lineHeight={{ xs: '185%', sm: '180%' }}
            letterSpacing={{ xs: '0.02em', sm: '0.02em' }}
            className="pt-4 pb-8 lg:w-[95%]">
            <b className="font-areaNorm">How to Apply:</b> If you have a unique
            perspective, innovative solutions, or transformative experiences to
            share, we encourage you to apply as a speaker for the Care At Work
            Summit.
            <br />
            <b className="font-areaNorm">Submission Deadline: </b> Kindly submit
            your speaker application by September 11, 2023. Our selection
            committee will thoughtfully review all applications, and chosen
            speakers will be notified by September 25th.
            <br />
            <b className="font-areaNorm">Be Part of the Conversation: </b> The
            Care At Work Summit is not just an event; it's a platform for
            shaping the future of work and care. Your voice, experience, and
            expertise can contribute to creating a more caring and supportive
            work environment for employees across industries. We eagerly
            anticipate your involvement in this transformative event.
          </Typography>

          {/* <Typography
            variant="caption"
            fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "185%", sm: "180%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="py-6 lg:w-[90%]"
          >
            Award Categories:
          </Typography>
          <Box className="flex gap-2">
            •
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
              className="pb-1 lg:w-[90%]"
            >
              Startups / SMBs
            </Typography>
          </Box>
          <Box className="flex gap-2">
            •
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
              className="pb-4 lg:w-[90%]"
            >
              Large Companies (500+ Employees)
            </Typography>
          </Box>

          <Typography
            variant="caption"
            fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "185%", sm: "180%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="py-6 lg:w-[90%]"
          >
            Deadlines
          </Typography>

          <Box className="flex gap-2">
            •
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
              className="lg:w-[90%]"
            >
              <b className="font-areaNorm"> Submission Deadline:</b> September
              22nd, 2023 12pm PST
            </Typography>
          </Box>
          <Box className="flex gap-2">
            •
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
              className="lg:w-[90%]"
            >
              <b className="font-areaNorm"> Judging Period:</b> September 22nd
              to September 29th
            </Typography>
          </Box>
          <Box className="flex gap-2">
            •
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
              className="lg:w-[90%]"
            >
              <b className="font-areaNorm"> Announce Shortlisted Companies</b>{" "}
              on October 3rd, 2023
            </Typography>
          </Box>
          <Box className="flex gap-2">
            •
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
              className="lg:w-[90%]"
            >
              <b className="font-areaNorm"> Winners</b> will be announced at
              Care At Work Summit on October 26, 2023
            </Typography>
          </Box> */}

          {/* <Link to={"/summit/caring-workplace"} className="underline">
            <Typography
              variant="body1"
              fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
              lineHeight={{ xs: "185%", sm: "180%" }}
              letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
              className="py-4"
            >
              Click here to nominate a caring workplace.
            </Typography>
            
          </Link> */}

          <Box className="relative p-4">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Caring-Leaders-Award.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md md:h-[350px] md:w-[629px] h-[255px] w-[343px]"
            />

            {/* Overlay for Image */}
            {/* <Box className="bg-[#194049] md:h-[471px] h-[255px] w-full object-cover absolute top-0 opacity-30 rounded-md"></Box> */}
          </Box>
          <Box
            justifyContent={'center'}
            // alignContent="center"
            // alignItems={"center"}
            display="flex">
            <MHButton
              fullWidth
              onClick={() => handleOpenCaringLeaders()}
              // sx={{ paddingY: 2, marginY: 2 }}
              type="submit"
              className=" text-[12px] leading-[102%] w-[35%] md:w-[30%] tracking-[0.05em] h-[52px] ">
              Apply Now
            </MHButton>
          </Box>

          <Box className="relative p-4">
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/Caring-Workplace-Award.png"
              alt="Image"
              style={{}}
              className="object-contain rounded-md md:h-[350px] md:w-[629px] h-[255px] w-[343px]"
            />

            {/* Overlay for Image */}
            {/* <Box className="bg-[#194049] md:h-[471px] h-[255px] w-full object-cover absolute top-0 opacity-30 rounded-md"></Box> */}
          </Box>
          <Box
            justifyContent={'center'}
            // alignContent="center"
            // alignItems={"center"}
            display="flex">
            <MHButton
              fullWidth
              onClick={() => handleOpenCaringWorkplace()}
              // sx={{ paddingY: 2, marginY: 2 }}
              type="submit"
              className=" text-[12px] leading-[102%] w-[35%] md:w-[30%] tracking-[0.05em] h-[52px] ">
              Apply Now
            </MHButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <SpeakerRequestForm />
        </Grid>
      </Grid>
      {/* FOOTER BEGINS */}
      {/* <MHSubscribe />
      <MHFooter /> */}
    </React.Fragment>
  );
};

export default SpeakerRequest;
