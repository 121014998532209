import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { SUMMIT_HeaderData } from "../../utils/summit-data";
import { SummitNavContext } from "../../store/context/summit-context";

interface SummitNavigatorProps {
  displayHeight: number;
}

const SummitNavigator: React.FC<SummitNavigatorProps> = ({ displayHeight }) => {
  const [showNavigation, setShowNavigation] = useState(false);
  // const [active, setActive] = React.useState("aboutsummit");

  const { active, setActive } = React.useContext(SummitNavContext);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: displayHeight,
  });

  useEffect(() => {
    setShowNavigation(trigger);
  }, [trigger]);

  return (
    <AppBar
      position="fixed"
      color="primary"
      style={{
        visibility: showNavigation ? "visible" : "hidden",
        height: "61px",
      }}
    >
      <Toolbar>
        {/* Add your navigation links here */}
        <Box
          id="scrollspy1"
          className="place-content-center hider mx-auto sticky top-0 overflow-hidden place-items-center"
        >
          <ul className="nav nav-pills flex overscroll-x-none overflow-auto">
            {SUMMIT_HeaderData.map((headerData, index) => (
              <li className="my-4" key={index}>
                <a
                  className="nav-link no-underline "
                  href={`#${headerData.linkTo}`}
                  onClick={() => {
                    setActive(headerData.linkTo);
                  }}
                >
                  <Typography
                    variant={`${
                      active === headerData.linkTo ? "subtitle1" : "subtitle2"
                    }`}
                    color="primary"
                    fontSize={{ xs: "12px", sm: "12px" }}
                    lineHeight={{ xs: "120%", sm: "102%" }}
                    letterSpacing={"0.05em"}
                    className=" capitalize h-fit w-[130px] md:w-fit mx-auto text-center md:px-4 mb-1"
                    justifyContent={{ xs: "center", sm: "center" }}
                  >
                    {headerData.title}
                  </Typography>

                  {active === headerData.linkTo ? (
                    <Box className="bg-[#194049] h-[1px] w-[40%] md:w-[30%] lg:w-[40%] mx-auto"></Box>
                  ) : null}
                </a>
              </li>
            ))}
          </ul>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default SummitNavigator;
