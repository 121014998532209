import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { ReactComponent as ResToolkitIcon } from "../../static/svg/resdot.svg";
import { ReactComponent as LeftBtn } from "../../static/svg/left-btn.svg";
import { ReactComponent as RightBtn } from "../../static/svg/right-btn.svg";
import MHButton from "../Common/Button/MHButton";
import ResCard from "../Resources/SubComponents/ResCard";
import { HttpResponse } from "../../models/api.interface";
import { ItemList, ResourceItemType } from "../../models/resources.interface";
import { checkInvalidArray } from "../../utils/utils";

type ResProps = {
  thumbNailImageSrc?: string;
  title?: string;
  texts?: string;
  itemList?: string[];
  id?: number;
  slug?: string;
  createdAt?: string;
  updatedAt?: string;
};

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute -top-[20%] right-0">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute -top-[20%] right-16">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

const LandingToolkit = (props: ResProps) => {
  const location = useLocation();
  const [resources, setResources] = useState<ResourceItemType[]>([]);

  var resUrl = `${process.env.REACT_APP_RES_TOOLKIT_URL}`;

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 3,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1100,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settingsTab = {
    centerMode: true,
    centerPadding: "-100px",
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 3,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1100,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settingsMobile = {
    centerMode: true,
    centerPadding: "30px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 3,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1100,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  let history = useHistory();

  const { path } = useRouteMatch();

  const handleClickOpen = () => {
    history.push(`${path}/toolkits`);
  };

  // console.log(location.pathname);

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getResource();
  }, []);

  return (
    <Box bgcolor="#F6F8F5" paddingTop={4} className="md:px-6 h-[741px]">
      <Box
        display="flex"
        paddingX={6}
        className="place-content-center md:place-content-start overscroll-x-hidden gap-2"
      >
        <ResToolkitIcon className="" height="24px" width="25px" />
        <Typography
          variant="body2"
          color="primary"
          fontWeight="900"
          fontSize="12px"
          fontFamily="Area-Extended"
          textAlign="center"
          textTransform="uppercase"
          // className="leading-[102%] tracking-[0.1em] mt-1"
        >
          The Toolkits
        </Typography>
      </Box>
      <Typography
        variant="h3"
        className="md:text-left w-[350px] md:w-fit md:text-[32px] mx-auto md:mx-0 text-center text-[30px] my-2 px-12 leading-[120.5%] tracking-[-0.04em]"
        color="primary"
        sx={{
          fontWeight: 500,
          fontFamily: "Columbia-Sans",
        }}
      >
        Resources To Make Life Easier
      </Typography>

      {/* Web */}
      <Box className="mx-auto pt-10 px-5 relative hidden md:hidden lg:block">
        <Grid container spacing={0}>
          <Box className="w-[100%] mx-auto">
            <Slider {...settings}>
              {resources.map((res, index, arr) => (
                <Grid item xs={3} key={index}>
                  <ResCard
                    cardClass="relative ml-8 w-[401px] mt-2 shadow-none h-[525px] rounded-md object-cover bg-cream-100"
                    iconClass="hidden"
                    imgBg="bg-cream-200 h-[401px]"
                    bodyBg="bg-white h-[79px]"
                    podTop="hidden"
                    top={
                      checkInvalidArray(res.newItemList).length
                        ? res.newItemList[0].interestName
                        : ""
                    }
                    imageSrc={res.thumbNailImageSrc}
                    title={res.title}
                    // category={res.itemList ? res.itemList[0].interestName : ""}
                    // categoryTwo={res.itemList ? res.itemList[1] : ""}
                    category={res.newItemList}
                    titleUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                    playUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                  />
                </Grid>
              ))}
            </Slider>
          </Box>
        </Grid>
      </Box>
      {/* Tab */}
      <Box className="mx-auto pt-10 px-5 relative hidden md:block lg:hidden">
        <Grid container spacing={0}>
          <Box className="w-[100%] mx-auto">
            <Slider {...settingsTab}>
              {resources.map((res, index, arr) => (
                <Grid item xs={3} key={index}>
                  <ResCard
                    cardClass="relative ml-8 w-[401px] mt-2 shadow-none h-[525px] rounded-md object-cover bg-cream-100"
                    iconClass="hidden"
                    imgBg="bg-cream-200 h-[401px]"
                    bodyBg="bg-white h-[79px]"
                    podTop="hidden"
                    top={
                      checkInvalidArray(res.newItemList).length
                        ? res.newItemList[0].interestName
                        : ""
                    }
                    imageSrc={res.thumbNailImageSrc}
                    title={res.title}
                    // category={res.itemList ? res.itemList[0].interestName : ""}
                    // categoryTwo={res.itemList ? res.itemList[1] : ""}
                    category={res.newItemList}
                    titleUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                    playUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                  />
                </Grid>
              ))}
            </Slider>
          </Box>
        </Grid>
      </Box>
      {/* Mobile */}
      <Box className="mx-auto pt-10 relative block md:hidden lg:hidden overflow-hidden">
        <Grid container spacing={0}>
          <Box className="w-[95%] mx-auto">
            <Slider {...settingsMobile}>
              {resources.map((res, index, arr) => (
                <Grid item xs={3} key={index}>
                  <ResCard
                    cardClass="relative -ml-6 w-[286px] mt-2 shadow-none h-[413px] rounded-md object-cover bg-cream-100"
                    iconClass="hidden"
                    imgBg="bg-cream-200 h-[286px]"
                    bodyBg="bg-white h-[79px]"
                    podTop="hidden"
                    top={
                      checkInvalidArray(res.newItemList).length
                        ? res.newItemList[0].interestName
                        : ""
                    }
                    imageSrc={res.thumbNailImageSrc}
                    title={res.title}
                    // category={res.itemList ? res.itemList[0].interestName : ""}
                    // categoryTwo={res.itemList ? res.itemList[1] : ""}
                    category={res.newItemList}
                    titleUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                    playUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                  />
                </Grid>
              ))}
            </Slider>
          </Box>
        </Grid>

        <Box bgcolor="#F6F8F5" className="flex justify-center py-8 md:hidden">
          <MHButton onClick={() => handleClickOpen()} sx={{ width: "113px" }}>
            View All
          </MHButton>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingToolkit;
