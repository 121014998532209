import React from 'react';

import Chip, { ChipProps } from '@mui/material/Chip';

import { ReactComponent as CancelIcon } from '../../../static/svg/cancel.svg';

const MHChip = (props: ChipProps) => {
  return (
    <Chip
      deleteIcon={<CancelIcon height=".65rem" width=".65rem" />}
      onDelete={props.onDelete}
      {...props}
      sx={{
        bgcolor: '#ECEDF9',
        borderRadius: 0,
        ...props.sx
      }}
    />
  );
};

export default MHChip;
