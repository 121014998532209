import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import MHButton from "../Common/Button/MHButton";

import { ReactComponent as CloseIcon } from "../../static/svg/cancel.svg";

type Props = {
  onClick?: (e: React.MouseEvent) => void;
};

const StickyLegal = (props: Props) => {
  return (
    <React.Fragment>
      {/* STICKY BOTTOM LEGAL */}
      <Box className="bg-navy-900 z-50 flex h-18 md:h-18 lg:h-10 items-center justify-between place-content-center fixed bottom-[0%] w-full">
        <Typography
          variant="body1"
          fontFamily="Area-Normal-Bold"
          color="#fff"
          fontSize={{ xs: "9px", sm: "14px", md: "10px" }}
          lineHeight={{ xs: "160.5%", sm: "160.5%" }}
          letterSpacing={{ xs: "0.04em", sm: "0.04em" }}
          className="justify-center ml-6 items-center  text-start pt-5"
          sx={{
            mb: 1,
          }}
        >
          We use our own and third-party cookies to improve your experience and
          our services, and to analyze the use of our website. If you continue
          browsing, we take that to mean that you accept their use.
          <Link to={"/privacy-policy"} className="text-white underline">
            See info.
          </Link>
        </Typography>
        <MHButton
          onClick={props.onClick}
          className=" nohova text-[12px] mt-3 leading-[102%] w-fit tracking-[0.05em] h-fit "
        >
          <CloseIcon />
        </MHButton>
      </Box>
    </React.Fragment>
  );
};

export default StickyLegal;
