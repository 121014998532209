import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type Props = {
  label?: string;
  title?: string;
  title2?: string;
  imageSrc?: string;
  imageMobSrc?: string;
  icon?: React.ReactNode;
  textColor?: string;
  headerClassName?: string;
  titleClassName?: string;
};

const StoryHeader = (props: Props) => {
  return (
    <Fragment>
      <Box className="relative">
        <img
          src={props.imageSrc}
          alt="Image"
          style={{}}
          className="object-cover h-[430px] w-full hidden md:hidden lg:block"
        />
        <img
          src={props.imageMobSrc}
          alt="Image"
          style={{}}
          className="object-cover h-[350px] md:h-[380px] md:w-[100%] w-full block md:block lg:hidden"
        />

        <Box className={props.headerClassName}>
          <Box className=" flex justify-center items-center">{props.icon}</Box>
          <Typography
            variant="caption"
            color={props.textColor}
            fontSize={{ xs: "11px", sm: "12px" }}
            lineHeight={{ xs: "230%", sm: "102%" }}
            letterSpacing={"0.1em"}
            className="uppercase"
          >
            {props.label}
          </Typography>
          <br />
          <br />

          <Typography
            variant="h3"
            color={props.textColor}
            fontSize={{ xs: "38px", sm: "40px", md: "50px" }}
            lineHeight={{ xs: "120%", sm: "120%" }}
            letterSpacing={"-0.04em"}
            className={`capitalize ${props.titleClassName}`}
          >
            {props.title}
          </Typography>
          <Typography
            variant="h3"
            color={props.textColor}
            fontSize={{ xs: "45px", sm: "70px", md: "88.29px" }}
            lineHeight={{ xs: "90%", sm: "90.5%" }}
            letterSpacing={"-0.065em"}
            fontWeight={500}
            className={`-mt-8 capitalize ${props.titleClassName}`}
          >
            {props.title2}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default StoryHeader;
