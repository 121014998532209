import React from "react";
import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { ReactComponent as CheckMarkIcon } from "../../static/svg/check-mark-md.svg";
import * as validators from "../../utils/validators";
import * as constants from "../../utils/constants";

import * as utils from "../../utils/utils";
import MHDialog from "../Common/Dialog/MHDialog";

const RequestConfirmationPrompt = ({
  open,
  onClose,
  header,
  message,
}: {
  open: boolean;
  onClose: () => void;
  header?: string;
  message?: string;
}) => {
  const history = useHistory();

  return (
    <MHDialog
      title={" "}
      open={open}
      handleClose={onClose}
      scroll="paper"
      maxWidth="sm"
      actions={null}
      fullWidth
    >
      <Box
        sx={{
          "& > svg": {
            textAlign: "center",
            mx: "auto",
            my: 2,
          },
        }}
      >
        <CheckMarkIcon />
        <Typography variant="h2" align="center" color="#194049" paragraph>
          {header}
        </Typography>

        <Divider
          light
          variant="middle"
          sx={{
            borderColor: "#F3F4F6",
            my: 2,
          }}
        />

        <Typography
          variant="body1"
          color="#194049"
          align="center"
          fontSize="16px"
          lineHeight="150%"
          sx={{
            opacity: 0.8,
          }}
          gutterBottom
        >
          {message}
        </Typography>
      </Box>
    </MHDialog>
  );
};

export default RequestConfirmationPrompt;
