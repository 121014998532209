import React from "react";
import FaqContactHeader from "../components/Common/Header/FaqContactHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { CustomInput } from "../components/Common/Form/MHCustomInput";
import ContactForm from "../components/Contact/ContactForm";
import ContactDemo from "../components/Contact/ContactDemo";
import MHSubscribe from "../components/Layout/MHSubscribe";
import MHFooter from "../components/Layout/MHFooter";
import useTitle from "../hooks/use-title";
import useAnalytics from "../hooks/use-analytics";

type Props = {};

const Contact = (props: Props) => {
  useTitle("Contact Us");

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: "Contact Us",
  });

  return (
    <React.Fragment>
      <FaqContactHeader
        label=" contact us"
        title=" Questions? Comment? Demos?Reach Out to our Care Team "
        textColor="white"
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1672946054/FAQ_ulvkpc.png"
        imageMobSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1672946054/FAQ-mob_kcwqmd.png"
        // Center Class attributes
        headerClassName="absolute items-center top-32 md:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[331px] mx-auto"

        // Left Class attributes
        // headerClassName="absolute items-center top-32 md:top-40 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      />

      <Grid container spacing={6} className="px-8 md:px-12 lg:pl-48 py-20 ">
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Typography
            variant="h2"
            fontSize={{ xs: "30px", sm: "43px", md: "43px" }}
            lineHeight={{ xs: "120%", sm: "120.5%" }}
            letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
            className=" text-start"
            alignItems="start"
          >
            Delivering Work-Life Care Solutions For All
          </Typography>

          <Typography
            variant="body1"
            fontSize={{ xs: "13px", sm: "14px", md: "14px" }}
            lineHeight={{ xs: "185%", sm: "180%" }}
            letterSpacing={{ xs: "0.02em", sm: "0.02em" }}
            className="py-10"
          >
            The Care Gap partners with employers, brand and solution partners to
            deliver world-class work-life care programs with an integrated,
            human-centric approach. Are you interested in learning more about
            working with us? Reach out and our team will get in touch to kick
            off the conversation. <br /> <br /> In this demo, we'll work with
            you to get a clear understanding of the problems your ERG or entire
            workforce is facing in managing career, caregiving, and work. Our
            team will then help break down how to best solve them with leading
            evidence-based work-life care solutions.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <ContactForm />
        </Grid>
      </Grid>

      <ContactDemo />

      {/* FOOTER BEGINS */}
      <MHSubscribe />
      <MHFooter />
    </React.Fragment>
  );
};

export default Contact;
