import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MHDialog from '../../Common/Dialog/MHDialog';
import { MHSelect } from '../../Common/Form/MHSelect';
import MHFormControl from '../../Common/Form/MHFormControl';
import MHButton from '../../Common/Button/MHButton';
import useInput from '../../../hooks/use-input';
import useHttp from '../../../hooks/use-http';

import { resolveErrorMessage } from '../../../utils/utils';
import * as validators from '../../../utils/validators';
import * as constants from '../../../utils/constants';
import StaticDataContext from '../../../store/context/static-data.context';
import { ReactComponent as DialogSuccessIcon } from '../../../static/svg/dialog-success.svg';

import { db } from '../../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import useToggle from '../../../hooks/use-toggle';
import AppContext from '../../../store/context/app.context';

const JoinWaitlist = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    value: enteredFirstName,
    valid: enteredFirstNameIsValid,
    error: enteredFirstNameHasError,
    onChange: firstNameInputChangeHandler,
    onBlur: firstNameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredLastName,
    valid: enteredLastNameIsValid,
    error: enteredLastNameHasError,
    onChange: lastNameInputChangeHandler,
    onBlur: lastNameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredJobTitle,
    valid: enteredJobTitleIsValid,
    error: enteredJobTitleHasError,
    onChange: jobTitleInputChangeHandler,
    onBlur: jobTitleInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredPhoneNumber,
    valid: enteredPhoneNumberIsValid,
    error: enteredPhoneNumberHasError,
    onChange: phoneNumberInputChangeHandler,
    onBlur: phoneNumberInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredCompanyName,
    valid: enteredCompanyNameIsValid,
    error: enteredCompanyNameHasError,
    onChange: companyNameInputChangeHandler,
    onBlur: companyNameInputBlurHandler
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredCompanyLocation,
    valid: enteredCompanyLocationIsValid,
    error: enteredCompanyLocationHasError,
    onChange: companyLocationInputChangeHandler,
    onBlur: companyLocationInputBlurHandler
    // markAsTouched: markMerchantNameInputAsTouched
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredCompanySize,
    valid: enteredCompanySizeIsValid,
    error: enteredCompanySizeHasError,
    onChange: companySizeInputChangeHandler,
    onBlur: companySizeInputBlurHandler,
    markAsTouched: markMerchantWebsiteInputAsTouched
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const {
    value: enteredInfoSource,
    valid: enteredInfoSourceIsValid,
    error: enteredInfoSourceHasError,
    onChange: infoSourceInputChangeHandler,
    onBlur: infoSourceInputBlurHandler,
    markAsTouched: markMerchantAddressInputAsTouched
  } = useInput([
    {
      validator: (value: string) => validators.required(value)
    }
  ]);

  const dataCtx = React.useContext(AppContext);
  const { states } = dataCtx;

  // const notificationCtx = React.useContext(Notifica)

  const [completed, setCompleted] = React.useState(false);

  let formIsValid =
    enteredLastNameIsValid &&
    enteredFirstNameIsValid &&
    enteredCompanyLocation &&
    enteredCompanySizeIsValid;

  // let amountErrorTip = resolveErrorMessage(enteredAmountHasError)(
  //   'Please enter a valid amount'
  // );
  // let merchantNameErrorTip = resolveErrorMessage(
  //   enteredCompanyLocationHasError
  // )('Please enter a merchant name');
  // let merchantWebsiteErrorTip = resolveErrorMessage(
  //   enteredCompanySizeHasError
  // )('Please enter a merchant website');
  // let merchantAddressErrorTip = resolveErrorMessage(
  //   enteredCompanyDescHasError
  // )('Please enter a merchant address');

  async function submitHandler(event: React.SyntheticEvent) {
    event.preventDefault();

    if (!formIsValid) {
      // markMerchantNameInputAsTouched();
      // markMerchantWebsiteInputAsTouched();
      // markMerchantAddressInputAsTouched();
      return;
    }

    const data = {
      FirstName: enteredFirstName,
      LastName: enteredLastName,
      JobTitle: enteredJobTitle,
      PhoneNumber: enteredPhoneNumber,
      WorkEmail: enteredWorkEmail,
      CompanyName: enteredCompanyName,
      CompanyHQ: enteredCompanyLocation,
      CompanySize: enteredCompanySize,
      InfoSource: enteredInfoSource,
      createdAt: serverTimestamp()
    };

    handleToggle(true)();
    await addDoc(collection(db, 'interim-demo-requests'), data);
    setCompleted(true);
    handleToggle(false)();
  }

  const { open: openLoader, handleToggle } = useToggle();

  return (
    <React.Fragment>
      <MHDialog
        open={open}
        title={!completed ? 'Ready to start working with us?' : ' '}
        handleClose={onClose}
        scroll="paper"
        actions={
          !completed ? (
            <MHButton
              type="submit"
              form="join-waitlist-form"
              loading={openLoader}
              fullWidth>
              Submit
            </MHButton>
          ) : (
            <MHButton onClick={onClose} fullWidth>
              Close
            </MHButton>
          )
        }
        maxWidth={completed ? 'xs' : 'sm'}
        fullWidth>
        {!completed ? (
          <Box
            component={'form'}
            onSubmit={submitHandler}
            id="join-waitlist-form">
            <Grid container spacing={{ xs: 0, md: 2 }}>
              <Grid item xs={12} md={6}>
                <MHFormControl
                  id="firstName"
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  value={enteredFirstName}
                  onChange={firstNameInputChangeHandler}
                  onBlur={firstNameInputBlurHandler}
                  required
                  // error={merchantNameErrorTip}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <MHFormControl
                  id="lastName"
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  value={enteredLastName}
                  onChange={lastNameInputChangeHandler}
                  onBlur={lastNameInputBlurHandler}
                  required
                  // error={merchantNameErrorTip}
                />
              </Grid>
            </Grid>

            <Grid container spacing={{ xs: 0, md: 2 }}>
              <Grid item xs={12} md={6}>
                <MHFormControl
                  id="firstName"
                  type="text"
                  label="Job Title"
                  placeholder="Job Title"
                  value={enteredJobTitle}
                  onChange={jobTitleInputChangeHandler}
                  onBlur={jobTitleInputBlurHandler}
                  required
                  // error={merchantNameErrorTip}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <MHFormControl
                  id="lastName"
                  type="tel"
                  label="Phone Number"
                  placeholder="Phone Number"
                  value={enteredPhoneNumber}
                  onChange={phoneNumberInputChangeHandler}
                  onBlur={phoneNumberInputBlurHandler}
                  required
                  // error={merchantNameErrorTip}
                />
              </Grid>
            </Grid>

            <MHFormControl
              id="workEmail"
              type="text"
              label="Work Email"
              placeholder="Work Email"
              value={enteredWorkEmail}
              onChange={workEmailInputChangeHandler}
              onBlur={workEmailInputBlurHandler}
              required
              // error={merchantWebsiteErrorTip}
            />

            <MHFormControl
              id="companyName"
              type="text"
              label="Company Name"
              placeholder="Company Name"
              value={enteredCompanyName}
              onChange={companyNameInputChangeHandler}
              onBlur={companyNameInputBlurHandler}
              required
              // error={merchantWebsiteErrorTip}
            />

            <MHSelect
              label="Where is your company headquarted?"
              placeholder="Please select"
              options={states || []}
              value={enteredCompanyLocation}
              onChange={(value) =>
                companyLocationInputChangeHandler(value as string)
              }
              onBlur={companyLocationInputBlurHandler}
              required
            />

            <MHSelect
              label="Company size"
              placeholder="Please select"
              options={constants.COMPANY_SIZE_OPTIONS || []}
              value={enteredCompanySize}
              onChange={(value) =>
                companySizeInputChangeHandler(value as string)
              }
              onBlur={companySizeInputBlurHandler}
              required
            />

            <MHSelect
              label="How did you hear about us?"
              placeholder="Please select"
              options={constants.INFO_SOURCE_OPTIONS || []}
              value={enteredInfoSource}
              onChange={(value) =>
                infoSourceInputChangeHandler(value as string)
              }
              onBlur={infoSourceInputBlurHandler}
              required
            />
          </Box>
        ) : (
          <Box
            sx={{
              margin: 'auto',
              '& svg': {
                display: 'block',
                mx: 'auto',
                mb: 2
              }
            }}>
            <DialogSuccessIcon width={150} />

            <Typography
              variant="body2"
              color="initial"
              align="center"
              mb={3}
              gutterBottom>
              Thank you, your request has been sent. We will respond shortly.
            </Typography>
          </Box>
        )}
      </MHDialog>
    </React.Fragment>
  );
};

export default JoinWaitlist;

// <Box
//   sx={{
//     px: 2,
//     '& svg': {
//       display: 'block',
//       marginX: 'auto',
//       marginY: '30px'
//     }
//   }}>
//   <CheckMarkRoundedLargeIcon />
//   <Typography
//     variant="body1"
//     align="center"
//     color="primary.main"
//     gutterBottom
//     paragraph>
//     You have successfully requested for a reimbursement on your
//     expense.
//   </Typography>
//   <Typography
//     variant="body1"
//     align="center"
//     color="primary.main"
//     paragraph
//     gutterBottom>
//     Your expense will be reviewed by MH Team and your connected
//     account will be credited upon approval.
//   </Typography>
// </Box>
