import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import MHButton from '../Button/MHButton';
import MHFooter from '../../Layout/MHFooter';

import errorBackgroundLg from '../../../static/svg/backgrounds/error-bound-lg.svg';
import errorBackgroundSm from '../../../static/svg/backgrounds/error-bound-sm.svg';

type Props = {
  title: string;
  content: string;
  buttonLabel: string;
  onButtonClick: () => void;
  includeFooter?: boolean;
};

const ErrorBoundaryPage = (props: Props) => (
  <React.Fragment>
    <Box className="relative">
      <img
        src={errorBackgroundLg}
        alt="error"
        className="object-cover h-fit w-full hidden md:hidden lg:block"
      />
      <img
        src={errorBackgroundSm}
        alt="error"
        className="object-cover h-fit md:h-[600px] md:w-[100%] w-full block md:block lg:hidden"
      />

      <Box className="absolute items-center top-32 md:top-12 lg:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: '38.52px', sm: '60px' }}
          lineHeight={{ xs: '100%', sm: '90.5%' }}
          letterSpacing={'-0.06em'}
          className="capitalize  pt-32">
          {props.title}
        </Typography>
        <br />
        <br />

        <Typography
          variant="body2"
          color="primary"
          fontSize={{ xs: '13px', sm: '18px' }}
          lineHeight={{ xs: '185%', sm: '169%' }}
          letterSpacing={{ xs: '0.03em', sm: '0.02em' }}
          className={`-mt-7 md:w-[688px] w-[331px] mx-auto`}>
          {props.content}
        </Typography>

        <MHButton
          onClick={props.onButtonClick}
          className="my-9 capitalize text-[12px] leading-[120%] md:leading-[102%] tracking-[0.05em] h-[46px] md:h-[52px] ">
          {props.buttonLabel}
        </MHButton>
      </Box>
    </Box>

    {props.includeFooter && <MHFooter />}
  </React.Fragment>
);

export default ErrorBoundaryPage;
