import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useHistory, useRouteMatch } from "react-router-dom";

import MHButton from "../Common/Button/MHButton";
import MHmarquee from "../Common/Marquee/MHmarquee";

// type Props = {}

const EnterprisePlan = () => {
  let history = useHistory();

  const handleClickOpen = () => {
    // history.push(`/employers`);
  };
  return (
    <React.Fragment>
      <Box className="pt-10 pb-10">
        <Box className="items-center place-content-center ">
          {/* <Typography className="uppercase font-areaNorm text-[12px] text-center md:text-[11px]  lg:text-[11px] leading-[102%] tracking-[0.1em] text-navy-900 ">
            ENTERPRISE SOLUTIONS

          </Typography> */}
          <Typography
            variant="subtitle1"
            fontSize={{ xs: "12px", sm: "11px" }}
            lineHeight={"102%"}
            letterSpacing={"0.1em"}
            className="uppercase text-center"
          >
            ENTERPRISE SOLUTIONS
          </Typography>
          {/* <Typography className="py-8 font-columbia text-[30px] text-center md:text-[40px]  lg:text-[50px] leading-[120.5%] tracking-[-0.04em] text-navy-900 ">
            Join the many forward-
            <br className="md:hidden block" />
            thinking companies <br /> supporting their employee caregivers
          </Typography> */}
          <Typography
            variant="h2"
            width={{ xs: "90%", md: "65%" }}
            fontSize={{ xs: "30px", sm: "40px", md: "50px" }}
            lineHeight={"120.5%"}
            letterSpacing={"-0.04em"}
            mx="auto"
            className="py-8 text-center"
          >
            Join forward-thinking companies choosing to close the care gap for
            parents, caregivers, and employees of all kinds
          </Typography>

          {/* Web */}

          <Box className="hidden md:flex items-center place-content-center mx-auto text-center">
            {/* <Typography className="ml-8 mr-8 py-8 font-areaSemi text-[14px] text-center md:text-[14px]  lg:text-[16px] leading-[170%] md:leading-[150%] tracking-[0.02em] text-navy-900 ">
              Personalized Employee Work- <br /> Life Care Solutions
            </Typography> */}
            <Typography
              variant="body1"
              fontSize={{ sm: "14px", md: "16px" }}
              lineHeight={"150%"}
              letterSpacing={"0.02em"}
              className="ml-8 mr-8 py-8  text-center !w-[20%]"
            >
              We work with employers to develop and implement more inclusive and
              caring workplace policies
            </Typography>
            &bull;
            <Typography
              variant="body1"
              fontSize={{ sm: "14px", md: "16px" }}
              lineHeight={"150%"}
              letterSpacing={"0.02em"}
              className="ml-8 mr-8 py-8  text-center !w-[20%] "
            >
              Provide awareness, training, and strategies to leaders, managers,
              ERGS, and HR.
            </Typography>
            &bull;
            <Typography
              variant="body1"
              fontSize={{ sm: "14px", md: "16px" }}
              lineHeight={"150%"}
              letterSpacing={"0.02em"}
              className="ml-8 py-8 text-center !w-[20%] "
            >
              Unlock access to a network of caregiving resources and support
              services for your workforce
            </Typography>
          </Box>

          {/* Mobile */}

          <Box className="md:hidden block items-center place-content-center mx-auto text-center">
            <Typography
              variant="body1"
              fontSize={{ xs: "10px" }}
              lineHeight={"170%"}
              letterSpacing={"0.02em"}
              className=" py-3 text-center"
            >
              We work with employers to develop <br /> and implement more
              inclusive and <br />
              caring workplace policies
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: "10px" }}
              lineHeight={"170%"}
              letterSpacing={"0.02em"}
              className=" py-3 text-center"
            >
              Provide awareness, training, <br /> and strategies to leaders,{" "}
              <br /> managers, ERGS, and HR.{" "}
            </Typography>

            <Typography
              variant="body1"
              fontSize={{ xs: "10px" }}
              lineHeight={"170%"}
              letterSpacing={"0.02em"}
              className=" py-3 text-center"
            >
              Unlock access to a network of <br /> caregiving resources and
              support <br />
              services for your workforce
            </Typography>
          </Box>

          <Box className="mx-auto text-center py-10 md:py-0">
            <MHButton
              onClick={() => {
                // window.open("https://calendly.com/brooke-thecaregap/the-care-gap-interest-call");
                history.push(`/schedule`);
              }}
              className=" md:text-[12px] lg:text-[12px] leading-[195%] tracking-[0.06em] md:w-[220px] lg:w-[220px] md:h-[52px] lg:h-[55px] py-5"
            >
              Schedule A Meeting
            </MHButton>
          </Box>
        </Box>

        <Typography
          variant="body1"
          fontFamily="Area-Extended"
          textTransform="uppercase"
          textAlign="center"
          color="#194049"
          fontSize={12}
          letterSpacing="0.1em"
          sx={{
            mt: 10,
            mb: 5,
          }}
        >
          Top Companies Trust MH
        </Typography>

        <MHmarquee />

        <hr className="hidden md:block w-[90%] mx-auto h-0 border-t-lilac-300" />
      </Box>
    </React.Fragment>
  );
};

export default EnterprisePlan;
