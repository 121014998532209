import React, { createContext, useState } from "react";

type SummitType = {
  children: React.ReactNode;
};

type SummitContextType = {
  active: string | any;
  setActive: React.Dispatch<React.SetStateAction<any>>;
};

export const SummitNavContext = createContext<SummitContextType>({
  active: "",
  setActive: () => {},
});

export default function SummitContext(props: SummitType) {
  const [active, setActive] = useState<any>("");

  return (
    <SummitNavContext.Provider value={{ active, setActive }}>
      {props.children}
    </SummitNavContext.Provider>
  );
}
