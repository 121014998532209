import React from "react";
import MHFooter from "../components/Layout/MHFooter";
import FaqContactHeader from "../components/Common/Header/FaqContactHeader";
import HowToPartner from "../components/BrandPartnership/HowToPartner";
import YellowSlider from "../components/Layout/YellowSlider";
import { SummitSlideData } from "../utils/landing-data";
import Clients from "../components/BrandPartnership/Clients";
import AboutPartnership from "../components/BrandPartnership/AboutPartnership";
import BlessingCard from "../components/Story/BlessingCard";
import AboutEquity from "../components/Equity/AboutEquity";
import ProgramsAndObjectives from "../components/Equity/ProgramsAndObjectives";

import { ReactComponent as ResLove } from "../static/svg/reslove.svg";
import StoryHeader from "../components/Story/StoryHeader";

import EquityBannerIcon from "../static/svg/banner.svg";
import useTitle from "../hooks/use-title";
import useAnalytics from "../hooks/use-analytics";

type Props = {};

const Equity = (props: Props) => {
  useTitle("Equity");

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: "Care Equity",
  });

  return (
    <React.Fragment>
      {/* <FaqContactHeader
        title="Equity"
        textColor="primary"
        imageSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1675708149/Frame_ichyxx.png"
        imageMobSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1675708149/Frame_1_zrx3yb.png"
        // Center Class attributes
        headerClassName="absolute items-center top-6 md:top-24 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[231px] mx-auto"

        // Left Class attributes
        // headerClassName="absolute items-center top-32 md:top-40 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      /> */}
      <StoryHeader
        title2="Care Equity"
        textColor="primary"
        icon={<ResLove />}
        imageSrc={EquityBannerIcon}
        imageMobSrc={EquityBannerIcon}
        // Center Class attributes
        headerClassName="absolute items-center top-32 md:top-32 md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
        titleClassName="md:w-[688px] w-[331px] mx-auto"

        // Left Class attributes
        // headerClassName="absolute items-center top-32 md:top-40 lg:pl-10 lg:text-left text-center md:w-full w-[331px] text-center left-0 right-0 md:ml-auto md:mr-auto mx-auto"
      />
      <AboutEquity />
      <ProgramsAndObjectives />
      {/* <Clients headerText="Select Clients and Brand Partners" />
      <YellowSlider data={SummitSlideData} />

      <BlessingCard /> */}

      <MHFooter />
    </React.Fragment>
  );
};

export default Equity;
