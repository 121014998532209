import { Fragment } from "react";
import React, { useEffect, useState } from "react";

import ResCard from "../SubComponents/ResCard";

import { ReactComponent as BgOverlay } from "../../../static/svg/video.svg";
import AllresHeader from "../SubComponents/AllresHeader";
import { Box, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Pagination from "../../UI/Pagination";
import VideoSort from "./VideoSort";
import MHSubscribe from "../../Layout/MHSubscribe";
import MHFooter from "../../Layout/MHFooter";
import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import { checkInvalidArray, compareStartTimeDesc } from "../../../utils/utils";
import VideoMobileFilter from "./VideoMobileFilter";
import useTitle from "../../../hooks/use-title";
import useAnalytics from "../../../hooks/use-analytics";

type ResProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  title?: string;
  texts?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
};

const VideosPage = (props: ResProps) => {
  useTitle('Videos');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Videos'
  });

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [resources, setResources] = useState<ResourceItemType[]>([]);

  var resUrl = `${process.env.REACT_APP_RES_VIDEO_URL}`;

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);

  // Filtered resources using startTime
  const dateFilteredData = resources.sort(compareStartTimeDesc);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = dateFilteredData.slice(firstPostIndex, lastPostIndex);

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getResource();
  }, []);

  return (
    <Fragment>
      <AllresHeader
        headerClassName="relative text-center pl-14 md:pl-0 h-[450px] md:h-[450px] lg:h-auto w-full bg-lilac-300 overflow-hidden"
        majorClassName="absolute top-8 md:top-12 md:inset-x-[18%] lg:top-24 lg:inset-x-[55%] text-center place-content-center"
        boxClassName="md:place-content-center pl-24 md:pl-4 md:overscroll-x-hidden md:gap-2 flex md:pb-4"
        podClassName="hidden"
        title="Videos"
        titleInfo="On-Demand Resources"
        titleInfoclassName="text-center my-2 -ml-6 md:ml-0 md:w-full font-columbia w-[60%] text-4xl capitalize font-[500]"
        pageInfo="  Achieve your personal and professional goals with personalized
        solutions from our specialized experts in career, care, and wellbeing."
        eventSocialClassName="hidden"
        pageInfoClassName="font-semibold pt-6 w-[70%] md:w-full md:ml-2 -ml-8 text-base md:text-[12px] font-areaSemi"
        ResIconUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661645343/image_2_tbop0x.png"
        BgUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661639769/image_1_anpmco.png"
      >
        <BgOverlay
          className="overflow-hidden absolute hidden md:block top-6 -right-12"
          height="400px"
          width="400px"
        />
        <BgOverlay
          className="overflow-hidden absolute md:hidden block rotate-[66deg] -scale-y-100 top-6 left-12"
          height="420px"
          width="450px"
        />
      </AllresHeader>

      {/* SEARCH FILTER */}
      <Typography
        color="primary"
        className=" lg:hidden block capitalize text-center pt-4 pb-2 opacity-50 text-[12px] font-areaSemi"
      >
        {resources.length} Results
      </Typography>

      {matchesMdDown ? (
        <VideoMobileFilter />
      ) : (
        <VideoSort resources={resources} resourcesLength={resources.length} />
      )}
      {/* SEARCH FILTER */}

      <Box className="mx-auto pt-10 bg-white px-12 md:px-20 py-4">
        <Grid container spacing={2}>
          {currentPosts.map((res, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[452px] object-cover bg-cream-100 rounded-md"
                iconClass="absolute top-14 left-[38%] mt-12 w-20 h-20"
                imgBg="bg-sky-400 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                top={
                  checkInvalidArray(res.newItemList).length
                    ? res.newItemList[0].interestName
                    : ""
                }
                podTop="hidden"
                title={res.title}
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/videos/${res.slug}/${res.id}`}
                playUrl={`${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Pagination
        totalPosts={resources.length}
        postsPerPage={postsPerPage}
        onClick={() => {
          setCurrentPage(currentPage - 1);
        }}
        onClicked={() => {
          setCurrentPage(currentPage + 1);
        }}
        // onClicks={() => {
        //   setCurrentPage(3);
        // }}
        currentPage={currentPage}
      />

      <MHSubscribe />
      <MHFooter />
    </Fragment>
  );
};

export default VideosPage;
