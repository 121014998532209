import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

type Props = {};

const RefundBodyCards = (props: Props) => {
  return (
    <React.Fragment>
      <Grid container spacing={1} className="pl-8 md:pl-16 mt-12 gap-1">
        <Grid item xs={12} sm={12} md={2.75} lg={2.75} className=" ">
          <Typography
            variant="caption"
            color="primary"
            fontSize={{ xs: "11px", sm: "12px" }}
            lineHeight={{ xs: "102%", sm: "102%" }}
            letterSpacing={"0.1em"}
            className="uppercase"
          >
            As of June 10, 2024
          </Typography>
        </Grid>

        <Grid item xs={11.5} sm={11} md={7} lg={7} className="">
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "16px" }}
            lineHeight={{ xs: "170%", sm: "200%" }}
            letterSpacing={"0.02em"}
            className=" pb-12"
          >
            At <b className="font-areaNorm"> MH WorkLife LLC, </b> we strive to
            provide exceptional care services to meet the needs of our clients.
            However, we understand that there may be situations where a refund
            is necessary. Our refund policy is designed to be fair and
            transparent to ensure customer satisfaction.
          </Typography>
          <Box className=" mb-4">
            <b className=" font-areaNorm "> Eligibility for Refunds:</b>
            <br />
            <div style={{ display: "flex", alignItems: "start" }}>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                {" "}
                1.
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                <b className=""> Service Cancellations: </b>
                <div
                  style={{ display: "flex", alignItems: "start" }}
                  className="pl-6"
                >
                  {"•"}
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    Full refunds are available for services canceled at least 24
                    hours in advance.
                  </Typography>
                </div>
                <div
                  style={{ display: "flex", alignItems: "start" }}
                  className="pl-6"
                >
                  {"•"}
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    Cancellations made less than 24 hours before the scheduled
                    service may be eligible for a partial refund at the
                    discretion of MH WorkLife LLC.
                  </Typography>
                </div>
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "start" }}>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                {" "}
                2.
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                <b className=""> Service Dissatisfaction:</b>
                <div
                  style={{ display: "flex", alignItems: "start" }}
                  className="pl-6"
                >
                  {"•"}
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    If you are not satisfied with the service provided, please
                    contact us within 24 hours of the service completion. We
                    will review your concerns and may offer a partial or full
                    refund based on the situation.
                  </Typography>
                </div>
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "start" }}>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                {" "}
                3.
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                <b className=""> Non-Delivery of Services:</b>
                <div
                  style={{ display: "flex", alignItems: "start" }}
                  className="pl-6"
                >
                  {"•"}
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    If MH WorkLife LLC fails to provide the scheduled service
                    without prior notice or acceptable reason, a full refund
                    will be issued.
                  </Typography>
                </div>
              </Typography>
            </div>
          </Box>
          <Box className=" mb-4">
            <b className=" font-areaNorm ">Non-Refundable Situations:</b>
            <br />
            <div style={{ display: "flex", alignItems: "start" }}>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                {" "}
                1.
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                <b className="">No-Shows:</b>
                <div
                  style={{ display: "flex", alignItems: "start" }}
                  className="pl-6"
                >
                  {"•"}
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    No refunds will be issued for no-shows or cancellations made
                    after the service has commenced.
                  </Typography>
                </div>
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "start" }}>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                {" "}
                2.
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                <b className="">Partial Services:</b>
                <div
                  style={{ display: "flex", alignItems: "start" }}
                  className="pl-6"
                >
                  {"•"}
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    If the service was partially completed due to circumstances
                    beyond our control (e.g., client unavailability, unsafe
                    environment), refunds will be considered on a case-by-case
                    basis.
                  </Typography>
                </div>
              </Typography>
            </div>
          </Box>
          <Box className=" mb-4">
            <b className=" font-areaNorm ">Refund Process:</b>
            <br />
            <div
              style={{ display: "flex", alignItems: "start" }}
              className="pl-6"
            >
              {"•"}
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                To request a refund, please contact our customer support team
                via email or phone with your service details and the reason for
                the refund request.
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "start" }}
              className="pl-6"
            >
              {"•"}
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                Refunds will be processed within 7-10 business days after
                approval.
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "start" }}
              className="pl-6"
            >
              {"•"}
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                Refunds will be issued to the original payment method used at
                the time of booking.
              </Typography>
            </div>
          </Box>
          <Box className=" mb-4">
            <b className=" font-areaNorm ">Contact Information:</b>
            <br />
            <div
              style={{ display: "flex", alignItems: "start" }}
              className="pl-6"
            >
              {"•"}
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                Email:{" "}
                <a
                  href="mailto:hello@mhworklife.com"
                  // target="_blank"
                  className="underline font-areaNorm"
                >
                  hello@mhworklife.com
                </a>
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "start" }}
              className=" pt-2"
            >
              <Typography
                variant="body2"
                color="primary"
                fontSize={{ xs: "14px", sm: "16px" }}
                lineHeight={{ xs: "185%", sm: "180%" }}
                letterSpacing={"0.02em"}
                // className=" mx-auto text-start"
              >
                We value your feedback and are committed to resolving any issues
                promptly. Your satisfaction is our priority, and we will work
                diligently to ensure your experience with MH WorkLife LLC is
                positive and fulfilling.
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RefundBodyCards;
