import { Fragment } from "react";
import React, { useState } from "react";

import ResCard from "../SubComponents/ResCard";

import { ReactComponent as BgOverlay } from "../../../static/svg/toolkit.svg";
import AllSort from "../SubComponents/AllSort";
import AllresHeader from "../SubComponents/AllresHeader";
import { Box, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Pagination from "../../UI/Pagination";
import MHSubscribe from "../../Layout/MHSubscribe";
import MHFooter from "../../Layout/MHFooter";
import MemberLoginDialog from "../../../pages/auth/MemberLoginDialog";
import MemberUpgradeDialog from "../../../pages/auth/MemberUpgradeDialog";
import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import { checkInvalidArray, compareStartTimeDesc } from "../../../utils/utils";
import ToolkitMobileFilter from "./ToolkitMobileFilter";
import ToolkitFilter from "./ToolkitFilter";
import useTitle from "../../../hooks/use-title";
import useAnalytics from "../../../hooks/use-analytics";

type ResProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  title?: string;
  texts?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
};

const ToolkitsPage = (props: ResProps) => {
  useTitle('Toolkits');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Toolkits'
  });

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [toolAuth, setToolAuth] = React.useState(false);
  const [toolUpgrade, setToolUpgrade] = React.useState(false);

  const [resources, setResources] = useState<ResourceItemType[]>([]);

  var resUrl = `${process.env.REACT_APP_RES_TOOLKIT_URL}`;

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);

  // Filtered resources using startTime
  const dateFilteredData = resources.sort(compareStartTimeDesc);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = dateFilteredData.slice(firstPostIndex, lastPostIndex);

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    getResource();
    console.log(resources, "Toolkits");
  }, []);

  React.useEffect(() => {
    const newsTimer = setTimeout(() => {
      setToolAuth(true);
    }, 3000);
    return () => clearTimeout(newsTimer);
  }, []);

  const handleAuthClose = () => {
    setToolAuth(false);
    setToolUpgrade(true);
  };
  const handleUpgClose = () => {
    setToolUpgrade(false);
  };

  return (
    <Fragment>
      <AllresHeader
        headerClassName="relative text-center pl-14 md:pl-0 h-[450px] md:h-[450px] lg:h-auto w-full bg-lilac-300 overflow-hidden"
        majorClassName="absolute top-14 md:top-12 md:inset-x-[18%] lg:top-24 lg:inset-x-[55%] text-center place-content-center"
        boxClassName="md:place-content-center pl-12 md:pl-4 md:overscroll-x-hidden md:gap-2 flex md:pb-4"
        podClassName="hidden md:hidden"
        title=" Toolkits & Guides"
        titleInfo="Download Our Helpful Toolkits"
        titleInfoclassName="text-center md:text-left -ml-2 md:ml-2 my-4 w-[60%] mx-auto md:w-full font-columbia text-4xl capitalize font-[500]"
        pageInfo="  Achieve your personal and professional goals with personalized
        solutions from our specialized experts in career, care, and wellbeing."
        eventSocialClassName="hidden"
        pageInfoClassName="font-semibold pt-6 w-[70%] md:w-full md:ml-2 -ml-8 text-base md:text-[12px] font-areaSemi"
        ResIconUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661643245/image_lqxpea.png"
        BgUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661639768/image_bfore8.png"
      >
        <BgOverlay
          className="overflow-hidden hidden md:block absolute -top-6 right-0 "
          height="530px"
          width="530px"
        />
        <BgOverlay
          className="overflow-hidden md:hidden block absolute top-0 left-44 -scale-y-100 "
          height="450px"
          width="450px"
        />
      </AllresHeader>

      {/* <AllSort resourcesLength={resources.length} /> */}

      {/* SEARCH FILTER */}
      <Typography
        color="primary"
        className=" lg:hidden block capitalize text-center pt-4 pb-2 opacity-50 text-[12px] font-areaSemi"
      >
        {resources.length} Results
      </Typography>

      {matchesMdDown ? (
        <ToolkitMobileFilter />
      ) : (
        <ToolkitFilter totalRecords={resources.length} />
      )}

      {/* SEARCH FILTER */}

      {/* For Web */}
      <Box className="mx-auto pt-10 bg-white px-12 md:px-20 py-4">
        <Grid container spacing={2}>
          {currentPosts.map((res, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[452px] object-cover bg-cream-100 rounded-md"
                iconClass="hidden"
                imgBg="bg-cream-200 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                // imageSrc="https://res.cloudinary.com/kehinde-motherhoneestly/image/upload/v1669837683/MHresources/square_qvetb1.png"
                top={
                  checkInvalidArray(res.newItemList).length
                    ? res.newItemList[0].interestName
                    : ""
                }
                title={res.title}
                podTop="hidden"
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/toolkits/${res.slug}/${res.id}`}
                playUrl={`/resources/toolkits/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Pagination
        totalPosts={resources.length}
        postsPerPage={postsPerPage}
        onClick={() => {
          setCurrentPage(currentPage - 1);
        }}
        onClicked={() => {
          setCurrentPage(currentPage + 1);
        }}
        currentPage={currentPage}
      />

      <MHSubscribe />
      <MHFooter />

      {/* <MemberLoginDialog open={toolAuth} onClose={handleAuthClose} /> */}
      <MemberUpgradeDialog open={toolUpgrade} onClose={handleUpgClose} />
    </Fragment>
  );
};

export default ToolkitsPage;
