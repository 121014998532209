import { Fragment } from "react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import ResCard from "../SubComponents/ResCard";

import { ReactComponent as BgOverlay } from "../../../static/svg/podcast.svg";

import AllresHeader from "../SubComponents/AllresHeader";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  IconButton,
  Typography,
  Zoom,
} from "@mui/material";

import PodcastPlayer from "./PodcastPlayer";
import Pagination from "../../UI/Pagination";
import { Link, useLocation } from "react-router-dom";
import PodSubscribe from "../../Layout/PodSubscribe";
import MHSubscribe from "../../Layout/MHSubscribe";
import MHFooter from "../../Layout/MHFooter";
import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import { checkInvalidArray } from "../../../utils/utils";
import useTitle from "../../../hooks/use-title";
import useAnalytics from "../../../hooks/use-analytics";

type ResProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  season?: string | number | any;
  source?: string;
  episode?: string | number | any;
  title?: string;
  texts?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
};

const PodcastsPage = (props: ResProps) => {
  useTitle('Podcasts');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Podcasts'
  });

  const [resources, setResources] = useState<ResourceItemType[]>([]);
  let [count] = useState(0);
  let [mobileCount] = useState(0);
  const location = useLocation();
  var resUrl = `${process.env.REACT_APP_RES_PODCAST_URL}`;

  const [noOfElement, setnoOfElement] = useState(1);

  var collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  const resOne = resources.slice(count, noOfElement);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(11);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = resources.slice(firstPostIndex, lastPostIndex);

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      const sortedData = formattedResources.sort((a: any, b: any) => {
        if (a.season === b.season) {
          return b.episode - a.episode;
        }
        return b.season - a.season;
      });

      setResources(sortedData);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: "22px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false,
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  useEffect(() => {
    getResource();

    console.log();
  }, []);

  return (
    <Fragment>
      <AllresHeader
        headerClassName="relative text-center pl-14 md:pl-0 h-[600px] md:h-[450px] lg:h-auto w-full bg-lilac-300 overflow-hidden"
        majorClassName="absolute top-8 md:top-12 md:inset-x-[18%] lg:top-24 lg:inset-x-[55%] text-center place-content-center"
        boxClassName=" pb-6 pl-20 md:pl-52 lg:pl-48 md:overscroll-x-hidden md:gap-2 flex md:pb-4"
        title="Podcast"
        titleInfo="The Podcast"
        titleInfoclassName="text-center mb-6 w-[60%] md:w-full font-columbia text-5xl capitalize font-semibold"
        pageInfo="  Achieve your personal and professional goals with personalized
        solutions from our specialized experts in career, care, and wellbeing."
        eventSocialClassName="hidden"
        podClassName="w-[58%] mt-12 md:mt-0 md:w-fit md:absolute md:top-64 md:left-8 md:flex md:gap-32"
        // podClassName2="mx-auto block"
        pageInfoClassName="font-semibold w-[70%] md:w-full md:ml-2 -ml-8 text-base md:text-[12px] font-areaSemi"
        ResIconUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661824668/image_1_oe6a7y.png"
        BgUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1676072145/Vector_ffvlt6.png"
      >
        <BgOverlay
          className="overflow-hidden absolute hidden md:block  top-0 right-0"
          height="500px"
          width="500px"
        />
        <BgOverlay
          className="overflow-hidden absolute md:hidden block scale-y-100 scale-x-100 -rotate-[140deg] -top-48 -left-80"
          height="650px"
          width="720px"
        />
      </AllresHeader>

      <Box className="mx-auto pt-10 bg-white px-4 md:px-24 lg:px-[170px] py-4">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-4"
        >
          Latest Episode
        </Typography>
        {resOne.map((resData, index) => (
          <PodcastPlayer spotifyUrl={resData.source} />
        ))}
      </Box>

      <Box className="bg-gray-300 h-[1px] w-[91.4%] opacity-50 overflow-hidden mx-auto"></Box>

      {/* For Mobile */}
      <Box className="mx-auto pt-10 bg-white px-4 md:px-20 pb-4 block md:hidden overflow-x-hidden">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-base md:text-xl text-center py-4"
        >
          Episodes You Might like
        </Typography>
        <Slider {...settings}>
          {resources.map((res, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[474px] object-cover bg-cream-100 rounded-md"
                iconClass="hidden"
                imgBg="bg-green-100 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                season={res.season}
                episode={res.episode}
                title={res.title}
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/podcasts/${res.slug}/${res.id}`}
                playUrl={`/resources/podcasts/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Slider>

        <Grid container spacing={2} className="">
          {currentPosts.map((res, index) => (
            <>
              <p className="hidden"> {(mobileCount = mobileCount + 1)}</p>

              {mobileCount === 1 ? (
                <Grid item xs={12} md={12} lg={6}>
                  <Zoom in style={{ transitionDelay: "200ms" }}>
                    <Card
                      className={`relative w-auto h-[460px] object-cover z-30 rounded-md`}
                    >
                      <IconButton
                        disabled
                        className="absolute outline-none top-[10%] left-[5%] drop-shadow-md z-50"
                      >
                        <Typography className=" capitalize font-areaSemi drop-shadow-md text-[15px] font-[900] leading-[92%] tracking-[0.1rem] text-white">
                          Podcast Play
                        </Typography>
                      </IconButton>
                      <Box className="  absolute top-[20%] left-[6%] z-30">
                        <Typography className="text-left capitalize line-clamp-5 drop-shadow-md w-[70%] font-areaSemi text-[22px] leading-[150%] tracking-[-0.04rem] font-[600] text-white">
                          {res.title}
                        </Typography>

                        <Box className="pt-12 text-left space-x-4 absolute z-30">
                          <Link
                            to={`${location.pathname}/${res.slug}/${res.id}
       `}
                            className="bg-white w-[108px] no-underline  px-6 py-4 h-[45px] text-navy-900 font-areaSemi not-italic text-[12px] font-[700] leading-[102%] tracking-[0.05rem]"
                          >
                            Listen
                          </Link>
                        </Box>
                      </Box>
                      <CardMedia
                        className="object-cover absolute bottom-0 h-[460px]"
                        component="img"
                        // image={res.thumbNailImageSrc}
                        image="https://res.cloudinary.com/mother-honestly/image/upload/v1678875641/pod_zp5lpj.jpg"
                        alt="Resource Image"
                      />
                    </Card>
                  </Zoom>
                </Grid>
              ) : null}
            </>
          ))}
        </Grid>
      </Box>

      {/* For Web */}
      <Box className="mx-auto pt-10 bg-white px-4 md:px-20 py-4 hidden md:block">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-base md:text-xl text-center py-4"
        >
          Episodes You Might like
        </Typography>

        <Grid container spacing={2}>
          {currentPosts.map((res, index) => (
            <>
              <div className="hidden"> {(count = count + 1)}</div>

              {count === 11 ? (
                <Grid item xs={12} md={12} lg={6}>
                  <Zoom in style={{ transitionDelay: "200ms" }}>
                    <Card
                      className={`relative w-auto h-[474px] object-cover bg-green-100 rounded-md`}
                    >
                      <IconButton
                        disabled
                        className="absolute outline-none top-[6%] left-[5%]"
                      >
                        <Typography className=" capitalize font-areaSemi text-[15px] font-[900] leading-[92%] tracking-[0.1rem] text-white">
                          Podcast Play
                        </Typography>
                      </IconButton>
                      <Box className="  absolute top-[10%] left-[6%] z-50 drop-shadow-md">
                        <Typography className="text-left capitalize line-clamp-3 w-[70%] font-areaSemi text-[22px] leading-[150%] tracking-[-0.04rem] font-[600] text-white">
                          {res.title}
                        </Typography>

                        <Box className="pt-12 text-left space-x-4 absolute z-50">
                          <Link
                            to={`${location.pathname}/${res.slug}/${res.id}
       `}
                            className="bg-white w-[108px] no-underline  px-6 py-4 h-[45px] text-navy-900 font-areaSemi not-italic text-[12px] font-[700] leading-[102%] tracking-[0.05rem]"
                          >
                            Listen
                          </Link>
                        </Box>
                      </Box>
                      <CardMedia
                        className="object-cover absolute bottom-0 h-full"
                        component="img"
                        // image={res.thumbNailImageSrc}
                        image="https://res.cloudinary.com/mother-honestly/image/upload/v1678875641/pod_zp5lpj.jpg"
                        alt="Resource Image"
                      />
                    </Card>
                  </Zoom>
                </Grid>
              ) : count !== 11 ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={index}
                  className="cardozo md:px-3"
                >
                  <ResCard
                    cardClass="relative mb-10 w-[305px] h-[474px] object-cover bg-cream-100 rounded-md"
                    iconClass="hidden"
                    imgBg="bg-green-100 h-[305px]"
                    bodyBg="bg-cream-100"
                    imageSrc={res.thumbNailImageSrc}
                    season={res.season}
                    episode={res.episode}
                    title={res.title}
                    // category={res.itemList ? res.itemList[0].interestName : ""}
                    // categoryTwo={res.itemList ? res.itemList[1] : ""}
                    category={res.newItemList}
                    titleUrl={`/resources/podcasts/${res.slug}/${res.id}`}
                    playUrl={`/resources/podcasts/${res.slug}/${res.id}`}
                  />
                </Grid>
              ) : null}
            </>
          ))}
        </Grid>
      </Box>
      <Box className="hidden md:block">
        <Pagination
          totalPosts={resources.length}
          postsPerPage={postsPerPage}
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}
          onClicked={() => {
            setCurrentPage(currentPage + 1);
          }}
          currentPage={currentPage}
        />
      </Box>
      <PodSubscribe />
      <MHSubscribe />
      <MHFooter />
    </Fragment>
  );
};

export default PodcastsPage;
