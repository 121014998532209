import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import { ReactComponent as MHIcon } from "../../static/svg/brand/Care_Gap_Hrzn.svg";
// import { ReactComponent as MHIcon } from "../../static/svg/brand/MHWorkLife_Horizontal_Color.svg";
import { ReactComponent as MHMobileIcon } from "../../static/svg/brand/Care_Gap_Hrzn.svg";
import { ReactComponent as FacebookIcon } from "../../static/svg/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../static/svg/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../static/svg/twitter.svg";
import { ReactComponent as PinterestIcon } from "../../static/svg/pinterest.svg";
import { ReactComponent as LinkedinIcon } from "../../static/svg/linkedin-icon.svg";
import { ReactComponent as YellowDashIcon } from "../../static/svg/yellow-dash.svg";
import { CONTENT, MEMBERSHIP, PLATFORM, TOPICS } from "../../utils/footer-data";
import Footer from "./Footer";
import MHButton from "../Common/Button/MHButton";
import StaticDataContext from "../../store/context/static-data.context";
import ContactDialog from "../Contact/BlessingDialog";

const MHFooter = () => {
  let match = useRouteMatch();

  const [open, setOpen] = React.useState(false);
  const dataCtx = React.useContext(StaticDataContext);
  const { openWaitlist, closeWaitlist } = dataCtx;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeWaitlist();
  };

  React.useEffect(() => {
    if (openWaitlist) {
      handleOpen();
    }
  }, [openWaitlist]);

  let history = useHistory();

  const handleClickOpen = () => {
    history.push(`/contact`);
  };

  const scheduleCall = () => {
    // window.open("https://calendly.com/brooke-thecaregap/the-care-gap-interest-call");
    history.push(`/schedule`);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className="px-5 md:px-4 lg:px-20 py-16 ">
        {/* Left - Side  */}

        <Grid item xs={12} sm={6} md={6} lg={6} className="hidden md:block">
          <Typography
            // variant="caption"
            fontFamily="Area-Extended"
            textTransform="uppercase"
            color="#194049"
            fontSize={10}
            fontWeight={700}
            letterSpacing="0.1em"
            lineHeight="102%"
            className=" text-start"
            sx={{
              mb: 2,
              paddingBottom: 2,
            }}
          >
            About us
          </Typography>

          <Typography
            variant="body1"
            fontFamily="Area-Normal-Semibold"
            color="primary.main"
            width={{ xs: "100%", md: "70%" }}
            fontSize="12px"
            letterSpacing="0.1em"
            lineHeight="180%"
            className=" text-start"
            paragraph
            sx={{
              mb: 2,
              paddingBottom: 2,
            }}
          >
            An ecosystem powering care for today's workforce and advancing
            equity, productivity, and wellbeing in today's ever-evolving
            workplaces.
          </Typography>

          <Box
            className="flex items-center gap-5"
            sx={{
              mb: 2,
              paddingBottom: 5,
            }}
          >
            <a
              onClick={() =>
                window.open(
                  "https://www.instagram.com/thecaregap?igsh=MXh3NWY2bTBrMnlkaw=="
                )
              }
              className="cursor-pointer"
            >
              <InstagramIcon className="w-4 h-4 " />
            </a>

            <a
              onClick={() =>
                window.open(
                  "https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
                )
              }
              className="cursor-pointer"
            >
              <FacebookIcon className="w-4 h-4" />
            </a>

            <a
              onClick={() =>
                window.open("https://www.pinterest.com/motherhonestly/")
              }
              className="cursor-pointer"
            >
              <PinterestIcon className="w-4 h-4" />
            </a>

            <a
              onClick={() =>
                window.open(
                  "https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
                )
              }
              className="cursor-pointer"
            >
              <TwitterIcon className="w-4 h-4" />
            </a>

            <a
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/caringblocc/mycompany/"
                )
              }
              className="cursor-pointer"
            >
              <LinkedinIcon className="w-4 h-4" />
            </a>
          </Box>

          <Box className="items-center">
            <Link
              // onClick={scheduleCall}
              to={"/schedule"}
              className="!no-underline !w-fit cursor-pointer"
            >
              <Typography
                variant="body1"
                fontFamily="Area-Normal-Bold"
                textTransform="capitalize"
                color="#194049"
                fontSize={12}
                letterSpacing="0.1em"
                lineHeight="102%"
                className=" text-start"
                sx={{
                  mb: 1,
                }}
              >
                Schedule A Call
              </Typography>
              <YellowDashIcon />
            </Link>
          </Box>
        </Grid>

        {/* Right Side  */}

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Grid container spacing={1} className="">
            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              lg={3}
              className="mb-6 md:mb-0 order-4 md:order-none"
            >
              <Typography
                // variant="caption"
                fontFamily="Area-Extended"
                textTransform="uppercase"
                color="#194049"
                fontSize={10}
                fontWeight={700}
                letterSpacing="0.1em"
                lineHeight="102%"
                className=" text-start"
                sx={{
                  mb: 2,
                  paddingBottom: 2,
                }}
              >
                Platform
              </Typography>
              {PLATFORM.map((item, index) => (
                <Link to={item.link} className="!no-underline" key={index}>
                  <Typography
                    variant="body1"
                    fontFamily="Area-Normal-Semibold"
                    color="primary.main"
                    width={{ xs: "100%", md: "100%" }}
                    fontSize="11px"
                    letterSpacing="0.1em"
                    lineHeight="180%"
                    className=" text-start "
                    paragraph
                    sx={{
                      mb: 1,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
              ))}
            </Grid>

            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              lg={3}
              className="mb-6 md:mb-0 order-3 md:order-none"
            >
              <Typography
                // variant="caption"
                fontFamily="Area-Extended"
                textTransform="uppercase"
                color="#194049"
                fontSize={10}
                fontWeight={700}
                letterSpacing="0.1em"
                lineHeight="102%"
                className=" text-start"
                sx={{
                  mb: 2,
                  paddingBottom: 2,
                }}
              >
                Legal
              </Typography>

              {MEMBERSHIP.map((item, index) => (
                <Link to={item.link} className="!no-underline" key={index}>
                  <Typography
                    variant="body1"
                    fontFamily="Area-Normal-Semibold"
                    color="primary.main"
                    width={{ xs: "100%", md: "100%" }}
                    fontSize="11px"
                    letterSpacing="0.1em"
                    lineHeight="180%"
                    className=" text-start"
                    paragraph
                    sx={{
                      mb: 1,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
              ))}
            </Grid>

            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              lg={3}
              className="mb-6 md:mb-0 order-2 md:order-none"
            >
              <Typography
                // variant="caption"
                fontFamily="Area-Extended"
                textTransform="uppercase"
                color="#194049"
                fontSize={10}
                fontWeight={700}
                letterSpacing="0.1em"
                lineHeight="102%"
                className=" text-start"
                sx={{
                  mb: 2,
                  paddingBottom: 2,
                }}
              >
                CONTENT
              </Typography>

              {TOPICS.map((item, index) => (
                <Link to={item.link} className="!no-underline" key={index}>
                  <Typography
                    variant="body1"
                    fontFamily="Area-Normal-Semibold"
                    color="primary.main"
                    width={{ xs: "100%", md: "100%" }}
                    fontSize="11px"
                    letterSpacing="0.1em"
                    lineHeight="180%"
                    className=" text-start "
                    paragraph
                    sx={{
                      mb: 1,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
              ))}
            </Grid>

            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              lg={3}
              className="mb-6 md:mb-0 order-1 md:order-none"
            >
              <Typography
                // variant="caption"
                fontFamily="Area-Extended"
                textTransform="uppercase"
                color="#194049"
                fontSize={10}
                fontWeight={700}
                letterSpacing="0.1em"
                lineHeight="102%"
                className=" text-start"
                sx={{
                  mb: 2,
                  paddingBottom: 2,
                }}
              >
                RESOURCES
              </Typography>

              {CONTENT.map((item, index) => (
                <Link to={item.link} className="!no-underline" key={index}>
                  <Typography
                    variant="body1"
                    fontFamily="Area-Normal-Semibold"
                    color="primary.main"
                    width={{ xs: "100%", md: "100%" }}
                    fontSize="11px"
                    letterSpacing="0.1em"
                    lineHeight="180%"
                    className=" text-start "
                    paragraph
                    sx={{
                      mb: 1,
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Box className="block md:hidden bg-[#F3F4F6] h-[1px] w-[90%] my-8 mx-auto"></Box>

        <Box className="!items-center !mx-auto !text-center w-[200px] block md:hidden">
          <a
            // href="/schedule"
            onClick={scheduleCall}
            className="!no-underline cursor-pointer"
          >
            <Typography
              variant="body1"
              fontFamily="Area-Normal-Bold"
              textTransform="capitalize"
              color="#194049"
              fontSize={12}
              letterSpacing="0.1em"
              lineHeight="102%"
              className="text-center"
              sx={{
                mb: 1,
              }}
            >
              Schedule A Call
            </Typography>
            <YellowDashIcon className="!mx-auto" />
          </a>
        </Box>

        <Box className="pt-12 md:pt-40 mx-auto items-center pl-0 md:pl-6">
          <MHIcon className="hidden md:block md:w-96 lg:w-[780px]" />
          {/* <img
            src="https://res.cloudinary.com/mother-honestly/image/upload/v1678196237/MHWorkLife_Horizontal_Color_wo0xxc.png"
            alt="MH Icon"
            className="w-64 md:w-[480px] md:h-[50px] lg:w-[780px] lg:h-[70px]"
          /> */}
          <MHMobileIcon className="w-64 h-[74px] mb-8 block md:hidden" />
        </Box>

        <Box
          className="md:hidden flex items-center gap-7 mx-auto"
          sx={{
            mb: 2,
          }}
        >
          <a
            onClick={() =>
              window.open(
                "https://www.instagram.com/thecaregap?igsh=MXh3NWY2bTBrMnlkaw=="
              )
            }
            className="cursor-pointer"
          >
            <InstagramIcon className="w-5 h-5 " />
          </a>

          <a
            onClick={() =>
              window.open(
                "https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
              )
            }
            className="cursor-pointer"
          >
            <FacebookIcon className="w-5 h-5" />
          </a>

          <a
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/caringblocc/mycompany/"
              )
            }
            className="cursor-pointer"
          >
            <LinkedinIcon className="w-5 h-5" />
          </a>

          <a
            onClick={() =>
              window.open("https://www.pinterest.com/motherhonestly/")
            }
            className="cursor-pointer"
          >
            <PinterestIcon className="w-5 h-5" />
          </a>

          <a
            onClick={() =>
              window.open(
                "https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
              )
            }
            className="cursor-pointer"
          >
            <TwitterIcon className="w-5 h-5" />
          </a>
        </Box>
      </Grid>

      <Footer />
      {match.path === "/summit" && <Box className="py-7"></Box>}

      <ContactDialog open={open} onClose={handleClose} />
    </React.Fragment>
  );
};

export default MHFooter;
