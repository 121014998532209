import React from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { DropdownButton } from "../../Common/Dropdown/MHDropdown";
import MobileDrawer from "../../Layout/MobileDrawer";
import useDrawer from "../../../hooks/use-drawer";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AppContext from "../../../store/context/app.context";
import { TopLevelCategory } from "../../../models/dashboard.model";
import MHCheckbox from "../../Common/Form/MHCheckbox";
import StackedRow from "../../UI/StackedRow";
import { ChecklistItem } from "../../Common/Dropdown/MHChecklistDropdown";
import MobileDrawerTop from "../../Layout/MobileDrawerTop";
import MobileDropdown from "../../Features/Consultants/MobileDropdown";

const MobileDropdownWrapper = styled(StackedRow)(({ theme }) => ({
  justifyContent: "center",
  border: "1px solid #F3F4F6",
  width: "50%",
  "div > &:nth-of-type(1)": {
    borderTopLeftRadius: ".4rem",
    borderBottomLeftRadius: ".4rem",
  },
  "div > &:nth-of-type(2)": {
    borderTopRightRadius: ".4rem",
    borderBottomRightRadius: ".4rem",
  },
  "& .MuiTypography-root": {
    fontFamily: "Area-Extended",
    textTransform: "uppercase",
  },
}));

const VideoMobileFilter = () => {
  const {
    open: isDrawerOpen,
    anchor,
    toggleDrawer,
  } = useDrawer("bottom", false);

  const staticDataCtx = React.useContext(AppContext);
  const { staticDataCacheMap } = staticDataCtx;
  const topLvlCategories: ChecklistItem[] =
    staticDataCacheMap.get("topLevelCategories")?.map((item) => ({
      id: item.id,
      label: item.sectionName,
    })) || [];

  const [checked, setChecked] = React.useState<ChecklistItem[]>([]);

  const handleToggle = (listItem: ChecklistItem) => () => {
    const currentIndex = checked.findIndex((item) => item.id === listItem.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(listItem);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    console.log(newChecked);

    setChecked(newChecked);
  };

  return (
    <React.Fragment>
      <StackedRow px={2} mt={2}>
        <MobileDropdownWrapper>
          <DropdownButton
            label="Filter By"
            isOpen={isDrawerOpen}
            onExpand={toggleDrawer(true)}
            sx={{
              p: "1rem",
            }}
          />
        </MobileDropdownWrapper>
        <MobileDropdownWrapper>
          <DropdownButton
            label="Sort By"
            isOpen={isDrawerOpen}
            sx={{
              p: "1rem",
            }}
          />
        </MobileDropdownWrapper>
      </StackedRow>

      <MobileDrawer
        variant="temporary"
        open={isDrawerOpen}
        anchor={anchor}
        onClose={toggleDrawer(false)}
        sx={{
          height: "400px",
        }}
      >
        <Box px={1.3}>
          <MobileDrawerTop title="FILTER BY" onClose={toggleDrawer} />

          <MobileDropdown label="Category">
            <List sx={{ width: "100%", p: 0 }}>
              {topLvlCategories.map((item, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <ListItem key={item.id} disablePadding disableGutters>
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(item)}
                      alignItems="center"
                      sx={{
                        py: 0,
                      }}
                      dense
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "30px",
                        }}
                      >
                        <MHCheckbox
                          edge="start"
                          checked={
                            checked.findIndex(
                              (checkedItem) => checkedItem.id === item.id
                            ) > -1
                          }
                          tabIndex={-1}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={item.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </MobileDropdown>
        </Box>
      </MobileDrawer>
    </React.Fragment>
  );
};

export default VideoMobileFilter;
