import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import MHSnackbar, {
  MHSnackbarProps
} from '../../components/Common/Snackbar/MHSnackbar';

const SnackbarContainer = styled(Stack)(({ theme }) => ({
  // position: 'fixed',
  // top: 20,
  // maxWidth: 700,
  // left: '50%',
  // transform: 'translateX(-50%)',
  // zIndex: theme.zIndex.modal + 1100
}));

interface SnackbarMessage {
  key: number;
}

interface SnackbarState {
  snackPack: Array<MHSnackbarProps>;
  toast: (snackbar: MHSnackbarProps) => void;
}

const SnackbarContext = React.createContext<SnackbarState>({
  snackPack: [],
  toast: (snackbar: MHSnackbarProps) => {}
});

export const SnackbarContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [snackPack, setSnackPack] = React.useState<
    Array<MHSnackbarProps & SnackbarMessage>
  >([]);
  const [messageInfo, setMessageInfo] = React.useState<
    (MHSnackbarProps & SnackbarMessage) | undefined
  >(undefined);
  const [open, setOpen] = React.useState(false);

  const toast = React.useCallback((snackbar: MHSnackbarProps) => {
    setSnackPack((prev) => [
      ...prev,
      {
        ...snackbar,
        key: new Date().getTime()
      }
    ]);
  }, []);

  React.useEffect(() => {
    if (
      (snackPack.length && !messageInfo) ||
      (snackPack.length && messageInfo && !open)
    ) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const contextValue: SnackbarState = {
    snackPack,
    toast
  };

  return (
    <SnackbarContext.Provider value={contextValue}>
      <React.Fragment>
        <MHSnackbar
          open={open}
          key={messageInfo ? messageInfo.key : undefined}
          variant={messageInfo ? messageInfo.variant : undefined}
          message={messageInfo ? messageInfo.message : undefined}
          TransitionProps={{ onExited: handleExited }}
          onClose={handleClose}
        />
      </React.Fragment>

      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContext;
