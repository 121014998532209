import React from 'react';

import { SelectOption } from '@mui/base';

import MHSnackbar from '../../components/Common/Snackbar/MHSnackbar';
import BackdropLoader from '../../components/Common/Loading/BackdropLoader';
import useHttp from '../../hooks/use-http';

import { HttpResponse } from '../../models/api.interface';

import { getURLWithQueryParams, sortListByIdAsc } from '../../utils/utils';
import AuthContext from './auth-context';
import { TopLevelCategory } from '../../models/dashboard.model';
import { Interest } from '../../models/onboarding';
import geoData from '../../data/georef-united-states-of-america-state.json';
import { Category, Merchant } from '../../models/wallet.model';

type AppCtxType = {
  staticDataCacheMap: Map<string, any[]>;
  interests: Interest[];
  states: SelectOption<string>[];
  configureStates: (geoData: any) => void;
};

const AppContext = React.createContext<AppCtxType>({
  staticDataCacheMap: new Map<string, any[]>(),
  interests: [],
  states: [],
  configureStates: (geoData: any) => {}
});

export const AppContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [dataCacheMap, setDataCacheMap] = React.useState<
    Map<string, (Category | Merchant | TopLevelCategory)[]>
  >(new Map());

  const authCtx = React.useContext(AuthContext);
  const { loading, error, sendHttpRequest: fetchCategories } = useHttp();
  const { sendHttpRequest: fetchMerchants } = useHttp();
  const { sendHttpRequest: fetchTopLevelCategories } = useHttp();

  const [interests, setInterests] = React.useState<Interest[]>([]);
  const {
    loading: loadingInterests,
    sendHttpRequest: fetchInterests
  } = useHttp();

  const reqOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authCtx.token}`
    }
  };

  React.useEffect(() => {
    configureStates(geoData);

    // fetchInterests(
    //   process.env.REACT_APP_API_BASE_URL + 'employee/dashboard/interests/base',
    //   reqOptions,
    //   (response: HttpResponse<Interest[]>) => {
    //     setInterests(response.data as Interest[]);
    //   }
    // );
  }, []);

  React.useEffect(() => {
    // fetchCategories(
    //   process.env.REACT_APP_API_BASE_URL + 'employee/dashboard/category/all',
    //   reqOptions,
    //   (response: HttpResponse<Category[]>) => {
    //     setCategoryData(response.data);
    //   }
    // );

    // fetchMerchants(
    //   process.env.REACT_APP_API_BASE_URL + 'employee/dashboard/merchant/all',
    //   reqOptions,
    //   (response: HttpResponse<Merchant[]>) => {
    //     setMerchantData(response.data);
    //   }
    // );

    fetchTopLevelCategories(
      process.env.REACT_APP_API_BASE_URL + 'employee/dashboard/toplevel/all',
      reqOptions,
      (response: HttpResponse<TopLevelCategory[]>) => {
        setTopLevelCategories(response.data);
      }
    );
  }, []);

  const setCategoryData = (categories: Category[]) => {
    setDataCacheMap((prevState) => {
      const newState = new Map(prevState);

      if (!categories) {
        return prevState;
      }

      const mappedCategories: (Category &
        SelectOption<string>)[] = categories.map((category) => ({
        ...category,
        value: category.id + '',
        label: category.categoryName
      }));
      newState.set('categories', mappedCategories);
      return newState;
    });
  };

  const setMerchantData = (merchants: Merchant[]) => {
    setDataCacheMap((prevState) => {
      const newState = new Map(prevState);

      if (!merchants) {
        return prevState;
      }

      const mappedMerchants: (Merchant & SelectOption<string>)[] = merchants
        .sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        })
        .concat({
          id: -1,
          merchantName: 'Other'
        } as Merchant)
        .map((merchant) => ({
          ...merchant,
          value: merchant.id + '',
          label: merchant.merchantName
          // categoryList: sortListByIdAsc(merchant.categoryList) // causes error when list is undefined
        }));
      newState.set('merchants', mappedMerchants);
      return newState;
    });
  };

  const setTopLevelCategories = (tlCategories: TopLevelCategory[]) => {
    setDataCacheMap((prevState) => {
      const newState = new Map(prevState);

      if (!tlCategories) {
        return prevState;
      }

      const mappedTopLevelCategories: (TopLevelCategory &
        SelectOption<string>)[] = tlCategories.map((cat) => ({
        ...cat,
        value: cat.id + '',
        label: cat.sectionName
      }));
      newState.set('topLevelCategories', mappedTopLevelCategories);
      return newState;
    });
  };

  const [states, setStates] = React.useState<Array<SelectOption<string>>>([]);

  const configureStates = (geoData: Array<unknown>) => {
    const mappedStates = geoData
      .filter((item: any) => item.fields.ste_type === 'state')
      .map((item: any) => {
        return {
          value: item.fields.ste_name,
          label: item.fields.ste_name
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    // console.log(mappedStates);
    setStates(mappedStates);
  };

  const { user } = authCtx;

  const contextValue: AppCtxType = {
    staticDataCacheMap: dataCacheMap,
    interests,
    states,
    configureStates
  };

  return (
    <AppContext.Provider value={contextValue}>
      {error && <MHSnackbar variant="error" message={error.message} />}
      {!loading ? children : <BackdropLoader open={loading} />}
    </AppContext.Provider>
  );
};

export default AppContext;
