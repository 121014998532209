import React, { Fragment, useEffect, useState } from 'react';
import ViewHeader from '../SubComponents/ViewHeader';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import ResCard from '../SubComponents/ResCard';
import { useParams } from 'react-router-dom';
import PodcastPlayer from './PodcastPlayer';
import MHSubscribe from '../../Layout/MHSubscribe';
import MHFooter from '../../Layout/MHFooter';
import Slider from 'react-slick';
import {
  ItemList,
  ResourceItemType
} from '../../../models/resources.interface';
import { HttpResponse } from '../../../models/api.interface';
import { checkInvalidArray } from '../../../utils/utils';
import shuffleArray from '../../../hooks/use-random';
import useTitle from '../../../hooks/use-title';
import useAnalytics from '../../../hooks/use-analytics';

type ComponentProps = {
  thumbNailImageSrc?: string;
  itemList?: string[] | undefined;
  season?: string;
  episode?: string;
  content?: string;
  title?: string;
  texts?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
};

const ViewPodcast = (props: ComponentProps) => {
  useTitle('Podcasts');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Podcasts'
  });

  const [resources, setResources] = useState<ResourceItemType[]>([]);

  const [noOfElement, setnoOfElement] = useState(4);
  const slice = resources.slice(0, noOfElement);

  var resUrl = `${process.env.REACT_APP_RES_PODCAST_URL}`;

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(',');
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList
            };
          })
        : [];

      // Shuffle data to display randomly
      const randomizedData = shuffleArray(formattedResources); // Randomize the filtered array
      setResources(randomizedData);

      // setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  const [data, setData] = useState<ResourceItemType | null>(null);

  const params = useParams<any>();
  console.log(params.id!);

  var viewUrl = `${process.env.REACT_APP_ALL_RES_VIEW_URL}${params.id}`;

  const getData = async () => {
    try {
      const response = await fetch(viewUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const { data }: HttpResponse<ResourceItemType> = await response.json();

      const priorityList: string[] = data.priorities.split(',');
      let newItemList: ItemList[] = [];

      for (const item of priorityList) {
        const newObject = data.itemList.find((it) => it.id === +item);

        if (newObject) {
          if (!newItemList.length) {
            newItemList = [newObject];
          } else {
            newItemList = [...newItemList, newObject];
          }
        }
      }

      const formattedResource: ResourceItemType = {
        ...data,
        newItemList
      };

      setData(formattedResource);
    } catch (err) {
      console.error('Cannot find Data');
    }
  };

  const settings = {
    centerMode: true,
    centerPadding: '22px',
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 800,
    autoplay: false
    // autoplaySpeed: 1500,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  useEffect(() => {
    getResource();
    getData();
  }, [params.id]);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <ViewHeader
        titles={data.title}
        headerClassName="bg-lilac-300 w-full h-full px-4 md:px-0 md:pt-2 lg:pt-6 pt-6 md:pl-12 lg:pl-20 relative"
        description={data.content}
        headerDateClass="text-center block md:hidden mt-12 text-[12px] uppercase font-areaExt"
        imageUrl={data.thumbNailImageSrc}
        // imageUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1661545700/image_hsichu.png"
        imageClass="overflow-hidden h-full w-full right-0 absolute"
        categoryClassName="lg:-bottom-14 lg:absolute md:relative md:-bottom-14 mb-14 md:w-2/3"
        category={data.newItemList}
        categoryOne={
          checkInvalidArray(data.newItemList).length
            ? data.newItemList[0].interestName
            : ''
        }
        downloadLink="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL21vdGhlcmhvbmVzdGx5L2ZlZWQueG1s"
        downloadClassName="flex -ml-4 my-8 hidden"
        SocialClassName="block"
        TabSocialClass="absolute gap-4 bottom-[35px] text-center place-content-center hidden md:flex lg:hidden md:right-4"
        date={data.date}
        ticketClassName="py-6 hidden"
        podClassName="md:bottom-[110px] lg:bottom-[200px] md:absolute md:flex podClass gap-32 md:gap-24 lg:gap-32 py-14 md:py-0"
        dateClassName="hidden text-left pb-2 w-3/4 text-base font-areaSemi"
        episodeClassName="text-center md:text-left pb-8 md:w-3/4 text-xl md:text-[22px] font-areaSemi"
        episode={data.episode}
        season={data.season}
        authorClassName="hidden"
      />

      <Box className="mx-auto pt-10 bg-white px-6 md:px-20 lg:px-[170px] py-4">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-4">
          Listen Now
        </Typography>

        <PodcastPlayer spotifyUrl={data.source} />
      </Box>
      <Box className="px-4 w-full md:px-20 lg:px-64 pb-6 bg-white">
        <Box className="flex">
          {/* Content */}
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </Box>
      </Box>

      <Box className="bg-gray-300 h-[1px] w-[91.4%] opacity-50 overflow-hidden mx-auto"></Box>

      {/* For Web */}
      <Box className="mx-auto pt-10 bg-white hidden md:block px-20 py-12">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-4">
          Episodes You Might like
        </Typography>
        <Grid container spacing={2}>
          {slice.map((res, index) => (
            <Grid item xs={12} sm={6} md={3} lg={3} key={res.id}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[474px] object-cover bg-cream-100 rounded-md"
                iconClass="hidden"
                imgBg="bg-green-100 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                // top={res.interests}
                season={res.season}
                episode={res.episode}
                title={res.title}
                category={res.newItemList}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                titleUrl={`/resources/podcasts/${res.slug}/${res.id}`}
                playUrl={`/resources/podcasts/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* For Mobile */}
      <Box className="mx-auto pt-10 md:hidden block bg-white px-4 py-4 overflow-x-hidden">
        <Typography
          variant="h1"
          color="primary"
          className="font-areaSemi text-xl text-center py-12">
          Episodes You Might like
        </Typography>
        <Slider {...settings}>
          {resources.map((res, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <ResCard
                cardClass="relative mb-10 w-[305px] h-[474px] object-cover bg-cream-100 rounded-md"
                iconClass="hidden"
                imgBg="bg-green-100 h-[305px]"
                bodyBg="bg-cream-100"
                imageSrc={res.thumbNailImageSrc}
                // top={res.interests}
                season={res.season}
                episode={res.episode}
                title={res.title}
                // category={res.itemList ? res.itemList[0].interestName : ""}
                // categoryTwo={res.itemList ? res.itemList[1] : ""}
                category={res.newItemList}
                titleUrl={`/resources/podcasts/${res.slug}/${res.id}`}
                playUrl={`/resources/podcasts/${res.slug}/${res.id}`}
              />
            </Grid>
          ))}
        </Slider>
      </Box>
      <MHSubscribe />
      <MHFooter />
    </Fragment>
  );
};

export default ViewPodcast;
