import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { PartnershipCard } from "./PartnershipCard";

type Props = {};

const ProgramsAndObjectives = (props: Props) => {
  return (
    <Fragment>
      <Box className="bg-lilac-300 overflow-hidden pb-10">
        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "30px", sm: "40px", md: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize pt-10 pb-6 md:w-[594px] w-[298px] text-center mx-auto"
        >
          Our Core Programs And Objectives
        </Typography>
        <Grid
          container
          spacing={0}
          className="lg:ml-5 lg:px-12 md:gap-4 lg:gap-0 md:mx-auto md:place-content-center"
        >
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://res.cloudinary.com/mother-honestly/image/upload/v1689590616/Promote_Care_Equity_kvgwm0.png"
              title="Promote Care Equity"
              content="Advocate for inclusive care benefits that address the specific caregiving needs of vulnerable workers, ensuring they have access to affordable and quality caregiving support."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://res.cloudinary.com/mother-honestly/image/upload/v1689590645/Champion_Career_Equity_y6ukhc.png"
              title="Champion Career Equity"
              content="Work towards equal opportunities and advancement for vulnerable workers, facilitating access to meaningful career pathways, professional development, and fair treatment in the workplace."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://res.cloudinary.com/mother-honestly/image/upload/v1689590669/Foster_Wellbeing_Equity_qajcvu.png"
              title="Foster Wellbeing Equity"
              content="Promote holistic well-being by advocating for resources, support networks, and work-life integration strategies that address the unique challenges faced by vulnerable workers, enabling them to lead fulfilling lives both personally and professionally."
            />
          </Grid>

          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://res.cloudinary.com/mother-honestly/image/upload/v1689590694/Drive_Policy_Change_gbqcyy.png"
              title="Drive Policy Change"
              content="Advocate for policy reforms that protect the rights and well-being of vulnerable workers, addressing issues such as flexible work arrangements, fair wages, and benefits that support caregiving responsibilities.
              "
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://res.cloudinary.com/mother-honestly/image/upload/v1689590718/Collaborate_Educate_wc62bg.png"
              title="Collaborate and Educate"
              content="ter collaboration among stakeholders, including employers, policymakers, community organizations, and vulnerable worker groups, to share knowledge, resources, and best practices that create positive change in the workplace and drive the future of work."
            />
          </Grid>
          {/* <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/Podcast.png"
              title="Podcast"
              content="Sponsor any of our podcast seasons or place ad reel within our podcast with over 1M+ downloads."
            />
          </Grid> */}
        </Grid>{" "}
      </Box>
    </Fragment>
  );
};

export default ProgramsAndObjectives;
