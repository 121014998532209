import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LegalBullets from "./LegalBullets";
import { Box } from "@mui/material";

type Props = {};

const BodyCards = (props: Props) => {
  return (
    <React.Fragment>
      <Grid container spacing={1} className="pl-8 md:pl-16 mt-12 gap-1">
        <Grid item xs={12} sm={12} md={2.75} lg={2.75} className=" ">
          <Typography
            variant="caption"
            color="primary"
            fontSize={{ xs: "11px", sm: "12px" }}
            lineHeight={{ xs: "102%", sm: "102%" }}
            letterSpacing={"0.1em"}
            className="uppercase"
          >
            As of Sept 17, 2023
          </Typography>
        </Grid>

        <Grid item xs={11.5} sm={11} md={7} lg={7} className="">
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "16px" }}
            lineHeight={{ xs: "170%", sm: "200%" }}
            letterSpacing={"0.02em"}
            className=" pb-12"
          >
            MHWorkLife.com(the <b className="font-areaNorm"> “Site” </b>) is
            owned and operated by MH Work Life LLC (hereinafter referred to as
            <b className="font-areaNorm"> “Company”, “we,” “us,” </b> or{" "}
            <b className="font-areaNorm"> “our” </b>). It is Company’s policy to
            respect your privacy and comply with any applicable law and
            regulation regarding any personal information we may collect about
            you, including across our Site and other sites we own and operate.
            <br />
            <br />
            Personal information is any information about you which can be used
            to identify you. This includes information about you as a person
            (such as name, address, and date of birth), your devices, payment
            details, and even information about how you use a website or online
            service.
            <br />
            <br />
            In the event our site contains links to third-party sites and
            services, please be aware that those sites and services have their
            own privacy policies. After following a link to any third- party
            content, you should read their posted privacy policy information
            about how they collect and use personal information. This Privacy
            Policy does not apply to any of your activities after you leave our
            site.
            <br />
            <br />
            You acknowledge that by using our Site, you agree to the collection,
            storage, sharing, and usage of your personal information in
            accordance with this Privacy Policy and our Terms of Use. If you do
            not agree to these terms, please do not access or use the Site.
            <br />
            <br />
            If you require any more information or have any questions about our
            privacy policy, please feel free to contact us by email at{" "}
            <a
              href="mailto:hello@mhworklife.com"
              className=" underline"
              target="_blank"
            >
              hello@mhworklife.com
            </a>
            <br />
            <br />
            <b className="font-areaNorm underline"> Information We Collect </b>
            <br />
            Information we collect falls into one of two categories:
            “voluntarily provided” information and “automatically collected”
            information.
            <br />
            <br />
            “Voluntarily provided” information refers to any information you
            knowingly and actively provide us when using or participating in any
            of our services and promotions.
            <br />
            <br />
            “Automatically collected” information refers to any information
            automatically sent by your devices in the course of accessing our
            products and services.
            <br />
            <br />
            <b className="font-areaNorm underline"> Log Data </b>
            <br />
            When you visit our Site, our servers may automatically log the
            standard data provided by your web browser. It may include your
            device’s Internet Protocol (IP) address, your browser type and
            version, the pages you visit, the time and date of your visit, the
            time spent on each page, and other details about your visit.
            <br />
            <br />
            Additionally, if you encounter certain errors while using the site,
            we may automatically collect data about the error and the
            circumstances surrounding its occurrence. This data may include
            technical details about your device, what you were trying to do when
            the error happened, and other technical information relating to the
            problem. You may or may not receive notice of such errors, even in
            the moment they occur, that they have occurred, or what the nature
            of the error is.
            <br />
            <br />
            Please be aware that while this information may not be personally
            identifying by itself, it may be possible to combine it with other
            data to personally identify individual persons.
            <br />
            <br />
            <b className="font-areaNorm underline"> Device Data </b>
            <br />
            When you visit our Site or interact with our services, we may
            automatically collect data about your device, such as:
            <br />
            <br />
            <LegalBullets
              bulletData={[
                "Device type",
                "Operating system",
                "Unique device identifiers",
                "Geo-location data",
                "User Agent",
                "Time spent on site",
                "URL of previous website",
              ]}
            />
            <br />
            Data we collect can depend on the individual settings of your device
            and software. We recommend checking the policies of your device
            manufacturer or software provider to learn what information they
            make available to us.
            <br />
            <br />
            <b className="font-areaNorm underline"> Personal Information </b>
            <br />
            We may ask for personal information — for example, when you Request
            a Demo of our Products/Services, Sign up for our email list or
            newsletter , Register as a user of any of our Products/Service or
            when you contact us — which may include one or more of the
            following:
            <br />
            <br />
            <LegalBullets
              bulletData={[
                "Name",
                "Email",
                "Phone/Mobile number",
                "Home/mailing address",
              ]}
            />
            <br />
            <b className="font-areaNorm underline"> Sensitive Information </b>
            <br />
            “Sensitive information” or “special categories of data” is a subset
            of personal information that is given a higher level of protection.
            Examples of sensitive information include information relating to
            your racial or ethnic origin, political opinions, religion, trade
            union or other professional associations or memberships,
            philosophical beliefs, sexual orientation, sexual practices or sex
            life, criminal records, health information, or biometric
            information.
            <br />
            <br />
            The types of sensitive information that we may collect about you
            include:
            <br />
            <br />
            <LegalBullets
              bulletData={[
                "Employment Information",
                "Personal Health Information",
                "Financial Information",
                "Household Information",
              ]}
            />
            <br />
            <b className="font-areaNorm">
              {" "}
              We will not collect sensitive information about you without first
              obtaining your consent, and we will only use or disclose your
              sensitive information as permitted, required, or authorized by
              law.{" "}
            </b>
            <br />
            <br />
            <b className="font-areaNorm underline"> Transaction Data </b>
            <br />
            Transaction data refers to data that accumulates over the normal
            course of operation on our platform. This may include transaction
            records, stored files, user profiles, analytics data and other
            metrics, as well as other types of information, created or
            generated, as users interact with our services.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Legitimate Reasons for Processing Your Personal Information{" "}
            </b>
            <br />
            We only collect and use your personal information when we have a
            legitimate reason for doing so. In which instance, we only collect
            personal information that is reasonably necessary to provide our
            services to you.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Collection and Use of Information{" "}
            </b>
            <br />
            We may collect personal information from you when you do any of the
            following on our Site:
            <br />
            <LegalBullets
              bulletData={[
                "Register for an Account",
                "Request a Demo of our Product",
                "Purchase a subscription",
                "Book a 1:1 Session",
                "Register for an Event or Conference",
                "Enter any of our competitions, contests, sweepstakes, and surveys",
                "Sign up to receive updates from us via email or social media channels",
                "Use a mobile device or web browser to access our content",
                "Contact us via email, social media, or on any similar technologies",
                "When you mention us on social media",
              ]}
            />
            <br />
            We may collect, hold, use, and disclose information{" "}
            <b className="underline">
              for the following purposes, and personal information will not be
              further processed in a manner that is incompatible with these
              purposes:
            </b>
            <br />
            <br />
            <LegalBullets
              bulletData={[
                "to provide you with our platform's core features and services",
                "to enable you to customize or personalize your experience of our Site",
                " to deliver products and/or services to you to enable you",
                "to access and use our website, associated applications, and associated social media platforms",
                "for internal record keeping and administrative purposes ",
                "to run competitions, sweepstakes, and/or offer additional benefits to you",
                "to comply with our legal obligations and resolve any disputes that we may have",
              ]}
            />
            <br />
            We may combine voluntarily provided and automatically collected
            personal information with general information or research data we
            receive from other trusted sources. For example, If you provide us
            with your location, we may combine this with general information
            about currency and language to provide you with an enhanced
            experience of our site and service.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Security of Your Personal Information{" "}
            </b>
            <br />
            When we collect and process personal information, and while we
            retain this information, we will protect it within commercially
            acceptable means to prevent loss and theft, as well as unauthorized
            access, disclosure, copying, use or modification.
            <br />
            <br />
            Although we will do our best to protect the personal information you
            provide to us, we advise that no method of electronic transmission
            or storage is 100% secure and no one can guarantee absolute data
            security.
            <br />
            <br />
            You are responsible for selecting any password and its overall
            security strength, ensuring the security of your own information
            within the bounds of our services. For example, ensuring any
            passwords associated with accessing your personal information and
            accounts are secure and confidential.
            <br />
            <br />
            <b className="font-areaNorm underline">
              How Long We Keep Your Personal Information
            </b>
            <br />
            We keep your personal information only for as long as we need to.
            This time period may depend on what we are using your information
            for, in accordance with this privacy policy. For example, if you
            have provided us with personal information as part of creating an
            account with us, we may retain this information for the duration
            your account exists on our system. If your personal information is
            no longer required for this purpose, we will delete it or make it
            anonymous by removing all details that identify you.
            <br />
            <br />
            However, if necessary, we may retain your personal information for
            our compliance with a legal, accounting, or reporting obligation or
            for archiving purposes in the public interest, scientific, or
            historical research purposes or statistical purposes.
            <br />
            <br />
            <b className="font-areaNorm underline"> Children’s Privacy </b>
            <br />
            We do not aim any of our products or services directly at children
            under the age of 13 and we do not knowingly collect personal
            information about children under 13.
            <br />
            <br />
            <b className="font-areaNorm ">
              Disclosure of Personal Information to Third Parties
            </b>
            <br />
            <br />
            <b className="font-areaNorm underline">
              We may disclose personal information to:
            </b>
            <br />
            <LegalBullets
              bulletData={[
                "a parent, subsidiary or affiliate of our company",
                `third-party service providers for the purpose of enabling them to
              provide their services, including (without limitation) IT service
              providers, data storage, hosting and server providers, ad networks,
              analytics, error loggers, debt collectors, maintenance or
              problem-solving providers, marketing providers, professional
              advisors, and payment systems operators`,
                "our employees, contractors, and/or related entities",
                "our existing or potential agents or business partners",
                "sponsors or promoters of any competition, sweepstakes, or promotion we run",
                "credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you",
                `courts, tribunals, regulatory authorities, and law enforcement
                officers, as required by law, in connection with any actual or
                prospective legal proceedings, or in order to establish, exercise,
                or defend our legal rights`,
                "third parties, including agents or sub-contractors who assist us in providing information, products, services, or direct marketing to you",
                "third parties to collect and process data",
                "an entity that buys, or to which we transfer all or substantially all of our assets and business",
              ]}
            />
            <br />
            <b className="font-areaNorm underline">
              Third parties we currently use include:
            </b>
            <LegalBullets
              bulletData={["Google Analytics", "Mail lite", "Stripe"]}
            />
            <br />
            <b className="font-areaNorm underline">
              International Transfers of Personal Information
            </b>
            <br />
            The personal information we collect is stored and/or processed in
            United States, or where we or our partners, affiliates, and
            third-party providers maintain facilities.
            <br />
            <br />
            The countries to which we store, process, or transfer your personal
            information may not have the same data protection laws as the
            country in which you initially provided the information. If we
            transfer your personal information to third parties in other
            countries: (i) we will perform those transfers in accordance with
            the requirements of applicable law; and (ii) we will protect the
            transferred personal information in accordance with this privacy
            policy.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Your Rights and Controlling Your Personal Information{" "}
            </b>
            <br />
            <b className="underline"> Your choice:</b> By providing personal
            information to us, you understand we will collect, hold, use, and
            disclose your personal information in accordance with this privacy
            policy. You do not have to provide personal information to us,
            however, if you do not, it may affect your use of our Site or the
            products and/or services offered on or through it.
            <br />
            <br />
            <b className="underline"> Information from third parties:</b> If we
            receive personal information about you from a third party, we will
            protect it as set out in this privacy policy. If you are a third
            party providing personal information about somebody else, you
            represent and warrant that you have such person’s consent to provide
            the personal information to us.
            <br />
            <br />
            <b className="underline"> Marketing permission:</b> If you have
            previously agreed to us using your personal information for direct
            marketing purposes, you may change your mind at any time by
            contacting us using the details below.
            <br />
            <br />
            <b className="underline"> Access:</b> You may request details of the
            personal information that we hold about you.
            <br />
            <br />
            <b className="underline"> Correction:</b> If you believe that any
            information we hold about you is inaccurate, out of date,
            incomplete, irrelevant, or misleading, please contact us using the
            details provided in this privacy policy. We will take reasonable
            steps to correct any information found to be inaccurate, incomplete,
            misleading, or out of date.
            <br />
            <br />
            <b className="underline"> Non-discrimination:</b> We will not
            discriminate against you for exercising any of your rights over your
            personal information. Unless your personal information is required
            to provide you with a particular service or offer (for example
            processing transaction data), we will not deny you goods or services
            and/or charge you different prices or rates for goods or services,
            including through granting discounts or other benefits, or imposing
            penalties, or provide you with a different level or quality of goods
            or services.
            <br />
            <br />
            <b className="underline">Downloading of Personal Information:</b> We
            provide a means for you to download the personal information you
            have shared through our site. Please contact us for more
            information.
            <br />
            <br />
            <b className="underline"> Notification of data breaches:</b> We will
            comply with laws applicable to us in respect of any data breach.
            <br />
            <br />
            <b className="underline"> Complaints:</b> If you believe that we
            have breached a relevant data protection law and wish to make a
            complaint, please contact us using the details below and provide us
            with full details of the alleged breach. We will promptly
            investigate your complaint and respond to you, in writing, setting
            out the outcome of our investigation and the steps we will take to
            deal with your complaint. You also have the right to contact a
            regulatory body or data protection authority in relation to your
            complaint.
            <br />
            <br />
            <b className="underline"> Unsubscribe:</b> To unsubscribe from our
            email database or opt-out of communications (including marketing
            communications), please contact us using the details provided in
            this privacy policy, or opt-out using the opt-out facilities
            provided in the communication. We may need to request specific
            information from you to help us confirm your identity.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Use of Cookies, Tracking Tools and how to Opt Out{" "}
            </b>
            <br />
            Our Site uses “cookies” to optimize your use of the Site and
            delivery of the information, products, or services you request. A
            cookie is a small piece of data saved on a user’s computer to
            optimize the user’s experience on a website. It will help us know if
            you’re a repeat customer and what information, products, or services
            you prefer. We may also use these cookies to inform you of
            additional products or services that we offer based on those
            preferences. Cookie use in no way compromises your personally
            identifiable information (all information collected through cookies
            is non-personal) on the Site – they are primarily for statistics and
            user experience improvement. Such non-personal information may
            include:
            <br />
            <br />
            Type of device you use
            <br />
            <br />
            <LegalBullets
              bulletData={[
                "Information on your browser type",
                "Information on your operating system",
                "Information on your Internet service provider",
                "Pages visited on the Site",
                "Search terms used on the Site",
                "Links clicked to external websites",
              ]}
            />
            <br />
            You can refuse use of cookies by modifying your browser preferences
            or by responding to the cookie notice on the Site. Please note
            however that if you choose to reject all cookies, certain parts of
            the Site may be inaccessible to you.
            <br />
            <br />
            <b className="font-areaNorm underline"> Business Transfers </b>
            <br />
            If we or our assets are acquired, or in the unlikely event that we
            go out of business or enter bankruptcy, we would include data,
            including your personal information, among the assets transferred to
            any parties who acquire us. You acknowledge that such transfers may
            occur, and that any parties who acquire us may, to the extent
            permitted by applicable law, continue to use your personal
            information according to this policy, which they will be required to
            assume as it is the basis for any ownership or use rights we have
            over such information.
            <br />
            <br />
            <b className="font-areaNorm underline"> Limits of Our Policy </b>
            <br />
            Our Site may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            policies of those sites, and cannot accept responsibility or
            liability for their respective privacy practices.
            <br />
            <br />
            <b className="font-areaNorm underline"> Changes to This Policy </b>
            <br />
            At our discretion, we may change our privacy policy to reflect
            updates to our business processes, current acceptable practices, or
            legislative or regulatory changes. If we decide to change this
            privacy policy, we will post the changes here at the same link by
            which you are accessing this privacy policy.
            <br />
            <br />
            If the changes are significant, or if required by applicable law, we
            will contact you (based on your selected preferences for
            communications from us) and all our registered users with the new
            details and links to the updated or changed policy.
            <br />
            <br />
            If required by law, we will get your permission or give you the
            opportunity to opt in to or opt out of, as applicable, any new uses
            of your personal information.
            <br />
            <br />
            <b className="font-areaNorm underline">
              Additional Disclosures for General Data Protection Regulation
              (GDPR) Compliance (EU)
            </b>
            <br />
            MH Work Life LLC, located at the address provided in our Contact Us
            section, are a Data Processor with respect to the personal
            information you provide to us.
            <br />
            <br />
            <b className="font-areaNorm underline">
              Legal Bases for Processing Your Personal Information
            </b>
            <br />
            We will only collect and use your personal information when we have
            a legal right to do so. In which case, we will collect and use your
            personal information lawfully, fairly, and in a transparent manner.
            If we seek your consent to process your personal information, and
            you are under 16 years of age, we will seek your parent or legal
            guardian’s consent to process your personal information for that
            specific purpose.
            <br />
            <br />
            Our lawful bases depend on the services you use and how you use
            them. This means we only collect and use your information on the
            following grounds:
            <br />
            <br />
            <Box className="grid items-center gap-2 pl-4">
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" pr-1"
                >
                  1.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline"> Consent From You:</b> Where you
                  give us consent to collect and use your personal information
                  for a specific purpose. You may withdraw your consent at any
                  time using the facilities we provide. While you may request
                  that we delete your contact details at any time, we cannot
                  recall any email we have already sent. If you have any further
                  enquiries about how to withdraw your consent, please feel free
                  to enquire using the details provided in the Contact Us
                  section of this privacy policy.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" pr-1"
                >
                  2.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline">
                    {" "}
                    Performance of a Contract or Transaction:
                  </b>{" "}
                  Where you have entered into a contract or transaction with us,
                  or in order to take preparatory steps prior to our entering
                  into a contract or transaction with you.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" pr-1"
                >
                  3.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline"> This information includes:</b>{" "}
                  Profile information, Payment Information, Employment
                  Information, Marital Status, Financial Information and
                  anything else required to effectively perform the Services you
                  have contracted us for.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" pr-1"
                >
                  4.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline"> Our Legitimate Interests:</b> Where
                  we assess it is necessary for our legitimate interests, such
                  as for us to provide, operate, improve and communicate our
                  services. We consider our legitimate interests to include
                  research and development, understanding our audience,
                  marketing and promoting our services, measures taken to
                  operate our services efficiently, marketing analysis, and
                  measures taken to protect our legal rights and interests.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" pr-1"
                >
                  5.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline"> Compliance with Law:</b> In some
                  cases, we may have a legal obligation to use or keep your
                  personal information. Such cases may include (but are not
                  limited to) court orders, criminal investigations, government
                  requests, and regulatory obligations. If you have any further
                  enquiries about how we retain personal information in order to
                  comply with the law, please feel free to enquire using the
                  details provided in the Contact Us section of this privacy
                  policy.
                </Typography>
              </div>
            </Box>
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              International Transfers Outside of the European Economic Area
              (EEA) or UK{" "}
            </b>
            <br />
            The site and our services are provided and operated in the USA,
            therefore your information is collected and stored here. If you are
            based within the EEA, by using our Site and Services, you understand
            and agree that your personal information will be transferred to the
            USA. You hereby acknowledge and authorize MH Work Life to transfer,
            store and process your information to and in the U.S., and possibly
            other countries. In some circumstances, your Personal Data may be
            transferred to the U.S. pursuant to a data processing agreement
            incorporating standard data protection clauses.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Your Rights and Controlling Your Personal Information{" "}
            </b>
            <br />
            <Box className="grid items-center gap-2 pl-4">
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline"> Restrict:</b> You have the right to
                  request that we restrict the processing of your personal
                  information if (i) you are concerned about the accuracy of
                  your personal information; (ii) you believe your personal
                  information has been unlawfully processed; (iii) you need us
                  to maintain the personal information solely for the purpose of
                  a legal claim; or (iv) we are in the process of considering
                  your objection in relation to processing on the basis of
                  legitimate interests.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline"> Objecting to processing:</b> You
                  have the right to object to processing of your personal
                  information that is based on our legitimate interests or
                  public interest. If this is done, we must provide compelling
                  legitimate grounds for the processing which overrides your
                  interests, rights, and freedoms, in order to proceed with the
                  processing of your personal information.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline"> Data portability:</b> You may have
                  the right to request a copy of the personal information we
                  hold about you. Where possible, we will provide this
                  information in CSV format or other easily readable machine
                  format. You may also have the right to request that we
                  transfer this personal information to a third party.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className=" underline"> Deletion:</b> You may have a right
                  to request that we delete the personal information we hold
                  about you at any time, and we will take reasonable steps to
                  delete your personal information from our current records. If
                  you ask us to delete your personal information, we will let
                  you know how the deletion affects your use of our Site or
                  products and services. There may be exceptions to this right
                  for specific legal reasons which, if applicable, we will set
                  out for you in response to your request. If you terminate or
                  delete your account, we will delete your personal information
                  within 60 days of the deletion of your account. Please be
                  aware that search engines and similar third parties may still
                  retain copies of your personal information that has been made
                  public at least once, like certain profile information and
                  public comments, even after you have deleted the information
                  from our services or deactivated your account.
                </Typography>
              </div>
            </Box>
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Additional Disclosures for California Compliance (US){" "}
            </b>
            <br />
            Under California Civil Code Section 1798.83, if you live in
            California and your business relationship with us is mainly for
            personal, family, or household purposes, you may ask us about the
            information we release to other organizations for their marketing
            purposes.
            <br />
            <br />
            To make such a request, please contact us using the details provided
            in this privacy policy with "Request for California privacy
            information" in the subject line. You may make this type of request
            once every calendar year. We will email you a list of categories of
            personal information we revealed to other organisations for their
            marketing purposes in the last calendar year, along with their names
            and addresses. Not all personal information shared in this way is
            covered by Section 1798.83 of the California Civil Code.
            <br />
            <br />
            <b className="font-areaNorm underline"> Do Not Track </b>
            <br />
            Some browsers have a "Do Not Track" feature that lets you tell
            websites that you do not want to have your online activities
            tracked. At this time, we do not respond to browser "Do Not Track"
            signals. We adhere to the standards outlined in this privacy policy,
            ensuring we collect and process personal information lawfully,
            fairly, transparently, and with legitimate, legal reasons for doing
            so.
            <br />
            <br />
            <b className="font-areaNorm underline"> Cookies and Pixels </b>
            <br />
            At all times, you may decline cookies from our site if your browser
            permits. Most browsers allow you to activate settings on your
            browser to refuse the setting of all or some cookies. Accordingly,
            your ability to limit cookies is based only on your browser’s
            capabilities. Please refer to the Cookies section of this privacy
            policy for more information.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              CCPA-permitted financial incentives{" "}
            </b>
            <br />
            In accordance with your right to non-discrimination, we may offer
            you certain financial incentives permitted by the CCPA that can
            result in different prices, rates, or quality levels for the goods
            or services we provide.
            <br />
            <br />
            Any CCPA-permitted financial incentive we offer will reasonably
            relate to the value of your personal information, and we will
            provide written terms that describe clearly the nature of such an
            offer. Participation in a financial incentive program requires your
            prior opt-in consent, which you may revoke at any time.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              California Notice of Collection{" "}
            </b>
            <br />
            In the past 12 months, we have collected the following categories of
            personal information enumerated in the California Consumer Privacy
            Act:
            <br />
            <br />
            <LegalBullets
              bulletData={[
                `Identifiers, such as name, email address, phone number, account
                name, IP address, and an ID or number assigned to your account.`,
                "Geolocation data.",
                "Employment and education data, such as data you provide when you apply for a job with us.",
              ]}
            />
            <br />
            For more information on information we collect, including the
            sources we receive information from, review the "Information We
            Collect" section. We collect and use these categories of personal
            information for the business purposes described in the "Collection
            and Use of Information" section, including to provide and manage our
            Service.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Right to Know and Delete{" "}
            </b>
            <br />
            If you are a California resident, you have rights to delete your
            personal information we collected and know certain information about
            our data practices in the preceding 12 months. In particular, you
            have the right to request the following from us:
            <br />
            <br />
            <LegalBullets
              bulletData={[
                "The categories of personal information we have collected about you;",
                `The categories of sources from which the personal information was
                collected;`,
                ` The categories of personal information about you we disclosed for a
                business purpose or sold;`,
                `The categories of third parties to whom the personal information was
                disclosed for a business purpose or sold;`,
                `The business or commercial purpose for collecting or selling the
                personal information; and`,
                `The specific pieces of personal information we have collected about
                you.`,
                `To exercise any of these rights, please contact us using the details
                provided in this privacy policy.`,
              ]}
            />
            <br />
            <b className="font-areaNorm underline"> Shine the Light </b>
            <br />
            If you are a California resident, in addition to the rights
            discussed above, you have the right to request information from us
            regarding the manner in which we share certain personal information
            as defined by California’s "Shine the Light" with third parties and
            affiliates for their own direct marketing purposes.
            <br />
            <br />
            To receive this information, send us a request using the contact
            details provided in this privacy policy. Requests must include
            "California Privacy Rights Request" in the first line of the
            description and include your name, street address, city, state, and
            ZIP code.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Third-Party Provided Content{" "}
            </b>
            <br />
            We may indirectly collect personal information about you from
            third-parties who have your permission to share it. For example, if
            you purchase a product or service from a business working with us,
            and give your permission for us to use your details in order to
            complete the transaction.
            <br />
            <br />
            We may also collect publicly available information about you, such
            as from any social media and messaging platforms you may use. The
            availability of this information will depend on both the privacy
            policies and your own privacy settings on such platforms.
            <br />
            <br />
            Additional Disclosure for Collection and Use of Personal Information
            <br />
            <br />
            In addition to the aforementioned purposes warranting the collection
            and use of personal information, we may also conduct marketing and
            market research activities, including how visitors use our site,
            website improvement opportunities and user experience.
            <br />
            <br />
            <b className="font-areaNorm underline">
              {" "}
              Personal Information No Longer Required for Our Purposes{" "}
            </b>
            <br />
            If your personal information is no longer required for our stated
            purposes, or if you instruct us under your Data Subject Rights, we
            will delete it or make it anonymous by removing all details that
            identify you (“Anonymisation”). However, if necessary, we may retain
            your personal information for our compliance with a legal,
            accounting, or reporting obligation or for archiving purposes in the
            public interest, scientific, or historical research purposes or
            statistical purposes.
            <br />
            <br />
            <b className="font-areaNorm underline"> Contact Us: </b>
            <br />
            We welcome your questions, comments or concerns regarding this
            Privacy Policy. To convey any of the foregoing to us, to modify or
            update your personal information that we have on file, or to request
            that we delete your personal information from our records, you can
            contact:
            <br />
            <br />
            <b className="font-areaNorm">Blessing Adesiyan, CEO</b>
            <br />
            <a
              href="mailto:legal@mhworklife.com"
              className=" font-areaNorm underline"
              target="_blank"
            >
              legal@mhworklife.com
            </a>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BodyCards;
