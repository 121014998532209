import React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SearchField from "../../Common/Form/SearchField";
import MHDropdown from "../../Common/Dropdown/MHDropdown";
import MHChecklistDropdown from "../../Common/Dropdown/MHChecklistDropdown";

import interests from "../../../data/interests.json";
import MHChip from "../../Common/Chip/MHChip";
import AppContext from "../../../store/context/app.context";
import { TopLevelCategory } from "../../../models/dashboard.model";
import { Category } from "../../../models/wallet.model";
import SearchContext from "../../../store/context/search-context";

type Tag = {
  id: number;
  label: string;
  onDelete: () => void;
};

type FilterBarProps = {
  totalRecords: number;
  tags?: Tag[];
};

const RATINGS = [
  {
    id: 1,
    label: "5-star",
  },
  {
    id: 2,
    label: "4-star",
  },
  {
    id: 3,
    label: "3-star",
  },
  {
    id: 4,
    label: "2-star",
  },
  {
    id: 5,
    label: "1-star",
  },
];

const DURATION_OPTIONS = [
  {
    label: "Quick - 15 Min",
    duration: 15,
    price: "$50",
  },
  {
    label: "Regular - 30 Min",
    duration: 30,
    price: "$100",
  },
  {
    label: "Extra - 45 Min",
    duration: 45,
    price: "$150",
  },
  {
    label: "All Access - 60 Min",
    duration: 60,
    price: "$200",
  },
];

const ToolkitFilter = (props: FilterBarProps) => {
  const staticDataCtx = React.useContext(AppContext);
  const { staticDataCacheMap, interests } = staticDataCtx;
  const topLvlCategories: TopLevelCategory[] = React.useMemo(
    () => staticDataCacheMap.get("topLevelCategories") || [],
    [staticDataCacheMap]
  );

  const searchCtx = React.useContext(SearchContext);
  const { selectedTopLvlCategoryIds, handleTopLvlCategorySelection } =
    searchCtx;

  const [checklistTags, setChecklistTags] = React.useState<Tag[]>([]);

  React.useEffect(() => {
    //construct tag list
    let checkedSet: Tag[] = [];

    for (const checkedId of selectedTopLvlCategoryIds) {
      const existingCheckedItem = topLvlCategories.find(
        (item) => item.id === checkedId
      );

      if (existingCheckedItem) {
        checkedSet = checkedSet.concat({
          id: existingCheckedItem.id,
          label: existingCheckedItem.sectionName,
          onDelete: () =>
            handleTopLvlCategorySelection(existingCheckedItem.id, false),
        });
      }
    }

    setChecklistTags(checkedSet);
  }, [
    selectedTopLvlCategoryIds,
    topLvlCategories,
    handleTopLvlCategorySelection,
  ]);

  return (
    <Stack
      direction="row"
      height="4rem"
      bgcolor="common.white"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      borderTop={1}
      borderBottom={1}
      borderColor="#F3F4F6"
      px={10}
    >
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        height="100%"
        pr="2rem"
      >
        <Typography
          variant="caption"
          color="primary"
          textTransform="uppercase"
          sx={{
            fontSize: ".75rem",
            mr: 2,
          }}
        >
          Filter By
        </Typography>

        <MHChecklistDropdown
          label="Topic"
          items={topLvlCategories.map((cat) => ({
            id: cat.id,
            label: cat.sectionName,
          }))}
          setOfCheckedId={selectedTopLvlCategoryIds}
          onCheck={handleTopLvlCategorySelection}
          // setOfCheckedId={props.tags ? props.tags?.map((tag) => tag.id) : []}
        />

        <MHChecklistDropdown
          label="Interest"
          items={interests.map((interest) => ({
            id: interest.id,
            label: interest.interest,
          }))}
          setOfCheckedId={[]}
        />

        {checklistTags.map((tag) => (
          <MHChip
            key={tag.id}
            label={tag.label}
            onDelete={tag.onDelete}
            sx={{
              marginRight: 2,
            }}
          />
        ))}

        {/* <MHChecklistDropdown
          label="Category"
          items={topLvlCategories.map((interest) => ({
            id: interest.id,
            label: interest.sectionName
          }))}
        />

       */}
        {/* 
        <MHChecklistDropdown
          label="Pricing"
          items={DURATION_OPTIONS.map((interest) => ({
            id: interest.duration,
            label: interest.label
          }))}
        /> */}

        {/*  */}

        {/* <MHChip label="Time Management" onDelete={() => {}} /> */}

        {/* <SearchField
          icon={<ArrowRightIcon width="1rem" />}
          placeholder="Search Coach"
          bgcolor="#FFFFFF"
          sx={{
            ml: 8,
            height: '85%'
          }}
        /> */}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={3}>
        <Typography variant="body2" color="grey.500" textTransform="capitalize">
          {props.totalRecords} Results
        </Typography>

        <MHDropdown
          label="Sort By"
          items={[
            {
              label: "Most Recent",
              onClick: () => {},
            },
            {
              label: "Most Popular",
              onClick: () => {},
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default ToolkitFilter;

/* <Box
          component={'form'}
          height={30}
          width={250}
          display="flex"
          className="my-2 ml-16 shadow-sm">
          <MHTextInput
            id="search-interest"
            type="text"
            placeholder="Search coach"
            className="flex-grow text-[12px] shadow-inner"
          />
          <MHButton
            sx={{
              minWidth: 'auto',
              '& svg': {
                stroke: 'grey.500',
                width: '1rem'
              },
              '&.MuiButton-root:hover svg': {
                stroke: 'primary'
              }
            }}>
            <ArrowRightIcon />
          </MHButton>
        </Box> */
