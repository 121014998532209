import React from 'react';
import { MuiTelInputInfo, matchIsValidTel } from 'mui-tel-input';

import { Validator } from '../models/form.model';

const usePhoneInput = (validators: Validator[]) => {
  const [enteredPhoneNumber, setEnteredPhoneNumber] = React.useState('');
  const [isValid, setIsValid] = React.useState(false);
  const [phoneInfo, setPhoneInfo] = React.useState<MuiTelInputInfo | null>(
    null
  );
  const [touched, setTouched] = React.useState(false);

  const markAsTouched = () => {
    setTouched(true);
  };

  const phoneNumberInputChangeHandler = (
    value: string,
    info: MuiTelInputInfo | null
  ) => {
    const validatorsResults = validators.map((validator) =>
      validator.validator(value)
    );
    const valid =
      matchIsValidTel(value) &&
      validatorsResults.reduce((acc, curr) => acc && curr, true);
    setEnteredPhoneNumber(value);
    setPhoneInfo(info);
    setIsValid(valid && touched);
  };

  return {
    phoneNumber: enteredPhoneNumber,
    phoneInfo,
    phoneNumberInputChangeHandler,
    isValid,
    markAsTouched
  };
};

export default usePhoneInput;
