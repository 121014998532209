import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";

import { ReactComponent as Instagram } from "../../../static/svg/instagram.svg";
import { ReactComponent as Facebook } from "../../../static/svg/facebook.svg";
import { ReactComponent as LinkedIn } from "../../../static/svg/linkedin-icon.svg";
import { ReactComponent as Pinterest } from "../../../static/svg/pinterest.svg";
import { ReactComponent as Twitter } from "../../../static/svg/twitter.svg";
import { Link } from "react-router-dom";

type HeaderProps = {
  BgUrl: string;
  ResIconUrl: string;
  title: string;
  titleExt?: string;
  titleInfo: string;
  pageInfo: string;
  eventSocialClassName: string;
  pageInfoClassName: string;
  titleInfoclassName: string;
  headerClassName: string;
  majorClassName: string;
  boxClassName: string;
  podClassName?: string;
  podClassName2?: string;
  children: React.ReactNode;
};

const AllresHeader = (props: HeaderProps) => {
  return (
    <Fragment>
      <Grid container spacing={0} className={props.headerClassName}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <img
            src={props.BgUrl}
            alt="Header Image"
            style={{
              width: "fit",
              height: "",
            }}
            className="hidden lg:block bg-red-600"
          />
          <Box className="" sx={{}}>
            {props.children}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className=" z-30">
            <Box className={props.majorClassName}>
              <Box
                className="text-center w-[500px]"
                color="primary"
                sx={{
                  fontWeight: 600,
                  fontSize: "12px",
                  fontFamily: "Area Normal",
                  "& > *": {},
                }}
              >
                <Box className={props.boxClassName} sx={{}}>
                  <img
                    src={props.ResIconUrl}
                    alt="Icon"
                    className="-mt-2 mr-2 "
                    style={{
                      width: "23px",
                      height: "26px",
                    }}
                  />

                  <Box>
                    <Typography
                      variant="caption"
                      className="text-center mx-auto mt-2 uppercase"
                      color="primary"
                      fontSize={{ xs: "11px", sm: "12px" }}
                      lineHeight={{ xs: "120%", sm: "102%" }}
                      letterSpacing={"0.01em"}
                    >
                      {props.title}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  className="text-left pl-8 md:pl-0 mt-1 mb-6 font-semibold font-areaSemi text-xl md:text-[24px]"
                  color="primary"
                >
                  {props.titleExt}
                </Typography>
                <Typography
                  variant="h3"
                  className={props.titleInfoclassName}
                  color="primary"
                >
                  {props.titleInfo}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  className={props.pageInfoClassName}
                >
                  {props.pageInfo}
                </Typography>
              </Box>

              <Box className={props.podClassName}>
                <button
                  onClick={() => {
                    window.open(
                      "https://open.spotify.com/show/54t1C1cw8TeS2UK4doDTL4"
                    );
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612804/spotify-pod_jyslea.png"
                    className="h-12 my-2 md:my-0 md:h-10 md:w-fit w-28 md:absolute  md:mt-0"
                    alt="Podcast Image"
                  />
                </button>

                <br className="block md:hidden" />
                <button
                  onClick={() => {
                    window.open(
                      "https://podcasts.apple.com/us/podcast/mother-honestly-podcast/id1439395271"
                    );
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/apple-pod_yzrrsw.png"
                    className="h-12 my-2 md:my-0 md:h-10 md:w-fit w-52 md:left-28 md:absolute"
                    alt="Podcast Image"
                  />
                </button>
                <br className="block md:hidden" />

                <button
                  onClick={() => {
                    window.open(
                      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL21vdGhlcmhvbmVzdGx5L2ZlZWQueG1s"
                    );
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/google-pod_eavovx.png"
                    className="h-12 my-2 md:my-0 md:h-10 md:w-fit w-52 md:absolute md:left-[275px]"
                    alt="Podcast Image"
                  />
                </button>
              </Box>
            </Box>

            <Box className={props.eventSocialClassName}>
              {/* Web */}
              <Box className="absolute gap-4 bottom-8 md:bottom-12 text-center place-content-center hidden md:flex md:right-20">
                <a
                  className="cursor-pointer"
                  href="https://instagram.com/mhworklife?igshid=YmMyMTA2M2Y="
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Instagram />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Facebook />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.linkedin.com/company/mhworklife/mycompany/"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <LinkedIn />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.pinterest.com/motherhonestly/"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Pinterest />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Twitter />
                </a>
              </Box>
              {/* Mobile */}
              <Box className="absolute gap-4 bottom-8 md:bottom-12 text-center place-content-center flex md:hidden right-0 left-0">
                <a
                  className="cursor-pointer"
                  href="https://instagram.com/mhworklife?igshid=YmMyMTA2M2Y="
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Instagram />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.facebook.com/motherhonestly?mibextid=LQQJ4d"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Facebook />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://www.pinterest.com/motherhonestly/"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Pinterest />
                </a>
                <a
                  className="cursor-pointer"
                  href="https://twitter.com/mhworklife?s=21&t=lhmzHgRp7adyrrMjPPyrsw"
                  target="_blanc"
                  title="Visit our socials"
                >
                  <Twitter />
                </a>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AllresHeader;
