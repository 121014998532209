import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";

import RequestConfirmationPrompt from "../Contact/RequestConfirmation";
import MHButton from "../Common/Button/MHButton";
import MHTextInput from "../Common/Form/MHTextInput";
import MHFormGroup from "../Common/Form/MHFormGroup";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import useInput from "../../hooks/use-input";

import * as validators from "../../utils/validators";

import { ReactComponent as ArrowRightIcon } from "../../static/svg/arrow-right.svg";
import { db } from "../../firebase";
import useDialog from "../../hooks/use-dialog";

type HeaderProps = {
  BgUrl: string;
  ResIconUrl: string;
  title: string;
  titleExt?: string;
  titleInfo: string;
  pageInfo: string;
  eventSocialClassName: string;
  pageInfoClassName: string;
  titleInfoclassName: string;
  headerClassName: string;
  majorClassName: string;
  boxClassName: string;
  podClassName?: string;
  podClassName2?: string;
  children: React.ReactNode;
};

const QuotientHeader = (props: HeaderProps) => {
  const [error, setError] = React.useState(false);
  // const { loading, error, sendHttpRequest } = useHttp();

  const {
    value: enteredWorkEmail,
    valid: enteredWorkEmailIsValid,
    error: enteredWorkEmailHasError,
    onChange: workEmailInputChangeHandler,
    onBlur: workEmailInputBlurHandler,
  } = useInput([
    {
      validator: (value: string) => validators.required(value),
    },
  ]);

  const httpReducer = (state: any, action: any) => {
    switch (action.type) {
      case "REQUEST":
        const updatedRequestState = {
          ...state,
          loading: true,
          error: null,
          data: null,
        };
        return updatedRequestState;

      case "RESPONSE":
        const updatedResponseState = {
          ...state,
          loading: false,
          error: action.error,
          data: action.data,
        };
        return updatedResponseState;

      default:
        return state;
    }
  };

  const [httpState, dispatch] = React.useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
  });

  const errorMail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(true);
  };

  const flodeskSubmit = async () => {
    // e.preventDefault();
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    try {
      const response = await fetch(`https://api.flodesk.com/v1/subscribers`, {
        method: "POST",
        headers: {
          "User-Agent": "The Care Gap (www.thecaregap.com)",
          Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // first_name: firstName,
          // last_name: lastName,
          email: enteredWorkEmail,
          status: "active",
          // segments: [
          //   {
          //     name: "mh-test",
          //   },
          // ],
          // Add other fields as needed
        }),
      });
      flodeskSegmentSubmit();
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const flodeskSegmentSubmit = async () => {
    const apiKey = process.env.REACT_APP_FLODESK_ENCODE_BASE64_KEY; // Get your Flowdesk API key from environment variables
    const segmentIds = ["651c4926b92f35b8012957c1"]; // Replace with actual segment IDs
    // const testsegmentIds = ["64edd24f139c9cfad37e4808"]; // Replace with actual segment IDs
    const requestBody = {
      segment_ids: segmentIds,
      // Add other subscriber information here as needed
      email: enteredWorkEmail,
    };
    try {
      const response = await fetch(
        `https://api.flodesk.com/v1/subscribers/${enteredWorkEmail}/segments`,
        {
          method: "POST",
          headers: {
            "User-Agent": "The Care Gap (www.thecaregap.com)",
            Authorization: `Basic ${apiKey}`, // Use the base64-encoded API key
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      if (response.ok) {
        // Handle success
        console.log("Form submitted successfully");
      } else {
        const errorData = await response.json();
        console.error("Form submission failed:", errorData);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const newsLetterHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch({ type: "REQUEST" });

    await addDoc(collection(db, "newsletter-subscriptions"), {
      NewsLetterEmail: enteredWorkEmail,
      createdAt: serverTimestamp(),
    });

    handleOpenDialog();
    flodeskSubmit();
    resetForm();
    setError(false);

    dispatch({ type: "RESPONSE", error: null, data: "success" });
  };

  const resetForm = () => {
    workEmailInputChangeHandler("");
  };

  const { openDialog, handleOpenDialog, handleCloseDialog } = useDialog();

  return (
    <Fragment>
      <Grid container spacing={0} className={props.headerClassName}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <img
            src={props.BgUrl}
            alt="Header Image"
            style={{
              width: "fit",
              height: "515px",
            }}
            className="hidden lg:block bg-red-600"
          />
          <Box className="" sx={{}}>
            {props.children}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="">
            <Box className={props.majorClassName}>
              <Box
                className="text-center w-[500px]"
                color="primary"
                sx={{
                  fontWeight: 600,
                  fontSize: "12px",
                  fontFamily: "Area Normal",
                  "& > *": {},
                }}
              >
                <Box className={props.boxClassName} sx={{}}>
                  <img
                    src={props.ResIconUrl}
                    alt="Icon"
                    className="-mt-2 mr-2 "
                    style={{
                      width: "23px",
                      height: "26px",
                    }}
                  />

                  <Box>
                    <Typography
                      variant="caption"
                      className="text-center mx-auto mt-2 uppercase"
                      color="primary"
                      fontSize={{ xs: "11px", sm: "12px" }}
                      lineHeight={{ xs: "120%", sm: "102%" }}
                      letterSpacing={"0.01em"}
                    >
                      {props.title}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  className="text-left pl-8 md:pl-0 mt-1 mb-6 font-semibold font-areaSemi text-xl md:text-[24px]"
                  color="primary"
                >
                  {props.titleExt}
                </Typography>
                <Typography
                  variant="h3"
                  className={props.titleInfoclassName}
                  color="primary"
                >
                  {props.titleInfo}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "15px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  fontWeight={600}
                  letterSpacing={"0.02em"}
                  className={props.pageInfoClassName}
                >
                  {props.pageInfo}
                </Typography>
              </Box>

              {/* <Box className={props.podClassName}>
                <button
                  onClick={() => {
                    window.open(
                      "https://open.spotify.com/show/54t1C1cw8TeS2UK4doDTL4"
                    );
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612804/spotify-pod_jyslea.png"
                    className="h-12 my-2 md:my-0 md:h-10 md:w-fit w-28 md:absolute  md:mt-0"
                    alt="Podcast Image"
                  />
                </button>

                <br className="block md:hidden" />
                <button
                  onClick={() => {
                    window.open(
                      "https://podcasts.apple.com/us/podcast/mother-honestly-podcast/id1439395271"
                    );
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/apple-pod_yzrrsw.png"
                    className="h-12 my-2 md:my-0 md:h-10 md:w-fit w-52 md:left-28 md:absolute"
                    alt="Podcast Image"
                  />
                </button>
                <br className="block md:hidden" />

                <button
                  onClick={() => {
                    window.open(
                      "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL21vdGhlcmhvbmVzdGx5L2ZlZWQueG1s"
                    );
                  }}
                >
                  <img
                    src="https://res.cloudinary.com/mother-honestly/image/upload/v1668612775/google-pod_eavovx.png"
                    className="h-12 my-2 md:my-0 md:h-10 md:w-fit w-52 md:absolute md:left-[275px]"
                    alt="Podcast Image"
                  />
                </button>
              </Box> */}
            </Box>

            <Box className={props.eventSocialClassName}>
              <MHButton
                loading={httpState.loading}
                onClick={() => {
                  window.open("https://thecaregap.substack.com/");
                }}
                sx={{
                  minWidth: "auto",
                  "& svg": {
                    stroke: "grey.500",
                    width: "1rem",
                  },
                  "&.MuiButton-root:hover svg": {
                    stroke: "primary",
                  },
                  gap: "5px",
                }}
              >
                Subscribe {<ArrowRightIcon />}
              </MHButton>
              {/* <MHFormGroup
                onSubmit={
                  validators.email(enteredWorkEmail) === true
                    ? newsLetterHandler
                    : errorMail
                }
                
              >
                <Box
                  component={"div"}
                  width={250}
                  display="flex"
                  bgcolor={"#F1F1F1"}
                  sx={{
                    width: {
                      xs: 280, // for extra-small screens
                      sm: 300, // for medium screens and larger
                      md: 360, // for medium screens and larger
                    },
                  }}
                >
                  <MHTextInput
                    id="email"
                    type="email"
                    placeholder="Enter your email address to subscribe"
                    value={enteredWorkEmail}
                    onChange={workEmailInputChangeHandler}
                    onBlur={workEmailInputBlurHandler}
                    required
                    className="flex-grow h-fit"
                  />

                  <MHButton
                    type="submit"
                    loading={httpState.loading}
                    sx={{
                      minWidth: "auto",
                      "& svg": {
                        stroke: "grey.500",
                        width: "1rem",
                      },
                      "&.MuiButton-root:hover svg": {
                        stroke: "primary",
                      },
                    }}
                  >
                    {<ArrowRightIcon />}
                  </MHButton>
                </Box>

                {error === false ? (
                  ""
                ) : enteredWorkEmail !== "" ? (
                  ""
                ) : (
                  <Typography
                    variant="body2"
                    color="red"
                    fontSize={{ xs: "11px", sm: "12px", md: "12px" }}
                    lineHeight={{ xs: "230%", sm: "102%" }}
                    letterSpacing={"0.1em"}
                    sx={{
                      mb: 2,
                      pt: 1,
                      paddingBottom: 3,
                    }}
                  >
                    Enter a valid email address
                  </Typography>
                )}
              </MHFormGroup> */}

              {openDialog && (
                <RequestConfirmationPrompt
                  open={openDialog}
                  onClose={handleCloseDialog}
                  header="Subscription Successful!"
                  message="Thank you for subscribing on MH WorkLife weekly newsletter. We will be in touch with
          you shortly."
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default QuotientHeader;
