import React from 'react';
import Slider, { Settings } from 'react-slick';

import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import IconButtonStyled from '../../Button/IconButtonStyled';
import RoundedLogoIcon from '../../../../theme/icons/RoundedLogo';
import classes from './MHSlider.module.scss';

import { ReactComponent as DirectionLeftIcon } from '../../../../static/svg/direction/direction-left.svg';
import { ReactComponent as DirectionRightIcon } from '../../../../static/svg/direction/direction-right.svg';
import CenteredFlexContainer from '../../../UI/CenteredFlexContainer';
import LoadingIndicator from '../../Loading/LoadingIndicator';

const directionSx: SxProps<Theme> = {
  bgcolor: 'common.white',
  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)'
};

const buttonSx: SxProps<Theme> = {
  zIndex: (theme) => theme.zIndex.appBar + 100,
  '&::before': {
    display: 'none'
  }
};

const settings = {
  // centerMode: true,
  // centerPadding: '0px',
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: false,
  // autoplaySpeed: 1100,
  // to resolve position issue
  nextArrow: (
    <IconButtonStyled sx={{ ...buttonSx, right: '5px !important' }}>
      <RoundedLogoIcon sx={directionSx}>
        <DirectionRightIcon />
      </RoundedLogoIcon>
    </IconButtonStyled>
  ),
  prevArrow: (
    <IconButtonStyled
      sx={{
        ...buttonSx
      }}>
      <RoundedLogoIcon sx={directionSx}>
        <DirectionLeftIcon />
      </RoundedLogoIcon>
    </IconButtonStyled>
  ),
  initialSlide: 0,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        centerPadding: '30px',
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: undefined,
        prevArrow: undefined
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 1330,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0
      }
    },
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0
      }
    }
  ]
};

type SliderProps = {
  slidesToShow: number;
  slidesToScroll: number;
  children: React.ReactElement[];
  speed?: number;
  containerSx?: SxProps<Theme>;
  hideLoadingIndicator?: boolean;
};

export const MHSliderItem = ({
  children,
  width,
  sx
}: {
  children: React.ReactNode;
  width: string;
  sx?: SxProps<Theme>
}) => {
  return (
    <Box className={classes['carousel-item']} px={2} sx={sx}>
      {children}
    </Box>
  );
};

const MHSlider = ({
  children,
  containerSx,
  ...others
}: Settings & SliderProps) => {
  return (
    <React.Fragment>
      {React.Children.count(children) ? (
        <Box
          height={'fit-content'}
          position="relative"
          sx={{
            width: '100%',
            mx: 'auto',
            ...containerSx
          }}>
          <Slider {...settings} {...others}>
            {React.Children.map(children, (child, index) => {
              return React.cloneElement(child, { width: `${100}%` });
            })}
          </Slider>
        </Box>
      ) : (
        <CenteredFlexContainer height={300}>
          {!others.hideLoadingIndicator && <LoadingIndicator />}
        </CenteredFlexContainer>
      )}
    </React.Fragment>

    // <DirectionControl
    //   onLeft={handleLeftDirection}
    //   onRight={handleRightDirection}
    // />
  );
};

export default MHSlider;
