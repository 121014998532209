import React from 'react';
import { useLocation } from 'react-router-dom';

import { HttpResponse } from '../models/api.interface';
import { HttpStatusCode } from '../models/http-status-codes';
import AuthContext from '../store/context/auth-context';
import useInterceptor from './use-interceptor';

const useHttp = () => {
  const authCtx = React.useContext(AuthContext);
  const { token, isAuthenticated } = authCtx;

  const location = useLocation();
  const isAuthRoute = () => location.pathname.includes('auth'); // to ensure bearer token isnt used when on auth pages so it doesnt overwrite other auth tokens

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<{ message: string } | null>(null);

  const interceptor = useInterceptor();

  const sendHttpRequest = React.useCallback(
    async (
      endpoint: string,
      requestConfig: RequestInit,
      responseHandlerFn: (response: HttpResponse<any>) => void,
      config?: {
        errorMessage: string;
        authAction: boolean;
      }
    ) => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(endpoint, {
          method: requestConfig.method || 'GET',
          headers: {
            ...(requestConfig.headers || {
              'Content-Type': 'application/json'
            }),
            ...(token && isAuthenticated && !isAuthRoute()
              ? { Authorization: `Bearer ${token.accessToken}` }
              : {})
          },
          ...(requestConfig.body && {
            body: requestConfig.body
          })
        });

        // if (!response.ok) {
        //   throw new Error('Response failed!');
        // }

        const responseData = await response.json();

        if (response.status !== HttpStatusCode.Ok) {
          throw new Error(responseData.message);
        }

        responseHandlerFn(responseData);
      } catch (error) {
        error instanceof Error &&
          setError({
            message:
              config?.errorMessage || error.message || 'Something went wrong'
          });
      }

      setLoading(false);
    },
    [token]
  );

  return { loading, error, sendHttpRequest };
};

export default useHttp;
