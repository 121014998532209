import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { SummitNavContext } from "../../store/context/summit-context";
import SponsorDialog from "./SponsorDialog";
// import StaticDataContext from "../../store/context/static-data.context";
// import SummitPaymentDialog from "./SummitPaymentDialog";

// type Props = {};

const StickySponsor = () => {
  const [open, setOpen] = React.useState(false);
  // const dataCtx = React.useContext(StaticDataContext);
  // const { openWaitlist, closeWaitlist } = dataCtx;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // closeWaitlist();
  };

  // React.useEffect(() => {
  //   if (openWaitlist) {
  //     handleOpen();
  //   }
  // }, [openWaitlist]);

  const { active, setActive } = React.useContext(SummitNavContext);

  return (
    <React.Fragment>
      {/* STICKY BOTTOM BECOME A MEMBER */}
      <Box className=" bg-sky-100 h-14 items-center place-content-center z-10 fixed bottom-[0%] w-full">
        <a
          href="#registration"
          // onClick={() => {
          //   window.open(
          //     "https://hopin.com/events/mhworklife-care-at-work-summit/registration"
          //   );
          // }}
          onClick={() => handleOpen()}
          // onClick={() => {
          //   setActive("#registration");
          // }}
          className="!no-underline mx-auto flex justify-center hover:!bg-sky-100 "
        >
          <Typography
            variant="subtitle1"
            textTransform="uppercase"
            color="primary"
            fontSize={{ xs: "12px", sm: "12px" }}
            letterSpacing={"1.249px"}
            lineHeight={"92%"}
            alignItems={"center"}
            alignContent={"center"}
            justifyContent={"center"}
            display={"flex"}
            className=" justify-center items-center flex text-center py-5"
            sx={{
              mb: 1,
            }}
          >
            Sponsor Our Next Summit
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-4 ml-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </Typography>
        </a>
      </Box>

      <SponsorDialog open={open} onClose={handleClose} />
      {/* <SummitPaymentDialog open={open} onClose={handleClose} /> */}
    </React.Fragment>
  );
};

export default StickySponsor;
