import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MHButton from "../Common/Button/MHButton";
import { Zoom } from "@mui/material";

import { ReactComponent as CloseIcon } from "../../static/svg/close.svg";

type ResProps = {
  thumbNailImageSrc?: string;
  title?: string;
  description?: string;
  id?: number;
  source?: string;
  onClick?: (e: React.MouseEvent) => void;
  setNoData: (noData: boolean) => void; // Add this line
};

const ToolkitPopUp = (props: ResProps) => {
  const [resources, setResources] = React.useState<ResProps[]>([]);
  const { setNoData } = props; // Destructure setNoData from props

  const [noOfElement, setnoOfElement] = React.useState(1);
  const resOne = resources.slice(0, noOfElement);

  var resUrl = `${process.env.REACT_APP_RES_TOOLKIT_URL}`;

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const jsonData = await response.json();
      setResources(jsonData.data);
      setNoData(false);

      // console.log(resources);
    } catch (err) {
      console.error("Cannot find Data");
      setNoData(true);
    }
  };

  React.useEffect(() => {
    getResource();
  }, []);

  return (
    <React.Fragment>
      <Box
        className="relative z-30"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <Box className="fixed inset-0 bg-navy-900 bg-opacity-60 transition-opacity"></Box>
        <Zoom in style={{ transitionDelay: "300ms" }}>
          <Box className="fixed z-50 inset-0 overflow-y-auto">
            <Box className="flex items-center justify-center min-h-full text-center ">
              <Box className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  h-[600px] md:h-[820px] lg:h-[490px] w-[350px] md:w-[700px] lg:w-[1000px]">
                <Box className="bg-white ">
                  <Grid container spacing={3} className=" !z-50 ">
                    {resOne.map((resData: any, index) => (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box className="relative">
                            <img
                              src={resData.thumbNailImageSrc}
                              //   src="https://res.cloudinary.com/mother-honestly/image/upload/v1673992663/Group_eku1ga.png"
                              alt=""
                              className=" w-full h-[250px] md:w-full md:h-[450px] lg:w-[490px] lg:h-[490px] object-cover"
                            />
                            <MHButton
                              sx={{
                                background: "transparent",
                                ":hover": "transparent",
                              }}
                              onClick={props.onClick}
                              className="nohova block lg:hidden absolute text-navy-900 -top-1 -right-3 w-fit font-areaBold text-[15px] leading-[102%] tracking-[0.05em] h-fit "
                            >
                              <CloseIcon />
                            </MHButton>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Box
                            justifyContent="center"
                            alignItems="center"
                            paddingX={{ xs: "10px", sm: "20px", md: "1px" }}
                            className="relative"
                          >
                            <MHButton
                              sx={{
                                background: "transparent",
                                ":hover": "transparent",
                              }}
                              onClick={props.onClick}
                              className="nohova hidden lg:block absolute text-navy-900 -top-1 right-0 w-fit font-areaBold text-[15px] leading-[102%] tracking-[0.05em] h-fit "
                            >
                              <CloseIcon />
                            </MHButton>
                            <Typography
                              variant="body1"
                              fontFamily="Area-Normal-Black"
                              textTransform="uppercase"
                              fontSize={{
                                xs: "11px",
                                sm: "12px",
                                md: "12px",
                              }}
                              lineHeight={{ xs: "230%", sm: "102%" }}
                              letterSpacing={"0.1em"}
                              textAlign={{
                                xs: "center",
                                sm: "center",
                                md: "start",
                              }}
                              sx={{
                                //   mt: 2,
                                mb: 2,
                                // paddingBottom: 3,
                              }}
                              paddingTop={{ md: "60px" }}
                            >
                              a free toolkit!
                            </Typography>

                            <Typography
                              variant="h1"
                              paragraph
                              fontSize={{
                                xs: "20px",
                                sm: "30px",
                                md: "30px",
                              }}
                              lineHeight={{ xs: "140%", sm: "143.5%" }}
                              letterSpacing={{
                                xs: "-0.02em",
                                sm: "-0.04em",
                              }}
                              textAlign={{
                                xs: "center",
                                sm: "center",
                                md: "start",
                              }}
                              sx={{
                                mb: 2,
                                // paddingBottom: 5,
                              }}
                            >
                              {/* Outsourcing: Your Guide to the Tasks You Can Take
                            off Your Plate, from Cooking to Cleaning */}
                              {resData.title}
                            </Typography>

                            <Typography
                              variant="body2"
                              // textTransform="capitalize"
                              fontSize={{
                                xs: "13px",
                                sm: "14px",
                                md: "14px",
                              }}
                              lineHeight={{ xs: "185%", sm: "180%" }}
                              letterSpacing={"0.02em"}
                              textAlign={{
                                xs: "center",
                                sm: "center",
                                md: "start",
                              }}
                              className="line-clamp-4"
                              // paragraph
                              // sx={{
                              //   mb: 2,
                              //   // paddingBottom: 5,
                              // }}
                            >
                              {/* Here's everything you need to know about hiring help
                            — and why it's worth it. your Entire Workforce */}

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: resData.description,
                                }}
                              />
                            </Typography>

                            <Box
                              marginLeft={{
                                xs: "65px",
                                sm: "230px",
                                md: "0px",
                              }}
                            >
                              <MHButton
                                sx={{
                                  paddingY: 2,
                                  marginY: 2,
                                }}
                                onClick={() => window.open(resData.source)}
                                className=" text-[12px] leading-[102%] w-[201px] lg:w-[201px] tracking-[0.05em] h-[52px] "
                              >
                                Download the toolkit
                              </MHButton>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Zoom>
      </Box>
    </React.Fragment>
  );
};

export default ToolkitPopUp;
