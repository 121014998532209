// import { Box, Typography } from "@mui/material";
// import React from "react";

// type BulletProps = {
//   bulletData?: string[];
// };

// const LegalNumbers: React.FC<BulletProps> = ({ bulletData = [] }) => {
//   return (
//     <Box className="grid items-center gap-2 py-2 !pl-10 ">
//       <ol>
//         {bulletData.map((item, index) => (
//           // <div key={index} style={{ display: "flex", alignItems: "center" }}>
//           <li>
//             <Typography
//               variant="body2"
//               color="primary"
//               fontSize={{ xs: "14px", sm: "16px" }}
//               lineHeight={{ xs: "185%", sm: "180%" }}
//               letterSpacing={"0.02em"}
//               // className=" mx-auto text-start"
//             >
//               {item}
//             </Typography>
//           </li>
//           // </div>
//         ))}
//       </ol>
//     </Box>
//   );
// };

// export default LegalNumbers;

import { Box, Typography } from "@mui/material";
import React from "react";

type BulletProps = {
  bulletData?: string[];
};

const LegalNumbers: React.FC<BulletProps> = ({ bulletData = [] }) => {
  return (
    <Box className="grid items-center gap-2 pl-4">
      {bulletData.map((item, index) => (
        <div key={index} style={{ display: "flex", alignItems: "start" }}>
          <span style={{ marginRight: "0.5em" }}>{index + 1}.</span>
          <Typography
            variant="body2"
            color="primary"
            fontSize={{ xs: "14px", sm: "16px" }}
            lineHeight={{ xs: "185%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            {item}
          </Typography>
        </div>
      ))}
    </Box>
  );
};

export default LegalNumbers;
