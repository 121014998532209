import { Box, Typography } from "@mui/material";
import React from "react";
import PressMediaGrid from "./PressMediaGrid";

type Props = {};

const PressMedia = (props: Props) => {
  return (
    <React.Fragment>
      <Box className=" bg-navy-900 md:h-[306px] h-[290px] flex justify-center items-center">
        <Typography
          variant="h3"
          color=""
          fontSize={{ xs: "18px", sm: "24px", md: "32px" }}
          lineHeight={{ xs: "120%", sm: "120%" }}
          letterSpacing={"-0.04em"}
          className={`capitalize w-[343px] md:w-[750px] lg:w-[816px] text-white text-center`}
        >
          An ecosystem powering care for today's workforce and advancing equity,
          productivity, and wellbeing in today's ever-evolving workplaces.
        </Typography>
      </Box>
      <Box className="">
        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "30px", sm: "38px", md: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize py-4 w-[321px] md:w-[607px] text-center mx-auto"
        >
          Press & Media
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "10px", sm: "12px", md: "12px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[316px] md:w-[400px] pt-4 text-center mx-auto"
        >
          If you want to get in touch with our press team, please email
          <a href="mailto:hello@mhworklife.com" className="ml-1 underline">
            hello@mhworklife.com
          </a>
        </Typography>
      </Box>

      <Box className="my-12">
        <PressMediaGrid />
      </Box>
    </React.Fragment>
  );
};

export default PressMedia;
