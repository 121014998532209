import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { ItemList } from "../../../models/resources.interface";

type HeaderProps = {
  title?: string;
  description?: string;
  imageUrl?: string;
  downloadClassName?: string;
  downloadLink?: string;
  categoryOne?: string;
  category?: ItemList[] | undefined;
  date?: string;
};

const VideoHeader = (props: HeaderProps) => {
  return (
    <Fragment>
      <Box className="bg-lilac-300 h-fit md:h-[440px] px-6 md:px-32 lg:px-72 text-center mx-auto pt-16">
        <Typography
          variant="body2"
          color="primary"
          className="text-center h-fit md:h-fit md:line-clamp-3 line-clamp-6 leading-[120%] md:leading-[120.5%] my-3 w-full font-columbia text-3xl md:text-2xl lg:text-[40px] capitalize font-[500]"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2"
          className="text-center line-clamp-6 w-[327px] md:w-[493px] mx-auto text-[16px] font-areaSemi"
          color="primary"
        >
          {props.description}
        </Typography>

        <Box className="py-8 lg:py-6">
          <Typography
            className="text-[11px] text-center font-areaExt uppercase text-navy-900"
            gutterBottom
            variant="h5"
            component="p"
          >
            {props.categoryOne}
          </Typography>
          <Typography
            variant="body2"
            className="text-center mt-1 text-[12px] opacity-50 capitalize font-areaSemi"
            color="primary"
          >
            {props.categoryOne ? (
              <div
                className="text-xs capitalize truncate text-navy-900 font-areaSemi"
                // size="small"
                // disabled
              >
                {props.category!.map((item) => item.interestName).join(", ")}
              </div>
            ) : null}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default VideoHeader;
