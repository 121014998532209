import React from "react";
import ImageGrid from "./ImageGrid";

type Props = {};

const StoryGrid = (props: Props) => {
  return (
    <React.Fragment>
      <ImageGrid
        // label="mother honestly"
        title="Our Why"
        demoClass="hidden"
        urlClass="hidden"
        order="order-last"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/ourwhy2.png"
        content="At the heart of our mission lies a bold vision: a world where the responsibility of care transcends traditional boundaries, embracing a collective commitment that spans genders, communities, and economies. We dream of a future where care is not seen as a duty designated to women by default but as a shared endeavor, distributed equitably among all members of society.
        We envision a world where every child, every elder, every woman, and every family has access to quality, affordable care solutions that empower them to live their fullest lives and deliver their greatest contributions. A world where the symbiosis between workplace productivity and employee wellbeing is not just recognized but actively nurtured, leading to unparalleled outcomes for individuals and organizations alike.
        "
        content2="hidden"
      />
      <ImageGrid
        // label="MH WorkLife"
        title="A World Transformed By Care        "
        demoClass="hidden"
        urlClass="hidden"
        order="order-last lg:order-first"
        imgSrc="https://mh-resources-production.s3.us-west-1.amazonaws.com/AboutMH+.png"
        content="Imagine with us a future where our elderly are honored with the dignity and respect they deserve, aging gracefully within the heart of our communities. A future where the vibrancy of youth is matched by the wisdom of age, each supported by a foundation of care that uplifts and sustains.
        This vision drives us at Caring Bloc to challenge the status quo, to innovate, and to partner with like-minded individuals and organizations. We are committed to closing the care gap, to building systems and solutions that do not merely address the needs of today but anticipate the challenges of tomorrow.
        
        Join us in reimagining a world where care is the cornerstone of a thriving society, where every individual, regardless of age or circumstance, is valued, supported, and empowered to contribute their best. Together, we can transform this vision into reality, creating a legacy of care that will resonate for generations to come.     
        "
        content2="hidden"
      />
      {/* <ImageGrid
        // label="mother honestly"
        // title="About MH"
        demoClass="hidden"
        urlClass="hidden"
        order="order-last"
        imgSrc="https://res.cloudinary.com/mother-honestly/image/upload/v1675688901/Group_zpktjn.png"
        content="Join us in reimagining a world where care is the cornerstone of a thriving society, where every individual, regardless of age or circumstance, is valued, supported, and empowered to contribute their best. Together, we can transform this vision into reality, creating a legacy of care that will resonate for generations to come.        "
        content2="hidden"
        url="contact"
      /> */}
    </React.Fragment>
  );
};

export default StoryGrid;
