import { useEffect } from 'react';
import { Redirect, matchPath, useLocation } from 'react-router-dom';

interface GuardedRouteProps {
  /**
   * pathname of protected page
   */
  protectedRoute: string;
  /**
   * Permission check for route
   * @default false
   */
  isRouteAccessible?: boolean;
  /**
   * Route to be redirected to
   * @default '/'
   */
  redirectRoute?: string;
  /**
   * callback fxn to be called when denied permission
   */
  onDeny: () => void;
  /**
   * JSX component to be rendered when access is granted
   * @required
   */
  guardedPage: JSX.Element;
  /**
   * should render component when set to true
   * @default false
   */
  renderGuardedPage?: boolean;
  /**
   * fxn to call when user leaves guarded route to unguarded route
   *
   */
  onDestroy?: () => void;
}

/**
 * Component for guarding restricted routes
 *
 * @example Default usage
 * ```ts
 * <GuardedRoute
 *	 isRouteAccessible={true}
 * />
 * ```
 *
 * @example Usage with custom redirected route
 * ```ts
 * <GuardedRoute
 *	 isRouteAccessible={false}
 *	 redirectRoute={'/login'}
 * />
 * ```
 */
const GuardedRoute = ({
  isRouteAccessible = false,
  redirectRoute = '/',
  guardedPage,
  protectedRoute,
  onDestroy,
  ...props
}: GuardedRouteProps): JSX.Element => {
  // console.log(protectedRoute)
  // console.log(window.location.pathname)

  const pathMatch = matchPath(window.location.pathname, {
    path: protectedRoute,
    exact: true,
    strict: false
  });

  useEffect(() => {
    return () => {
      if (!pathMatch && !window.location.pathname.startsWith(protectedRoute)) {
        onDestroy && onDestroy();
      }
    };
  }, [onDestroy, protectedRoute]);

  if (
    !isRouteAccessible &&
    (pathMatch || window.location.pathname.startsWith(protectedRoute))
  ) {
    props.onDeny();
  }

  if (props.renderGuardedPage) {
    return guardedPage;
  }

  return isRouteAccessible ? (
    guardedPage
  ) : (
    <Redirect to={redirectRoute} from={window.location.pathname} />
  );
};

export default GuardedRoute;
