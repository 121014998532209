import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { TopLevelCategory } from "../../../models/dashboard.model";
import { ResourceItemType } from "../../../models/resources.interface";

import { ReactComponent as SortIcon } from "../../../static/svg/chevron-down.svg";
import AppContext from "../../../store/context/app.context";

type VideoSortProps = {
  resourcesLength: number | undefined;
  resources: ResourceItemType[];
};
const VideoSort = (props: VideoSortProps) => {
  const staticDataCtx = React.useContext(AppContext);
  const { staticDataCacheMap, interests } = staticDataCtx;

  const topLvlCategories: TopLevelCategory[] = React.useMemo(
    () => staticDataCacheMap.get("topLevelCategories") || [],
    [staticDataCacheMap]
  );

  const [videoCategoriesCount, setVideoCategoriesCount] = React.useState<{
    [key: string]: number;
  }>({});

  const countCategories = React.useCallback(() => {
    for (const category of topLvlCategories) {
      const filteredResources = props.resources.filter((resource) => {
        const resourceIndex = resource.itemList.findIndex(
          (item) => item.interestName === category.sectionName
        );

        if (resourceIndex > -1) {
          return true;
        }

        return false;
      });

      setVideoCategoriesCount((prev) => {
        const updatedCategoriesCount = {
          ...prev,
          [category.sectionName]: filteredResources.length,
        };

        return updatedCategoriesCount;
      });
    }
  }, [topLvlCategories, props.resources]);

  React.useEffect(() => {
    if (topLvlCategories.length > 0) {
      countCategories();
    }
  }, [topLvlCategories, countCategories]);

  return (
    <Fragment>
      <Box className="bg-white h-12 hidden md:flex px-[62px] relative ">
        <Typography
          color="primary"
          className="uppercase p-4"
          sx={{
            fontWeight: 900,
            fontSize: "12px",
            fontFamily: "Area-Extended",
          }}
        >
          All Categories
        </Typography>

        {topLvlCategories.map((category, index) => (
          <Typography
            color="primary"
            className="capitalize p-4 flex"
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              fontFamily: "Area-Normal-Semibold",
            }}
          >
            {category.sectionName}
            <Typography
              className="px-1"
              sx={{
                fontWeight: 600,
                fontSize: "10px",
                fontFamily: "Area-Normal-Semibold",
              }}
            >
              {videoCategoriesCount[category.sectionName]}
            </Typography>
          </Typography>
        ))}

        <Box className="absolute right-16 flex">
          <Typography
            color="primary"
            className="capitalize p-4 flex opacity-50 text-[12px] font-areaSemi"
          >
            {props.resourcesLength} Results
          </Typography>
          <Link to={"/"} className="cursor-pointer no-underline">
            <Typography
              color="primary"
              className="capitalize p-4 flex font-areaSemi text-[12px]"
            >
              Sort by
              <SortIcon className="mt-[2px] ml-1" height="10px" width="10px" />
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box className="bg-gray-300 h-[1px] hidden md:block w-full opacity-50 overflow-hidden mx-auto absolute"></Box>

      {/* Mobile Filter */}
      <Box className="bg-white flex md:hidden h-24 mb-6 px-[62px] relative">
        <Box className="mx-auto pt-6">
          <Typography
            color="primary"
            className="capitalize text-center flex opacity-50 text-[12px] font-areaSemi"
          >
            {props.resourcesLength} Results
          </Typography>
        </Box>

        <Box className="">
          <div className="mt-12 ">
            <Box className="">
              <Link
                to={"/"}
                className="cursor-pointer p-4 absolute flex left-6 no-underline"
              >
                <Typography
                  color="primary"
                  className="uppercase"
                  sx={{
                    fontWeight: 900,
                    fontSize: "12px",
                    fontFamily: "Area-Extended",
                  }}
                >
                  Categories
                </Typography>
                <SortIcon className="ml-1" height="10px" width="10px" />
              </Link>
            </Box>
            <Box className="">
              <Link
                to={"/"}
                className="cursor-pointer p-4 absolute flex right-6 no-underline"
              >
                <Typography
                  color="primary"
                  className="uppercase"
                  sx={{
                    fontWeight: 900,
                    fontSize: "12px",
                    fontFamily: "Area-Extended",
                  }}
                >
                  Sort By
                </Typography>
                <SortIcon
                  className="mt-[2px] ml-1"
                  height="10px"
                  width="10px"
                />
              </Link>
            </Box>
          </div>
        </Box>
      </Box>
    </Fragment>
  );
};

export default VideoSort;
