import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { PartnershipCard } from "../Equity/PartnershipCard";

type Props = {};

const Expectations = (props: Props) => {
  return (
    <Fragment>
      <Box className="bg-lilac-300 overflow-hidden pb-10">
        <Typography
          variant="h3"
          color="primary"
          fontSize={{ xs: "30px", sm: "40px", md: "43px" }}
          lineHeight={{ xs: "120%", sm: "120.5%" }}
          letterSpacing={{ xs: "-0.04em", sm: "-0.06em" }}
          className="capitalize pt-10 pb-6 md:w-[594px] w-[298px] text-center mx-auto"
        >
          What to Expect
        </Typography>
        <Grid
          container
          spacing={0}
          className="lg:ml-5 lg:px-12 md:gap-4 lg:gap-0 md:mx-auto md:place-content-center"
        >
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/Data-Driven+Insights.png"
              title="Data-Driven Insights"
              content="Navigate the numbers with clarity. From the financial strain of caregiving to the evolving demographics of America, understand the critical data and trends redefining our work and personal lives."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/expert-conversa.png"
              title="Expert Conversations"
              content="Engage with thought leaders from around the globe, gaining valuable perspectives on the challenges and opportunities in both the workplace and care sectors."
            />
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={4}>
            <PartnershipCard
              image="https://mh-resources-production.s3.us-west-1.amazonaws.com/Actionable+Solutions.png"
              title="Solutions & Advisory"
              content="Whether you're an employer, a parent, or someone juggling multiple responsibilities, discover strategies to find balance and thrive."
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Expectations;
