import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useDialog from '../hooks/use-dialog';
import StaticDataContext from '../store/context/static-data.context';
import geoData from '../data/georef-united-states-of-america-state.json';
import MHButton from '../components/Common/Button/MHButton';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FAQ from '../components/Features/Employers/FAQ';
import FollowMH from '../components/Landing/FollowMH';
import MHFooter from '../components/Layout/MHFooter';
import JoinWaitlist from '../components/Features/Employers/JoinWaitlist';
import MHSubscribe from '../components/Layout/MHSubscribe';
import CareFundSection from '../components/Features/CareWallet/CareFundSection';
import CareWalletHeroSlider from '../components/Features/CareWallet/CareWalletHeroSlider';
import CareReliefFundHeroSlider from '../components/Features/CareWallet/CareReliefFundHeroSlider';
import useTitle from '../hooks/use-title';
import useAnalytics from '../hooks/use-analytics';

const QUESTIONS = [
  {
    'id': 1,
    'question': 'What is the Care Fund?',
    'answer':
      'The MH WorkLife Care Fund is an employee benefit program designed to support our team members in times of need. It provides financial assistance for unexpected and unavoidable hardships.'
  },
  {
    'id': 2,
    'question': 'How does the Care Wallet work?',
    'answer':
      'The Care Wallet is a financial wellness benefit designed to help employees pay for unexpected care costs throughout the year. With the Care Wallet, employees can view their available balance, request fund disbursements for their unique parenting or caregiving needs, and manage their account details.'
  },
  {
    'id': 3,
    'question':
      'I am an employer, how can do I set up a Care Fund or Wallet for my employees?',
    'answer':
      'You can send us an email at hello@mhworklife.com or set up a demo request here.'
  },
  {
    'id': 4,
    'question': 'How can I access my Care Wallet?',
    'answer':
      'You can access your Care Wallet through the MH WorkLife web portal.'
  },
  {
    'id': 5,
    'question': 'How can I qualify for assistance from the Care Fund?',
    'answer':
      'To qualify for assistance, employees must meet certain criteria, which includes facing an unexpected and unavoidable financial hardship. Specific guidelines and application procedures can be found on our internal portal.'
  },
  {
    'id': 6,
    'question':
      'Who can I contact if I have further questions about the Care Fund or Care Wallet?',
    'answer':
      'For any further inquiries, please reach out to our HR department or the dedicated Care Fund support team at hello@mhworklife.com'
  }
];

const ADDITIONAL_QUESTIONS = [
  {
    'id': 1,
    'question':
      'How much financial assistance can I receive from the Care Fund?',
    'answer':
      'The amount of assistance varies based on your employer’s contribution, individual circumstances and the nature of the hardship. Each case is evaluated on its merits, and decisions are made on a case-by-case basis.'
  },
  {
    'id': 2,
    'question':
      'Are there any fees associated with accessing funds from my Care Wallet?',
    'answer':
      'No, there are no fees for employees when accessing or using funds from the Care Wallet.'
  },
  {
    'id': 3,
    'question':
      'How long does it take to process a request from the Care Fund?',
    'answer':
      'Typically, requests are processed within 5 business days. However, processing times may vary based on the complexity of the situation.'
  },
  {
    'id': 4,
    'question':
      'Is the information I provide when applying for assistance kept confidential?',
    'answer':
      'Yes, all information provided during the application process is kept strictly confidential. MH WorkLife is committed to protecting the privacy of its employees.'
  },
  {
    'id': 5,
    'question': 'What happens if my request for assistance is denied?',
    'answer':
      'If your request is denied, you will receive a detailed explanation. You may also have the opportunity to appeal the decision or provide additional documentation to support your case.'
  },
  {
    'id': 6,
    'question':
      'Are there any restrictions on how I can use the funds from the Care Fund?',
    'answer':
      'Funds from the Care Fund are intended for specific hardships or emergencies, so there may be restrictions based on the nature of your request. Please refer to our guidelines for more details.'
  }
];

const CareWallet = () => {
  const history = useHistory();

  const {
    openDialog: open,
    handleOpenDialog: handleOpen,
    handleCloseDialog
  } = useDialog();

  const handleClose = () => {
    handleCloseDialog();
    closeWaitlist();
  };

  const dataCtx = React.useContext(StaticDataContext);
  const { openWaitlist, closeWaitlist } = dataCtx;

  React.useEffect(() => {
    if (openWaitlist) {
      handleOpen();
    }
  }, [openWaitlist]);

  useTitle('Care Fund');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Care Fund'
  });

  // console.log(
  //   window.location.origin + window.location.pathname
  // );

  return (
    <React.Fragment>
      <Grid container direction={{ xs: 'column-reverse', md: 'row' }} mb={4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              '& > img': {
                height: 600,
                width: '100%',
                objectPosition: 'center',
                objectFit: 'cover'
              }
            }}>
            <img
              src="https://res.cloudinary.com/mother-honestly/image/upload/v1688473368/49F74692-997E-4A5A-A880-0C295D1E3819_xopdnr.png"
              alt="Banner"
              width="700"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} bgcolor="#F3F4F6">
          <Stack
            height={600}
            justifyContent="center"
            alignItems="center"
            px={{ xs: 2, md: 10 }}
            sx={{
              '& > p': {
                textAlign: 'center',
                pointerEvents: 'none'
              }
            }}>
            <Typography
              variant="h1"
              fontSize={{ xs: '30px', sm: '40px', md: '50px' }}
              textTransform="capitalize"
              width="75%"
              sx={{
                mb: 4
              }}
              paragraph>
              A Care Wallet And Fund For Your Entire Workforce
            </Typography>

            <Typography
              variant="body1"
              align="center"
              color="#194049"
              width="75%"
              sx={{
                mb: 7
              }}
              paragraph>
              Build an additional layer of protection for your employees to
              cover unexpected care and well-being expenses for personal,
              children, parents, and loved ones. Start with a small care fund,
              then scale over time.
            </Typography>

            <MHButton
              sx={{
                width: 'fit-content'
              }}
              onClick={() => history.push('/contact')}>
              Request a Demo
            </MHButton>
          </Stack>
        </Grid>
      </Grid>

      <CareFundSection />

      <CareWalletHeroSlider />

      <MHButton
        sx={{
          width: 'fit-content',
          display: 'block',
          mx: 'auto'
        }}
        onClick={() => history.push('/contact')}>
        Request a Demo
      </MHButton>

      <CareReliefFundHeroSlider />

      <MHButton
        sx={{
          width: 'fit-content',
          display: 'block',
          mx: 'auto'
        }}
        onClick={() => history.push('/contact')}>
        Request a Demo
      </MHButton>

      <Divider
        variant="middle"
        sx={{
          borderColor: 'primary.main',
          width: '91%',
          mx: 'auto',
          opacity: 0.1,
          my: 8
        }}
      />

      <Box px={{ xs: 3, md: 8 }} mb={6}>
        <FAQ questions={QUESTIONS} additionalQuestions={ADDITIONAL_QUESTIONS} />
      </Box>

      <FollowMH />

      <MHSubscribe />

      <MHFooter />

      <JoinWaitlist open={open} onClose={handleClose} />
    </React.Fragment>
  );
};

export default CareWallet;
