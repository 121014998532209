import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as Vivvi } from "../../static/svg/vivvi.svg";
import { ReactComponent as Indeed } from "../../static/svg/indeed-icon.svg";
import { ReactComponent as Lumo } from "../../static/svg/sponsors/lumo.svg";

type Props = {
  headerText?: string;
};

const PresentingSponsors = (props: Props) => {
  return (
    <React.Fragment>
      <section id="eventsponsors" className="pt-12">
        <Box className="my-6  overflow-hidden">
          <Typography
            variant="caption"
            fontSize={{ xs: "11px", sm: "11px" }}
            fontWeight={900}
            lineHeight={{ xs: "100%", sm: "102%" }}
            letterSpacing={"0.1em"}
            className=" uppercase text-center flex justify-center"
          >
            {props.headerText}
          </Typography>
        </Box>
        <Grid
          container
          className=" text-center my-6 lg:px-6 py-4 gap-4 md:pl-0 mx-auto place-content-center items-center"
        >
          <Grid
            item
            xs={5}
            sm={3}
            md={2}
            lg={2}
            justifyContent="center"
            justifyItems="center"
            display="flex"
          >
            <Indeed className="w-[150px] h-[60px]  object-cover grayscale" />
          </Grid>
          {/* <Grid item xs={5} sm={3} md={2} lg={2}>
            <Vivvi className="w-[150px] h-[60px]  object-cover grayscale" />
          </Grid>
          <Grid item xs={5} sm={3} md={2} lg={2}>
            <Lumo className="w-[150px] h-[60px]  object-cover grayscale" />
          </Grid> */}
          <Grid
            item
            xs={5}
            sm={3}
            md={2}
            lg={2}
            justifyContent="center"
            justifyItems="center"
            display="flex"
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/IFC-WBG-horizontal-Black-high.png"
              alt="Sponsors"
              className="  h-8 grayscale"
            />
          </Grid>
          <Grid
            item
            xs={5}
            sm={3}
            md={2}
            lg={2}
            justifyContent="center"
            justifyItems="center"
            display="flex"
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/bumo2.png"
              alt="Sponsors"
              className="  h-10 grayscale"
            />
          </Grid>

          <Grid
            item
            xs={5}
            sm={3}
            md={2}
            lg={2}
            justifyContent="center"
            justifyItems="center"
            display="flex"
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/gateFound.png"
              // src="https://mh-resources-production.s3.us-west-1.amazonaws.com/bill-gate.png"
              alt="Sponsors"
              className="  h-10 grayscale"
            />
          </Grid>

          <Grid
            item
            xs={5}
            sm={3}
            md={2}
            lg={2}
            justifyContent="center"
            justifyItems="center"
            display="flex"
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/somethingMajor.jpeg"
              alt="Sponsors"
              className="  h-16 grayscale"
            />
          </Grid>

          <Grid
            item
            xs={5}
            sm={3}
            md={2}
            lg={2}
            justifyContent="center"
            justifyItems="center"
            alignItems="center"
            alignContent="center"
            display="flex"
          >
            <img
              src="https://mh-resources-production.s3.us-west-1.amazonaws.com/babyLounge.png"
              alt="Sponsors"
              className="  h-14 grayscale"
            />
          </Grid>
        </Grid>
      </section>
    </React.Fragment>
  );
};

export default PresentingSponsors;
