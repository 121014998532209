import { Fragment } from "react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import MHSubscribe from "../../Layout/MHSubscribe";
import MHFooter from "../../Layout/MHFooter";

import {
  Box,
  Card,
  CardMedia,
  Grid,
  IconButton,
  Typography,
  Zoom,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { ReactComponent as BgOverlay } from "../../../static/svg/article.svg";

import Pagination from "../../UI/Pagination";
import AllresHeader from "../SubComponents/AllresHeader";
import ResCard from "../SubComponents/ResCard";
import AllSort from "../SubComponents/AllSort";
import {
  ItemList,
  ResourceItemType,
} from "../../../models/resources.interface";
import { HttpResponse } from "../../../models/api.interface";
import { checkInvalidArray, compareStartTimeDesc } from "../../../utils/utils";
import ArticleMobileFilter from "./ArticleMobileFilter";
import ArticleFilter from "./ArticleFilter";
import useTitle from "../../../hooks/use-title";
import useAnalytics from "../../../hooks/use-analytics";

type ResProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  title?: string;
  author?: string;
  catchPhrase?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdDate?: string;
  updatedAt?: string;
};

const ArticlesPage = (props: ResProps) => {
  useTitle('Articles');

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: 'Articles'
  });

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();
  const [resources, setResources] = useState<ResourceItemType[]>([]);
  let [count] = useState(0);

  var resUrl = `${process.env.REACT_APP_RES_ARTICLE_URL}`;

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(11);

  // Filtered resources using startTime
  const dateFilteredData = resources.sort(compareStartTimeDesc);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = dateFilteredData.slice(firstPostIndex, lastPostIndex);

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getResource();
  }, []);

  return (
    <Fragment>
      <AllresHeader
        headerClassName="relative text-center pl-14 z-30  md:pl-0 h-[450px] md:h-[450px] lg:h-auto w-full mx-auto bg-lilac-300 overflow-hidden"
        majorClassName="md:absolute z-50 top-8 md:top-12 md:inset-x-[18%] lg:top-24 lg:inset-x-[55%] text-center place-content-center"
        boxClassName="md:place-content-center pl-20 md:pl-0 md:overscroll-x-hidden md:gap-2 flex md:pb-4"
        title="Articles"
        titleInfo="Read The Latest Articles + Interviews"
        titleInfoclassName="text-center md:text-left -ml-12 my-2 w-3/4 mx-auto md:w-screen font-columbia text-4xl capitalize font-[500]"
        pageInfo="  Achieve your personal and professional goals with personalized
      solutions from our specialized experts in career, care, and wellbeing."
        podClassName="hidden"
        eventSocialClassName="hidden"
        pageInfoClassName="font-semibold pt-8 w-[70%] md:w-full md:ml-2 -ml-8 text-base md:text-[12px] font-areaSemi"
        ResIconUrl="https://res.cloudinary.com/mother-honestly/image/upload/v1678056764/Vector_h4qbql.png"
        BgUrl="https://res.cloudinary.com/kehinde-motherhoneestly/image/upload/v1668532869/MHresources/image_bdm0lx.png"
      >
        <BgOverlay
          className="overflow-hidden hidden md:block absolute top-12 -right-20"
          height="500px"
          width="500px"
        />
        <BgOverlay
          className="overflow-hidden md:hidden block absolute -z-50 top-9 left-[140px]"
          height="500px"
          width="500px"
        />
      </AllresHeader>
      {/* <AllSort resourcesLength={resources.length} /> */}

      {/* SEARCH FILTER */}
      <Typography
        color="primary"
        className=" lg:hidden block capitalize text-center pt-4 pb-2 opacity-50 text-[12px] font-areaSemi"
      >
        {resources.length} Results
      </Typography>

      {matchesMdDown ? (
        <ArticleMobileFilter />
      ) : (
        <ArticleFilter totalRecords={resources.length} />
      )}

      {/* SEARCH FILTER */}

      {/* For Mobile */}
      <Box className="mx-auto pt-10 bg-white px-4 md:px-20 pb-4 ">
        <Grid container spacing={2} className="mb-12">
          {currentPosts.map((res, index) => (
            <>
              <p className="hidden"> {(count = count + 1)}</p>

              {count === 1 ? (
                <Grid item xs={12} md={12} lg={6}>
                  <Zoom in style={{ transitionDelay: "200ms" }}>
                    <Card
                      className={`relative w-auto h-[400px] md:h-[500px] object-cover  rounded-md`}
                    >
                      <IconButton
                        disabled
                        className="absolute outline-none top-[6%] left-[5%]"
                      >
                        <Typography className=" z-30 capitalize font-areaSemi text-[15px] font-[900] leading-[92%] tracking-[0.1rem] text-white">
                          Latest Article
                        </Typography>
                      </IconButton>
                      <Box className="  absolute top-[20%] left-[6%] z-30">
                        <Typography className="text-left capitalize line-clamp-4  z-50 lg:w-[50%] md:w-[45%] w-[60%] font-areaSemi text-[22px] leading-[150%] tracking-[-0.04rem] font-[600] text-white">
                          {res.title}
                        </Typography>

                        <Box className="pt-12 text-left space-x-4 absolute z-50">
                          <Link
                            to={`${location.pathname}/${res.slug}/${res.id}
                     `}
                            className="bg-white w-[108px] no-underline  px-6 py-4 h-[45px] text-navy-900 font-areaSemi not-italic text-[12px] font-[700] leading-[102%] tracking-[0.05rem]"
                          >
                            Read
                          </Link>
                        </Box>
                      </Box>
                      <CardMedia
                        className="object-cover bg-cover absolute bottom-0 h-full w-full"
                        component="img"
                        image="https://res.cloudinary.com/mother-honestly/image/upload/v1678872436/articles_dmdlst.png"
                        // image="https://res.cloudinary.com/mother-honestly/image/upload/v1661545700/image_hsichu.png"
                        alt="Resource Image"
                      />
                    </Card>
                  </Zoom>
                </Grid>
              ) : count !== 1 ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={index}
                  className="cardozo md:px-3 px-12  mt-6 md:mt-0"
                >
                  <ResCard
                    cardClass="relative w-[305px] mb-10 h-[500px] object-cover bg-cream-100 rounded-md shadow-sm"
                    iconClass="hidden absolute top-10 ml-20 mt-12 w-20 h-20" //absolute top-10 ml-20 mt-12 w-20 h-20
                    imgBg="bg-navy-100 h-[305px]"
                    bodyBg="bg-cream-100"
                    imageSrc={res.thumbNailImageSrc}
                    title={res.title}
                    top={
                      checkInvalidArray(res.newItemList).length
                        ? res.newItemList[0].interestName
                        : ""
                    }
                    text={res.catchPhrase}
                    podTop="hidden"
                    // category={res.itemList ? res.itemList[0].interestName : ""}
                    // categoryTwo={res.itemList ? res.itemList[1] : ""}
                    category={res.newItemList}
                    titleUrl={`/resources/articles/${res.slug}/${res.id}`}
                    playUrl={`/resources/articles/${res.slug}/${res.id}`}
                  />
                </Grid>
              ) : null}
            </>
          ))}
        </Grid>
      </Box>

      <Pagination
        totalPosts={resources.length}
        postsPerPage={postsPerPage}
        onClick={() => {
          setCurrentPage(currentPage - 1);
        }}
        onClicked={() => {
          setCurrentPage(currentPage + 1);
        }}
        currentPage={currentPage}
      />
      <MHSubscribe />
      <MHFooter />
    </Fragment>
  );
};

export default ArticlesPage;
