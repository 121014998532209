import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { ReactComponent as ResEventIcon } from "../../static/svg/resevent.svg";
import { ReactComponent as LeftBtn } from "../../static/svg/left-btn.svg";
import { ReactComponent as RightBtn } from "../../static/svg/right-btn.svg";

import dayjs from "dayjs";
import ResCard from "../Resources/SubComponents/ResCard";
import MHButton from "../Common/Button/MHButton";
import { ItemList, ResourceItemType } from "../../models/resources.interface";
import { checkInvalidArray } from "../../utils/utils";
import { HttpResponse } from "../../models/api.interface";
// import MHButton from "../Common/Button/MHButton";

type ResProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  startTime?: number;
  endTime?: number;
  title?: string;
  texts?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdAt?: string;
  updatedAt?: string;
};

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 -right-4">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 -left-7">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

const LandingEvent = (props: ResProps) => {
  const location = useLocation();
  const [resources, setResources] = useState<ResourceItemType[]>([]);

  var resUrl = `${process.env.REACT_APP_RES_EVENT_URL}`;

  const settings = {
    centerMode: true,
    centerPadding: "45px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1300,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  let history = useHistory();

  const { path } = useRouteMatch();

  const handleClickOpen = () => {
    history.push(`/resources/events`);
  };

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getResource();
  }, []);

  return (
    <Fragment>
      <Box className=" bg-white block md:hidden lg:hidden">
        <Box className="mx-auto overscroll-x-hidden flex" sx={{}}>
          <Typography
            variant="h3"
            color="#194049"
            fontSize={"30px"}
            fontWeight={500}
            lineHeight={"120%"}
            letterSpacing={"-0.004em"}
            className="text-center mx-auto py-6"
          >
            Upcoming Events
          </Typography>
        </Box>

        {resources.length === 0 ? (
          <Typography
            variant="body1"
            textTransform="uppercase"
            fontSize={{
              xs: "11px",
              sm: "11px",
              md: "12px",
            }}
            lineHeight={{ xs: "230%", sm: "102%" }}
            letterSpacing={"0.1em"}
            textAlign="center"
            className="py-4"
          >
            New Event coming soon
          </Typography>
        ) : null}

        <Box className="mx-auto pt-10 bg-white overflow-hidden block md:hidden lg:hidden relative">
          <Grid container spacing={3}>
            <Box className="w-[98%] bg-white mx-auto ">
              <Slider {...settings}>
                {resources.map((res, index) => (
                  <Grid item xs={12} md={6} lg={3} key={index}>
                    <ResCard
                      cardClass="relative w-[286px] h-[437px] shadow-none object-cover bg-cream-100 rounded-md"
                      iconClass="hidden"
                      imgBg="bg-pink-700  h-[286px]"
                      bodyBg="bg-cream-100"
                      top={dayjs(res.startTime!).format("MMMM D, h:mma")}
                      imageSrc={res.thumbNailImageSrc}
                      title={res.title}
                      podTop="hidden"
                      // category={res.itemList ? res.itemList[0].interestName : ""}
                      // categoryTwo={res.itemList ? res.itemList[1] : ""}
                      category={res.newItemList}
                      titleUrl={`/resources/events/${res.slug}/${res.id}`}
                      playUrl={`/resources/events/${res.slug}/${res.id}`}
                    />
                  </Grid>
                ))}
              </Slider>
            </Box>
          </Grid>
          <div className="flex justify-center py-12">
            <MHButton
              onClick={() => handleClickOpen()}
              sx={{ width: "152px", height: "46px" }}
            >
              See All Events
            </MHButton>
          </div>
        </Box>
      </Box>
    </Fragment>
  );
};

export default LandingEvent;
