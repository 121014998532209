import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import MHSlider, {
  MHSliderItem
} from '../../Common/SliderCarousel/Slider/MHSlider';
import ContentCard from '../../UI/ContentCard';
import MHmarquee from '../../Common/Marquee/MHmarquee';

const CARE_RELIEF_FUND_SLIDER_CONTENT = [
  {
    imageSrc:
      'https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Set+up+Your+Organization\'s+Care+Fund.png',
    title: 'SetUp Your Organization’s Care Fund',
    description:
      'Set up your organization’s care fund starting with an investment as low as $5,000 using our sophisticated fintech and HRIS integration.'
  },
  {
    imageSrc:
      'https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Employees+Request+For+Grant.png',
    title: 'Employees Request For Grant',
    description:
      'Employees onboard their personalized dashboard where they can effortlessly request a care grant for emergency relief and unexpected care costs'
  },
  {
    imageSrc:
      'https://mh-resources-production.s3.us-west-1.amazonaws.com/Website+Images/Website+Images+(1)/Approve+%26+Disburse+Care+Grant.png',
    title: 'Approve & Disburse Care Grant',
    description:
      'MH discreetly and promptly disperses qualifying grants, ensuring a seamless and confidential process, including anonymized insights on your employer dashboard.'
  }
];

const CareReliefFundHeroSlider = () => {
  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Box width={{ xs: '90%', sm: '60%' }} mx="auto" mt={10}>
        <Typography
          variant="h1"
          fontSize={{ xs: '30px', sm: '40px', md: '50px' }}
          textTransform="capitalize"
          align="center"
          sx={{
            mb: 4
          }}
          paragraph>
          THE CARE RELIEF FUND
        </Typography>
        <Typography
          variant="body1"
          color="primary.main"
          width={{ xs: '100%', md: '100%' }}
          fontSize={{ sm: '14px', md: '16px' }}
          lineHeight={'190%'}
          letterSpacing={'0.02em'}
          align="center"
          mx="auto"
          paragraph>
          The Care Fund is an emergency relief program to provide support to
          employees facing significant caregiving needs. The fund aims to
          alleviate financial burdens and provide assistance to employees who
          are experiencing high intensity caregiving challenges that may affect
          their physical, emotional, and financial well-being. These challenges
          may arise due to various reasons, such as caring for family members
          with serious medical conditions, supporting children with special
          needs, or handling unexpected emergencies related to childcare.
        </Typography>
      </Box>

      <Box py="3rem" px={{ xs: 0, md: '4rem' }}>
        <MHSlider slidesToShow={3} slidesToScroll={1}>
          {CARE_RELIEF_FUND_SLIDER_CONTENT.map((item, index) => (
            <MHSliderItem width="100%" key={index}>
              <ContentCard
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                onMouseClick={() => {}}
              />
            </MHSliderItem>
          ))}
        </MHSlider>
      </Box>
    </div>
  );
};

export default CareReliefFundHeroSlider;
