import React from 'react';
import { Redirect } from 'react-router-dom';

import BackdropLoader from '../../Common/Loading/BackdropLoader';
import useHttp from '../../../hooks/use-http';

import { getURLWithQueryParams } from '../../../utils/utils';
import { Employee } from '../../../models/user.model';
import { HttpResponse } from '../../../models/api.interface';
import AuthContext from '../../../store/context/auth-context';
import geoData from '../../../data/georef-united-states-of-america-state.json';

const Startup = () => {
  const authCtx = React.useContext(AuthContext);
  const { loading, error, sendHttpRequest: getUser } = useHttp();

  const { userId, token, synchronizeUser } = authCtx;

  const fetchUser = React.useCallback(async () => {
    if (!token || !userId) {
      return;
    }

    await getUser(
      getURLWithQueryParams(
        process.env.REACT_APP_API_BASE_URL +
          'employee/dashboard/employee/token/uuid',
        {
          uuid: String(userId)
        }
      ),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token?.accessToken}`
        }
      },
      (response: HttpResponse<Employee>) => {
        // console.log(response);
        synchronizeUser(response.data);
      }
    );
  }, [userId, token, getUser, synchronizeUser]);

  React.useEffect(() => {
    fetchUser();
    // console.log('Just logged in', userId);
  }, [token]);

  if (error) {
    // return <Redirect to="/auth/sign-in" />;
  }

  if (loading) {
    return <BackdropLoader open />;
  }

  return null;
};

export default Startup;
