import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {};

const AboutEquity = (props: Props) => {
  return (
    <Box mb={10}>
      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "11px", sm: "12px" }}
          lineHeight={{ xs: "230%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase w-[335px] md:w-[105px]"
        >
          What is Care Equity?
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[335px] md:w-[650px] lg:w-[846px] pt-4 text-center mx-auto"
        >
          At The Care Gap, our driving force is the recognition that in
          conversations about the future of work, the most vulnerable workers in
          America, including Blacks, Latinos, Immigrants, and service and hourly
          workers, are often excluded. Many of these individuals, who
          predominantly work in the service and hospitality industry, face
          unique challenges in accessing flexible work arrangements and
          balancing their caregiving responsibilities. Our why is to create a
          future of work that prioritizes the needs of these individuals,
          ensuring they have equitable access to care benefits, resources,
          flexibility, and support. We believe that by addressing the specific
          barriers they face, we can build a more inclusive and fair work
          environment for all.
        </Typography>
      </Box>
      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "11px", sm: "12px" }}
          lineHeight={{ xs: "230%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase w-[335px] md:w-[105px]"
        >
          Vision
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[335px] md:w-[650px] lg:w-[846px] pt-4 text-center mx-auto"
        >
          Our vision is a future of work that recognizes the value and dignity
          of every worker, including Blacks, Latinos, Immigrants, and service
          and hourly workers. We envision a future where they can attend to
          their caregiving needs while balancing work responsibilities,
          accessing comprehensive care benefits, resources, flexibility, and
          more. This future of work is characterized by equal opportunities,
          inclusivity, and support for all individuals, regardless of their
          background or employment status.
        </Typography>
      </Box>
      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "11px", sm: "12px" }}
          lineHeight={{ xs: "230%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase w-[335px] md:w-[105px]"
        >
          Mission
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[335px] md:w-[650px] lg:w-[846px] pt-4 text-center mx-auto"
        >
          At The Care Gap, our mission is to advance equity in the workplace by
          championing the needs and aspirations of vulnerable workers, including
          Blacks, Latinos, Immigrants, and service and hourly workers. We strive
          to dismantle systemic barriers, promote inclusive practices, and
          foster a work environment that empowers them to attend to their
          caregiving needs while accessing the necessary resources, care
          benefits, flexibility, and support. Through advocacy, collaboration,
          and targeted initiatives, we aim to create a future of work where
          every individual can thrive, no matter their circumstances.
        </Typography>
      </Box>

      {/* <Box className=" py-12 mx-auto grid place-content-center lg:flex lg:justify-center lg:gap-10 ">
        <Box>
          <img
            src="https://res.cloudinary.com/mother-honestly/image/upload/v1672856564/blessing_od4e3q.png"
            alt=""
            className="w-[235px] h-[235px] md:w-[400px] md:h-[400px] lg:w-[401px] lg:h-[401px] rounded-[6px] my-4"
          />
          <Typography
            variant="h3"
            color="primary"
            fontSize={{ xs: '43px', sm: '43px', md: '43px' }}
            lineHeight={{ xs: '120%', sm: '120.5%' }}
            letterSpacing={{ xs: '-0.04em', sm: '-0.06em' }}
            className="capitalize w-[230px] md:w-[301px] text-center mx-auto">
            Employers
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: '12px', sm: '14px', md: '14px' }}
            lineHeight={{ xs: '180%', sm: '195%' }}
            letterSpacing={{ xs: '0.05em', sm: '0.06em' }}
            className=" w-[230px] md:w-[280px] pt-4 text-center mx-auto">
            We partner with employers to support their employee resource groups,
            parents, caregivers, and build community, and foster connections.
          </Typography>
        </Box>
        <Box>
          <img
            src="https://res.cloudinary.com/mother-honestly/image/upload/v1672856564/Image_rh0bgb.png"
            alt=""
            className="w-[235px] h-[235px] md:w-[400px] md:h-[400px] lg:w-[401px] lg:h-[401px] rounded-[6px] my-4"
          />
          <Typography
            variant="h3"
            color="primary"
            fontSize={{ xs: '43px', sm: '43px', md: '43px' }}
            lineHeight={{ xs: '120%', sm: '120.5%' }}
            letterSpacing={{ xs: '-0.04em', sm: '-0.06em' }}
            className="capitalize w-[230px] md:w-[301px] text-center mx-auto">
            Brands
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: '12px', sm: '14px', md: '14px' }}
            lineHeight={{ xs: '180%', sm: '195%' }}
            letterSpacing={{ xs: '0.05em', sm: '0.06em' }}
            className=" w-[230px] md:w-[301px] pt-4 text-center mx-auto">
            We help brands position themselves as caring brands, building trust
            and strengthening their connection to our immediate and extended
            communities.
          </Typography>
        </Box>
        <Box>
          <img
            src="https://res.cloudinary.com/mother-honestly/image/upload/v1670669593/image_ghzlqf.png"
            alt=""
            className="w-[235px] h-[235px] md:w-[400px] md:h-[400px] lg:w-[401px] lg:h-[401px] rounded-[6px] my-4"
          />
          <Typography
            variant="h3"
            color="primary"
            fontSize={{ xs: '43px', sm: '43px', md: '43px' }}
            lineHeight={{ xs: '120%', sm: '120.5%' }}
            letterSpacing={{ xs: '-0.04em', sm: '-0.06em' }}
            className="capitalize w-[230px] md:w-[301px] text-center mx-auto">
            Care Innovators
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: '12px', sm: '14px', md: '14px' }}
            lineHeight={{ xs: '180%', sm: '195%' }}
            letterSpacing={{ xs: '0.05em', sm: '0.06em' }}
            className=" w-[230px] md:w-[301px] pt-4 text-center mx-auto">
            We help innovators who are building care and family technology
            connect authentically and intentionally with parents, caregivers,
            and employers within our ecosystem.
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default AboutEquity;
