import { Box, Typography } from "@mui/material";
import React from "react";

type Props = {};

const aboutData = [
  {
    text: "47% of employees in their 40s and 50s find themselves 'sandwiched' between the demands of aging parents and their children.",
  },
  {
    text: "Alarming data from the U.S. Department of Labor reveals the soaring costs of childcare, presenting significant challenges for working families.",
  },
  {
    text: "As the cost of eldercare surges, caregivers are feeling the strain now more than ever.",
  },
  {
    text: "Economic pressures persist with 72% of Americans living paycheck to paycheck.",
  },
  {
    text: "With around 17% of Americans now over the age of 65, the age demographic shift has far-reaching implications for care, work, and life.",
  },
];

const AboutQuotient = (props: Props) => {
  return (
    <React.Fragment>
      <Box className="h-[343px] w-[343px] md:h-[650px] md:w-[650px] mt-2 place-content-center mx-auto block lg:hidden">
        <img
          src="https://res.cloudinary.com/mother-honestly/image/upload/v1663241743/BlessingAdesiyan_eixt3a.png"
          alt="Image"
          className=" rounded-[6px] object-contain md:mt-10 mx-auto"
        />
      </Box>
      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "11px", sm: "12px" }}
          lineHeight={{ xs: "230%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase w-[335px] md:w-[105px]"
        >
          {/* About Care Quotient */}
          About The Care Gap
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[335px] md:w-[650px] lg:w-[846px] pt-4 text-center mx-auto"
        >
          {/* In an age where 73% of mothers are part of the labor force, and an
          astounding 73% of employees are caregivers, the interplay between work
          and caregiving has never been more pronounced. Join [Your Name], CEO
          of MH WorkLife, every week as we delve into the pressing data and
          nuances shaping our modern work landscape. "Care Quotient" isn't just
          a CEO newsletter and podcast; it's a movement, championing insights
          that empower not only parents and caregivers but also employers
          seeking to understand and adapt to these shifting dynamics. */}
          In the midst of a caregiving revolution, "The Care Gap" emerges as the
          critical beacon for parents, caregivers, and forward-thinking
          employers. Spearheaded by Blessing Adesiyan, CEO of MH WorkLife, this
          newsletter is your weekly dispatch at the forefront of change—where
          every percentage point and policy shift is dissected and discussed.
          <br />
          <br />
          Caregiving isn't just a personal issue; it's the silent engine of our
          economies and the soft fabric of our societies. Yet, the gap between
          what is and what could be remains vast. "The Care Gap" is dedicated to
          closing that divide, bringing you the raw, unfiltered data that
          underpins our work-life balance, and offering clear, actionable
          insights that catalyze transformation in homes, workplaces, and policy
          circles.
          <br />
          <br />
          It’s written by me - Blessing Adesiyan, Founder & CEO of MH WorkLife
          where we are building care infrastructure for today’s modern
          workforce. I am a mother of four with a background in Chemical
          Engineering, Energy & Sustainability, and Business - spending over 15
          years working for five Fortune 100 companies leading operations and
          managing energy infrastructures across the United States and EMEA
          region.
          <br />
          <br />
          15 years ago, I walked into Corporate America as a black woman,
          immigrant, single and breastfeeding mother in a predominantly male
          profession - manufacturing. My experiences shaped my approach to
          caregiving and work, and I started Mother Honestly (also MH WorkLife)
          as a place for parents, caregivers, and workplaces can find resources
          and solutions for caregiving, work and life.
          <br />
          <br />I co-founded the CareForce and Chamber Of Mothers in the height
          of the pandemic, supported hundreds of workplaces, over 500,000
          mothers, and have spoken at TechCrunch, FastCompany, Amazon, AdWeek,
          Fortune, NASA, SAP, Google, and more.
        </Typography>
      </Box>
      <Box className="text-center mt-10 overflow-hidden">
        <Typography
          variant="caption"
          color="primary"
          fontSize={{ xs: "11px", sm: "12px" }}
          lineHeight={{ xs: "230%", sm: "102%" }}
          letterSpacing={"0.1em"}
          className="uppercase w-[335px] md:w-[105px]"
        >
          {/* Why This Matters */}
        </Typography>

        <Typography
          variant="body1"
          color="primary"
          fontSize={{ xs: "12px", sm: "14px", md: "14px" }}
          lineHeight={{ xs: "180%", sm: "195%" }}
          letterSpacing={{ xs: "0.05em", sm: "0.06em" }}
          className=" w-[335px] md:w-[650px] lg:w-[846px] pt-4 text-center mx-auto"
        >
          {/* In today's evolving socio-economic landscape, a significant portion of
          our workforce faces multifaceted challenges. Nearly half (47%) of
          employees in their 40s and 50s grapple with the 'sandwich generation'
          dilemma, where they find themselves juggling the responsibilities of
          raising children while also caring for aging parents. This situation
          grows even more complex when we consider the recent revelations from
          the U.S. Department of Labor, which highlight the accelerating costs
          of childcare — a development that has placed immense financial and
          emotional burdens on working families.
          <br />
          <br />
          Eldercare, too, is undergoing its own financial transformation. As its
          costs rise steeply, caregivers, many of whom are already stretched
          thin, are feeling an unprecedented strain on their resources and
          well-being. This is happening against a backdrop where a staggering
          72% of Americans navigate the challenges of living paycheck to
          paycheck, an indicator of the broader economic pressures weighing on
          households across the nation.
          <br />
          <br />
          To add another layer to this intricate tapestry, we must also
          recognize the demographic transformation happening in real-time. With
          17% of Americans now over the age of 65, we're witnessing a
          substantial shift that not only impacts caregiving but also has ripple
          effects on the workforce, retirement dynamics, and broader societal
          structures. As we stand at this intersection of care, work, and life,
          the need for informed discussions, actionable solutions, and
          supportive communities has never been greater. */}
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default AboutQuotient;
