import React from 'react';
import SnackbarContext from '../store/context/snackbar.context';
import { SnackbarContentProps } from '@mui/material/SnackbarContent';
import { SnackbarAlert } from '../components/Common/Snackbar/MHSnackbar';

const useToast = () => {
  const snackbarCtx = React.useContext(SnackbarContext);
  const { toast: showToast } = snackbarCtx;

  const toast = (
    message: SnackbarContentProps['message'],
    variant: SnackbarAlert
  ) => {
    showToast({ message, variant });
  };

  return toast;
};

export default useToast;
