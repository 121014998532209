import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import LegalBullets from "./LegalBullets";
import LegalNumbers from "./LegalNumbers";
import LegalAlphabet from "./LegalAlphabet";
import { Link } from "react-router-dom";

type Props = {};

const BodyCard = (props: Props) => {
  return (
    <React.Fragment>
      <Grid container spacing={1} className="pl-8 md:pl-16 mt-12 gap-1">
        <Grid item xs={12} sm={12} md={2.75} lg={2.75} className=" ">
          <Typography
            variant="caption"
            color="primary"
            fontSize={{ xs: "11px", sm: "12px" }}
            lineHeight={{ xs: "102%", sm: "102%" }}
            letterSpacing={"0.1em"}
            className="uppercase"
          >
            As of Sept 17, 2023
          </Typography>
        </Grid>

        <Grid item xs={11.5} sm={11} md={7} lg={7} className="">
          <Typography
            variant="body1"
            color="primary"
            fontSize={{ xs: "14px", sm: "16px" }}
            lineHeight={{ xs: "170%", sm: "200%" }}
            letterSpacing={"0.02em"}
            className=" pb-12"
          >
            Welcome to the website of MH Work Life LLC a Delaware Limited
            Liability Company (hereinafter referred to as the{" "}
            <b className=" font-areaNorm">"Company", "MH’, we", "us",</b> or{" "}
            <b className=" font-areaNorm"> "our" </b>). These Terms of Service
            and Use ("Terms") constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity (
            <b className=" font-areaNorm">“you”, “Customer”, “your”</b>) and us,
            concerning your access to and use of our website, products, and
            services, including any content, functionality, and services offered
            on or through{" "}
            <Link to={"/"}>
              <b className=" font-areaNorm"> www.mhworklife.com </b>
            </Link>
            (collectively the <b className=" font-areaNorm">"Site" </b>). Please
            read these Terms carefully before using our Site or our services. By
            accessing or using the Site, you agree to be bound by these Terms
            and all terms incorporated by reference. If you do not agree to
            these Terms, please do not use the Site.
            <br />
            <br />
            Your interaction with us, be it as an employer setting aside funds,
            an employee accessing benefits, an individual seeking guidance, a
            subscriber to one of our plans, an event attendee, or any other
            user, is subject to these Terms. By accessing or using our Site or
            services, you consent to both these Terms and our Privacy Policy. If
            you disagree with any conditions in either document, please refrain
            from using our Site and services.
            <br />
            <br />
            We reserve the right to review and amend any of these Terms of
            Service at our sole discretion. Upon doing so, we will update this
            page. Any changes to these Terms of Service will take effect
            immediately from the date of publication.
            <br />
            <br />
            <i className="md:pl-12 lg:pl-32">
              {" "}
              These Terms are revised and updated as of Sept 17, 2023
            </i>
            <br />
            <br />
            <b className=" underline font-areaNorm">DEFINITIONS</b>
            <br />
            “Services” encompasses all offerings provided by MH, including but
            not limited to Care Fund, Care Wallet, 1:1 Guided Session,
            Events/Conferences, and Cover The Care Gap.
            <br />
            <br />
            “Products” refers to specific programs or initiatives designed by
            MH, including the Care Fund, Care Wallet, 1:1 Guided Session,
            Events/Conferences, and Cover The Care Gap.
            <br />
            <br />
            “MH Advisor” denotes an expert or consultant affiliated with MH,
            providing personalized advice and consultations.
            <br />
            <br />
            “MH Dashboard” refers to MH’s proprietary online platform where
            users, such as employers and employees, interact with various
            Products and Services.
            <br />
            <br />
            “Administrative User” signifies an employee or authorized
            representative of the Customer, who accesses the MH Dashboard to
            oversee and manage the Products and Services.
            <br />
            <br />
            “Customer Data” encompasses all data and information directly
            provided by the Customer or extracted from users of the MH
            Dashboard, including but not limited to personal details and
            relevant employment data.
            <br />
            <br />
            “Third-Party Products” denotes any external products or services
            that might be integrated with or used in conjunction with the MH
            platform.
            <br />
            <br />
            "Subscription" refers to the commitment by employers or employees to
            an MH plan. MH offers four primary Subscription plans:
            <br />
            <br />
            <LegalNumbers
              bulletData={[
                "Professional",
                "Professional Plus",
                "Manager Pro",
                "Enterprise",
              ]}
            />
            <br />
            Each Subscription plan has its unique set of benefits, terms, and
            pricing.
            <br />
            <br />
            "Subscription Period" denotes the agreed duration for which a
            Subscriber (employer or employee) commits to an MH plan. The
            Subscription Period commences on the date of activation and expires
            at the end of the term agreed upon, unless renewed.
            <br />
            <br />
            "Order" refers to the formal written or electronic request made by
            the Customer to purchase a Subscription plan, product, or service
            from MH. The Order outlines specific details including, but not
            limited to, the type of Subscription or service, the Fees
            associated, the duration of the Subscription, and any other
            pertinent terms or conditions related to the purchase.
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              International Users{" "}
            </b>{" "}
            <br /> The Site is owned and operated in the United States. We
            welcome users from countries outside of the United States to access
            the Site and Services. If you reside outside of the US, you are
            responsible for compliance with local and federal laws (including
            compliance with Global Data Protection Regulation or “GDPR” if you
            reside in the European Union or “EU”).
            <br />
            <br />
            We reserve the right to deny access to the Site for IP addresses
            from any prohibited countries for any purpose or reason.
            <br />
            <br />
            <b className=" font-areaNorm underline"> Limitations of Use </b>
            <br />
            By using our Site and Services, you warrant on behalf of yourself,
            your users, and other parties you represent that you will not:
            <br />
            <br />
            <LegalBullets
              bulletData={[
                "modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on our Site;",
                "remove any copyright or other proprietary notations from any materials and software on our Site;",
                "transfer the materials to another person or 'mirror' the materials on any other server;",
                "knowingly or negligently use this Site or any of its associated services in a way that abuses or disrupts our networks or any other service MH provides;",
                "use our Site or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;",
                "use our Site or its associated services in violation of any applicable laws or regulations;",
                "use our Site in conjunction with sending unauthorized advertising or spam;",
                "harvest, collect, or gather user data without the user's consent;",
                "or use our Site or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.",
              ]}
            />
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Intellectual Property
            </b>{" "}
            <br />
            The Site, its entire contents, features, functionality, and the
            intellectual property inherent in them (including but not limited to
            its design, text, graphics, images, video, audio, source code,
            software, data, applications, and any logos, marks, or other
            content) (collectively, the “Site IP”) are owned by MH, its
            licensors, or other providers of such material and are protected by
            United States and international copyright, trademark, patent, trade
            secret, moral rights, and other intellectual property or proprietary
            rights laws.
            <br />
            <br />
            MH grants you a limited, non-transferable license to use the Site
            for your personal, non-commercial use only. You may not access,
            reproduce, copy, distribute, modify, create derivative works of,
            publicly display, publicly perform, republish, download, store,
            transmit, sell, rent, license, or otherwise exploit any part of the
            Site IP, without our express prior written consent. This restriction
            includes any automated or systematic collection of data from the
            Site. You must respect all copyright, trademark, and other
            intellectual property rights contained in the original material or
            any copy you make of the Site IP. This license shall automatically
            terminate if you violate any of these restrictions or the Terms of
            Service, and may be terminated by MH at any time.
            <br />
            <br />
            Any feedback, comments, suggestions, ideas, or other submissions you
            provide regarding the Site or its content shall be deemed to be
            non-confidential and MH shall be free to use, disclose, reproduce,
            license, or otherwise distribute, and exploit these submissions as
            it sees fit, without any obligation or restriction based on
            intellectual property rights or otherwise.
            <br />
            <br />
            <b className=" font-areaNorm underline"> Responsibilities Of MH</b>
            <br />
            <LegalAlphabet
              bulletData={[
                "Service Availability: MH shall make all reasonable efforts to ensure that its Site and services are available to the Customer, barring maintenance periods or any unforeseen technical issues outside of MH’s control.",
                "Support: MH shall provide reasonable support to assist Customers in the use of the Site and services. This support will be available during MH's regular business hours and will be provided via the contact methods specified on the Site.",
                "Data Protection: MH shall employ appropriate security measures to protect Customer data against unauthorized access, loss, destruction, or alteration.",
                "Updates: MH may, from time to time, introduce updates or improvements to its services. Whenever possible, MH shall notify Customers in advance of significant changes.",
                "Compliance: MH shall comply with all relevant laws and regulations pertaining to the provision of its services.",
              ]}
            />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Responsibilities Of Customer{" "}
            </b>
            <br />
            <LegalAlphabet
              bulletData={[
                "Correct Information: You the Customer shall provide accurate and complete information during the subscription process and update this information as necessary..",
                "Account Security: You the Customer are responsible for maintaining the confidentiality of your account details and are liable for all activities that occur under your account.",
                "Acceptable Use: You the Customer shall use the Site and services in compliance with our Terms and all applicable laws and regulations. Any misuse or prohibited activity may result in suspension or termination of service.",
                "Payments: You the Customer are responsible for timely payment of all fees associated with your chosen Subscription or any other service or product you procure from us.",
                "Feedback: While not obligatory, You are encouraged to provide feedback to MH regarding your experience, which can assist in the ongoing improvement of the services.",
              ]}
            />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              One on One Sessions (1:1 Sessions):{" "}
            </b>
            <br />
            <LegalAlphabet
              bulletData={[
                "Nature of Services:As one of its offerings, MH provides a platform facilitating 1:1 sessions between users and potential mentors or advisors. These sessions are intended to be informative and supportive. They are not a substitute for professional advice, diagnosis, treatment, or therapy. Users are encouraged to consult with a qualified professional for specific concerns.",
                "Selection and Qualifications: While MH strives to maintain a high standard for advisors or mentors on its platform, we do not control, certify, or oversee the individual qualifications, expertise, or advice of these professionals. It is the responsibility of the user to verify and determine the suitability of an advisor or mentor for their needs.",
                "Responsibility for Interactions: All interactions during 1:1 sessions are between the user and the chosen advisor or mentor. MH is not responsible for the content, quality, or outcome of these interactions. Users should exercise discretion and judgment when sharing personal information or making decisions based on discussions during sessions.",
                "No Guarantee of Outcomes: The content and advice provided during 1:1 sessions are offered 'AS IS' without any warranties or guarantees, either express or implied. MH does not guarantee specific results or improvements from participating in these sessions.",
                "Limitation on Liability: MH shall not be responsible for any actions taken, or not taken, by users based on their 1:1 sessions. Any liability related to the content, advice, or actions resulting from these sessions rests solely between the user and the advisor or mentor.",
                "Confidentiality: While MH prioritizes user privacy and implements measures to protect data, we cannot guarantee complete confidentiality for information shared during sessions. Users are advised to refrain from sharing excessively sensitive or personal information during 1:1 sessions.",
                "Respect and Conduct: Users are expected to maintain a respectful demeanor during sessions. Any reported misconduct may result in the termination of access to MH's services.",
              ]}
            />
            <br />
            <b className=" font-areaNorm underline"> Payments</b>
            <br />
            Fees: Customer agrees to pay the fees set forth in the Order (the
            <b className=" font-areaNorm "> “Fees” </b>). Fees for Subscription
            plans and any other services or products offered by MH will be
            invoiced as indicated in the Order. Payments are to be made using
            available payment methods specified in the Customer’s MH account or
            as otherwise agreed upon in writing. MH may adjust Fees at the
            commencement of each Subscription renewal period. If a Customer
            disagrees with any Fee adjustments, they may choose not to renew
            their Subscription at that time. All Fees are non-refundable.
            <br />
            <br />
            Payment Processor: MH uses Stripe as a third-party payment processor
            for billing and other financial transactions. All payment activities
            are subject to Stripe’s terms and conditions in addition to this
            Agreement. MH is not responsible for any errors, or other acts or
            omissions of Stripe or any other payment processor.
            <br />
            <br />
            Delinquencies: If a Customer's payment is overdue by sixty (60) days
            or more, MH reserves the right to suspend or terminate access to
            their services, and take any other necessary actions, including
            deletion of the Customer's data.
            <br />
            <br />
            Changes to Orders: Any material changes to the Subscription,
            product, or service, including but not limited to changes in the
            type or duration of the Subscription or the number of users, may
            require the execution of a new Order with updated pricing and Fees.
            <br />
            <br />
            <b className=" font-areaNorm underline"> Taxes</b>
            <br />
            Exclusivity of Fees: The Fees mentioned in the Payments section do
            not include taxes. Customers are responsible for paying any sales,
            use, and other taxes arising from this Agreement, excluding taxes
            based on MH’s net income.
            <br />
            <br />
            Reimbursement: If MH is mandated by law to pay any such taxes, these
            will be itemized in an invoice to the Customer, who will then
            reimburse MH for these charges.
            <br />
            <br />
            <b className=" font-areaNorm underline"> Term and Termination </b>
            <br />
            <Box className="grid items-center gap-2  pl-4">
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  a.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className="underline">Term.</b> For Subscription holders,
                  these Terms of Use shall commence on the Effective Date and
                  will remain in effect for the duration of the chosen
                  Subscription Period. Unless either Party provides notice of
                  its intention not to renew at least thirty (30) days before
                  the end of the current Subscription Period, the Subscription
                  will automatically renew for subsequent, equivalent periods.
                  For general users of the Site, these Terms of Use remain in
                  effect as long as they access or use the Site.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  b.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className="underline"> Termination.</b>
                  <div
                    style={{ display: "flex", alignItems: "start" }}
                    className="pl-6"
                  >
                    i.{" "}
                    <Typography
                      variant="body2"
                      color="primary"
                      fontSize={{ xs: "14px", sm: "16px" }}
                      lineHeight={{ xs: "185%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      // className=" mx-auto text-start"
                    >
                      Termination For Cause. Either Party may terminate this
                      Agreement:
                    </Typography>
                  </div>
                  <Box className="pl-16">
                    <div style={{ display: "flex", alignItems: "start" }}>
                      1.{" "}
                      <Typography
                        variant="body2"
                        color="primary"
                        fontSize={{ xs: "14px", sm: "16px" }}
                        lineHeight={{ xs: "185%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        // className=" mx-auto text-start"
                      >
                        on thirty (30) days’ prior Notice if the other Party
                        materially breaches any of the terms of this Agreement
                        and such breach remains uncured at the end of those
                        thirty (30) days;
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      2.{" "}
                      <Typography
                        variant="body2"
                        color="primary"
                        fontSize={{ xs: "14px", sm: "16px" }}
                        lineHeight={{ xs: "185%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        // className=" mx-auto text-start"
                      >
                        immediately on Notice if:
                      </Typography>
                    </div>
                  </Box>
                  <Box className="pl-24">
                    <div style={{ display: "flex", alignItems: "start" }}>
                      a.{" "}
                      <Typography
                        variant="body2"
                        color="primary"
                        fontSize={{ xs: "14px", sm: "16px" }}
                        lineHeight={{ xs: "185%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        // className=" mx-auto text-start"
                      >
                        all or substantially all of the assets of the other
                        Party are transferred to an assignee for the benefit of
                        creditors, to a receiver or a trustee in bankruptcy;
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      b.{" "}
                      <Typography
                        variant="body2"
                        color="primary"
                        fontSize={{ xs: "14px", sm: "16px" }}
                        lineHeight={{ xs: "185%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        // className=" mx-auto text-start"
                      >
                        a proceeding is commenced by or against the other Party
                        for relief under bankruptcy or similar laws, and such
                        proceeding is not dismissed within thirty (30) days; or
                      </Typography>
                    </div>
                    <div style={{ display: "flex", alignItems: "start" }}>
                      c.{" "}
                      <Typography
                        variant="body2"
                        color="primary"
                        fontSize={{ xs: "14px", sm: "16px" }}
                        lineHeight={{ xs: "185%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        // className=" mx-auto text-start"
                      >
                        the other Party is adjudged bankrupt or insolvent.
                      </Typography>
                    </div>
                  </Box>
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  c.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className="underline">Termination for Convenience.</b> You
                  may terminate this Agreement for any reason by providing
                  notice through their account portal or by contacting their
                  account manager and completing a brief exit survey about your
                  experience. MH reserves the right to, in its sole discretion
                  and without notice or liability, deny access to and use of the
                  Site (including blocking certain IP addresses) for breach of
                  any representation, warranty, or covenant contained in these
                  Terms of Use or of any applicable law or regulation.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  d.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className="underline">Consequences of Termination.</b> If
                  MH terminates or suspends your account for any reason, you are
                  prohibited from registering and creating a new account under
                  your name, a fake or borrowed name, or the name of any third
                  party, even if you may be acting on behalf of the third party.
                  In addition to terminating or suspending your account, MH
                  reserves the right to take appropriate legal action, including
                  without limitation pursuing civil, criminal, and injunctive
                  redress.
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  e.
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  <b className="underline">Survival.</b> Upon termination or
                  expiration of this Agreement, any provision of this Agreement
                  that, by its nature or terms, should survive the termination
                  or expiration of this Agreement shall continue in full force
                  and effect, including but not limited to the following
                  provisions: intellectual property rights, indemnification,
                  limitation of liability, and dispute resolution.
                </Typography>
              </div>
            </Box>
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Disclaimer of Warranties{" "}
            </b>
            <br />
            <b className=" font-areaNorm">
              {" "}
              THE SITE, ITS CONTENT, THE SERVICES, AND ANY OTHER SERVICES OR
              ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS
              AVAILABLE" BASIS. NEITHER MH NOR ANY ASSOCIATED PERSON OR
              AFFILIATE OF MH MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
              TO THE FOLLOWING:
            </b>
            <br />
            <br />
            <b className=" font-areaNorm">
              <LegalNumbers
                bulletData={[
                  "THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE;",
                  "THAT THE SITE IS ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED;",
                  "THAT DEFECTS ON THE SITE WILL BE CORRECTED;",
                  "THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS;",
                  "THAT THE SITE, THE SERVICES, OR ANY OTHER SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL HELP YOU ACHIEVE CERTAIN RESULTS; OR",
                  "THAT THE SITE, THE SERVICES, OR ANY OTHER SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.",
                ]}
              />
            </b>
            <br />
            <br />
            <b className=" font-areaNorm ">
              TO THE FULLEST EXTENT PERMITTED BY LAW, MH HEREBY DISCLAIMS,
              WITHOUT LIMIT, FOR THE SITE AND THE SERVICES, ALL WARRANTIES OF
              ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
              INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY,
              NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
            </b>
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </b>
            <br />
            <br />
            <b className=" font-areaNorm underline">
              Limitation of Liability
            </b>{" "}
            <br /> MH shall not be liable for any claims, demands, actions,
            suits, obligations, losses, damages, liabilities, expenses or costs,
            arising out of or relating to the Site, the Services, or your use of
            either.
            <br />
            <br />
            In no event shall MH or its officers, employees, managers,
            directors, partners, members, agents, contractors, shareholders,
            affiliates, licensors, successors, or assigns have any liability to
            you for any direct, indirect, special, incidental, multiple,
            exemplary, punitive, or consequential damages, or for lost profits,
            loss of use, business interruption, costs of procurement of
            substitute goods or services, either in contract, tort or under any
            other theory of liability, whether or not the possibility of such
            damage has been advised to you.
            <br />
            <br />
            In no event shall MH’s maximum liability hereunder exceed the fees
            paid by you for the Services, whether in contract, tort or under any
            other theory of liability.
            <br />
            <br />
            No personal liability shall accrue hereunder against any individual,
            member, partner, officer, director, representative, employee,
            trustee, fiduciary, or principal (disclosed or undisclosed) of MH.
            <br />
            <br />
            Furthermore, we are not responsible for any of the actions or
            conduct of our users. This also includes what a user says online in
            a public forum (e.g., comments on a blog post or on social media).
            We are also not responsible for any actions or words stated both on
            or off the Site. We also claim no responsibility should a user
            decide to upload any inappropriate material or viruses, malware, or
            spyware.
            <br />
            <br />
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
            <br />
            <br />
            <b className=" font-areaNorm underline"> Indemnification </b>
            <br />
            Mutual Indemnity. Each party (the "Indemnifying Party") will: <br />{" "}
            <Box className="grid items-center gap-2  pl-4">
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  a.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  defend the other party, its affiliates, and its and their
                  respective officers, directors, employees, agents, successors,
                  and assigns from and against any and all third-party claims,
                  suits, actions, or proceedings (collectively, a “Claim”)
                  arising out of or resulting from:
                </Typography>
              </div>
              <Box className="grid items-center gap-2  pl-4">
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    {" "}
                    i.{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    any security incidents affecting the systems of the
                    Indemnifying Party or its vendors;
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    {" "}
                    ii.{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    the Indemnifying Party’s (or its personnel) gross negligence
                    or more culpable acts or omissions; or
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    {" "}
                    iii.{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    the Indemnifying Party’s breach of its representations,
                    warranties, covenants, or obligations under this Agreement;
                    and
                  </Typography>
                </div>
              </Box>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  b.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  indemnify and hold harmless the other party from and against
                  any and all losses, damages, liabilities, costs, and expenses,
                  including reasonable attorneys’ fees, incurred in connection
                  with such a Claim.
                </Typography>
              </div>
            </Box>
            <br />
            <br />
            Customer Indemnity. You the Customer will: <br />
            <Box className="grid items-center gap-2  pl-4">
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  a.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  defend MH, its affiliates, and its and their respective
                  officers, directors, employees, agents, successors, and
                  assigns from and against any Claims arising out of or
                  resulting from:
                </Typography>
              </div>
              <Box className="grid items-center gap-2  pl-4">
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    {" "}
                    i.{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    Customer's misuse of MH’s products or services;
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    {" "}
                    ii.{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    Customer's violation of applicable laws or regulations in
                    connection with the use of MH’s products or services; and
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    {" "}
                    iii.{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    fontSize={{ xs: "14px", sm: "16px" }}
                    lineHeight={{ xs: "185%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    // className=" mx-auto text-start"
                  >
                    any content, data, or information provided by the Customer
                    to MH that infringes on third-party rights; and
                  </Typography>
                </div>
              </Box>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  b.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  indemnify and hold harmless MH from and against any losses
                  incurred in connection with such a Claim.
                </Typography>
              </div>
            </Box>
            <br />
            Indemnity Procedures. The party seeking indemnification (the
            “Indemnified Party”) will: <br />
            <Box className="grid items-center gap-2  pl-4">
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  a.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  provide the Indemnifying Party prompt notice of any Claim,
                  although any delay in notification will not relieve the
                  Indemnifying Party of its obligations except to the extent it
                  is prejudiced by the delay;
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  b.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  permit the Indemnifying Party to assume and control the
                  defense and settlement of the Claim, provided that the
                  Indemnifying Party may not settle any Claim in a manner that
                  incurs cost or liability for the Indemnified Party without its
                  written consent; and
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "start" }}>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  {" "}
                  c.{" "}
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  fontSize={{ xs: "14px", sm: "16px" }}
                  lineHeight={{ xs: "185%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  // className=" mx-auto text-start"
                >
                  offer all reasonable assistance in the defense of such Claim
                  at the Indemnifying Party's expense. The Indemnified Party
                  retains the right to participate in the defense at its own
                  expense. If the Indemnifying Party does not assume control of
                  a Claim within thirty (30) days after notice, the Indemnified
                  Party may take control, and the Indemnifying Party will be
                  liable for all reasonable costs incurred by the Indemnified
                  Party in defending the Claim.
                </Typography>
              </div>
            </Box>
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Governing Law and Jurisdiction
            </b>{" "}
            <br /> These Terms and your use of the Site and the Services, and
            all matters arising out of or related to (directly or indirectly)
            the foregoing (including tort claims), are governed by Texas law,
            without giving effect to its principles of conflicts of law. Subject
            to the Dispute Resolution provision below, you expressly agree to be
            subject to the jurisdiction of the state and federal courts located
            in Harris County, Texas.
            <br />
            <br />
            <b className=" font-areaNorm underline"> Dispute Resolution</b>{" "}
            <br /> You understand and agree that prior to taking any legal
            action, you will first attempt to resolve any issues with the Site
            or the Services by contacting us via the channels listed below. If
            after thirty (30) days, the Parties are unable to resolve any issues
            through informal negotiations, any dispute then both parties agree
            to resolve the dispute through binding arbitration by JAMS, Inc.
            (“JAMS”) under JAMS’ Optional Expedited Arbitration Procedures. The
            arbitration will be conducted in Harris County, Texas unless both
            parties agree otherwise. Any judgment on the award rendered by the
            arbitrator may be entered in any court of competent jurisdiction.
            YOU UNDERSTAND AND AGREE THAT WITHOUT THIS PROVISION, YOU WOULD HAVE
            THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
            <br />
            <br />
            Notwithstanding the above, it is agreed by all parties that the
            following disputes are not subject to the above provisions
            concerning binding arbitration:
            <LegalBullets
              bulletData={[
                "any disputes seeking to enforce or protect, or concerning the validity of,any of the intellectual property rights of a party; ",
                "any dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and",
                "any claim for injunctive relief.",
              ]}
            />
            <br />
            <br />
            If this provision is found to be illegal or unenforceable, then
            neither Party will elect to arbitrate any dispute falling within
            that portion of this provision found to be illegal or unenforceable.
            Such a dispute shall be decided by a court of competent jurisdiction
            within Harris County, Texas, and the Parties agree to submit to the
            personal jurisdiction of that court.
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Compliance with Laws
            </b>{" "}
            <br /> You acknowledge, consent, and agree that MH may access,
            preserve, and disclose your information and/or any User Content you
            submit or make available for inclusion on the Services, if required
            to do so by law or in a good faith belief that such access,
            preservation, or disclosure aligns with MH’s Privacy Policy or is
            reasonably necessary for any of the following reasons:
            <br />
            <br />
            To comply with legal obligations or processes; To uphold these
            Terms, our <b className=" font-areaNorm "> Privacy Policy,</b> or
            other agreements with you, including the investigation of potential
            violations thereof; To address claims that any content infringes the
            rights of third parties; To attend to your requests for customer
            support; To protect the rights, property, or personal safety of MH,
            its employees, its users, and the general public.
            <br />
            <br />
            This might include sharing information with other entities for fraud
            detection, spam/malware prevention, and similar security purposes.
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Consent to Electronic Communication
            </b>{" "}
            creating an account with MH and using or subscribing to our
            services, you hereby consent to receiving electronic communications,
            including but not limited to emails, text messages, and platform
            notifications, from MH. These communications may include notices
            about your account (e.g., payment authorizations, password changes,
            and other transactional information) and are part of your
            relationship with MH. You agree that any notices, agreements,
            disclosures, or other communications that we send to you
            electronically will satisfy any legal communication requirements,
            including that such communications be in writing. You should
            maintain copies of electronic communications by printing a paper
            copy or saving an electronic copy.
            <br />
            You also consent to receiving certain other communications from us,
            such as newsletters about new MH features and content, special
            offers, promotional announcements, and customer surveys via email or
            other methods. If you no longer want to receive certain
            non-transactional communications, simply follow the “unsubscribe”
            link in any email communication from MH.
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Respect for Privacy
            </b>{" "}
            <br />
            MH values your privacy. To understand how we collect, utilize, and
            safeguard the data you provide or we gather, please refer to our{" "}
            <b className=" font-areaNorm ">Privacy Policy,</b> which is an
            integral part of these Terms.
            <br />
            <br />
            <b className=" font-areaNorm underline"> Entire Agreement</b> These
            Terms, together with our Privacy Policy (collectively, the{" "}
            <b className=" font-areaNorm underline">“Company Policies”</b>),
            contain the entire agreement between you and MH with regard to the
            Site and the Services. The Company Policies supersede any prior
            written or oral agreements between you and MH.
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Severability{" "}
            </b> <br /> If any provision of these Terms shall be held to be
            invalid or unenforceable for any reason, the remaining provisions
            shall continue to be valid and enforceable. If a court finds that
            any provision of these Terms is invalid or unenforceable, but that
            by limiting such provision it would become valid and enforceable,
            then such provision shall be deemed to be written, construed, and
            enforced as so limited.
            <br />
            <br />
            <b className=" font-areaNorm underline">Notice </b> <br /> MH may
            give notice by means of a general notice on the MH Platform,
            electronic mail to the email address associated with your account,
            or text message to any phone number provided in connection with your
            account. For notices made by email or text message, the date of
            receipt will be deemed the date on which such notice is transmitted.
            <br />
            <br />
            <b className=" font-areaNorm underline">
              Changes and Updates{" "}
            </b>{" "}
            <br /> We reserve the right to make changes and update these Terms
            as needed. We will notify you of any material changes via email or
            by posting a notice on the Site. We advise you to review our
            policies from time to time as your continued use of the Site and/or
            Services will constitute acknowledgement and acceptance of these
            policies.
            <br />
            <br />
            <b className=" font-areaNorm underline">
              {" "}
              Contact Information{" "}
            </b>{" "}
            <br />
            While we provide you with these Terms, we will do our best to ensure
            that you better understand them so you can use our website legally
            and lawfully. We welcome your questions, comments or concerns
            regarding these Terms. To convey any of the foregoing to us, you can
            contact:
            <br />
            <br />
            <b className=" font-areaNorm "> Blessing Adesiyan, CEO,</b> <br />
            <a
              href="mailto:legal@mhworklife.com"
              // target="_blank"
              className="underline font-areaNorm"
            >
              legal@mhworklife.com
            </a>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BodyCard;
