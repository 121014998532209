import React from 'react';
import { AUTH_LOCATION } from '../store/context/auth-context';

/**
 * react hook to be attached to auth-context to log user out of open tabs when logout event is triggered
 * @returns
 */
const useLogoutTabs = (logoutHandler: () => void) => {
  const handleLogoutTabs = React.useCallback((event: StorageEvent) => {
    if (!event) {
      return;
    }
    if (event.key === AUTH_LOCATION && event.oldValue && !event.newValue) {
      logoutHandler();
    }
  }, [logoutHandler]);

  React.useEffect(() => {
    window.addEventListener('storage', handleLogoutTabs);

    return () => {
      window.removeEventListener('storage', handleLogoutTabs);
    };
  }, [handleLogoutTabs]);
};

export default useLogoutTabs;
