import React, { useEffect, useState } from "react";
import YellowSlider from "../components/Layout/YellowSlider";
import { SummitSlideData } from "../utils/landing-data";
import MHFooter from "../components/Layout/MHFooter";
import SummitContext from "../store/context/summit-context";
import useTitle from "../hooks/use-title";
import useAnalytics from "../hooks/use-analytics";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import PastSummitHeader from "../components/PastSummit/PastSummitHeader";
import PastSummitGallery from "../components/PastSummit/PastSummitGallery";
import { useParams } from "react-router-dom";
import InflutientialSpeakers from "../components/Story/InflutientialSpeakers";
import Clients from "../components/BrandPartnership/Clients";
import StickySponsor from "../components/PastSummit/StickySponsor";

type Props = {
  title?: string | any;
  date?: string | any;
  caption?: string | any;
  venue?: string | any;
  slug?: string | any;
  link?: string | any;
  imgSrc?: string | any;
  imgSrcMob?: string | any;
  imageList?: string[] | any;
  createdAt?: string | any;
};

const defaultSummitData: Props = {
  title: "",
  date: "",
  caption: "",
  slug: "",
  imgSrc: "",
  imgSrcMob: "",
  imageList: [],
  createdAt: "",
  // Set default values for other properties
};

const PastSummit = (props: Props) => {
  useTitle("Past Summit");

  useAnalytics({
    pathname: window.location.pathname,
    pageTitle: "Past Summit",
  });

  const params = useParams<any>();
  const [summitData, setSummitData] = useState<Props>(defaultSummitData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = collection(db, "mh-past-summit");
        const querySnapshot = await getDocs(
          query(collectionRef, where("slug", "==", params.slug))
        );

        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data() as Props;
          setSummitData(data);
          console.log(data, "Data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [summitData, params.slug]);

  return (
    <React.Fragment>
      <SummitContext>
        <PastSummitHeader
          title={summitData.title}
          date={summitData.date}
          venue={summitData.venue}
          link={summitData.link}
          caption={summitData.caption}
          imgSrc={summitData.imgSrc}
          imgSrcMob={summitData.imgSrcMob}
        />

        <Clients headerText="Past Sponsors" />

        <PastSummitGallery imageList={summitData.imageList} />
        <InflutientialSpeakers />
        <YellowSlider data={SummitSlideData} />

        <MHFooter />
        <StickySponsor />
      </SummitContext>
    </React.Fragment>
  );
};

export default PastSummit;
