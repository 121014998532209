import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type Props = {
  text?: string;
  author?: string;
};

const YellowCard = (props: Props) => {
  return (
    <React.Fragment>
      <Stack
        className="summit-stats-component h-[600px] lg:h-[450px] "
        justifyContent="center"
        alignItems="center"
        sx={{
          "& svg": {
            color: "common.white",
          },
          "& button:hover svg": {
            color: "primary.main",
          },
        }}
      >
        <Typography
          variant="body1"
          fontSize={{ xs: "16px", sm: "18px" }}
          fontWeight={700}
          lineHeight={{ xs: "200%", sm: "169%" }}
          letterSpacing={"0.02em"}
          className="text-center w-[77%] lg:w-[65%] mx-auto"
        >
          {props.text}
        </Typography>

        <Typography
          variant="caption"
          fontSize={{ xs: "12px", sm: "12px" }}
          fontWeight={900}
          lineHeight={{ xs: "20px", sm: "12px" }}
          letterSpacing={"0.1em"}
          className="py-8 uppercase text-center w-[65%]"
        >
          {props.author}
        </Typography>
      </Stack>
    </React.Fragment>
  );
};

export default YellowCard;
