import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Fragment } from "react";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { ReactComponent as ResArticleIcon } from "../../static/svg/resart.svg";
import { ReactComponent as LeftBtn } from "../../static/svg/left-btn.svg";
import { ReactComponent as RightBtn } from "../../static/svg/right-btn.svg";
import ResArtCard from "./ResArtCard";
import ResCard from "../Resources/SubComponents/ResCard";
import { ItemList, ResourceItemType } from "../../models/resources.interface";
import { HttpResponse } from "../../models/api.interface";
import { checkInvalidArray } from "../../utils/utils";

type ResProps = {
  thumbNailImageSrc?: string;
  itemList?: string[];
  title?: string;
  author?: string;
  catchPhrase?: string;
  categ?: string;
  id?: number;
  slug?: string;
  createdAt?: string;
  updatedAt?: string;
};

type ArrowProps = {
  onClick?: (e: React.MouseEvent) => void;
};

function SampleNextArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 lg:-right-8 md:-right-6">
      <RightBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

function SamplePrevArrow(props: ArrowProps) {
  return (
    <Box className="absolute top-[30%] z-10 -left-8">
      <LeftBtn className="cursor-pointer" onClick={props.onClick} />
    </Box>
  );
}

const LandingArticle = (props: ResProps) => {
  const location = useLocation();
  const [resources, setResources] = useState<ResourceItemType[]>([]);
  const [noOfElement, setnoOfElement] = useState(4);

  var resUrl = `${process.env.REACT_APP_RES_ARTICLE_URL}`;

  let history = useHistory();

  const { path } = useRouteMatch();

  const handleClickOpen = () => {
    history.push(`${path}/articles`);
  };

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settingsTab = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const settingsMobile = {
    centerMode: true,
    centerPadding: "40px",
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    // autoplaySpeed: 1400,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  const getResource = async () => {
    try {
      const response = await fetch(resUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let jsonData: HttpResponse<ResourceItemType[]> = await response.json();

      const formattedResources = checkInvalidArray(jsonData.data)
        ? jsonData.data.map((dataItem) => {
            const priorityList: string[] = dataItem.priorities.split(",");
            let newItemList: ItemList[] = [];

            for (const item of priorityList) {
              const newObject = dataItem.itemList.find(
                (it: any) => it.id === +item
              );

              if (newObject) {
                if (!newItemList.length) {
                  newItemList = [newObject];
                } else {
                  newItemList = [...newItemList, newObject];
                }
              }
            }

            return {
              ...dataItem,
              newItemList,
            };
          })
        : [];

      setResources(formattedResources);
      // console.log(resources);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getResource();
  }, []);

  return (
    <Fragment>
      <Box className=" md:py-12 md:px-20 pb-10 pt-16 bg-white">
        <Box className="mx-auto overscroll-x-hidden flex" sx={{}}>
          <Typography
            className="mx-auto md:mx-0 md:text-left gap-2 flex"
            variant="body2"
            color="primary"
            fontWeight="900"
            fontSize="12px"
            fontFamily="Area-Extended"
            textAlign="center"
            textTransform="uppercase"
          >
            <ResArticleIcon className="-mt-2" height="24px" width="25px" />
            Articles
          </Typography>
        </Box>
        <Typography
          variant="h3"
          className="md:text-left w-[380px] md:w-fit md:text-[32px] mx-auto md:mx-0 text-center text-[30px] my-2 px-12 leading-[120.5%] tracking-[-0.04em]"
          color="primary"
          sx={{
            fontWeight: 500,
            fontFamily: "Columbia-Sans",
          }}
        >
          Over 456+ Articles On All Things Caregiving
        </Typography>

        {/* Web */}
        <Box className="mx-auto pt-12 ml-9 hidden md:hidden lg:block bg-white relative">
          <Grid container spacing={6}>
            <Box className="w-[98%] bg-white mx-auto ">
              <Slider {...settings}>
                {resources.map((res, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    key={index}
                    className=" ml-1"
                  >
                    <ResArtCard
                      cardClass="relative w-[305px] containered my-6 h-[500px] shadow-none object-cover bg-cream-100 rounded-md"
                      iconClass="hidden"
                      imgBg="bg-navy-100 h-[305px] imageArt"
                      bodyBg="bg-cream-100"
                      podTop="hidden"
                      imageSrc={res.thumbNailImageSrc}
                      top={
                        checkInvalidArray(res.newItemList).length
                          ? res.newItemList[0].interestName
                          : ""
                      }
                      title={res.title}
                      text={res.catchPhrase}
                      // category={res.itemList ? res.itemList[0].interestName : ""}
                      // categoryTwo={res.itemList ? res.itemList[1] : ""}
                      category={res.newItemList}
                      titleUrl={`/resources/articles/${res.slug}/${res.id}`}
                      playUrl={`/resources/articles/${res.slug}/${res.id}`}
                    />
                  </Grid>
                ))}
              </Slider>
            </Box>
          </Grid>
        </Box>
        {/* Tab */}
        <Box className="mx-auto pt-12 ml-9 hidden md:block lg:hidden bg-white relative">
          <Grid container spacing={6}>
            <Box className="w-[98%] bg-white mx-auto ">
              <Slider {...settingsTab}>
                {resources.map((res, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    key={index}
                    className=" ml-1"
                  >
                    <ResCard
                      cardClass="relative w-[286px] cardozoLandingW landingArtH my-6 h-[470px] shadow-none object-cover bg-cream-100 rounded-md"
                      iconClass="hidden"
                      imgBg="bg-navy-100 h-[286px] cardozoLandingH"
                      bodyBg="bg-cream-100"
                      podTop="hidden"
                      imageSrc={res.thumbNailImageSrc}
                      top={
                        checkInvalidArray(res.newItemList).length
                          ? res.newItemList[0].interestName
                          : ""
                      }
                      title={res.title}
                      text={res.catchPhrase}
                      // category={res.itemList ? res.itemList[0].interestName : ""}
                      // categoryTwo={res.itemList ? res.itemList[1] : ""}
                      category={res.newItemList}
                      titleUrl={`/resources/articles/${res.slug}/${res.id}`}
                      playUrl={`/resources/articles/${res.slug}/${res.id}`}
                    />
                  </Grid>
                ))}
              </Slider>
            </Box>
          </Grid>
        </Box>
        {/* Mobile */}
        <Box className="mx-auto pt-20 overflow-hidden block md:hidden lg:hidden bg-white relative">
          <Grid container spacing={2}>
            <Box className="w-[98%] bg-white mx-auto ">
              <Slider {...settingsMobile}>
                {resources.map((res, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    key={index}
                    className="-ml-3"
                  >
                    <ResCard
                      cardClass="relative w-[286px] my-6 h-[470px] shadow-none object-cover bg-cream-100 rounded-md"
                      iconClass="hidden"
                      imgBg="bg-navy-100 h-[286px]"
                      bodyBg="bg-cream-100"
                      podTop="hidden"
                      imageSrc={res.thumbNailImageSrc}
                      top={
                        checkInvalidArray(res.newItemList).length
                          ? res.newItemList[0].interestName
                          : ""
                      }
                      title={res.title}
                      text={res.catchPhrase}
                      // category={res.itemList ? res.itemList[0].interestName : ""}
                      // categoryTwo={res.itemList ? res.itemList[1] : ""}
                      category={res.newItemList}
                      titleUrl={`/resources/articles/${res.slug}/${res.id}`}
                      playUrl={`/resources/articles/${res.slug}/${res.id}`}
                    />
                  </Grid>
                ))}
              </Slider>
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box className="bg-gray-300 h-[1px] w-[91.5%] -mt-10 mx-auto"></Box>
    </Fragment>
  );
};

export default LandingArticle;
