import React from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography, { typographyClasses } from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';

import IconButtonStyled from '../../Common/Button/IconButtonStyled';
import StackedIconWithText from '../../UI/StackedIconWithText';
import CenteredFlexContainer from '../../UI/CenteredFlexContainer';
import MHLink from '../../Common/Link/MHLink';
import MHButton from '../../Common/Button/MHButton';

import MHLogo from '../../../theme/icons/MHLogo';
import { ReactComponent as CloseIcon } from '../../../static/svg/close.svg';
import { ReactComponent as PlusMdIcon } from '../../../static/svg/plus-md.svg';
import { formatAmount } from '../../../utils/utils';
import CartContext, { Cart } from '../../../store/context/cart-context';
import MembershipContext from '../../../store/context/membership.context';
import { MembershipPlanType } from '../../../models/membership.type';
import AbsolutePositionedContainer from '../../UI/AbsolutePositionedContainer';
import RoundedLogoIcon from '../../../theme/icons/RoundedLogo';
import { retrievePriceId } from '../../../utils/stripe';

export const CartItemBillingPeriod = ({ cartItem }: { cartItem: Cart }) => {
  return (
    // {cartItem.period}
    <Typography
      component="span"
      variant="body2"
      color="#194049"
      fontSize="11px"
      lineHeight="160.5%"
      letterSpacing="0.04em">
      month billed {cartItem.period + 'ly'}
    </Typography>
  );
};

const CartDrawerContent = () => {
  const history = useHistory();

  const membershipCtx = React.useContext(MembershipContext);
  const { membershipPlans } = membershipCtx;

  const cartCtx = React.useContext(CartContext);
  const { cart, addItemToCart, removeItemFromCart, closeCartDrawer } = cartCtx;

  const [upsellIndex, setUpsellIndex] = React.useState<number>(-1);

  React.useEffect(() => {
    if (!cart.length) {
      return;
    }

    const [CART] = cart;
    const MEMBERSHIP_INDEX = membershipPlans.findIndex(
      (plan) => plan.id === CART.id
    );

    if (MEMBERSHIP_INDEX === -1) {
      return;
    }

    setUpsellIndex(MEMBERSHIP_INDEX + 1);
  }, [membershipPlans, cart]);

  const purchaseItemHandler = (
    e: React.MouseEvent<Element, MouseEvent>,
    membershipPlan: MembershipPlanType
  ) => {
    if (membershipPlan.price === Infinity) {
      return;
    }

    const CART: Cart = {
      id: membershipPlan.id,
      type: 'membership',
      amount: membershipPlan.basePrice,
      yearlyCharge: membershipPlan.yearlyPrice,
      description: membershipPlan.name,
      priceId: retrievePriceId(membershipPlan),
      period: membershipPlan.billingPeriod
    };
    addItemToCart(CART)(true, e);
  };

  const submitCartHandler = (e: React.KeyboardEvent | React.MouseEvent) => {
    history.push('/cart');
    closeCartDrawer(e);
  };

  const theme = useTheme();
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack height={'100vh'} p={3} width="100%">
      <Box height={'calc(100% - 190px)'} width="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2.5}
          height={70}
          borderBottom={1}
          borderColor="#F3F4F6"
          mb={3}>
          <Typography
            variant="caption"
            fontSize="18.2837px"
            lineHeight="189%"
            letterSpacing="0.1em">
            CART
          </Typography>

          <IconButtonStyled onClick={closeCartDrawer}>
            <CloseIcon />
          </IconButtonStyled>
        </Stack>
        {/* cart item -> StackedIconWithText */}
        {cart && cart.length ? (
          cart.map((item) => (
            <StackedIconWithText>
              <Avatar
                variant="rounded"
                alt="consult"
                sx={{
                  bgcolor: '#FFFC9B',
                  width: 100,
                  height: 100,
                  mr: 1,
                  borderRadius: '10.6px',
                  '& img': {
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    objectPosition: 'center'
                  }
                }}>
                <MHLogo />
              </Avatar>

              <Stack
                direction="row"
                justifyContent="space-between"
                flexGrow={1}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="#194049"
                    fontSize="12px"
                    lineHeight="102%"
                    letterSpacing="0.05em"
                    mb={0.4}>
                    {item.description}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="#194049"
                    fontSize="11px"
                    lineHeight="150%"
                    letterSpacing="0.115em"
                    textTransform="uppercase"
                    my={0.75}>
                    {item.type}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#194049"
                    fontSize="11px"
                    lineHeight="160.5%"
                    letterSpacing="0.04em"
                    mb={1}>
                    {formatAmount(item.amount)}/
                    <CartItemBillingPeriod cartItem={item} />
                  </Typography>

                  <MHLink
                    type="default"
                    sx={{
                      letterSpacing: '0.05em',
                      lineHeight: '102%',
                      fontSize: '12px',
                      opacity: 0.6
                    }}
                    onClick={() => removeItemFromCart(item.id)}>
                    Remove
                  </MHLink>

                  {/* <Typography
                        variant="body2"
                        color="#194049"
                        fontSize="12px"
                        lineHeight="102%"
                        letterSpacing="0.05em"
                        sx={{
                          textDecoration: 'underline',
                          opacity: 0.6
                        }}>
                        Remove
                      </Typography> */}
                </Box>

                <Typography
                  variant="subtitle1"
                  fontSize="12px"
                  lineHeight="102%"
                  letterSpacing="0.05em">
                  {formatAmount(
                    item.period === 'month' ? item.amount : item.yearlyCharge
                  )}
                </Typography>
              </Stack>
            </StackedIconWithText>
          ))
        ) : (
          <CenteredFlexContainer height={200}>
            <Typography
              variant="body1"
              color="#194049"
              fontSize="12px"
              lineHeight="102%"
              letterSpacing="0.05em"
              align="center"
              mb={0.4}>
              No items in cart
            </Typography>
          </CenteredFlexContainer>
        )}

        <Divider
          light
          sx={{
            my: 4
          }}
        />

        {membershipPlans[upsellIndex]?.price !== Infinity && (
          <React.Fragment>
            <CenteredFlexContainer mb={2}>
              <Typography
                variant="caption"
                color="#194049"
                fontSize="11px"
                lineHeight="150%"
                letterSpacing="0.115em"
                textAlign="center"
                mx="auto"
                align="center">
                Upgrade your plan today!
              </Typography>
            </CenteredFlexContainer>

            <StackedIconWithText
              p={2}
              borderRadius={'10px'}
              bgcolor="#F3F4F6"
              position="relative">
              <Avatar
                variant="rounded"
                alt="consult"
                sx={{
                  bgcolor: '#F8F246',
                  width: 100,
                  height: 100,
                  mr: 1,
                  borderRadius: '10.6px',
                  '& img': {
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    objectPosition: 'center'
                  }
                }}>
                <MHLogo />
              </Avatar>
              <Stack
                direction="row"
                justifyContent="space-between"
                flexGrow={1}>
                <Box width="250px">
                  <Typography
                    variant="subtitle2"
                    color="#194049"
                    fontSize="12px"
                    lineHeight="102%"
                    letterSpacing="0.05em"
                    mb={0.4}>
                    {upsellIndex > -1 ? membershipPlans[upsellIndex].name : ''}
                  </Typography>
                  {upsellIndex > -1 ? (
                    <Typography
                      variant="subtitle1"
                      color="#194049"
                      fontSize="11px"
                      lineHeight="150%"
                      letterSpacing="0.115em"
                      textTransform="uppercase"
                      my={0.75}>
                      {formatAmount(
                        Number(membershipPlans[upsellIndex]?.yearlyPrice / 12)
                      )}
                      /month billed yearly
                    </Typography>
                  ) : null}
                  <Typography
                    variant="body2"
                    component="p"
                    color="#194049"
                    fontSize="11px"
                    lineHeight="160.5%"
                    letterSpacing="0.04em"
                    mb={1}>
                    Upgrade for 1 year
                  </Typography>
                </Box>
                {!matchesMdDown && (
                  <Typography
                    variant="subtitle1"
                    fontSize="12px"
                    lineHeight="102%"
                    letterSpacing="0.05em">
                    Upgrade
                  </Typography>
                )}
              </Stack>
              <AbsolutePositionedContainer bottom={15} right={15} defaultPos>
                <IconButtonStyled
                  aria-label="close"
                  onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                    purchaseItemHandler(e, membershipPlans[upsellIndex])
                  }>
                  <RoundedLogoIcon
                    sx={{
                      bgcolor: '#ffffff',
                      boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.10)'
                    }}>
                    <PlusMdIcon width="1rem" title="addtocart" />
                  </RoundedLogoIcon>
                </IconButtonStyled>
              </AbsolutePositionedContainer>
            </StackedIconWithText>
          </React.Fragment>
        )}
      </Box>

      {cart.length
        ? cart.map((item) => (
            <Box height="90px">
              <Divider
                light
                sx={{
                  my: 4
                }}
              />
              <Grid container mb={1.5}>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    align="left"
                    fontSize="20px"
                    lineHeight="140%">
                    {'Subtotal'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="primary.main"
                    fontSize="20px"
                    lineHeight="140%"
                    letterSpacing="0.05em"
                    align="right">
                    {formatAmount(
                      item.period === 'month' ? item.amount : item.yearlyCharge
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <MHButton
                type="submit"
                form="cart-form"
                onClick={(e) => submitCartHandler(e)}
                fullWidth>
                Please complete details
              </MHButton>{' '}
              <Typography
                variant="body1"
                color="primary.main"
                fontSize="11px"
                lineHeight="160.5%"
                letterSpacing="0.04em"
                textAlign="center"
                mt={3}>
                Shipping & taxes calculated at Checkout
              </Typography>
            </Box>
          ))
        : null}
    </Stack>
  );
};

export default CartDrawerContent;
