import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const MarqueeItemAlt = styled('li')<{ reducedOpacity?: boolean }>(
  ({ theme, reducedOpacity }) => ({
    listStyle: 'none',
    opacity: reducedOpacity ? 0.4 : 1
  })
);

const MarqueeHorizontal = (props: {
  children: React.ReactNode;
  continuous: boolean;
  reverse?: boolean;
}) => {
  return (
    <Box
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden'
        // my: 10,
      }}
      className="marquee">
      <Stack
        component="ul"
        className={
          !props.reverse ? 'marquee__content' : 'marquee__content-reverse'
        }
        direction="row"
        spacing={5}
        justifyContent="space-around"
        alignItems="center">
        {props.children}
      </Stack>

      <Stack
        component="ul"
        className={
          !props.reverse ? 'marquee__content' : 'marquee__content-reverse'
        }
        direction="row"
        spacing={5}
        justifyContent="space-around"
        alignItems="center"
        aria-hidden="true">
        {props.children}
      </Stack>
    </Box>
  );
};

export default MarqueeHorizontal;
