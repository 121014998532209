import React from 'react';
import AuthContext from '../store/context/auth-context';
import { Token } from '../models/user.model';
import { HttpStatusCode } from '../models/http-status-codes';

const useInterceptor = () => {
  const { refreshTokenPair, token } = React.useContext(AuthContext);

  React.useEffect(() => {
    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
      let [resource, config] = args;
      let modifiedConfig: RequestInit & { sent: boolean } = {
        ...config,
        sent: false
      };
      // request interceptor here
      let response = await originalFetch(resource, config);
      //response interceptor here
      //   console.log(resource);
      //   console.log(config);
      //   console.log(response);

      const handleRequestResend = async function (token: Token) {
        modifiedConfig.headers = {
          ...modifiedConfig.headers,
          'Authorization': `Bearer ${token.accessToken}`
        };
        response = await originalFetch(resource, modifiedConfig);
      };

      if (
        response.status === HttpStatusCode.Forbidden &&
        !modifiedConfig.sent
      ) {
        modifiedConfig.sent = true;
        refreshTokenPair(String(token?.refreshToken), handleRequestResend);
      }

      if (response.status !== HttpStatusCode.Ok) {
        // no longer needed
        // return Promise.reject(response);
      }

      return response;
    };

    return () => {
      const { fetch: originalFetch } = window;

      window.fetch = async (...args) => {
        let [resource, config] = args;
        const response = await originalFetch(resource, config);
        return response;
      };
    };
  }, [refreshTokenPair, token?.refreshToken]);
};

export default useInterceptor;
