import React from 'react';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, {
  formControlLabelClasses
} from '@mui/material/FormControlLabel';
import { typographyClasses } from '@mui/material/Typography';
import { styled, SxProps, Theme } from '@mui/material/styles';

// import { ReactComponent as CheckboxBlankIcon } from '../../../static/svg/checkbox-blank.svg';
// import { ReactComponent as CheckboxFilledIcon } from '../../../static/svg/checkbox-filled.svg';

const CheckboxIcon = styled(Box)(({ theme }) => ({
  border: '1px solid #194049',
  borderRadius: 6,
  width: 18,
  height: 18
}));

const MHCheckbox = styled(
  ({
    label,
    disableTypography,
    containerSx,
    ...props
  }: CheckboxProps & {
    label?: string;
    disableTypography?: boolean;
    containerSx?: SxProps<Theme>;
  }) => (
    <FormControlLabel
      label={label || ''}
      control={
        <Checkbox
          icon={<CheckboxIcon />}
          checkedIcon={<CheckboxIcon bgcolor="primary.main" />}
          checked={props.checked}
          {...props}
          sx={{
            paddingY: 0,
          }}
          disableRipple></Checkbox>
      }
      value={props.value}
      disableTypography={disableTypography}
      sx={{
        alignItems: 'flex-start',
        [`& .${typographyClasses.root}`]: {
          fontSize: { xs: '10px', sm: '10px', md: '11px' },
          lineHeight: { xs: '160.5%', sm: '160.5%' },
          letterSpacing: { xs: '0.04em', sm: '0.04em' },
          opacity: 0.6
        },
        [`&.${formControlLabelClasses.root}`]: {
          ...containerSx
        }
      }}
      className={clsx(props.className, props.required ? 'required' : '')}
    />
  )
)(({ theme }) => ({
  // the styles in this styled function call do not get applied, only the sx gets applied cos the returned component is in JSX
  fontSize: '18px',
  lineHeight: '160.5%',
  letterSpacing: '0.04em',
  [`& .${typographyClasses.root}`]: {
    // the styles here in this nested obj do get applied
    // color: 'red !important',
    opacity: 0.5
  }
}));

export default MHCheckbox;
